import React from "react";
import FormBanner from "./components/formBanner";
import BannerCreatorProvider from "./provider";

const WebsiteBannerCreator = () => {
  return (
    <BannerCreatorProvider>
      <FormBanner />
    </BannerCreatorProvider>
  );
};

export default WebsiteBannerCreator;
