import {useSideBar} from "../AdminLayout";
import {FaBars, FaUserAlt} from "react-icons/fa";
import {Col, Navbar, Row} from "react-bootstrap";
import React from "react";
import {useAuth} from "../Auth/auth";

interface NavType {
  title?: string;
  hideToggle?: boolean;
}

const Nav = (props: NavType) => {

  const sideBar = useSideBar();
  const auth = useAuth();

  return (
    <>
      <div className={"header-navbar-shadow"}/>
      <small style={{fontSize: '0.4rem', color: '#d6d6d6'}} className={'position-absolute end-0'}>Tech Brand © 2022</small>
      <Navbar className={"container"} >
        <div className={'container shadow-card'}>
          <div className={'d-flex'}>
            {
              !props.hideToggle &&
              <div className="btn-toggle" onClick={sideBar.onToggleClicked}>
                <FaBars />
              </div>
            }
            <h1 className={'h3 content-header-title mt-1'}>
              {props.title}
            </h1>
          </div>
          <Row className={'me-0 align-items-center'}>
            <Col className={'text-end'}>
              <div className={"username"}>
                {auth.user?.username ?? ''}
              </div>
              <div className={"username-subline font-weight-lighter"}>
                {auth.user?.phone ?? ''}
              </div>
            </Col>
            <div className={'avatar col-auto'}>
              <FaUserAlt size={14} color={'#dadada'}/>
            </div>
          </Row>
        </div>
      </Navbar>
    </>
  )
}

export default Nav;