import React from 'react';
import Views from "./components/views";
import UsersProvider from './provider';

const Users = () => {

  return (
    <UsersProvider>
      <Views/>
    </UsersProvider>
  );
};

export default Users;