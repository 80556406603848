import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { chartUtils } from "../../../util/chart_util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface ClosedLogItemType {
  log: any,
  index: number,
  page: number
}

const ClosedLogsItem = (props: ClosedLogItemType) => {
  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        {props.log.getIn(['foodApp'])}
      </td>
      <td>
        {props.log.getIn(['name'])}
      </td>
      <td style={{width: '50%'}}>
        <div style={{height: 56, width: '100%', position: 'relative'}}>
          <Line 
            options={chartUtils.timeHitLineOptions} 
            data={{
              labels: props.log.getIn(['chart', 'labels']).toJS(),
              datasets: [
              {
                label: '2',
                data: props.log.getIn(['chart', 'data']).map((d: any) => d.get('data')).toJS(),
                borderColor: 'rgb(75, 192, 192)',
                backgroundColor: 'rgb(75, 192, 192)',
                hoverBackgroundColor: '#ffc107',
                hoverBorderColor: '#ffc107',
                pointHoverBorderWidth: 0,
                borderWidth: 2,
                tension: 1,
                fill: false,
                pointRadius: 3,
                showLine: true,
                pointHoverRadius: 5
              },
              ]
            }} />
        </div>
      </td>
    </tr>
  )
}

export default ClosedLogsItem;