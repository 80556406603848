import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useWebsiteMenuItemCategories} from "../provider";
import MenuItemCategory from "./menu_item_category";

const SectionMenuItemCategories = () => {
  const useMenuItemCategoriesProvider = useWebsiteMenuItemCategories();

  if (useMenuItemCategoriesProvider.data.size < 1 && useMenuItemCategoriesProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Danh mục</th>
          <th>Nguồn đơn</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          useMenuItemCategoriesProvider.data.map((item: any, index: number) => {
            return <MenuItemCategory
              key={`menu_item_category_${item.get('_id')}`}
              index={index}
              page={useMenuItemCategoriesProvider.queries.get('page')}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!useMenuItemCategoriesProvider.data && useMenuItemCategoriesProvider.data.size >= 20) ||
          useMenuItemCategoriesProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={useMenuItemCategoriesProvider.queries.get('page')}
          onPreviousPage={useMenuItemCategoriesProvider.onPreviousPage}
          onNextPage={useMenuItemCategoriesProvider.onNextPage}
          onFirstPage={useMenuItemCategoriesProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionMenuItemCategories;