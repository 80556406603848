import React from "react";

interface StorePickerItemType {
  item: any,
  index: number,
  onChanged: (item: any) => void
}

const StorePickerItem = (props: StorePickerItemType) => {

  const _onUpdateStatusChanged = () => {
    props.onChanged(props.item)
  }

  return (
    <tr>
      <td>
        {props.index + 1}
      </td>
      <td>
        {props.item.getIn(['name'])}
      </td>
      <td>
        {props.item.getIn(['address'])}
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={_onUpdateStatusChanged}
            checked={!!props.item.get('selected')}/>
        </div>
      </td>
    </tr>
  )
}

export default StorePickerItem;