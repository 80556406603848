import {AxiosResponse} from "axios";
import {_get, _post} from "./request";

export const BeFoodApi = {
  updateAccount(store: string, username: string, password: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/befood/account`, {
      username,
      password
    })
  },
  fetchMerchants(store: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/befood/restaurants`, {})
  },
  updateMerchant(store: string, merchant: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/befood/restaurants`, merchant)
  },
}