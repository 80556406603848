import React from 'react'
import Views from "./components/views";
import WebsitePromotionProvider from "./provider";

const WebsitePromotions = () => {
  return (
    <WebsitePromotionProvider>
      <Views/>
    </WebsitePromotionProvider>
  )
}

export default WebsitePromotions
