import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useWebsiteBanners} from "../provider";
import BannerItem from "./banner_item";

const SectionBanners = () => {
  const bannersProvider = useWebsiteBanners();

  if (bannersProvider.data.size < 1 && bannersProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div>
      <Table striped hover bordered responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Tên</th>
          <th>Ảnh Desktop</th>
          <th>Ảnh Mobile</th>
          <th>URL</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          bannersProvider.data.map((item: any, index: number) => {
            return <BannerItem
              key={`banner_item_${item.get('_id')}`}
              index={index}
              page={bannersProvider.queries.get('page', 1)}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!bannersProvider.data && bannersProvider.data.size >= 20) ||
          bannersProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={bannersProvider.queries.get('page')}
          onPreviousPage={bannersProvider.onPreviousPage}
          onNextPage={bannersProvider.onNextPage}
          onFirstPage={bannersProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionBanners;