import { AxiosResponse } from 'axios'
import { _get, _post } from './request'

export const CrmApi = {
  fetchCustomers(page: number): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/crm`, {
      offset: (page - 1) * 20,
      limit: 20
    })
  },
}
