import Nav from "../../../components/Navbars/Nav";
import {Container} from "react-bootstrap";
import BackBtn from "../../../components/Button/back_btn";
import React from "react";
import {useGrabPlatformOrders} from "../provider";
import {useLocation, useNavigate} from "react-router";
import SectionFilter from "./section_filter";
import SectionOrders from "./section_orders";


const Views = () => {

  const location: any = useLocation();
  const navigate = useNavigate();
  const provider = useGrabPlatformOrders();

  React.useEffect(() => {
    if (!!location.state?.provider) {
      provider.initConfig(location.state?.brand, location.state?.store, location.state?.provider);
    } else {
      window.alert('Không tìm thấy tài khoản')
    }
  }, []);

  const _onBackClicked = () => {
    navigate(-1)
  }

  return (
    <main>
      <Nav title={'Đơn hàng'}/>
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={`Lấy đơn Grab | ${provider.config.getIn(['brand', 'name'])} - ${provider.config.getIn(['store', 'name'])}`}/>
      </Container>
      <Container>
        <div className={'mt-3 shadow-card'}>
          <SectionFilter/>
        </div>
        <SectionOrders/>
      </Container>
    </main>
  )
}

export default Views;