import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Api } from '../../repository/api'

interface WebsiteCreatorContextType {
  loading: boolean
  setQueries: React.Dispatch<any>
  queries: any
  subBrands: any
  setSubBrand: React.Dispatch<any>
  website: any
}
let WebsiteCreatorContext = React.createContext<WebsiteCreatorContextType>(null!)

const useWebsiteCreatorForm = () => {
  return React.useContext(WebsiteCreatorContext)
}
const WebsiteCreatorProvider: React.FC = ({ children }) => {
  const [subBrands, setSubBrand] = useState<any>(fromJS([]))
  const [website, setWebsite] = useState<any>(fromJS({}))
  const [loading, setLoading] = React.useState(false)
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const params = useParams()
  const _fetchSubBrands = async () => {
    try {
      const res = await Api.brand.fetchSubBrand(queries.get('search'))
      setSubBrand(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchWebsite = async () => {
    try {
      if (params.websiteId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(params.websiteId)
        setLoading(false)
        setWebsite(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  useEffect(() => {
    if (queries.size < 1) return
    _fetchSubBrands()
  }, [queries])

  useEffect(() => {
    if (params.websiteId) {
      _fetchWebsite()
    }
  }, [params.websiteId])
  const value = { setQueries, subBrands, queries, setSubBrand, website, loading }

  return <WebsiteCreatorContext.Provider value={value}>{children}</WebsiteCreatorContext.Provider>
}
export { useWebsiteCreatorForm }
export default WebsiteCreatorProvider
