import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation, useParams} from "react-router-dom";
import { useUserProfile } from "./provider";

interface UserOutletPermissionContextType {
  stores: any,
  loading: boolean,
  queries: any,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  onSubmitSearch: (search: string) => void,
  onSelectAll: () => void,
  onSelectItem: (item: any) => void,
  onSubmit: (permission: string) => Promise<boolean>,
  checkSelected: (list?: any) => any
}

let UserOutletPermissionContext = React.createContext<UserOutletPermissionContextType>(null!);

const useUserOutletPermission = () => {
  return React.useContext(UserOutletPermissionContext);
};

function UserOutletPermissionProvider({ children }: { children: React.ReactNode }) {


  const [stores, setStores] = React.useState<any>(fromJS([]))
  const [loading, setLoading] = React.useState(false);
  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const userProfileProvider = useUserProfile()

  const { userId } = useParams()

  if (!userId) {
    window.alert('Not found user')
  }

  const _fetchStores = async() => {
    try {
      const rs: any = await Api.store.fetchStores(queries.get('page'), queries.get('search'))
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const checkSelected = (list: any) => {
    let newList = list ?? stores
    if (userProfileProvider.outletPermissions.size > 0) {
      newList = newList.map((s: any) => {
        const activated = userProfileProvider.outletPermissions.find((p: any) => p.get('_id') == s.get('_id'))
        return s.updateIn(['activated'], () => activated)
      })
    }
    if (!!list) {
      return newList;
    }
    setStores(newList)
    return newList;
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    let list: any = await _fetchStores()
    list = checkSelected(list)
    setStores(list)

    setLoading(false)
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSubmitSearch = (search?: string) => {
    setQueries(
      queries
        .updateIn(['search'], () => search)
        .updateIn(['page'], () => 1)
    )
  }

  const onSelectAll = () => {
    const activatedCount = stores.count((s: any) => !!s.get('activated'))
    const selectedCount = stores.count((s: any) => !!s.get('selected'))
    const isSelectAll = stores.size - activatedCount == selectedCount

    setStores(
      stores.map((s: any) => {
        if (!!s.get('activated', false)) {
          return s;
        }
        return s.updateIn(['selected'], () => !isSelectAll)
      })
    )
  }

  const onSelectItem = (item: any) => {
    const itemIndex = stores.findIndex((s: any) => s.get('_id') == item.get('_id'))
    if (itemIndex < 0) return
    setStores(
      stores.updateIn([itemIndex, 'selected'], (value: any) => !value)
    )
  }

  const onSubmit = async (permission: string) => {
    const selectedStores = stores.filter((s: any) => !!s.get('selected') && !s.get('activated'))
    if (!selectedStores || selectedStores.size < 1) return false

    setLoading(true)
    for (const store of selectedStores) {
      try {
        const rs: any = await Api.user.setOutletUserPermission(userId ?? '', store.get('_id'), permission)
      } catch (e: any) {
        console.log(e)
      }
    }
    await userProfileProvider.loadOutletPermissions();
    setLoading(false)
    return true;
  }

  React.useEffect(() => {
    if (queries.size < 1 || loading) return;
    _refresh()
  }, [queries]);

  let value = {
    loading,
    stores,
    queries,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSubmitSearch,
    onSelectAll,
    onSelectItem,
    onSubmit,
    checkSelected
  };

  return <UserOutletPermissionContext.Provider value={value}>{children}</UserOutletPermissionContext.Provider>;
}

export default UserOutletPermissionProvider;
export { useUserOutletPermission };