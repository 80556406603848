import {Dropdown} from "react-bootstrap";

interface SectionFilterOrderStateItemType {
  state: any,
  label?: string,
  onSelect: (brand: any) => void
}

const SectionFilterOrderStateItem = (props: SectionFilterOrderStateItemType) => {

  const _onClick = () => {
    props.onSelect(props.state ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.label ?? props.state}
    </Dropdown.Item>
  )
}

export default SectionFilterOrderStateItem;