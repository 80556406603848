import React, {useEffect, useState} from "react";
import {Card, Dropdown} from 'react-bootstrap'
import {dishplaceholder} from "../../../util/myUtil";
import {numberFormat} from "../../../config";
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useNavigate} from "react-router";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import {useMenuItemCategories} from "../provider";
import MenuItemCategoryCreator from "../../MenuItemCategoryCreator";

interface MenuItemOptionType {
  item: any,
  index: number,
  page: number
}

const MenuItemCategory = (props: MenuItemOptionType) => {

  const useMenuItemCategoriesProvider = useMenuItemCategories()
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const navigate = useNavigate()
  const [newCat, setNewCat] = useState<any>(fromJS({}))

  const storeName = () => {
    return props.item.getIn(['store', 'name']) || props.item.getIn(['source', 'store', 'name'])
  }

  const sourceName = () => {
    return props.item.getIn(['source', 'name'])
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Danh mục này?')
    if (!rs) return
    useMenuItemCategoriesProvider.deleteMenuItemCategory(props.item.get('_id'))
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewCat(fromJS({}))
  }

  const handleSubmitUpdater = () => {
    if (!newCat.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newCat.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    useMenuItemCategoriesProvider.onEditMenuItemCategory(props.item.get('_id'), newCat.toJS())
  }

  useEffect(() => {
    if (!isCreatorOpen) {
      setNewCat(fromJS({
        ...props.item?.toJS(),
        brandId: props.item.getIn(['source', 'store', 'brand', '_id']) ?? props.item.getIn(['brand', '_id'], ''),
        storeId: props.item.getIn(['source', 'store', '_id']),
        sourceId: props.item.getIn(['source', '_id'])
      }))
    }
  }, [isCreatorOpen, props.item])

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <strong>
          {props.item.get('name')}
        </strong>
      </td>
      <td>
        {storeName()}
      </td>
      <td>
        {sourceName()}
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={onCreatorOpen}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Chỉnh sửa danh mục',
          content: <MenuItemCategoryCreator category={newCat} setCategory={setNewCat} />,
          confirmationText: 'Thêm'
        }}
        onCancel={handleCloseCreator}
        onConfirm={handleSubmitUpdater}
      />
    </tr>
  )
}

export default MenuItemCategory;