import {Spinner, Table} from "react-bootstrap";
import React from "react";
import {useAutoCrawlOrderJobs} from "../provider";
import CrawlerItem from "./crawler_item";
import { sourceInfo } from "../../../util/common_utils";

const SectionJobs = () => {
  const provider = useAutoCrawlOrderJobs();

  if (provider.data.size < 1 && provider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  const platformName = sourceInfo(provider.queries.get('platform') ?? '', '')?.label;

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Thương hiệu</th>
          <th>Cửa hàng</th>
          <th>Platform</th>
          <th>Tự động</th>
        </tr>
        </thead>
        <tbody>
        {
          provider.data
          .filter((d: any) => {
            if (!!platformName) {
              const source: any = sourceInfo(d.get('name', ''), '');
              return source?.label == platformName;
            }
            return true;
          })
          .map((crawler: any, index: number) => {
            return <CrawlerItem key={`crawler_${index}`} index={index} crawler={crawler}/>
          })
        }
        </tbody>
      </Table>
    </div>
  )
}

export default SectionJobs;