import PageLoading from "../../../components/page_loading";
import {Button, Container, Dropdown, Row} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import {useWebsiteMenuItemOptions} from "../provider";
import SectionMenuItemOptions from "./section_menu_item_options";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import OptionForm from "../../MenuItemOptionCreator/components/optionForm";
import {useModalState} from "../../../util/hook/useModalState";
import {ChoiceType} from "../../../repository/model/menuItem";
import BackBtn from "../../../components/Button/back_btn";

const Views = () => {

  const menuItemOptionsProvider = useWebsiteMenuItemOptions();
  const navigate = useNavigate();
  const { isOpen: isOptionCreatorOpen, onOpen: onOptionCreatorOpen, onClose: onOptionCreatorClose } = useModalState()
  const [newOption, setNewOption] = useState<any>(fromJS({}))

  const _onBackClicked = () => navigate(-1)

  const _onBrandChanged = (brand?: any) => {
    menuItemOptionsProvider.onBrandChanged(brand);
  }

  const handleCloseCreator = () => {
    onOptionCreatorClose()
    setNewOption(fromJS({}))
  }

  const handleSubmitNewOption = async () => {
    if (!newOption.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newOption.get('name', '')) {
      window.alert('Tùy chọn là bắt buộc!')
      return
    }
    if (newOption.get('choices', []).filter((c: any) => !c.get('name')).size) {
      window.alert('Lựa chọn là bắt buộc!')
      return
    }

    const minCount = newOption.getIn(['minCount'], 0)
    const maxCount = newOption.getIn(['maxCount'], 0)

    if (minCount > maxCount) {
      window.alert('Giới hạn tối thiểu KHÔNG thể lớn hơn Giới hạn tối đa')
      return
    }

    let optionReq = { ...newOption.toJS() }
    optionReq.choices = optionReq.choices.map((c: ChoiceType) => ({
      ...c,
      ...(!c.price && { price: 0 })
    }))
    const rs = await menuItemOptionsProvider.onCreateMenuItemOption(optionReq)
    if (rs) {
      handleCloseCreator()
    }
  }

  React.useEffect(() => {
    if (!!menuItemOptionsProvider.queries.getIn(['brand', '_id']))  {
      setNewOption(
        newOption.clear().updateIn(['brandId'], () => menuItemOptionsProvider.queries.getIn(['brand', '_id']))
      )
    }
  }, [isOptionCreatorOpen])

  return (
    <main>
      <Nav title={'Món tùy chọn'} />
      {
        menuItemOptionsProvider.loading && menuItemOptionsProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <BackBtn onBackClicked={_onBackClicked} label={'Thực đơn'}/>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm món tùy chọn mới'}
              icon={<FaPlus size={12} />}
              onClick={onOptionCreatorOpen}
            />
          </div>
        </div>
        <Row>
          <SectionBrandFilter
            brands={menuItemOptionsProvider.website.get('subBrands')}
            brandSelected={menuItemOptionsProvider.queries.getIn(['brand'])}
            onBrandSelected={_onBrandChanged}/>
        </Row>
        <SectionMenuItemOptions/>
      </Container>
      <NormalModal
        size="lg"
        open={isOptionCreatorOpen}
        options={{
          title: 'Thêm tùy chọn',
          content: (
            <OptionForm
              option={newOption}
              setOption={setNewOption}
              target={'website'}
              brands={menuItemOptionsProvider.website.get('subBrands')}/>
          ),
          confirmationText: 'Thêm'
        }}
        onCancel={handleCloseCreator}
        onConfirm={handleSubmitNewOption}
      />
    </main>
  );
}

export default Views;