import React from 'react'
import {Card, Col, Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import {FaEllipsisH, FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useWebsites} from "../provider";
import {dishplaceholder} from "../../../util/myUtil";
import {numberFormat} from "../../../config";

export interface WebsiteItemType {
  item: any
  index: number
  page: number
}

const WebsiteItem = (props: WebsiteItemType) => {
  const navigate = useNavigate();
  const websitesProvider = useWebsites();

  const _onEditClicked = () => {
    navigate(`/websites/${props.item.get('_id')}`)
  }

  const _onWebsiteMenuClicked = () => {
    navigate(`/websites/${props.item.get('_id')}/menu`)
  }

  const _onBannerClicked = () => {
    navigate(`/websites/${props.item.get('_id')}/banner`)
  }

  const _onHomePageConfigClicked = () => {
    navigate(`/websites/${props.item.get('_id')}/homepage`)
  }

  const _onPromotionClicked = () => {
    navigate(`/websites/${props.item.get('_id')}/promotion`)
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Trang Web này?')
    if (!rs) return
    websitesProvider.deleteWebsite(props.item.get('_id'))
  }

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <strong>
          {props.item.get('name')}
        </strong>
      </td>
      <td className={'text-wrap'}>
        <Card.Img
          variant="top"
          src={props.item.get('logo')}
          style={{
            height: 64,
            width: 'auto',
            objectFit: 'contain'
          }}
        />
      </td>
      <td>
        <Card.Img
          variant="top"
          src={props.item.get('cover')}
          style={{
            height: 64,
            width: 'auto',
            objectFit: 'contain'
          }}
        />
      </td>
      <td>
        <Dropdown className={'position-absolute'}>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onHomePageConfigClicked}>
              Cấu hình trang chủ
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onPromotionClicked}>
              Mã giảm giá
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onBannerClicked}>
              Banner
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onWebsiteMenuClicked}>
              Thực đơn
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  )
}

export default WebsiteItem
