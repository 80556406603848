import React from 'react'
import { Form, Stack } from 'react-bootstrap'
import {useApp} from "../../components/AdminLayout/admin_provider";

interface MenuItemUnitCreatorPropType {
  unit: any
  setUnit: React.Dispatch<any>
}

const MenuItemUnitCreator: React.FC<MenuItemUnitCreatorPropType> = ({ unit, setUnit }) => {
  const app = useApp()

  const onNameChanged = (name: string) => {
    setUnit(unit.updateIn(['name'], () => name))
  }

  const onBrandChanged = (brand: string) => {
    setUnit(unit.updateIn(['brandId'], () => brand))
  }
  return (
    <Form autoComplete="off">
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            Thương hiệu <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Select
            value={unit.get('brandId', '')}
            onChange={(e) => onBrandChanged(e.target.value)}
          >
            <option value="">Chọn thương hiệu</option>
            {app.brands.map((b: any, i: number) => (
              <option key={i} value={b.get('_id')}>
                {b.get('name')}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Đơn vị <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Control
            placeholder="Suất, chai, hộp,..."
            type="text"
            value={unit.get('name')}
            onChange={(el) => onNameChanged(el.target.value)}
          />
        </Form.Group>
      </Stack>
    </Form>
  )
}

export default MenuItemUnitCreator
