import React, {ChangeEvent} from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/Auth/auth';
import {FaUser, FaLock} from "react-icons/fa";
import logo from '../../assets/img/logo.svg';

const styles = {
	layout: {
		background: '#f5f5f5',
		height: '100%'
	},
	contentBody: {
		padding: 16,
	},
};

const Login = () => {
	const navigate = useNavigate();
	const [username, setUserName] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const [requestState, setRequestState] = React.useState({loading: false, error: undefined});
	const auth = useAuth();

	const _onUserChanged = (e: ChangeEvent<HTMLInputElement>) => {
		setUserName(e.currentTarget.value);
	}

	function _onPasswordChanged(e: ChangeEvent<HTMLInputElement>) {
		setPassword(e.currentTarget.value);
	}

	const _onLoginClicked = async (e: any) => {
		e?.preventDefault();
		setRequestState({error: undefined, loading: true});

		try {
			await auth.signin(username, password);
			navigate('/', { replace: true });
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<div style={styles.layout} className={'vh-100'}>
			<Container className={'h-100'}>
				<Row className={'h-100'}>
					<div className={'col-12 col-sm-6 col-md-7 col-xl-4 m-auto'} style={styles.contentBody}>
						<div className={'shadow-card'}>
							<Form onSubmit={_onLoginClicked}>
								<Row className="justify-content-md-center">
									<Col md="auto">
										<div className={'sidebar-header'}>
											<span className="brand-logo me-2">
												<img src={logo} alt="logo"/>
											</span>
											HubApp
										</div>
									</Col>
								</Row>
								<InputGroup className="mb-3 mt-5">
									<InputGroup.Text id="form-username">
										<FaUser/>
									</InputGroup.Text>
									<FormControl
										placeholder="Tên đăng nhập"
										aria-label="Username"
										aria-describedby="form-username"
										onChange={_onUserChanged}
									/>
								</InputGroup>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">
										<FaLock/>
									</InputGroup.Text>
									<FormControl
										placeholder="Mật khẩu"
										aria-label="Password"
										aria-describedby="basic-addon1"
										type={'password'}
										onChange={_onPasswordChanged}
									/>
								</InputGroup>
								<div className="d-grid gap-2">
									<Button
										type={'submit'}
										variant="outline-primary"
										onClick={_onLoginClicked}>
										{
											requestState.loading &&
											<Spinner animation={'border'} size={'sm'}/>
										}
										Đăng nhập
									</Button>
								</div>
								{
									requestState.error &&
									<div>
										{requestState.error}
									</div>
								}
							</Form>
						</div>
					</div>
				</Row>
			</Container>
		</div>
	);
};

export default Login;