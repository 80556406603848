import PageLoading from "../../../components/page_loading";
import {Button, Container, Dropdown, Row} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import {ChoiceType} from "../../../repository/model/menuItem";
import BackBtn from "../../../components/Button/back_btn";
import {useMenuItemCategories} from "../provider";
import SectionMenuItemCategories from "./section_menu_item_categories";
import MenuItemCategoryCreator from "../../MenuItemCategoryCreator";

const Views = () => {

  const menuItemCategoriesProvider = useMenuItemCategories();
  const app = useApp();
  const navigate = useNavigate();
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newCat, setNewCat] = useState<any>(fromJS({}))

  const _onBackClicked = () => navigate(-1)

  const _onBrandChanged = (brand?: any) => {
    menuItemCategoriesProvider.onBrandChanged(brand);
  }

  const _onStoreSelected = (store?: any) => {
    menuItemCategoriesProvider.onStoreChanged(store);
  }

  const _onSourceSelected = (source?: any) => {
    menuItemCategoriesProvider.onSourceChanged(source);
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewCat(fromJS({}))
  }

  const handleSubmitNewCat = () => {
    if (!newCat.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newCat.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    menuItemCategoriesProvider.onCreateMenuItemCategory(newCat.toJS())
  }

  return (
    <main>
      <Nav title={'Danh mục món'} />
      {
        menuItemCategoriesProvider.loading && menuItemCategoriesProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <BackBtn onBackClicked={_onBackClicked} label={'Thực đơn'}/>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm danh mục mới'}
              icon={<FaPlus size={12} />}
              onClick={onCreatorOpen}
            />
          </div>
        </div>
        <Row>
          <SectionBrandFilter
            brandSelected={menuItemCategoriesProvider.queries.getIn(['brand'])}
            storeSelected={menuItemCategoriesProvider.queries.getIn(['store'])}
            sourceSelected={menuItemCategoriesProvider.queries.getIn(['source'])}
            onBrandSelected={_onBrandChanged}
            onStoreSelected={_onStoreSelected}
            onSourceSelected={_onSourceSelected}/>
        </Row>
        <SectionMenuItemCategories/>
      </Container>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Thêm danh mục',
          content: <MenuItemCategoryCreator category={newCat} setCategory={setNewCat} />,
          confirmationText: 'Thêm'
        }}
        onCancel={onCreatorClose}
        onConfirm={handleSubmitNewCat}
      />
    </main>
  );
}

export default Views;