import React from 'react'
import { Button, Modal, ModalBody, Stack } from 'react-bootstrap'
import { ModalBaseProps } from './modalProvider'

const NormalModal: React.FC<ModalBaseProps> = ({ open, options, onCancel, onConfirm, size }) => {
  const { title, content, confirmationText, cancellationText, confirmBtn, scrollable } = options
  return (
    <Modal show={open} centered onHide={onCancel} size={size} scrollable={!!scrollable}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <ModalBody>{typeof content === 'function' ? content() : content}</ModalBody>
      {
        (!!confirmationText || !!cancellationText || !!confirmBtn) &&
        <Modal.Footer>
          <Stack direction="horizontal" className="gap-3">
            {!!cancellationText && (
              <Button variant="secondary" onClick={onCancel}>
                {cancellationText}
              </Button>
            )}

            {!!confirmationText && (
              <Button type="submit" onClick={onConfirm}>
                {confirmationText}
              </Button>
            )}

            {
              confirmBtn
            }
          </Stack>
        </Modal.Footer>
      }
    </Modal>
  )
}

export default NormalModal
