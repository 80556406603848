import {Badge, Button, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {FaLock, FaPercentage, FaUser} from "react-icons/fa";
import React, {ChangeEvent} from "react";
import {useFoodAppConnect} from "../provider";
import ModalUpdateAccount from "./modal_update_account";
import {fromJS} from "immutable";
import {useModal} from "../../../components/Modal/modalProvider";
import MenuItemSourceCreator from "../../MenuItemSourceCreator";
import {PAYMENT_METHOD, sourceInfo} from "../../../util/common_utils";
import {Api} from "../../../repository/api";
import {useModalState} from "../../../util/hook/useModalState";
import NormalModal from "../../../components/Modal/normalModal";

interface UserPasswordConnectType {
  providerName: string;
}

const UserPasswordApp = (props: UserPasswordConnectType) => {

  const providerName = props.providerName;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [displayUpdateAccountModal, setDisplayUpdateAccountModal] = React.useState<any>(false);
  const [account, setAccount] = React.useState<any>(fromJS({}));
  const [source, setSource] = React.useState<any>(fromJS({}));
  const [newSource, setNewSource] = React.useState<any>(fromJS({}));
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const foodAppConnectProvider = useFoodAppConnect();
  const sourceConfig = sourceInfo(providerName, '');

  React.useEffect(() => {
    const acc = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName);
    setAccount(acc ?? fromJS({}));
  }, [foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)]);

  React.useEffect(() => {
    console.log(providerName, foodAppConnectProvider.sources.toJS())
    const so = foodAppConnectProvider.sources?.find((acc: any) => acc.get('name').toLowerCase() === providerName.toLowerCase());
    setSource(so ?? fromJS({}));
  }, [foodAppConnectProvider.sources?.find((acc: any) => acc.get('name').toLowerCase() === providerName.toLowerCase())]);

  const _handleCloseUpdateModal = () => setDisplayUpdateAccountModal(false);

  const _onConnectClicked = async () => {
    if (!source || source.size < 1) {
      onCreatorOpen();
      return
    }
    setDisplayUpdateAccountModal(true);
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewSource(fromJS({}))
  }

  const handleSubmitNewUnit = async () => {
    if (!newSource.get('storeId', '')) {
      window.alert('Cửa hàng là bắt buộc!')
      return
    }
    if (!newSource.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    if (!newSource.get('commission')) {
      window.alert('Vui lòng cung cấp Commission')
      return
    }
    handleCloseCreator()
    try {
      const result = await Api.store.createSource(foodAppConnectProvider.store.get('_id'), newSource.toJS())
      foodAppConnectProvider.sourceAdded(
        fromJS(result.data.data)
      )
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  React.useEffect(() => {
    if (isCreatorOpen) {
      setNewSource(fromJS({
        brandId: foodAppConnectProvider.store.getIn(['brand', '_id']),
        storeId: foodAppConnectProvider.store.getIn(['_id']),
        name: sourceConfig.label
      }))
    }
  }, [isCreatorOpen])

  return (
    <div className={'mt-3 col-12 col-xl-4 col-md-6 col-sm-6'}>
      <div className={'shadow-card'}>
        <Row>
          <div className={'col'}>
            <h5 className={'color-' + providerName}>
              {providerName.toUpperCase()}
            </h5>
          </div>
          <div className={'col-auto'}>
            {
              !source?.get('name') && <Badge bg="danger">Chưa có nguồn đơn</Badge>
            }
            {
              source ? null :
              account?.get('connected') === true ?
                <Badge bg="success">Đã kết nối</Badge> :
                <Badge bg="warning">Chưa kết nối</Badge>
            }
          </div>
        </Row>
        <div className={'mt-3 pb-2 mx-1'}>
          <FaPercentage size={13}/>
          <small>{` Commission: `}</small>
          <strong>{source?.get('commission', '-') ?? '-'}</strong>{` %`}
        </div>
        <div className={'pb-2 mx-1'}>
          <FaUser size={12}/>
          {` `}
          {account?.get('username')}
        </div>
        <div className="d-grid gap-1 mt-3">
          <button className={`btn btn-sm btn-${providerName}`} onClick={_onConnectClicked} disabled={loading}>
            {
              !source || source.size < 1 ? 'Tạo nguồn đơn' : 'Kết nối'
            }
          </button>
        </div>
      </div>
      <ModalUpdateAccount
        providerName={providerName}
        show={displayUpdateAccountModal}
        onClose={_handleCloseUpdateModal}/>

      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Thêm nguồn đơn',
          content: (
            <MenuItemSourceCreator
              source={newSource}
              setSource={setNewSource}
              disableName={true}
              disableTargetSelector={true}/>
          ),
          confirmationText: 'Thêm'
        }}
        onCancel={onCreatorClose}
        onConfirm={handleSubmitNewUnit}
      />
    </div>
  )
}

export default UserPasswordApp;