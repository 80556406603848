import React, { useEffect, useState } from 'react'
import { Container, Dropdown, Form, FormControl, Stack } from 'react-bootstrap'
import useDebounce from '../../../util/useDebounce'
import { useUsers } from '../provider'
import Chip from '../../../components/Chip/Chip'
import { useAuth } from '../../../components/Auth/auth'

type UserRole = {
  value: string
  label: string
  checked: boolean
}

const initialRole: UserRole[] = [
  {
    value: 'owner',
    label: 'Owner',
    checked: false
  },
  {
    value: 'cashier',
    label: 'Cashier',
    checked: false
  }
]

interface FormUserProps {
  hasUser?: boolean
  setBody: React.Dispatch<any>
  bodyRequest: any
}

const UserFormModal: React.FC<FormUserProps> = ({ bodyRequest, setBody, hasUser }) => {
  const [userRole, setUserRole] = useState<UserRole[]>(initialRole)
  const [phone, setPhone] = useState<string>('')
  const debouncedValue = useDebounce<string>(phone)

  const { user } = useAuth()

  const { queries, setQueries } = useUsers()

  const handleRoleChanged = (roleIndex: number) => {
    userRole[roleIndex].checked = !userRole[roleIndex].checked
    setUserRole([...userRole])
    setBody(
      bodyRequest.updateIn(['role'], () =>
        userRole.filter((role) => role.checked).map((role) => role.value)
      )
    )
  }

  const handleUserClicked = (user: any) => {
    setBody(bodyRequest.updateIn(['user'], () => user))
  }

  useEffect(() => {
    if (phone) {
      setQueries(queries.updateIn(['phone'], () => phone))
    } else {
      setQueries(queries.updateIn(['phone'], () => phone).updateIn(['users'], () => undefined))
    }
  }, [debouncedValue])

  useEffect(() => {
    if (bodyRequest.get('role') && hasUser) {
      setUserRole([
        ...userRole.map((role) => {
          if (bodyRequest.get('role').includes(role.value)) return { ...role, checked: true }
          else return { ...role }
        })
      ])
    }
  }, [bodyRequest.get('role'), hasUser])

  return (
    <Container className="mb-4">
      <Form>
        {!hasUser && (
          <Form.Group className="mb-3">
            <Form.Label>
              Số điện thoại <span className="text-danger fs-5">*</span>
            </Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className="filter text-start w-100"
                variant={'light'}
                style={{ whiteSpace: 'normal' }}
              >
                <FormControl
                  className="w-100"
                  placeholder="Tìm kiếm..."
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-3 w-100">
                {!!queries?.get('users')?.size ? (
                  queries.get('users').map((user: any, index: number) => (
                    <Dropdown.Item key={index} onClick={() => handleUserClicked(user)}>
                      {user.get('username')}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item>Không có thành viên</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            {!!bodyRequest.get('user') && (
              <Stack direction="horizontal" gap={2} className="mt-3 flex-wrap">
                <Chip
                  text={
                    bodyRequest.getIn(['user', 'platform']) === 'email'
                      ? bodyRequest.getIn(['user', 'email'])
                      : bodyRequest.getIn(['user', 'phone']) +
                        ' - ' +
                        bodyRequest.getIn(['user', 'username'])
                  }
                />
              </Stack>
            )}
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>
            Vai trò <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Stack direction="horizontal" gap={3}>
            {userRole.map((role: UserRole, index: number) => (
              <Form.Check
                key={index}
                label={role.label}
                value={role.value}
                disabled={
                  user?._id === bodyRequest.getIn(['user', '_id']) &&
                  role.value === initialRole[0].value &&
                  hasUser
                }
                checked={bodyRequest.get('role')?.includes(role.value)}
                onChange={() => handleRoleChanged(index)}
              />
            ))}
          </Stack>
        </Form.Group>
      </Form>
    </Container>
  )
}

export default UserFormModal
