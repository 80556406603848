import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";
import moment from "moment";

interface OrdersContextType {
  queries: any,
  data: any,
  meta: any,
  platformAccounts: any,
  loading: boolean,
  availableSources: any,
  onDateSelected: (formDate: any, toDate: any, option: any) => void,
  onHubChanged: (hub?: any) => void,
  onBrandChanged: (brand?: any) => void,
  onStoreChanged: (store?: any) => void,
  onStateChanged: (state?: any) => void,
  submitSearch: (search?: string) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  onOrderCreated: (order: any) => void,
  onTimeChanged: (from: Date, to: Date) => void,
  onSourceChanged: (source?: any) => void,
}

let OrdersContext = React.createContext<OrdersContextType>(null!);

const useOrders = () => {
  return React.useContext(OrdersContext);
};

function OrdersProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]));
  const [meta, setMeta] = React.useState<any>(fromJS({}));
  const [platformAccounts, setPlatformAccounts] = React.useState<any>(fromJS([]));
  const [loading, setLoading] = React.useState(false);
  const [availableSources, setAvailableSources] = React.useState<any>(fromJS([]));
  const app = useApp();

  const _getBrandFilters = () => {
    if (!app.brands) return null
    let brands: any = [];
    if (!queries.get('hub')) {
      if (!!queries.get('brand')) {
        let brand: any = {
          id: queries.getIn(['brand', '_id']),
        }
        if (!!queries.get('store')) {
          brand.stores = [queries.getIn(['store', '_id'])]
        } else {
          brand.stores = queries.getIn(['brand', 'stores']).map((s: any) => s.get('_id'))
        }
        brands.push(brand);
      } else {
        brands.push(...app.brands.map((b: any) => {
          return {
            id: b.get('_id'),
            stores: b.get('stores').map((s: any) => s.get('_id'))
          }
        }).toJS())
      }
    }
    return brands
  }

  const _fetchOrders = async () => {
    try {
      const hubs: any = [];
      if (!queries.get('brand')) {
        if (queries.get('hub')) {
          hubs.push(queries.getIn(['hub', '_id']))
        } else if (app.hubs) {
          hubs.push(
            ...app.hubs.map((h: any) => h.get('_id'))
          )
        }
      }

      const brands: any = _getBrandFilters()

      const rs = await Api.order.fetchOrders(
        brands,
        queries.get('page', 1),
        queries.get('state'),
        queries.get('search'),
        hubs,
        queries.get('from'),
        queries.get('to'),
        queries.get('source'));
      setMeta(fromJS(rs.data.meta))
      return fromJS(rs.data.data)
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return;
    }
  }

  const _fetchAvailableSources = async () => {
    console.log('-> _fetchAvailableSources')
    const brands: any = _getBrandFilters()
    if (!brands) return
    try {
      const rs = await Api.fetchAllQuerySources(brands);
      setAvailableSources(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return;
    }
  }

  const _refresh = async() => {
    setLoading(true);

    const list: any = await _fetchOrders();
    setData(list)

    setLoading(false)
  }

  const _initData = async() => {
    if (!app.brands) {
      await app.fetchBrand();
    }
  }

  const _initQueries = async() => {
    const brands = app.brands;
    if (!brands || !app.hubs) return;
    if (!brands || brands.size < 1) {
      window.alert('Bạn cần liên hệ Admin để có thể truy cập')
      return;
    }
    _fetchAvailableSources()
    setQueries(
      queries.updateIn(['page'], () => 1)
    )
  }

  const _fetchPlatformAccounts = async (id: string) => {
    try {
      const rs = await Api.store.fetchPlatforms(id);
      if (!!rs) {
        setPlatformAccounts(fromJS(rs.data.data))
      }
    } catch (e) {
      setPlatformAccounts(fromJS([]))
    }
  }

  const onDateSelected = (fromDate: any, toDate: any, option: any) => {
    setQueries(
      queries.updateIn(['from'], () => fromDate)
        .updateIn(['to'], () => toDate)
        .updateIn(['date_option'], () => option)
    )
  }

  const onHubChanged = (hub?: any) => {
    setQueries(
      queries.updateIn(['hub'], () => hub)
        .updateIn(['page'], () => 1)
        .updateIn(['brand'], () => undefined)
        .updateIn(['store'], () => undefined)
    )
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['page'], () => 1)
        .updateIn(['store'], () => undefined)
    )
  }

  const onStoreChanged = (store?: any) => {
    setQueries(
      queries.updateIn(['store'], () => store)
        .updateIn(['page'], () => 1)
    )
  }

  const onStateChanged = (state?: any) => {
    setQueries(
      queries.updateIn(['state'], () => state)
        .updateIn(['page'], () => 1)
    )
  }

  const submitSearch = (search?: string) => {
    setQueries(
      queries.updateIn(['search'], () => search)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onOrderCreated = (_: any) => {
    if (queries.get('page', 0) <= 1) {
      _refresh();
    }
  }

  const onTimeChanged = (from: Date, to: Date) => {
    const start = moment(from).startOf('day').valueOf()
    const end = moment(to).endOf('day').valueOf()
    setQueries(
      queries.updateIn(['from'], () => start)
        .updateIn(['to'], () => end)
        .updateIn(['page'], () => 1)
    )
  }

  const onSourceChanged = (source: any) => {
    setQueries(
      queries.updateIn(['source'], () => source)
        .updateIn(['page'], () => 1)
    )
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading && app.brands) {
      _initData();
    }
  }, []);

  React.useEffect(() => {
    if (queries.size > 1) return;
    _initQueries();
  }, [app.brands, app.hubs]);

  React.useEffect(() => {
    if (queries.size < 1) return;
    _refresh()
  }, [queries]);

  React.useEffect(() => {
    if (!queries.getIn(['store', '_id'])) {
      if (platformAccounts.size < 1) return
      setPlatformAccounts(fromJS([]));
      return
    }
    _fetchPlatformAccounts(queries.getIn(['store', '_id']));
  }, [queries.getIn(['store', '_id'])])

  React.useEffect(() => {
    if (app.brands && app.brands.size > 0) {
      _fetchAvailableSources()
    }
  }, [queries.getIn(['brand', '_id'])])

  let value = {
    queries,
    data,
    meta,
    platformAccounts,
    loading,
    availableSources,
    submitSearch,
    onDateSelected,
    onHubChanged,
    onBrandChanged,
    onStoreChanged,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onOrderCreated,
    onStateChanged,
    onTimeChanged,
    onSourceChanged,
  };

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
}

export default OrdersProvider;
export { useOrders };