import { Form, FormCheck, InputGroup, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'

type choicesPropType = {
  option: any
  setOption: React.Dispatch<any>
}
const Choices: React.FC<choicesPropType> = ({ option, setOption }) => {
  const handleNameChanged = (index: number, value: string) => {
    if (option.get('choices', []).filter((c: any) => c.get('name') === value).size) {
      window.alert('Tên đã tồn tại!')
      return
    }
    setOption(option.updateIn(['choices', index, 'name'], () => value))
  }

  const handlePriceChanged = (index: number, value: number) => {
    if (value >= 999999) return
    setOption(option.updateIn(['choices', index, 'price'], () => Number(value)))
  }

  const handleSKUChanged = (index: number, value: string) => {
    setOption(option.updateIn(['choices', index, 'sku'], () => value))
  }

  const handleMaxChanged = (index: number, value: number) => {
    setOption(option.updateIn(['choices', index, 'maxCount'], () => value))
  }

  const handleIsDefaultChanged = (index: number) => {
    setOption(
      option.updateIn(['choices'], (e: any) =>
        e.map((c: any, i: number) =>
          i === index
            ? c.updateIn(['isDefault'], () => true)
            : c.updateIn(['isDefault'], () => false)
        )
      )
    )
  }

  const handleDelete = (index: number) => {
    setOption(
      option.updateIn(['choices'], (e: any) => e.filter((c: any, i: number) => i !== index))
    )
  }

  return (
    <Stack className="gap-3">
      {option.get('choices', [])?.map((choice: any, index: number) => (
        <Form.Group key={index} className="d-flex">
          <FormCheck className="d-flex">
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip>
                  {choice.get('isDefault') ? 'Lựa chọn mặc định' : 'Là lựa chọn mặc định?'}
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <FormCheck.Input
                  {...triggerHandler}
                  ref={ref}
                  name="isDefault"
                  value={choice.get('name')}
                  checked={choice.get('isDefault')}
                  onChange={() => handleIsDefaultChanged(index)}
                  className="my-auto me-2"
                  type="radio"
                />
              )}
            </OverlayTrigger>

            <Form.Label className="m-0">
              <div key={index} className="d-flex gap-3">
                <InputGroup>
                  <InputGroup.Text id="name">Tên *</InputGroup.Text>
                  <Form.Control
                    type="text"
                    value={choice.get('name')}
                    onChange={(e) => handleNameChanged(index, e.target.value)}
                  />
                </InputGroup>

                <InputGroup>
                  <Form.Control
                    type="number"
                    min="0"
                    max="999999"
                    placeholder="Giá tuỳ chọn"
                    value={choice.get('price')}
                    onChange={(e) => handlePriceChanged(index, Number(e.target.value))}
                  />
                  <InputGroup.Text id="price">đ</InputGroup.Text>
                </InputGroup>

                <InputGroup>
                  <InputGroup.Text id="max">Tối đa</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="1"
                    min={0}
                    max={999}
                    value={choice.get('maxCount')}
                    onChange={(e) => handleMaxChanged(index, Number(e.target.value))}
                  />
                </InputGroup>

                <InputGroup>
                  <InputGroup.Text id="sku">Mã món</InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="SKU"
                    value={choice.get('sku')}
                    onChange={(e) => handleSKUChanged(index, e.target.value)}
                  />
                </InputGroup>
              </div>
            </Form.Label>
          </FormCheck>

          <FaPlus
            className="ms-2 mt-2 btn p-0"
            style={{ transform: 'rotate(45deg)' }}
            size="20"
            color="red"
            onClick={() => handleDelete(index)}
          />
        </Form.Group>
      ))}
    </Stack>
  )
}

export default Choices
