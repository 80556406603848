import {useOrderCreator} from "../provider";
import React from "react";
import {Dropdown, FormControl} from "react-bootstrap";
import {FaChevronDown, FaTrashAlt} from "react-icons/fa";
import SectionMenuItemSelection from "./section_menu_item_selection";
import SectionMenuItemOption from "./section_menu_item_option";
import SectionMenuItemPrice from "./section_menu_item_price";
import LabelIconBtn from "../../../components/Button/label_icon_btn";

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
    const [value, setValue] = React.useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Tìm kiếm..."
          onChange={(e: any) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {!!children && React.Children.toArray(children).filter(
            (child: any) => {
              return !value || child.props.item.get('name', '').toLowerCase().includes(value.toLowerCase());
            },
          )}
        </ul>
      </div>
    );
  },
);

interface SectionMenuItemType {
  item: any
}

const SectionMenuItem = (props: SectionMenuItemType) => {

  const orderCreator = useOrderCreator();

  const _onMenuItemClicked = (item?: any) => {
    orderCreator.onMenuItemSelected(props.item, item)
  }

  const _onNoteChanged = (e: any) => {
    orderCreator.onMenuItemNoteChanged(props.item.get('key'), e.target.value)
  }

  const _onDeleteItemClicked = () => {
    const rs = window.confirm(`Bạn có chắc chắn muốn xóa món "${props.item.get('name')}"?`)
    if (!rs) return;
    orderCreator.removeMenuItem(props.item)
  }

  const _onItemPriceChanged = (e: any) => {
    const value = e.target.value;
    try {
      const price = Number.parseInt(value);
      if (isNaN(price)) {
        orderCreator.onMenuItemPriceChanged(props.item.get('key'), 0)
        return;
      }
      if (price < 1) {
        window.alert('Giá phải lớn hơn 0');
        return;
      }
      orderCreator.onMenuItemPriceChanged(props.item.get('key'), price)
    } catch(e) {
      window.alert(e)
    }
  }

  return (
    <div className={'mt-2 border-bottom pt-1 pb-3 shadow-card'}>
      <div className={'row'}>
        <div className={'col'}>

        </div>
        <div className={'col-auto'}>
          <LabelIconBtn
            label={'Xóa món'}
            icon={<FaTrashAlt size={12} color={'#d20808'}/>}
            onClick={_onDeleteItemClicked}
            color={'#d20808'}/>
        </div>
      </div>
      <div className={'row align-items-center'}>
        <div className={'col'}>
          <Dropdown>
            <Dropdown.Toggle className={'filter w-100 text-start'} variant={'light'} style={{whiteSpace: 'normal'}}>
              {props.item?.get('name') ?? 'Chọn món'}
              <FaChevronDown size={13} className={'ms-2'}/>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {
                orderCreator.menu?.map((menuItem: any, index: number) => {
                  return (
                    <SectionMenuItemSelection
                      item={menuItem}
                      onClick={_onMenuItemClicked}
                      key={`option_menu_item_${index}_${props.item.get('key')}`}/>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={'col-auto'}>
          <div className={'hstack'}>
            <div style={{width: 184}} className={'text-end ms-2'}>
              {
                !!props.item?.get('name') &&
                <input
	                type={'number'}
	                className="form-control form-control-sm"
	                value={props.item.get('price', 0)}
	                placeholder="1"
	                onChange={_onItemPriceChanged}/>
              }
            </div>
          </div>
        </div>
      </div>

      <div className={'mt-2'}>
        {
          props.item.get('options')?.map((option: any) => {
            return (
              <SectionMenuItemOption
                key={`${props.item.get('key')}_option_${option.get('_id')}`}
                itemKey={props.item.get('key')}
                option={option}
              />
            )
          })
        }
        <SectionMenuItemPrice item={props.item}/>
      </div>

      <div className="mt-1">
        <label className="form-label"><strong className={'opacity-75'}>Ghi chú</strong></label>
        <textarea className="form-control" rows={3} onChange={_onNoteChanged} value={props.item.get('note', '')}>

        </textarea>
      </div>
    </div>
  )
}

export default SectionMenuItem;