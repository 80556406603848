import React from 'react'
import { fromJS } from 'immutable'
import { Api } from '../../repository/api'

interface WebsitesContextType {
  queries: any
  data: any
  loading: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  onFirstPage: () => void
  onSearchChanged: (brand?: any) => void
  meta: any,
  deleteWebsite: (websiteId: any) => void
}

let WebsitesContext = React.createContext<WebsitesContextType>(null!)

const useWebsites = () => {
  return React.useContext(WebsitesContext)
}

const WebsitesProvider: React.FC = ({ children }) => {
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const _fetchWebsites = async () => {
    try {
      const rs: any = await Api.brand.fetchMajorBrandList(queries.get('page'), queries.get('search'))
      console.log('-->>> ', rs.data.data)
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    setLoading(true)

    const list: any = await _fetchWebsites()
    setData(list)

    setLoading(false)
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSearchChanged = (brand?: any) => {
    setQueries(queries.updateIn(['search'], () => brand))
  }

  const deleteWebsite = async (websiteId: any) => {
    const websiteIndex = data.findIndex((s: any) => s.get('_id') === websiteId)

    if (websiteIndex < 0) return

    const website = data.get(websiteIndex)

    setData(data.delete(websiteIndex))
    try {
      const rs = await Api.brand.deleteMajorBrand(websiteId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(websiteIndex, website))
      return null
    }
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initQueries()
    }
  }, [])

  React.useEffect(() => {
    if (queries.size < 1) return
    _refresh()
  }, [queries])

  let value = {
    queries,
    data,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSearchChanged,
    meta,
    deleteWebsite
  }

  return <WebsitesContext.Provider value={value}>{children}</WebsitesContext.Provider>
}

export default WebsitesProvider
export { useWebsites }
