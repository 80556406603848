import React from 'react';
import Views from "./components/views";
import MenuItemCreatorProvider from "./provider";

const MenuItemCreator = () => {

  return (
    <MenuItemCreatorProvider>
      <Views/>
    </MenuItemCreatorProvider>);
};

export default MenuItemCreator;