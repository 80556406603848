import { Pagination, Table } from 'react-bootstrap'
import { useBrands } from '../provider'
import BrandItem from './brandItem'
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaPlus} from "react-icons/fa";
import React from "react";
import {useNavigate} from "react-router";

const BrandList: React.VFC = () => {
  const brandProvider = useBrands()
  const navigate = useNavigate();

  const _onCreateClicked = () => {
    navigate(`/brands/create`);
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'d-flex justify-content-between'}>
        <Pagination size={'sm'}>
          <Pagination.Item
            disabled={brandProvider.queries.get('page') <= 1}
            onClick={brandProvider.onPreviousPage}
          >
            Trang trước
          </Pagination.Item>
          {brandProvider.queries.get('page') > 1 && (
            <Pagination.Item onClick={brandProvider.onFirstPage}>1</Pagination.Item>
          )}
          <Pagination.Ellipsis disabled />
          <Pagination.Item active>{brandProvider.queries.get('page')}</Pagination.Item>
          <Pagination.Ellipsis disabled />
          <Pagination.Item
            disabled={brandProvider?.meta?.totalPage <= brandProvider.queries.get('page')}
            onClick={brandProvider.onNextPage}
          >
            Trang kế
          </Pagination.Item>
        </Pagination>
        <div>
          <LabelIconBtn
            label={'Thêm mới'}
            icon={<FaPlus size={12} />}
            onClick={_onCreateClicked}
          />
        </div>
      </div>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>STT</th>
            <th>Tên thương hiệu</th>
            <th>Slug</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {brandProvider.data.map((brand: any, index: number) => {
            return (
              <BrandItem
                key={brand.get('_id')}
                index={index}
                brand={brand}
                page={brandProvider.queries.get('page')}
              />
            )
          })}
        </tbody>
      </Table>
      <Pagination size={'sm'} className="mt-3">
        <Pagination.Item
          disabled={brandProvider.queries.get('page') <= 1}
          onClick={brandProvider.onPreviousPage}
        >
          Trang trước
        </Pagination.Item>
        {brandProvider.queries.get('page') > 1 && (
          <Pagination.Item onClick={brandProvider.onFirstPage}>1</Pagination.Item>
        )}
        <Pagination.Ellipsis disabled />
        <Pagination.Item active>{brandProvider.queries.get('page')}</Pagination.Item>
        <Pagination.Ellipsis disabled />
        <Pagination.Item
          disabled={brandProvider?.meta?.totalPage <= brandProvider.queries.get('page')}
          onClick={brandProvider.onNextPage}
        >
          Trang kế
        </Pagination.Item>
      </Pagination>
    </div>
  )
}

export default BrandList
