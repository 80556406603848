import React from "react";
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {useShopeePlatformOrderImporter} from "./provider";
import {numberFormat} from "../../../config";
import DishItem from "./dish_item";

interface ViewsType {
  id: string;
  show: boolean;
  storeId: string;
  provider: string;
  close: () => void;
}

const Views = (props: ViewsType) => {

  const importerProvider = useShopeePlatformOrderImporter();

  React.useEffect(() => {
    if (!props.show || importerProvider.loading) return
    importerProvider.fetchOrder(props.storeId, props.provider, props.id);
  }, [props.show]);

  const _onCreateClicked = async () => {
    const rs: boolean = await importerProvider.createOrder();
    if (rs) props.close();
  }

  if (importerProvider.loading && importerProvider.data.size < 1) {
    return (
      <Modal.Body>
        <Spinner animation="border" size={'sm'}/>
      </Modal.Body>
    )
  }

  return (
    <>
      <Modal.Body>
        <Table striped hover responsive bordered className={'mt-3'}>
          <thead>
          <tr>
            <th>STT</th>
            <th>Món</th>
            <th>Số lượng</th>
            <th>Đơn giá</th>
            <th>Thành tiền</th>
          </tr>
          </thead>
          <tbody>
          {
            importerProvider.data.getIn(['dishes'])?.map((dish: any, index: number) => {
              return (
                <DishItem key={`${dish.get('name')}_${index}`} index={index} dish={dish}/>
              )
            })
          }
          <tr>
            <td colSpan={4} className={'text-end'}>
              <strong className={'me-3'}>Tổng tiền</strong>
            </td>
            <td>
              <strong>
                {numberFormat.format(importerProvider.data.getIn(['orderItemPrice']))}
              </strong>
            </td>
          </tr>
          <tr>
            <td colSpan={4} className={'text-end'}>
              <strong className={'me-3'}>
                Khuyến mãi {!!importerProvider.data.getIn(['promoCode']) ? `(${importerProvider.data.getIn(['promoCode'])})` : ''}
              </strong>
            </td>
            <td>
              <strong>
                -{numberFormat.format(importerProvider.data.getIn(['promotionValue']))}
              </strong>
            </td>
          </tr>
          <tr>
            <td colSpan={4} className={'text-end'}>
              <strong className={'me-3'}>Phí hoa hồng ({importerProvider.data.getIn(['commission'])}%)</strong>
            </td>
            <td>
              <strong className={'text-danger'}>
                {importerProvider.data.getIn(['commissionFee']) > 0 && '-'}{numberFormat.format(importerProvider.data.getIn(['commissionFee']))}
              </strong>
            </td>
          </tr>
          <tr>
            <td colSpan={4} className={'text-end'}>
              <strong className={'me-3'}>Net</strong>
            </td>
            <td>
              <strong className={'text-success'}>
                {numberFormat.format(importerProvider.data.getIn(['netPrice']))}
              </strong>
            </td>
          </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={_onCreateClicked} disabled={importerProvider.loading}>
          {
            importerProvider.loading ? <Spinner animation="border" size={'sm'}/> : 'Nhập đơn'
          }
        </Button>
      </Modal.Footer>
    </>
  )
}

export default Views;