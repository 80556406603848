import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {MenuItemSource, MenuUnitType} from "../../repository/model/menuItem";
import {useParams} from "react-router";

interface MenuItemSourcesContextType {
  queries: any,
  data: any,
  loading: boolean,
  storeDetail: any,
  deleteMenuItemSource: (itemId: any) => void,
  onCreateMenuItemSource: (source: MenuItemSource) => any,
  onEditMenuItemSource: (sourceId: string, source: MenuItemSource) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let MenuItemSourcesContext = React.createContext<MenuItemSourcesContextType>(null!);

const useMenuItemSources = () => {
  return React.useContext(MenuItemSourcesContext);
};

function MenuItemSourcesProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const [storeDetail, setStoreDetail] = React.useState<any>()
  const {storeId} = useParams()

  const _fetchMenuItemSources = async() => {
    if (!storeId) return
    try {
      const rs: any = await Api.store.fetchSources(storeId!!, queries.get('page'), 20)
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _fetchStoreDetail = async () => {
    if (!storeId) return
    try {
      const rs: any = await Api.store.fetchDetail(storeId!!)
      setStoreDetail(fromJS(rs.data.data))
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const _refresh = async () => {
    if (loading || !storeId) return
    setLoading(true)

    const list: any = await _fetchMenuItemSources()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
    _fetchStoreDetail()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onCreateMenuItemSource = async (source: MenuItemSource) => {
    try {
     await Api.store.createSource(storeId, source)
      window.alert('Thêm mới thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const deleteMenuItemSource = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const sourceItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.store.deleteSource(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, sourceItem))
      return null
    }
  }

  const onEditMenuItemSource = async (sourceId: string, source: MenuItemSource) => {
    try {
      await Api.store.editSource(sourceId, source)
      window.alert('Chỉnh sửa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }


  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, []);

  React.useEffect(() => {
    if (queries.size < 1) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    storeDetail,
    deleteMenuItemSource,
    onCreateMenuItemSource,
    onEditMenuItemSource,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <MenuItemSourcesContext.Provider value={value}>{children}</MenuItemSourcesContext.Provider>;
}

export default MenuItemSourcesProvider;
export { useMenuItemSources };