import {useUsers} from "../provider";
import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import { FaPlus } from "react-icons/fa";
import UserItem from "./user_item";
import NormalModal from "../../../components/Modal/normalModal";
import CreatorForm from "./creator_form";
import { useModalState } from "../../../util/hook/useModalState";

const SectionUsers = () => {
  const provider = useUsers();
  const { isOpen: isOpenCreator, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()

  const _onCreateClicked = () => {
    onCreatorOpen()
  }

  if (provider.data.size < 1 && provider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'d-flex justify-content-between'}>
        <div></div>
        <div>
          <LabelIconBtn
            label={'Thêm mới'}
            icon={<FaPlus size={12} />}
            onClick={_onCreateClicked}
          />
        </div>
      </div>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>SĐT</th>
          <th>Tên</th>
          <th>Email</th>
          <th>Ngày tạo</th>
        </tr>
        </thead>
        <tbody>
        {
          provider.data.map((user: any, index: number) => {
            return <UserItem
              key={`user_${user.get('_id')}`}
              index={index}
              page={provider.queries.get('page')}
              user={user}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!provider.data && provider.data.size >= 20) ||
          provider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={provider.queries.get('page')}
          onPreviousPage={provider.onPreviousPage}
          onNextPage={provider.onNextPage}
          onFirstPage={provider.onFirstPage}/>
      }
      <NormalModal
        open={isOpenCreator}
        size={'lg'}
        options={{
          title: 'Thêm người dùng',
          scrollable: false,
          content: (
            <CreatorForm onClose={onCreatorClose}/>
          ),
        }}
        onCancel={onCreatorClose}
        onConfirm={() => {}}
      />
    </div>
  )
}

export default SectionUsers;