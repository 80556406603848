import {useOrderCreator} from "../provider";
import React from "react";
import SectionMenuItem from "./section_menu_item";
import SectionMenuItemAddMore from "./section_menu_item_add_more";
import OrderSummaries from "../OrderSummaries";

const SectionCreator = () => {

  const orderCreator = useOrderCreator();

  if (orderCreator.config.get('view_state') === 'confirm') {
    return <OrderSummaries/>
  }

  const _onConfirmClicked = () => {
    orderCreator.updateViewState('confirm')
  }

  return (
    <div className={'mt-3 mb-3 pb-2'}>

      {
        orderCreator.order.get('items', [])?.map((item: any, index: number) => {
          return (
            <SectionMenuItem key={`key_menu_item_${index}`} item={item}/>
          )
        })
      }
      <SectionMenuItemAddMore/>

      <div className={'mt-4'}>
        <div className="d-grid gap-2">
          <button className="btn btn-primary" type="button" onClick={_onConfirmClicked}>
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  )
}

export default SectionCreator;