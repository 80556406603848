import React, {useEffect, useState} from "react";
import {Dropdown} from 'react-bootstrap'
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import {useMenuItemSources} from "../provider";
import MenuItemSourceCreator from "../../MenuItemSourceCreator";
import { useNavigate } from "react-router";

interface MenuItemSourceType {
  item: any,
  index: number,
  page: number
}

const MenuItemSource = (props: MenuItemSourceType) => {

  const useMenuItemSourcesProvider = useMenuItemSources()
  const navigate = useNavigate()
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newSource, setNewSource] = useState<any>(fromJS({}))


  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Đơn vị này?')
    if (!rs) return
    useMenuItemSourcesProvider.deleteMenuItemSource(props.item.get('_id'))
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewSource(fromJS({}))
  }

  const handleSubmitUpdater = () => {
    if (!newSource.get('storeId', '')) {
      window.alert('Cửa hàng là bắt buộc!')
      return
    }
    if (!newSource.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    useMenuItemSourcesProvider.onEditMenuItemSource(props.item.get('_id'), newSource.toJS())
  }

  const _onTableManagerClick = () => {
    const brandQ= `${props.item.getIn(['store', 'brand', 'name'])}.${props.item.getIn(['store', 'brand', '_id'])}`
    const storeQ= `${props.item.getIn(['store', 'name'])}.${props.item.getIn(['store', '_id'])}`
    const sourceQ= `${props.item.get('name')}.${props.item.get('_id')}.${props.item.get('pickingTableEnabled')}`
    navigate(`/tables?brand=${brandQ}&store=${storeQ}&source=${sourceQ}`)
  }

  useEffect(() => {
    if (isCreatorOpen) {
      setNewSource(fromJS({
        ...props.item?.toJS(),
        brandId: props.item.getIn(['store', 'brand', '_id']),
        storeId: props.item.getIn(['store', '_id']),
      }))
    }
  }, [isCreatorOpen, props.item])

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <div>
          {props.item.getIn(['store', 'brand', 'name'])}
        </div>
        <div className={'fw-lighter opacity-50'}>
          {props.item.getIn(['store', 'name'])}
        </div>
      </td>
      <td>
        {props.item.get('name')}
      </td>
      <td>
        <strong>
          {props.item.get('commission')}%
        </strong>
      </td>
      
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={onCreatorOpen}>
              Sửa
            </Dropdown.Item>
           <Dropdown.Item as={'button'} onClick={_onTableManagerClick}>
              Quản lý bàn
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Chỉnh sửa nguồn đơn',
          content: <MenuItemSourceCreator source={newSource} setSource={setNewSource}/>,
          confirmationText: 'Sửa'
        }}
        onCancel={handleCloseCreator}
        onConfirm={handleSubmitUpdater}
      />
    </tr>
  )
}

export default MenuItemSource;