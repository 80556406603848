import {useUserProfile} from "../provider";
import {Form, FormControl, FormLabel, Row, Spinner, Button, InputGroup, Badge, Table} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { fromJS } from "immutable";
import { FaCheck, FaRegUserCircle } from "react-icons/fa";
import { permissionClass } from "../../../util/common_utils";

interface SectionPermissionsBrandItemype {
  brand: any,
  index: number,
}

const SectionPermissionsBrandItem = (props: SectionPermissionsBrandItemype) => {
  const provider = useUserProfile();
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>
        {
          props.brand.get('name')
        }
      </td>
      <td>
        {props.brand.getIn(['phone'])}
      </td>
      <td>
        <Badge bg={permissionClass(props.brand.getIn(['permission', 'label']))}>
          {props.brand.getIn(['permission', 'label'])}
        </Badge>
      </td>
      <td>
        
      </td>
    </tr>
  )
}

export default SectionPermissionsBrandItem;