import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {useParams} from "react-router";

interface WebsiteBannersContextType {
  queries: any,
  data: any,
  website: any,
  loading: boolean,
  submitSearch: (search?: any) => void,
  deleteBanner: (id: any) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let WebsiteBannersContext = React.createContext<WebsiteBannersContextType>(null!);

const useWebsiteBanners = () => {
  return React.useContext(WebsiteBannersContext);
};

function WebsiteBannersProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [website, setWebsite] = React.useState<any>(fromJS({}))
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const {websiteId} = useParams()

  const _fetchWebsite = async () => {
    try {
      if (websiteId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(websiteId)
        setLoading(false)
        setWebsite(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchBanners = async() => {
    try {
      if (!websiteId) return
      const rs: any = await Api.banner.fetchBanners(
        websiteId!!,
        queries.get('search'),
        queries.get('page', 1),
      )
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await _fetchBanners()
    setData(list)

    setLoading(false)
  }

  const submitSearch = async (search?: string) => {
    setQueries(
      queries.updateIn(['search'], () => search)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const deleteBanner = async (id: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === id)

    if (itemIndex < 0) return

    const banner = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.banner.deleteBanner(id)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, banner))
      return null
    }
  }

  React.useEffect(() => {
    _refresh()
  }, [queries]);

  React.useEffect(() => {
    _fetchWebsite()
  }, [])

  let value = {
    queries,
    data,
    website,
    loading,
    submitSearch,
    deleteBanner,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <WebsiteBannersContext.Provider value={value}>{children}</WebsiteBannersContext.Provider>;
}

export default WebsiteBannersProvider;
export { useWebsiteBanners };