import React from "react";
import Views from "./components/views";
import GrabPlatformOrdersProvider from "./provider";

const GrabPlatformOrders = () => {

  return (
    <GrabPlatformOrdersProvider>
      <Views/>
    </GrabPlatformOrdersProvider>
  );
};

export default GrabPlatformOrders;

const PAGE_SIZE = 100;
export { PAGE_SIZE }