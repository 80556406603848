import * as React from 'react';
import {fromJS} from "immutable";
import {Api} from "../../repository/api";

interface AppContextType {
  fetchBrand: any,
  brands?: any,
  hubs?: any
}

let AppContext = React.createContext<AppContextType>(null!);

const useApp = () => {
  return React.useContext(AppContext);
};

function AppProvider({ children }: { children: React.ReactNode }) {

  const [data, setData] = React.useState<any>(fromJS({}));
  let _loadingBrands = false
  let _loadingHubs = false

  const fetchHub = async () => {
    if (_loadingHubs) return
    try {
      _loadingHubs = true
      const rs = await Api.hub.fetchHubList(1, undefined, 200);
      const hubs = rs.data.data;
      setData(
        data.updateIn(['hubs'], () => fromJS(hubs))
      )
    } catch (e: any) {
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      _loadingHubs = false
      return;
    }
  }

  const fetchBrand = async () => {
    try {
      if (_loadingBrands) {
        return
      }
      _loadingBrands = true
      const rs = await Api.brand.fetchBrandList(1, undefined, 100);
      const brands = rs.data.data;
      setData(
        data.updateIn(['brands'], () => fromJS(brands))
      )
    } catch (e: any) {
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      _loadingBrands = false;
      return;
    }
  }

  React.useEffect(() => {
    if (data.get('brands')) {
      _loadingBrands = false;
      fetchHub()
    }
  }, [data.get('brands')])

  React.useEffect(() => {
    if (data.get('hubs')) {
      _loadingHubs = false;
    }
  }, [data.get('hubs')])

  let value = {
    fetchBrand,
    brands: data.get('brands'),
    hubs: data.get('hubs'),
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppProvider;
export { useApp };