import { useEffect, useState } from 'react'
import { FormControl, Row } from 'react-bootstrap'
import useDebounce from '../../../util/hook/useDebounce'

interface BrandFilterType {
  onChange: (name?: string) => void
}

const Brandilter = (props: BrandFilterType) => {
  const [search, setSearch] = useState<string>('')
  const debouncedValue = useDebounce<string>(search, 500)

  useEffect(() => {
    props.onChange(search)
  }, [debouncedValue])
  return (
    <Row>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>Tên thương hiệu</h4>
          <FormControl
            type="text"
            placeholder="Tên thương hiệu"
            value={search}
            onChange={(el) => setSearch(el.target.value)}
          />
        </div>
      </div>
    </Row>
  )
}

export default Brandilter
