import React from 'react'
import {Dropdown} from "react-bootstrap";
import {FaCog, FaEllipsisV} from "react-icons/fa";
import {useNavigate} from "react-router";
export interface BrandItemType {
  brand: any
  index: number
  page: number
}

const BrandItem = (props: BrandItemType) => {
  const { brand, index, page } = props
  const navigate = useNavigate();

  const _onEditClicked = () => {
    navigate(`/brands/${brand.get('_id')}/edit`);
  }

  const _onStoreListClicked = () => {
    navigate(`/stores`, {
      state: {
        brand: brand.toJS()
      }
    });
  }

  return (
    <tr>
      <td>{(page - 1) * 20 + index + 1}</td>
      <td>
        <div>{brand.get('name')}</div>
      </td>
      <td>
        <div>{brand.get('slug')}</div>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onStoreListClicked}>
              Danh sách cửa hàng
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  )
}

export default BrandItem
