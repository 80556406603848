import PageLoading from "../../../components/page_loading";
import {Container, Dropdown, Row} from "react-bootstrap";
import Nav from "../../../components/Navbars/Nav";
import {useClosedLogs} from "../provider";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import SectionLogs from "./section_logs";
import Flatpickr from "react-flatpickr";
import { FaChevronDown } from "react-icons/fa";
import SectionFilterStringItem from "../../../components/Filters/section_filter_string_item";
import { useEffect, useState } from "react";


const Views = () => {

  const logsProvider = useClosedLogs();
  const [dates, setDates] = useState<any>([]);

  const _onBrandChanged = (brand?: any) => {
    logsProvider.onBrandChanged(brand);
  }

  const _onStoresChanged = (stores?: any) => {
    logsProvider.onStoresChanged(stores);
  }

  const _onAppSelected = (app?: any) => {
    logsProvider.onAppChanged(app);
  }

  const _onLocationSelected = (name?: any) => {
    logsProvider.onLocationChanged(name);
  }

  const _onTimeChanged = (e: any) => {
    if (!e || e.length < 2) {
      return
    }
    setDates(e)
  }

  useEffect(() => {
    logsProvider.onDateChanged(dates[0], dates[1])
  }, [dates])

  return (
    <main>
      <Nav title={'Closed Logs'} />
      {
        logsProvider.loading && logsProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <Row>
        <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                APP
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {logsProvider.queries.get('app') ?? 'Tất cả app'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterStringItem
                    value={null}
                    label={'Tất cả'}
                    onSelect={_onAppSelected}/>

                  <SectionFilterStringItem
                    value={'shopee_food'}
                    label={'Shopee Food'}
                    onSelect={_onAppSelected}/>

                  <SectionFilterStringItem
                    value={'grab'}
                    label={'Grab'}
                    onSelect={_onAppSelected}/>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Địa điểm
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {logsProvider.queries.get('location') ?? 'Tất cả'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterStringItem
                    value={null}
                    label={'Tất cả'}
                    onSelect={_onLocationSelected}/>

                  {
                    logsProvider.locations?.map((name: string) => {
                      return (
                        <SectionFilterStringItem
                          key={`loc_${name}`}
                          value={name}
                          label={name}
                          onSelect={_onLocationSelected}/>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <SectionBrandFilter
            columnClassNames="mt-3 col-6 col-xl-3 col-sm-3"
            brandSelected={logsProvider.queries.getIn(['brand'])}
            onBrandSelected={_onBrandChanged}
            onStoresSelected={_onStoresChanged}/>

          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>Thời gian</h4>
              <div className={'mt-1'}>
                <Flatpickr
                  className={'form-control'}
                  value={dates}
                  options={{
                    mode: 'range',
                    dateFormat: "d-m-Y",
                    maxDate: "today",
                  }}
                  onClose={_onTimeChanged}
                />
              </div>
            </div>
          </div>
        </Row>
        <SectionLogs/>
      </Container>
    </main>
  );
}

export default Views;