import { fromJS } from 'immutable'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useApp } from '../../components/AdminLayout/admin_provider'
import {Api} from "../../repository/api";
import {OpenTimeType} from "../../components/StoreOpenTimes/store_open_times";

interface StoreCreatorContextType {
  loading: boolean
  store: any,
  createStore: (data: any) => Promise<boolean>
}
let StoreCreatorContext = React.createContext<StoreCreatorContextType>(null!)

const useStoreCreator = () => {
  return React.useContext(StoreCreatorContext)
}
const StoreCreatorProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [store, setStore] = React.useState<any>(fromJS({}))
  const app = useApp()
  const { storeId } = useParams()

  const _fetchStore = async () => {
    try {
      if (!storeId) return
      setLoading(true)
      const res = await Api.store.fetchDetail(storeId)
      setLoading(false)
      setStore(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _initData = async () => {
    if (!app.brands) {
      app.fetchBrand()
    }
  }

  const createStore = async (data: any) => {
    if (!storeId && !data.brand?._id) {
      window.alert('Vui lòng chọn Thương hiệu của Cửa hàng!')
      return false
    }

    if (data.openTimes.length < 1 || data.openTimes[0].times.length < 1 || data.openTimes[0].times[0].length < 2) {
      window.alert('Vui lòng nhập tối thiểu 1 giờ mở cửa!')
      return false
    }

    data.openTimes = data.openTimes.map((el: OpenTimeType) => ({
      ...el,
      times: el.times.filter((t) => !!t.length)
    }))

    if (typeof store.logo == 'object') {
      const resLogo = await Api.uploadImage(store.logo)
      data.logo = resLogo.data.data.url
    }

    if (typeof store.cover == 'object') {
      const resCover = await Api.uploadImage(store.cover)
      data.cover = resCover.data.data.url
    }

    if (!storeId) {
      data.brand = data.brand._id
      await Api.store.createStore(data)
    } else {
      await Api.store.editStore(storeId, data)
    }
    window.alert((storeId ? 'Cập nhật' : 'Tạo') + ' Cửa hàng thành công!')
    return true
  }

  useEffect(() => {
    if (storeId) {
      _fetchStore()
    }
    _initData()
  }, [])

  const value = { loading, store, createStore }
  return <StoreCreatorContext.Provider value={value}>{children}</StoreCreatorContext.Provider>
}
export { useStoreCreator }
export default StoreCreatorProvider
