import PageLoading from "../../../components/page_loading";
import {Button, Container, Dropdown, Row} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import BackBtn from "../../../components/Button/back_btn";
import {useMenuItemUnits} from "../provider";
import SectionMenuItemUnits from "./section_menu_item_units";
import MenuItemUnitCreator from "../../MenuItemUnitCreator";

const Views = () => {

  const useMenuItemUnitsProvider = useMenuItemUnits();
  const app = useApp();
  const navigate = useNavigate();
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newUnit, setNewUnit] = useState<any>(fromJS({}))

  const _onBackClicked = () => navigate(-1)

  const _onBrandChanged = (brand?: any) => {
    useMenuItemUnitsProvider.onBrandChanged(brand);
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewUnit(fromJS({}))
  }

  const handleSubmitNewUnit = () => {
    if (!newUnit.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newUnit.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    useMenuItemUnitsProvider.onCreateMenuItemUnit(newUnit.toJS())
  }

  return (
    <main>
      <Nav title={'Đơn vị món'} />
      {
        useMenuItemUnitsProvider.loading && useMenuItemUnitsProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <BackBtn onBackClicked={_onBackClicked} label={'Thực đơn'}/>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm đơn vị mới'}
              icon={<FaPlus size={12} />}
              onClick={onCreatorOpen}
            />
          </div>
        </div>
        <Row>
          <SectionBrandFilter
            brandSelected={useMenuItemUnitsProvider.queries.getIn(['brand'])}
            onBrandSelected={_onBrandChanged}/>
        </Row>
        <SectionMenuItemUnits/>
      </Container>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Thêm đơn vị',
          content: <MenuItemUnitCreator unit={newUnit} setUnit={setNewUnit} />,
          confirmationText: 'Thêm'
        }}
        onCancel={onCreatorClose}
        onConfirm={handleSubmitNewUnit}
      />
    </main>
  );
}

export default Views;