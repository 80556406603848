import React, { useState } from 'react';
import SideBar from '../Sidebar';
import './adminlayout.scss';
import { Outlet } from 'react-router-dom';

interface SidebarContextType {
	onCollapseClicked: () => void;
	onToggleClicked: () => void;
}

export const SidebarContext = React.createContext<SidebarContextType>({
	onCollapseClicked(): void {},
	onToggleClicked(): void{}
});

export const useSideBar = () => {
	return React.useContext(SidebarContext);
};

function AdminLayout() {
	const [collapsed, setCollapsed] = useState(false);
	const [toggled, setToggled] = useState(false);
	const sidebarContext = useSideBar();

	const handleCollapsedChange = () => {
		setCollapsed(!collapsed);
	};

	const handleToggleSidebar = () => {
		setToggled(!toggled);
	};

	sidebarContext.onCollapseClicked = handleCollapsedChange
	sidebarContext.onToggleClicked = handleToggleSidebar

	return (
		<div className={`app ${toggled ? 'toggled' : ''}`}>
			<SideBar
				collapsed={collapsed}
				toggled={toggled}/>
			<Outlet/>
		</div>
	);
}

export default AdminLayout;