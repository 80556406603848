import React from "react";
import {numberFormat} from "../../../config";
import {FaStore} from "react-icons/fa";

interface CustomerItemType {
  customer: any,
  index: number,
  page: number
}

const CustomerItem = (props: CustomerItemType) => {

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td className={'text-wrap'}>
        {props.customer.get('phone')}
      </td>
      <td>
        {props.customer.getIn(['displayNames', 0])}
      </td>
      <td>
        {numberFormat.format(props.customer.getIn(['order', 'totalOrderAmount']))}
      </td>
      <td>
        <img src={'https://image.dichung.vn/60f79ca0788f73001cf48013-logograbfood.jpg'} width={20} height={20}/>
        {` `}
        {props.customer.getIn(['order', 'grabOrder'])}
        <img src={'https://image.dichung.vn/60f79cde788f73001cf48015-logobaemin.jpg'} width={20} height={20} style={{marginLeft: 32}}/>
        {` `}
        {props.customer.getIn(['order', 'baeminOrder'])}
        <FaStore size={20} color={'#cecece'} style={{marginLeft: 32}}/>
        {` `}
        {props.customer.getIn(['order', 'otherOrder'])}
      </td>
      <td>

      </td>
    </tr>
  )
}

export default CustomerItem;