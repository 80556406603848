import {Container, Dropdown, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {useFoodAppConnect} from "../provider";
import {useLocation, useNavigate} from "react-router";
import BackBtn from "../../../components/Button/back_btn";
import UserPasswordApp from "./user_password_app";
import ShopeeFoodApp from "./shopee_food_app";
import {foodApps} from "../../../util/common_utils";
import GofoodApp from "./gofood_app";
import BefoodApp from "./befood_app";

const Views = () => {

  const foodAppConnectProvider = useFoodAppConnect();
  const location: any = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!!location.state?.store) {
      foodAppConnectProvider.initData(location.state.store);
    } else {
      navigate(-1)
    }
  }, [])

  const _onBackClicked = () => {
    navigate(-1)
  }

  return (
    <main>
      <Nav title={'Kết nối Food App'}/>
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Cửa hàng'}/>
      </Container>
      <Container>
        <Row>
          <div className={'mt-3 col'}>
            <h4 className={'section-title border-bottom pb-2'}>
              {foodAppConnectProvider.store?.getIn(['brand', 'name'])} | {foodAppConnectProvider.store?.get('name')}
            </h4>
          </div>
        </Row>
        <Row>
          <UserPasswordApp providerName={foodApps.baemin}/>
          <UserPasswordApp providerName={foodApps.grab}/>
          <ShopeeFoodApp/>
          <GofoodApp/>
          <BefoodApp/>
        </Row>
      </Container>
    </main>
  );
}

export default Views;