import React from 'react';
import './styles.css';
import OrderCreatorProvider from "./provider";
import Views from "./components/views";

const OrderCreator = () => {

  return (
    <OrderCreatorProvider>
      <Views/>
    </OrderCreatorProvider>
  );
};

export default OrderCreator;