import React, { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { useBankConfig } from "./bank_config_provider";
import { Container, Dropdown, Form, FormControl, Stack } from "react-bootstrap";
import { BankModalType } from "../../../repository/model/enums/BankModalType";
import { BankInfoType } from "./bank_config_modal";

interface BankConfigFormPropType {
  store: any
  bankInfo: any
  type?: BankModalType | BankModalType.store
  setBankInfo: Dispatch<any>
  validated: boolean
}

const BankConfigForm: React.FC<BankConfigFormPropType> = ({ store, bankInfo, type, setBankInfo, validated }) => {
  const { banks } = useBankConfig()
  const [bankSelected, setBankSelected] = useState<string>()

  const bankOptions = banks.filter((bank: any) => {
    return (
      !bankSelected ||
      bank.get('name', '').toLowerCase().includes(bankSelected.toLowerCase()) ||
      bank.get('shortName', '').toLowerCase().includes(bankSelected.toLowerCase())
    )
  })

  const _onAccountNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setBankInfo(bankInfo.updateIn(['accountName'], () => e.target.value.toUpperCase()))
  }

  const _onAccountNumberChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setBankInfo(bankInfo.updateIn(['accountNumber'], () => e.target.value))
  }

  const _onBankChanged = (bank: any) => {
    setBankInfo(bankInfo.updateIn(['bank'], () => bank))
    setBankSelected(bank.get('shortName', '') + ' - ' + bank.get('name'))
  }

  const _onValueChanged = (value?: string) => {
    if (!value) {
      setBankInfo(bankInfo.updateIn(['bank'], () => undefined))
    }
    setBankSelected(value)
  }

  useEffect(() => {
    if (bankInfo.get('bank')?.size) {
      setBankSelected(bankInfo.getIn(['bank', 'shortName'], '') + ' - ' + bankInfo.getIn(['bank', 'name'], ''))
    }
  }, [])

  return <Container>
    <Form autoComplete='off' validated={validated}>
      {
        type === BankModalType.store ?
          <div>
            <div>
              Thương hiệu: {store.getIn(['brand', 'name'], '')}
            </div>
            <p>
              Cửa hàng: {store.getIn(['name'], '')}
            </p>
          </div> :
          <div className="mb-2">
            Điểm bán: {store.getIn(['name'], '')}
          </div>
      }

      <Stack gap={3}>
        <Form.Group>
          <Form.Control
            required
            type='text' placeholder='Tên tài khoản *'
            value={bankInfo.get('accountName', '')}
            onChange={_onAccountNameChanged} />
        </Form.Group>

        <Dropdown>
          <Dropdown.Toggle
            className="filter text-start w-100 p-0"
            variant={'light'}
          >
            <FormControl
              required
              className="me-3 w-100"
              placeholder="Ngân hàng *"
              onChange={(e: any) => _onValueChanged(e.target.value)}
              value={bankSelected ?? ''}
            />
          </Dropdown.Toggle>
          {!!bankOptions.size && <Dropdown.Menu className='w-100'>
            {bankOptions.map((bank: any, index: number) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => _onBankChanged(bank)}
                >
                  {bank.get('shortName', '') + ' - ' + bank.get('name')}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>}
        </Dropdown>

        <Form.Group>
          <Form.Control
            required
            type='text' placeholder='Số tài khoản *'
            value={bankInfo.get('accountNumber', '')}
            onChange={_onAccountNumberChanged} />
        </Form.Group>
      </Stack>
    </Form>
  </Container>
}

export default BankConfigForm