import React from "react";
import Views from "./components/views";
import BaeminPlatformOrdersProvider from "./provider";

const BaeminPlatformOrders = () => {

  return (
    <BaeminPlatformOrdersProvider>
      <Views/>
    </BaeminPlatformOrdersProvider>
  );
};

export default BaeminPlatformOrders;