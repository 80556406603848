import React, { useEffect, useState } from 'react'
import { FormLabel, Stack } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import OpenTimeItem from './open_time_item'

const DAYS = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN']

export type OpenTimeType = {
  times: Array<Array<string>>
  day: string
}

type OpenTimeProps = {
  openTimes: any;
  onOpenTimeChanged: (openTimes: OpenTimeType[]) => void
}

const StoreOpenTimes: React.FC<OpenTimeProps> = (props) => {
  const [openTimes, setOpenTimes] = useState<OpenTimeType[]>([])

  const handleAdd = () => {
    if (openTimes.length >= 7) {
      window.alert('Không thể  thực hiện!')
      return
    }
    setOpenTimes([
      ...openTimes,
      { day: DAYS.find((e) => !openTimes.find((o) => o.day === e)) || 'T2', times: [[]] }
    ])
    console.log({ day: DAYS.find((e) => !openTimes.find((o) => o.day === e)) || 'T2', times: [[]] })
  }

  const handleDelete = (index: number) => {
    setOpenTimes([...openTimes.filter((el, i) => i !== index)])
  }

  const handleDaychanged = (value: string, index: number) => {
    if (openTimes.map((el) => el.day).includes(value)) {
      window.alert(`${value} đã tồn tại.`)
      return
    }
    openTimes[index].day = value
    setOpenTimes([...openTimes])
  }

  const handleTimeChanged = (times: Array<string[]>, index: number) => {
    openTimes[index].times = times
    setOpenTimes([...openTimes])
  }

  useEffect(() => {
    props.onOpenTimeChanged(openTimes)
  }, [openTimes])

  useEffect(() => {
    if (props.openTimes && (!openTimes || openTimes.length < 1)) {
      if (Array.isArray(props.openTimes)) return
      if (props.openTimes.size > 0) {
        let openTimes = props.openTimes.toJS()
        setOpenTimes(openTimes)
      }
    }
  }, [props.openTimes])

  return (
    <>
      <div>
        <FormLabel>
          Giờ mở cửa <span className="text-danger fs-5">*</span>
        </FormLabel>
        <FaPlus size="25" className="btn text-primary p-0 ps-1" color="red" onClick={handleAdd} />
      </div>
      <div>
        <Stack gap={2}>
          {!!openTimes &&
            openTimes.map((item, index) => (
              <div className="d-flex" key={index}>
                {openTimes.length > 1 && (
                  <div className="m-2 align-items-center d-flex justijy-content-center">
                    <FaPlus
                      className="ms-2 mt-2 btn p-0"
                      style={{ transform: 'rotate(45deg)' }}
                      size="30"
                      color="red"
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                )}
                <OpenTimeItem
                  openTime={openTimes}
                  openTimeItem={item}
                  key={index}
                  index={index}
                  dayOptions={DAYS}
                  handleDayChanged={handleDaychanged}
                  handleTimeChanged={handleTimeChanged}
                />
              </div>
            ))}
        </Stack>
      </div>
    </>
  )
}

export default StoreOpenTimes
