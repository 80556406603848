import {fromJS} from 'immutable'
import {useEffect, useState} from 'react'
import {Button, Dropdown, FormControl, FormLabel, Modal, Row, Stack} from 'react-bootstrap'
import {TiDelete} from 'react-icons/ti'
import {dishplaceholder} from '../../../util/myUtil'
import DropdownItem from './dropdownItem'
import MenuItemCard from './menuItemCard'
import SelectPick from './sectionPick'
import {STATUS} from "../../../util/constant";
import {useWebsiteHomePageConfig} from "../provider";

interface FormBothModalProps {
  index?: number
  config?: any
  show: boolean
  handleClose: () => void
  onConfigChanged: (config: any, index: number) => void
  handleAddConfig?: (config: any) => void
}

const FormBothModal: React.FC<FormBothModalProps> = (
  {
    config,
    index,
    show,
    handleClose,
    onConfigChanged,
    handleAddConfig
  }) => {
  const {queries, banners, onBannerNameChanged, setQueries} = useWebsiteHomePageConfig()
  const [dataConfig, setDataConfig] = useState<any>(
    fromJS({
      ui: 'BOTH',
      title: '',
      link: '',
      btnLink: '',
      btnLabel: '',
      banners: [],
      menuItems: [],
      pickType: ''
    })
  )

  const bannerOptions = banners.filter((banner: any) => {
    let check = false
    if (dataConfig.get('banners')) {
      check = !!dataConfig.get('banners').find((b: any) => b.get('_id') === banner.get('_id'))
    }
    return (
      (!queries.getIn(['bannerSearch', 'name'], '') ||
        banner
          .get('name', '')
          .toLowerCase()
          .includes(queries.getIn(['itemSearch', 'name'], '').toLowerCase())) &&
      !check
    )
  })

  const handleAddBanner = (banner: any) => {
    let temp = dataConfig.get('banners').push(banner)
    setDataConfig(dataConfig.updateIn(['banners'], () => temp))
  }

  const handleDeleteBanner = (banner: any) => {
    setDataConfig(
      dataConfig.updateIn(['banners'], (e: any) => e.filter((el: any) => el !== banner))
    )
  }

  const handleValueChanged = (key: string, value: any) => {
    setDataConfig(dataConfig.updateIn([key], () => value))
  }

  const handleCardDelete = (menuItem: any) => {
    let temp = dataConfig.get('menuItems').filter((el: any) => el !== menuItem)
    setDataConfig(dataConfig.updateIn(['menuItems'], () => temp))
  }

  const _onMenuItemChanged = (menuItem: any) => {
    handleValueChanged('menuItems', fromJS(menuItem))
  }

  const handleSubmit = () => {
    if (typeof index != 'undefined') {
      onConfigChanged(dataConfig, index)
    }

    !!handleAddConfig && handleAddConfig(dataConfig)
    handleClose()
  }

  useEffect(() => {
    setDataConfig(dataConfig.updateIn(['menuItems'], () => fromJS([])))
  }, [dataConfig.get('pickType')])

  useEffect(() => {
    if (config) {
      setDataConfig(config)
    }
  }, [show, config])
  return (
    <Modal
      dialogClassName="mw-90"
      size="xl"
      show={show}
      onHide={() => {
        setQueries(
          queries
            .updateIn(['bannerSearch', 'page'], () => 1)
            .updateIn(['itemSearch', 'brand'], () => undefined)
        )
        handleClose()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{!isNaN(Number(index)) ? 'Chỉnh sửa' : 'Thêm'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3} className="mb-3">
          <FormControl
            className="col-12 col-sm-6"
            type="text"
            placeholder="Tiêu đề"
            value={dataConfig.get('title')}
            onChange={(el) => handleValueChanged('title', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Đường dẫn tiêu đề"
            value={dataConfig.get('link')}
            onChange={(el) => handleValueChanged('link', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Tiêu đề nút xem thêm"
            value={dataConfig.get('btnLabel')}
            onChange={(el) => handleValueChanged('btnLabel', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Đường dẫn nút xem thêm"
            value={dataConfig.get('btnLink')}
            onChange={(el) => handleValueChanged('btnLink', el.target.value)}
          />
        </Stack>

        <Stack gap={1} className="mb-3 mt-5">
          <FormLabel className="fw-bolder">Banners</FormLabel>
          <Row className="col-12">
            <div className="col-12 col-md-4">
              <Dropdown>
                <Dropdown.Toggle
                  className={'filter w-100 text-start'}
                  variant={'light'}
                  style={{whiteSpace: 'normal'}}
                >
                  <FormControl
                    className="my-2 w-auto"
                    placeholder="Tìm kiếm..."
                    onChange={(e: any) => onBannerNameChanged(e.target.value)}
                    value={queries.getIn(['bannerSearch', 'name'], '')}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {!bannerOptions.size ? (
                    <Dropdown.Item>{'Không có dữ liệu!'}</Dropdown.Item>
                  ) : (
                    bannerOptions.map((item: any, index: number) => (
                      <DropdownItem
                        key={index}
                        isBanner
                        index={index}
                        disabled={item.get('status') === STATUS.INACTIVE}
                        text={item.get('name')}
                        imageName={item.get('name')}
                        imageUrl={
                          item.get('desktop_image_url') ||
                          item.get('mobile_image_url') ||
                          dishplaceholder
                        }
                        thresholdIndex={bannerOptions.size - 1}
                        handlePageChanged={() =>
                          setQueries(
                            queries.updateIn(['bannerSearch', 'page'], (e: number) => ++e)
                          )
                        }
                        handleClick={() => handleAddBanner(item)}
                      />
                    ))
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Row>

          <Stack
            gap={2}
            direction="horizontal"
            className="overflow-scroll bg-gradient bg-light"
            style={{minHeight: 200}}
          >
            {!!dataConfig.get('banners') &&
              dataConfig.get('banners').map((banner: any, index: number) => (
                <div key={index} className="col-12 col-sm-6 col-lg-4 position-relative">
                  <img
                    src={
                      banner.get('desktop_image_url') ||
                      banner.get('mobile_image_url') ||
                      dishplaceholder
                    }
                    alt={banner.get('name')}
                    style={{
                      width: '100%',
                      maxHeight: 200,
                      minHeight: 200,
                      height: ' auto',
                      objectFit: 'contain'
                    }}
                    className="img-thumbnail"
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    color="#d8d8d8"
                    className="position-absolute"
                    style={{right: 1}}
                    onClick={() => handleDeleteBanner(banner)}
                  />
                </div>
              ))}
          </Stack>
        </Stack>

        <Stack>
          <FormLabel className="fw-bolder">Sản phẩm</FormLabel>
          <SelectPick
            initType={dataConfig.get('pickType')}
            menuItems={dataConfig.get('menuItems')}
            onMenuItemChanged={_onMenuItemChanged}
            onPickTypeChanged={handleValueChanged}
          />
        </Stack>

        <Stack
          gap={2}
          direction="horizontal"
          className="overflow-scroll"
          style={{minHeight: 270}}
        >
          {!!dataConfig.get('menuItems') &&
            dataConfig
              .get('menuItems')
              .map((el: any, index: number) => (
                <MenuItemCard key={index} value={el} handleCardDelete={handleCardDelete}/>
              ))}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        {dataConfig.get('menuItems')?.size ? (
          <Button variant="outline-primary" onClick={handleSubmit}>
            {!isNaN(Number(index)) ? 'Xác nhận' : 'Thêm'}
          </Button>
        ) : (
          <Modal.Title>Vui lòng chọn tối thiểu 1 sản phẩm</Modal.Title>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default FormBothModal
