import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useWebsiteMenuItemOptions} from "../provider";
import MenuItemOption from "./menu_item_option";

const SectionMenuItemOptions = () => {
  const menuItemOptionsProvider = useWebsiteMenuItemOptions();

  if (menuItemOptionsProvider.data.size < 1 && menuItemOptionsProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Tùy chọn</th>
          <th>Thương hiệu</th>
          <th>Nguồn đơn</th>
          <th>Chọn tối đa</th>
          <th>Lựa chọn</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          menuItemOptionsProvider.data.map((item: any, index: number) => {
            return <MenuItemOption
              key={`menu_item_${item.get('_id')}`}
              index={index}
              page={menuItemOptionsProvider.queries.get('page')}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!menuItemOptionsProvider.data && menuItemOptionsProvider.data.size >= 20) ||
          menuItemOptionsProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={menuItemOptionsProvider.queries.get('page')}
          onPreviousPage={menuItemOptionsProvider.onPreviousPage}
          onNextPage={menuItemOptionsProvider.onNextPage}
          onFirstPage={menuItemOptionsProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionMenuItemOptions;