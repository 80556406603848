import React from "react";
import StoreCreatorForm from "./components/StoreCreatorForm";
import StoreCreatorProvider from "./provider";

const StoreCreator = () => {
  return (
    <StoreCreatorProvider>
      <StoreCreatorForm />
    </StoreCreatorProvider>
  );
};

export default StoreCreator;
