import React from 'react'
import { fromJS } from 'immutable'
import { HubApi } from '../../repository/api/hubs'

interface HubContextType {
  queries: any
  data: any
  loading: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  onFirstPage: () => void
  onSearchChanged: (hub?: any) => void
  meta: any
  deleteHub: (hubId: string) => void
  onBankInfoChanged: (hub: any) => void
}

let HubContext = React.createContext<HubContextType>(null!)

const useHubs = () => {
  return React.useContext(HubContext)
}

const HubProvider: React.FC = ({ children }) => {
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>(fromJS({}))
  const [loading, setLoading] = React.useState(false)

  const _fetchHubs = async () => {
    try {
      const rs = await HubApi.fetchHubList(queries.get('page'), queries.get('search'))
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const deleteHub = async (hubId: string) => {
    try {
      await HubApi.deleteHub(hubId)
      window.alert('Xóa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _refresh = async () => {
    setLoading(true)

    const list: any = await _fetchHubs()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSearchChanged = (hub?: any) => {
    setQueries(queries.updateIn(['search'], () => hub))
  }

  const onBankInfoChanged = (hub: any) => {
    if (!hub) return
    const itemIndex = data.findIndex((item: any) => item.get('_id') === hub._id)
    if(itemIndex != -1) return 
    setData(data.updateIn([itemIndex, 'bankInfo'], () => fromJS(hub.bankInfo)))
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData()
    }
  }, [])

  React.useEffect(() => {
    if (queries.size < 1) return
    _refresh()
  }, [queries])

  let value = {
    queries,
    data,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSearchChanged,
    meta,
    deleteHub,
    onBankInfoChanged
  }

  return <HubContext.Provider value={value}>{children}</HubContext.Provider>
}

export default HubProvider
export { useHubs }
