import {fromJS} from 'immutable'
import {useEffect, useState} from 'react'
import {Button, Dropdown, FormControl, Modal, Row, Stack} from 'react-bootstrap'
import {TiDelete} from 'react-icons/ti'
import {dishplaceholder} from '../../../util/myUtil'
import DropdownItem from './dropdownItem'
import {STATUS} from "../../../util/constant";
import {useWebsiteHomePageConfig} from "../provider";

interface ModalFormBannerProps {
  index?: number
  config?: any
  onConfigChanged: (config: any, index: number) => void
  show: boolean
  handleClose: () => void
  handleAddConfig?: (config: any) => void
}

const ModalFormBanner: React.FC<ModalFormBannerProps> = (
  {
    config,
    index,
    show,
    handleClose,
    onConfigChanged,
    handleAddConfig
  }) => {
  const {banners, onBannerNameChanged, queries, setQueries} = useWebsiteHomePageConfig()
  const [dataConfig, setDataConfig] = useState<any>(
    fromJS({
      ui: 'BANNER',
      title: '',
      link: '',
      btnLink: '',
      btnLabel: '',
      banners: [],
      menuItems: []
    })
  )

  const bannerOptions = banners.filter((banner: any) => {
    let check = false
    if (dataConfig.get('banners')) {
      check = !!dataConfig.get('banners').find((b: any) => b.get('_id') === banner.get('_id'))
    }
    return (
      (!queries.getIn(['itemSearch', 'name'], '') ||
        banner
          .get('name', '')
          .toLowerCase()
          .includes(queries.getIn(['itemSearch', 'name'], '').toLowerCase())) &&
      !check
    )
  })
  const handleAddBanner = (banner: any) => {
    let temp = dataConfig.get('banners').push(banner)
    setDataConfig(dataConfig.updateIn(['banners'], () => temp))
  }

  const handleDeleteBanner = (banner: any) => {
    let temp = dataConfig.get('banners').filter((el: any) => el !== banner)
    setDataConfig(dataConfig.updateIn(['banners'], () => temp))
  }

  const handleValueChanged = (key: string, value: any) => {
    setDataConfig(dataConfig.updateIn([key], () => value))
  }

  const handleSubmit = () => {
    if (typeof index != 'undefined') {
      onConfigChanged(dataConfig, index)
    }
    !!handleAddConfig && handleAddConfig(dataConfig)
    handleClose()
  }

  useEffect(() => {
    if (config) {
      setDataConfig(config)
    }
  }, [show, config])

  return (
    <Modal
      dialogClassName="mw-90"
      size="xl"
      show={show}
      onHide={() => {
        setQueries(queries.updateIn(['bannerSearch', 'page'], () => 1))
        handleClose()
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{!isNaN(Number(index)) ? 'Chỉnh sửa' : 'Thêm'} Banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3} className="mb-3">
          <FormControl
            type="text"
            placeholder="Tiêu đề"
            value={dataConfig.get('title')}
            onChange={(el) => handleValueChanged('title', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Đường dẫn tiêu đề"
            value={dataConfig.get('link')}
            onChange={(el) => handleValueChanged('link', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Tiêu đề nút xem thêm"
            value={dataConfig.get('btnLabel')}
            onChange={(el) => handleValueChanged('btnLabel', el.target.value)}
          />

          <FormControl
            type="text"
            placeholder="Đường dẫn nút xem thêm"
            value={dataConfig.get('btnLink')}
            onChange={(el) => handleValueChanged('btnLink', el.target.value)}
          />
        </Stack>

        <Row className="col-12 mb-3">
          <div className="mt-3 col-12 col-md-4">
            <Dropdown>
              <Dropdown.Toggle
                className={'filter w-100 text-start'}
                variant={'light'}
                style={{whiteSpace: 'normal'}}
              >
                <FormControl
                  className="my-2 w-auto"
                  placeholder="Tìm kiếm..."
                  onChange={(e: any) => onBannerNameChanged(e.target.value)}
                  value={queries.getIn(['bannerSearch', 'name'], '')}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {!bannerOptions.size ? (
                  <Dropdown.Item>{'Không có dữ liệu!'}</Dropdown.Item>
                ) : (
                  bannerOptions.map((item: any, index: number) => (
                    <DropdownItem
                      key={index}
                      isBanner
                      index={index}
                      disabled={item.get('status', '') === STATUS.INACTIVE}
                      text={item.get('name')}
                      imageName={item.get('name')}
                      imageUrl={
                        item.get('desktop_image_url') ||
                        item.get('mobile_image_url') ||
                        dishplaceholder
                      }
                      thresholdIndex={bannerOptions.size - 1}
                      handlePageChanged={() =>
                        setQueries(queries.updateIn(['bannerSearch', 'page'], (e: number) => ++e))
                      }
                      handleClick={() => handleAddBanner(item)}
                    />
                  ))
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Row>

        <Stack
          gap={2}
          direction="horizontal"
          className="overflow-scroll bg-gradient bg-light"
          style={{minHeight: 200}}
        >
          {!!dataConfig.get('banners') &&
            dataConfig.get('banners').map((banner: any, index: number) => (
              <div key={index} className="col-12 col-sm-6 col-lg-4 position-relative">
                <img
                  src={banner.get('desktop_image_url') || dishplaceholder}
                  alt={banner.get('desktop_image_url')}
                  style={{
                    width: '100%',
                    maxHeight: 200,
                    minHeight: 200,
                    height: ' auto',
                    objectFit: 'contain'
                  }}
                  className="img-thumbnail"
                />
                <TiDelete
                  fontSize={25}
                  cursor="pointer"
                  color="#d8d8d8"
                  className="position-absolute"
                  style={{right: 1}}
                  onClick={() => handleDeleteBanner(banner)}
                />
              </div>
            ))}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        {dataConfig.get('banners')?.size ? (
          <Button variant="outline-primary" onClick={handleSubmit}>
            {!isNaN(Number(index)) ? 'Xác nhận' : 'Thêm'}
          </Button>
        ) : (
          <Modal.Title>Vui lòng chọn tối thiểu 1 banner</Modal.Title>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ModalFormBanner
