import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

interface UserItemType {
  user: any,
  index: number,
  page: number
}

const UserItem = (props: UserItemType) => {
  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <Link to={`/users/${props.user.get('_id')}`} target={'_blank'}>
          <strong className={'order-code p-0'}>
            {props.user.get('phone')}
          </strong>
        </Link>
      </td>
      <td>
        {props.user.getIn(['username'])}
      </td>
      <td>
        {props.user.getIn(['email'])}
      </td>
      <td>
        {moment(props.user.get('createdAt')).local().format('HH:mm, DD/MM/YYYY')}
      </td>
    </tr>
  )
}

export default UserItem;