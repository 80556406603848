import React, {createContext, useContext, useEffect, useState} from 'react'
import {fromJS} from 'immutable'
import {Api} from '../../repository/api'
import {useApp} from '../../components/AdminLayout/admin_provider'
import {useParams} from 'react-router'
import {useLocation, useNavigate} from "react-router-dom"

interface WebsitePromotionContextType {
  queries: any
  data: any
  website: any
  loading: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  onFirstPage: () => void
  onSearchChanged: (store?: any) => void
  meta: any
  onDelete: (id: string) => void
  updateStatusItem: (item: any) => void
}

let WebsitePromotionContext = createContext<WebsitePromotionContextType>(null!)

const useWebsitePromotion = () => {
  return useContext(WebsitePromotionContext)
}

const WebsitePromotionProvider: React.FC = ({children}) => {
  const [queries, setQueries] = useState<any>(fromJS({}))
  const [data, setData] = useState<any>(fromJS([]))
  const [website, setWebsite] = useState<any>(fromJS([]))
  const [meta, setMeta] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const app = useApp()
  const {websiteId, promoId} = useParams()
  const location = useLocation()

  const _fetchWebsite = async () => {
    try {
      if (websiteId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(websiteId)
        setLoading(false)
        setWebsite(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  React.useEffect(() => {
    if (!loading && website.size < 1) {
      _fetchWebsite()
    }
  }, [websiteId])

  const _fetchPromotions = async () => {
    try {
      const rs = await Api.webPromotion.fetchPromotionList({
        search: queries.get('search'),
        majorBrand: websiteId,
        page: queries.get('page'),
        limit: 20,
        brandFields: 'name,slug'
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onDelete = async (id: string) => {
    try {
      await Api.webPromotion.deletePromotion(id)
      window.alert('Xóa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _refresh = async () => {
    setLoading(true)

    const list: any = await _fetchPromotions()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    if (!app.brands) {
      await app.fetchBrand()
    }
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSearchChanged = (store?: any) => {
    setQueries(queries.updateIn(['search'], () => store))
  }

  const updateStatusItem = async (item: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === item.get('_id'))

    if (itemIndex < 0) return
    const lastStatus = item.get('status')
    try {
      if (item.get('status') === 'active') {
        setData(data.updateIn([itemIndex, 'status'], () => 'inactive'))
        await Api.webPromotion.inActivePromotion(item.get('_id'))
      } else {
        setData(data.updateIn([itemIndex, 'status'], () => 'active'))
        await Api.webPromotion.activePromotion(item.get('_id'))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.updateIn([itemIndex, 'status'], () => lastStatus))
    }
  }

  useEffect(() => {
    if (queries.size > 1 || location.pathname.includes('create') || promoId) return
    _initQueries()
  }, [app.brands])

  useEffect(() => {
    if (queries.size < 1) return
    _refresh()
  }, [queries])

  useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData()
    }
  }, [])

  let value = {
    queries,
    data,
    website,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSearchChanged,
    meta,
    onDelete,
    updateStatusItem
  }

  return <WebsitePromotionContext.Provider value={value}>{children}</WebsitePromotionContext.Provider>
}

export default WebsitePromotionProvider
export {useWebsitePromotion}
