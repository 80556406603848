import {useUserProfile} from "../provider";
import {Form, FormControl, FormLabel, Row, Spinner, Button, InputGroup, Badge, Table} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { fromJS } from "immutable";
import { FaCheck, FaRegUserCircle } from "react-icons/fa";
import SectionPermissionsBrandItem from "./section_permissions_brand_item";

const SectionPermissionsBrand = () => {
  const provider = useUserProfile();
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className={'mt-3 col-12 col-xl-6 col-sm-6'}>
      <div className={'shadow-card'}>
        <h5>THƯƠNG HIỆU</h5>
        <Table striped hover responsive>
          <thead>
          <tr>
            <th>STT</th>
            <th>Tên</th>
            <th>SĐT</th>
            <th>Quyền</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            {
              provider.brandPermissions?.map((b: any, index: number) => {
                return (
                  <SectionPermissionsBrandItem
                    key={`brand_${index}_${b.get('_id')}`}
                    brand={b}
                    index={index}/>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default SectionPermissionsBrand;