import { AxiosResponse } from 'axios'
import {_get, _getVietQr, _post, _put, _delete} from './request'
import {BankInfoType} from "../../views/Stores/BankConfig";
import {MenuItemSource} from "../model/menuItem";
import {PAYMENT_METHOD} from "../../util/common_utils";

export const StoreApi = {
  fetchPlatforms(store: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platforms`)
  },
  fetchOrderCrawlers(brandId?: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/order/crawlers`, { brand: brandId })
  },
  saveOrderCrawler(
    storeId: string,
    platform: string,
    autoPull: boolean
  ): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${storeId}/order/crawler`, {
      platform,
      autoPull
    })
  },
  fetchStoreAll(): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/all`)
  },
  fetchStores(page: number, search?: string, brandId?: string, limit: number = 20): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores`, {
      page, search, brandId, limit
    })
  },
  createStore(store: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores`, store)
  },
  editStore(storeId: string, store: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/stores/${storeId}`, store)
  },
  fetchDetail(storeId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${storeId}`)
  },
  fetchBankList(): Promise<AxiosResponse<any>> {
    return _getVietQr(`banks`)
  },
  updateBankInfo(id: string, bankInfo: BankInfoType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/stores/${id}/bank`, bankInfo)
  },
  deleteStore(storeId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/stores/${storeId}`)
  },
  fetchSources(storeId?: string, page: number = 1, limit: number = 20, group?: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/sources`, {
      store: storeId, page, limit, group
    })
  },
  createSource(storeId: any, source: MenuItemSource): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/sources`, {
      name: source.name,
      commission: source.commission,
      paymentMethod: source.paymentMethod ?? PAYMENT_METHOD.online.key,
      storeId,
      pickingTableEnabled: source?.pickingTableEnabled
    })
  },
  editSource(sourceId: string, source: MenuItemSource): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/sources/${sourceId}`, {
      name: source?.name,
      commission: source?.commission,
      paymentMethod: source?.paymentMethod ?? PAYMENT_METHOD.online.key,
      pickingTableEnabled: source?.pickingTableEnabled
    })
  },
  editSourceTableConfig(sourceId: string,pickingTableEnabled:boolean): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/sources/${sourceId}/table-picking`, {
      pickingTableEnabled: pickingTableEnabled
    })
  },
  deleteSource(sourceId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/sources/${sourceId}`)
  },
  getSource(sourceId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/sources/${sourceId}`)
  },
}
