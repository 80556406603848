import { fromJS } from 'immutable'
import { useEffect, useState } from 'react'
import { Button, Dropdown, Form, FormControl, Modal, Stack, Table } from 'react-bootstrap'
import Chip from '../../../components/Chip/Chip'
import useDebounce from '../../../util/hook/useDebounce'
import { useWebsiteCreatorForm } from '../provider'
import SubBrandItem from './subBrandItem'

interface SubBrandTableProps {
  handleSubBrandAdd: (brand: any) => void
  brandList: any
  handleSubBrandDeleted: (index: number) => void
}

const SubBrandTable: React.FC<SubBrandTableProps> = ({
  handleSubBrandAdd,
  brandList,
  handleSubBrandDeleted
}) => {
  const [show, setShow] = useState<boolean>(false)
  const { subBrands, setQueries, queries, setSubBrand } = useWebsiteCreatorForm()
  const [value, setValue] = useState<string>('')
  const [brands, setBrand] = useState<any>(fromJS([]))
  const debouncedValue = useDebounce<string>(value, 500)

  const handleClose = () => {
    setValue('')
    setShow(false)
    setBrand(fromJS([]))
    setQueries(fromJS({}))
  }

  const onBrandChanged = (brand: any) => {
    setBrand(brands.push(brand))
  }

  const onBrandDeleted = (index: number) => {
    setBrand(brands.delete(index))
  }

  const onSubmit = () => {
    handleSubBrandAdd(brands.map((brand: any) => brand.get('brand')))
    handleClose()
  }

  useEffect(() => {
    if (value) {
      setQueries(queries.updateIn(['search'], () => value))
    } else {
      setQueries(queries.updateIn(['search'], () => undefined))
    }
    setSubBrand(fromJS([]))
  }, [debouncedValue])

  return (
    <div className={'mt-3'}>
      <Stack direction="horizontal" className="justify-content-between">
        <Form.Label>
          Danh sách thương hiệu <span className="text-danger fs-5">*</span>
        </Form.Label>

        <Button variant="primary" onClick={() => setShow(true)} className={'btn-sm'}>
          Thêm thương hiệu
        </Button>
      </Stack>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>Tên thương hiệu</th>
            <th>Slug</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!brandList &&
            brandList.map((brand: any, index: number) => {
              return (
                <SubBrandItem
                  brand={brand}
                  key={index}
                  index={index}
                  handleSubBrandDeleted={handleSubBrandDeleted}
                />
              )
            })}
        </tbody>
      </Table>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thêm thương hiệu</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3">
            <Dropdown>
              <Dropdown.Toggle
                className={'filter w-100 text-start'}
                variant={'light'}
                style={{ whiteSpace: 'normal' }}
              >
                <FormControl
                  className="mx-3 my-2 w-auto"
                  placeholder="Tìm kiếm..."
                  onChange={(e: any) => {
                    setValue(e.target.value)
                  }}
                  value={value}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {subBrands.size ? (
                  subBrands.map((brand: any, index: number) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => onBrandChanged(brand)}
                      disabled={
                        brands.includes(brand) ||
                        brandList?.find(
                          (el: any) => el.get('_id') === brand.getIn(['brand', '_id'])
                        )
                      }
                    >
                      {brand.get('text')}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item>Vui lòng nhập tối thiểu 1 ký tự</Dropdown.Item>
                )}
              </Dropdown.Menu>

              <Stack direction="horizontal" gap={2} className="mt-3 flex-wrap">
                {!!brands &&
                  brands.map((brand: any, index: number) => (
                    <Chip
                      key={index}
                      text={brand.get('text')}
                      data={brand}
                      onDeleteClicked={() => onBrandDeleted(index)}
                    />
                  ))}
              </Stack>
            </Dropdown>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit}>
            Thêm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default SubBrandTable
