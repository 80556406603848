import {Row} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import SectionPermissionsBrand from "./section_permissions_brand";
import SectionPermissionsOutlet from "./section_permissions_outlet";
import UserOutletPermissionProvider from "../user_outlet_permission_provider";

const SectionPermissions = () => {
  return (
    <Row>
      <SectionPermissionsBrand/>
      <UserOutletPermissionProvider>
        <SectionPermissionsOutlet/>
      </UserOutletPermissionProvider>
    </Row>
  )
}

export default SectionPermissions;