import { AxiosResponse } from 'axios'
import {_delete, _get, _post, _put} from './request'
import {Brand} from "../model/Brand";

export const BrandApi = {
  fetchBrands(): Promise<AxiosResponse<any>> {
    return _get(`brand/v1/brands`)
  },
  fetchBrandList(page: number, brand?: any, limit: number = 20): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/brands`, {
      search: brand,
      page: page,
      // offset: (page - 1) * 20,
      limit
    })
  },
  fetchBrand(brandId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/brands/${brandId}`)
  },
  createBrand(brand: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/brands/brands`, brand)
  },
  editBrand(brand: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/brands/brands/${brand._id}`, brand)
  },
  fetchMajorBrandList(page: number, brand?: string, limit = 20): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/brands/major-brands`, {
      search: brand,
      page: page,
      limit: limit
    })
  },
  fetchSubBrand(brand: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/brands/search-sub-brands`, {
      search: brand
    })
  },
  createMajorBrand(majorBrand: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/brands/major-brands`, majorBrand)
  },
  editMajorBrand(majorBrand: Brand): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/brands/major-brands/${majorBrand._id}`, majorBrand)
  },
  deleteMajorBrand(brandId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/brands/${brandId}?type=major`)
  },
  fetchHomePageConfig(brandId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/brands/${brandId}/home-configs`)
  },
  updateHomePageConfig(brandId: string, homeConfig: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/brands/${brandId}/home-configs`, homeConfig)
  },

}
