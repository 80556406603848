import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import moment from "moment";

interface GrabPlatformOrdersContextType {
  timeSelected?: any;
  loading: boolean;
  data: any;
  queries: any;
  config: any;
  initConfig: (brand: any, store: any, provider: any) => void;
  setTime: (dateTime: any) => void;
  fetchOrders: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  onFirstPage: () => void;
}

let GrabPlatformOrdersContext = React.createContext<GrabPlatformOrdersContextType>(null!);

const useGrabPlatformOrders = () => {
  return React.useContext(GrabPlatformOrdersContext);
};

function GrabPlatformOrdersProvider({ children }: { children: React.ReactNode }) {

  const [timeSelected, setTimeSelected] = React.useState<any>(undefined);
  const [config, setConfig] = React.useState<any>(fromJS({}));
  const [queries, setQueries] = React.useState<any>(fromJS({
    page: 1
  }));
  const [data, setData] = React.useState<any>(fromJS({}));
  const [loading, setLoading] = React.useState<boolean>(false);

  const initConfig = (brand: any, store: any, provider: any) => {
    setConfig(
      config.updateIn(['brand'], () => fromJS(brand))
        .updateIn(['store'], () => fromJS(store))
        .updateIn(['provider'], () => provider)
    )
  }

  const setTime = (dateTime: any) => {
    setTimeSelected(dateTime);
  }

  const fetchOrders = async () => {
    if (!timeSelected) {
      window.alert('Bạn chưa chọn thời gian');
      return;
    }

    const from = timeSelected.getTime();
    const to = moment(from).endOf('day').valueOf();

    setLoading(true);

    const rs = await Api.grab.fetchOrders(config.getIn(['store', '_id']), from, to, queries.get('page'))
    setData(fromJS(rs.data.data))

    setLoading(false);
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  React.useEffect(() => {
    if (queries.size < 1 || !timeSelected) return;
    fetchOrders()
  }, [queries])

  let value = {
    timeSelected,
    loading,
    data,
    queries,
    config,
    initConfig,
    setTime,
    fetchOrders,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <GrabPlatformOrdersContext.Provider value={value}>{children}</GrabPlatformOrdersContext.Provider>;
}

export default GrabPlatformOrdersProvider;
export { useGrabPlatformOrders };
