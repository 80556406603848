import {Button, Form, FormControl, InputGroup, Modal} from "react-bootstrap";
import React, {ChangeEvent, EventHandler, FormEvent, MouseEventHandler} from "react";
import {FaLock, FaUser} from "react-icons/fa";
import {useFoodAppConnect} from "../provider";
import {foodApps} from "../../../util/common_utils";

interface ModalShopeeConnectorType {
  show: boolean;
  onClose: () => void;
}

const ModalShopeeConnector = (props: ModalShopeeConnectorType) => {

  const providerName = foodApps.shopee

  const [loading, setLoading] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<any>('');
  const [pickingMerchants, setPickingMerchants] = React.useState<boolean>(false);
  const [merchant, setMerchant] = React.useState<any>(null);
  const [pickingRestaurants, setPickingRestaurants] = React.useState<boolean>(false);
  const [restaurant, setRestaurant] = React.useState<any>(null);
  const foodAppConnectProvider = useFoodAppConnect();

  React.useEffect(() => {
    const account = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName);
    setPhone(account?.get('username', '') ?? '')
  }, [foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)])

  const _onClose = () => {
    if (loading) return;
    setPickingRestaurants(false)
    setPickingMerchants(false)
    setRestaurant(null)
    setMerchant(null)
    setPhone('')
    props.onClose();
  }

  const _onConnectClicked = async () => {
    if (!phone || phone.trim().length < 1) {
      const confirm = window.confirm('Vui lòng nhập Số điện thoại Shopee Merchant')
      if (!confirm) return;
    }

    setLoading(true);
    let rs = await foodAppConnectProvider.connectShopee(phone);
    setLoading(false);
    if (!rs) return
    setPickingMerchants(true);
  }

  const _onSubmitClicked = (e: FormEvent) => {
    e.preventDefault();
  }

  const _onConfirmRestaurantClicked = async () => {
    setLoading(true);
    let rs = await foodAppConnectProvider.updateShopeeRestaurant(phone, merchant, restaurant)
    console.log('--- rs', rs)
    setLoading(false);
    if (rs) {
      window.alert('Kết nối Shopee Food thành công.')
      props.onClose();
    }
  }

  const _onPhoneChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent: any = e.nativeEvent;
    if (!nativeEvent['inputType']) return;
    setPhone(e.currentTarget.value);
  }

  const _onMerchantClicked = async (e: React.MouseEvent<HTMLElement>) => {
    const res = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)
      ?.get('merchants', [])
      ?.find((merchant: any) => merchant.get('merchantId') == e.currentTarget.id)
    if (!res) {
      window.alert('Không thấy Thương hiệu')
      return
    }
    setMerchant(res);
    setLoading(true);
    let rs = await foodAppConnectProvider.merchantShopeeSelected(phone, res);
    setLoading(false);
    if (!rs) return
    setPickingRestaurants(true);
  }

  const _onRestaurantClicked = (e: React.MouseEvent<HTMLElement>) => {
    const res = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)
      ?.get('restaurants', [])
      ?.find((restaurant: any) => restaurant.get('store_id') == e.currentTarget.id)
    if (!res) {
      window.alert('Không thấy cửa hàng')
      return
    }
    setRestaurant(res);
  }

  const _onClearSelectedRestaurantClicked = () => {
    setRestaurant(null);
  }

  const _onClearSelectedMerchantClicked = () => {
    setMerchant(null);
    setPickingRestaurants(false);
    setRestaurant(null);
  }

  return (
    <Modal show={props.show} onHide={_onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Kết nối SHOPEE { pickingRestaurants ? ' | CHỌN CỬA HÀNG' : '' }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          !pickingMerchants &&
          !pickingRestaurants &&
          <Form onSubmit={_onSubmitClicked}  autoComplete='off'>
            <InputGroup className="mb-3 mt-3">
              <InputGroup.Text>
                <FaUser/>
              </InputGroup.Text>
              <FormControl
                placeholder="Số điện thoại"
                aria-label="Số điện thoại"
                disabled={loading}
                value={phone}
                autoComplete="off"
                onChange={_onPhoneChanged}
              />
            </InputGroup>
          </Form>
        }
        {
          pickingMerchants &&
          !merchant &&
          <>
          Chọn Thương hiệu:
          {
            foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)
            ?.get('merchants', [])
            ?.map((merchant: any, index: number) => {
              return (
                <div className="d-grid gap-2 mt-2" key={`account_${index}_${merchant.get('merchantId')}`}>
                  <button 
                    className="btn btn-outline-primary" 
                    type="button" 
                    style={{textAlign: 'start'}} 
                    disabled={loading}
                    id={merchant.get('merchantId')} 
                    onClick={_onMerchantClicked}>
                    {merchant.get('merchantName')}
                  </button>
                </div>
              )
            })
          }
          </>
        }
        {
          merchant &&
          <div className="d-grid gap-2">
            Thương hiệu:
            <button 
              className="btn btn-primary" 
              type="button" 
              style={{textAlign: 'start'}} 
              disabled={loading}
              onClick={_onClearSelectedMerchantClicked}>
              {merchant.get('merchantName')}
            </button>
          </div>
        }
        {
          pickingRestaurants &&
          !restaurant &&
          <>
          <div className="mt-2">
          Chọn cửa hàng:
          </div>
          {
            foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === providerName)
            ?.get('restaurants', [])
            ?.map((restaurant: any, index: number) => {
              return (
                <div className="d-grid gap-2 mt-2" key={`account_${index}_${restaurant.get('store_id')}`}>
                  <button 
                    className="btn btn-outline-primary" 
                    type="button" 
                    style={{textAlign: 'start'}} 
                    id={restaurant.get('store_id')} 
                    onClick={_onRestaurantClicked}>
                    {restaurant.get('store_name')}
                  </button>
                </div>
              )
            })
          }
          </>
        }
        {
          restaurant &&
          <div className="d-grid gap-2 mt-2">
            Cửa hàng:
            <button 
              className="btn btn-primary" 
              type="button" 
              style={{textAlign: 'start'}} 
              onClick={_onClearSelectedRestaurantClicked}>
              {restaurant.get('store_name')}
            </button>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        {
          !pickingMerchants &&
          !pickingRestaurants &&
          <button className={`btn btn-shopee`} onClick={_onConnectClicked} disabled={loading}>
            Kết nối
          </button>
        }
        {
          !!merchant && !!restaurant &&
          <button className={`btn btn-shopee`} onClick={_onConfirmRestaurantClicked} disabled={loading}>
            Xác nhận
          </button>
        } 
      </Modal.Footer>
    </Modal>
  )
}

export default ModalShopeeConnector;