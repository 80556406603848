import React, {useEffect, useState} from "react";
import NormalModal from "../../../components/Modal/normalModal";
import {fromJS} from "immutable";
import BankConfigProvider, {useBankConfig} from "./bank_config_provider";
import BankConfigForm from "./bank_config_form";
import { BankModalType } from "../../../repository/model/enums/BankModalType";

interface BankConfigModalPropType {
  data: any
  type?: BankModalType
  isOpen: boolean
  onClose: () => void,
  onBankInfoUpdated: (info: any) => void
}

export type BankInfoType = {
  id: string
  accountName: string
  accountNumber: string
  bankId: string
  bankNane: string
}

const BankConfigModal: React.FC<BankConfigModalPropType> = ({isOpen, onClose, data,type, onBankInfoUpdated}) => {
  const [bankInfo, setBankInfo] = useState<any>(fromJS({}))
  const [validated, setValidated] = useState<boolean>(false)
  const useBankConfigProvider = useBankConfig()

  const handleClose = () => {
    setBankInfo(fromJS({}))
    setValidated(false)
    onClose()
  }

  const handleSubmit = async () => {
    if (!bankInfo.get('accountName') || !bankInfo.get('accountNumber') || !bankInfo.get('bank')) {
      setValidated(true)
      return
    }

    const info = await useBankConfigProvider.onSubmit({
      ...bankInfo.toJS(),
      id: data.get('_id'),
      bankId: bankInfo.getIn(['bank', 'bin']),
      bankName: bankInfo.getIn(['bank', 'name'])
    },type)
    if (info) {
      onBankInfoUpdated(info)
    }
    handleClose()
  }

  useEffect(() => {
    if (data.get('bankInfo')?.size) {
      setBankInfo(
        bankInfo.updateIn(['accountName'], () => data.getIn(['bankInfo', 'accountName']))
          .updateIn(['accountNumber'], () => data.getIn(['bankInfo', 'accountNumber']))
          .updateIn(['bank'], () => useBankConfigProvider.banks.find((bank: any) => bank.get('bin') === data.getIn(['bankInfo', 'bankId']))))
    }
  }, [useBankConfigProvider.banks, isOpen, data])

  return <NormalModal
    open={isOpen}
    options={{
      title: 'Cập nhật thông tin chuyển khoản',
      content: (
        <BankConfigForm store={data} bankInfo={bankInfo} setBankInfo={setBankInfo} validated={validated}/>
      ),
      confirmationText: 'Cập nhật'
    }}
    onCancel={handleClose}
    onConfirm={handleSubmit}
  />
}


export {BankConfigModal}
