import { fromJS } from "immutable";
import moment from "moment";
import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { CSVLink } from 'react-csv';
import {FaFileDownload, FaRegFileExcel} from "react-icons/fa";
import { useClosedLogs } from "../provider";

const ExcelReport = () => {
  const provider = useClosedLogs();
  const [summaryCsv, setSummaryCsv] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [downloadEnabled, setDownloadEnabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    setDownloadEnabled(false)
    setSummaryCsv([])
    setLoading(false)
  }, [provider.queries])

  const _prepareDownloadData = (items: any) => {
    let csvData = [];
    csvData[0] = ['HUBAPP', 'Báo cáo trạng thái đóng mở cửa hàng trên app Shopee Food và Grab'];
    csvData[1] = []
    csvData[2] = ['STT', 'App', 'Tên', 'Trạng thái', 'Ghi chú', 'Thời gian'];

    let count = 1;
    for (let log of items) {
      csvData[csvData.length] = [
        count,
        log.get('foodApp', ''),
        log.get('name', ''),
        log.get('status', ''),
        log.get('note', ''),
        moment(log.get('created')).local().format('HH:mm - DD/MM/YYYY'),
      ]
      count++
    }
    setSummaryCsv(csvData)
    setDownloadEnabled(true)
  }

  const _onExportClicked = async () => {

    const from = provider.queries.get('from')
    const to = provider.queries.get('to')
    if (!from || !to) {
      window.alert('Vui lòng chọn khung thời gian để xuất csv')
      return
    }
    if (to - from >= 30 * 24 * 60 * 60 * 1000) {
      window.alert('Vui lòng chọn khung thời gian trong vòng 30 ngày')
      return
    }

    setLoading(true);
    try {
      let page = 1
      let items: any = fromJS([]);
      while (true) {
        const rs = await provider.fetchLogs(page, 100)
        if (rs && rs.length > 0) {
          const list: any = fromJS(rs)
          items = items.push(...list);
        }
        if (!rs || rs.length < 100) {
          break;
        }
        page += 1;
      }
      _prepareDownloadData(items)
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <>
      {
        !downloadEnabled &&
        <Button variant="outline-primary" size="sm" disabled={loading} onClick={_onExportClicked}>
        {
          !loading &&
          <>
            <FaRegFileExcel size={14} className={'mb-1'}/>
            {` `}
            Xuất CSV
          </>
        }
        {
          loading &&
          <Spinner animation="border" size="sm"/>
        }
        </Button>
      }
      {
        downloadEnabled &&
		    <div>
			    <CSVLink data={summaryCsv} filename={`hub_report_app_closed.csv`} className={'btn btn-primary btn-sm'}>
				    <FaFileDownload/>
				    <small className={'ms-2'}>Tải xuống</small>
          </CSVLink>
		    </div>
      }
    </>
  )
}

export default ExcelReport;