import React, { useState } from 'react'
import { Button, Container, Row, Stack } from 'react-bootstrap'
import Nav from '../../../components/Navbars/Nav'
import SectionBanner from './sectionBanner'
import SectionBoth from './sectionBoth'
import FormBothModal from './formBothModal'
import ModalFormBanner from './formBannerModal'
import {useWebsiteHomePageConfig} from "../provider";
import BackBtn from "../../../components/Button/back_btn";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaPlus} from "react-icons/fa";
import {useNavigate} from "react-router";
import PageLoading from "../../../components/page_loading";

const Views = () => {
  const provider = useWebsiteHomePageConfig()

  const [showBanner, setShowBanner] = useState<boolean>(false)
  const [showProduct, setShowProduct] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleSubmit = () => {
    provider.submitHomeConfig()
  }

  const listConfig = provider.homeConfig.sort((con1: any, con2: any) => con1.get('pos') - con2.get('pos'))

  const onConfigChanged = (config: any, index: number) => {
    provider.homeConfigChanged(listConfig.updateIn([index], () => config))
  }

  const inCreasePos = (index: number) => {
    if (!index) {
      window.alert('Đã ở vị trí đầu tiên!')
      return
    }

    let pos = listConfig.getIn([index, 'pos'])
    let prevPos = listConfig.getIn([index - 1, 'pos'])

    provider.homeConfigChanged(
      listConfig.updateIn([index, 'pos'], () => prevPos).updateIn([index - 1, 'pos'], () => pos)
    )
  }

  const DeCreasePos = (index: number) => {
    if (listConfig.size <= index + 1) {
      window.alert('Đã ở vị trí cuối cùng!')
      return
    }

    let pos = listConfig.getIn([index, 'pos'])
    let afterPos = listConfig.getIn([index + 1, 'pos'])

    provider.homeConfigChanged(
      listConfig.updateIn([index, 'pos'], () => afterPos).updateIn([index + 1, 'pos'], () => pos)
    )
  }

  const handleAddConfig = (config: any) => {
    const temp = listConfig.push(config.updateIn(['pos'], () => listConfig.size + 1))
    provider.homeConfigChanged(temp)
  }

  const _onBackClicked = () => navigate(-1)

  return (
    <main>
      <Nav title={'Cài đặt trang chủ '} />
      {
        provider.loading && provider.website.size < 1 && <PageLoading/>
      }
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách Website'}/>
      </Container>
      <Container>
        <Row>
          <div className={'mt-3 col'}>
            <h6 className={'border-bottom pb-2'}>
              {provider.website?.getIn(['name'])}
            </h6>
          </div>
        </Row>
      </Container>
      <Container className={'mt-3'}>
        <Stack gap={3}>
          {!!listConfig &&
            listConfig.map((config: any, index: number) => {
              const ui = config.get('ui')

              if (ui === 'BANNER') {
                return (
                  <SectionBanner
                    key={index}
                    index={index}
                    config={config}
                    onConfigChanged={onConfigChanged}
                    inCreasePos={inCreasePos}
                    DeCreasePos={DeCreasePos}
                  />
                )
              }
              if (ui === 'BOTH') {
                return (
                  <SectionBoth
                    key={index}
                    index={index}
                    config={config}
                    onConfigChanged={onConfigChanged}
                    inCreasePos={inCreasePos}
                    DeCreasePos={DeCreasePos}
                  />
                )
              }
              return <></>
            })}
        </Stack>

        <Row className="mb-9">
          <Stack direction="horizontal" gap={3} className="col-10 d-flex my-3">
            <Button
                variant="outline-primary"
                type="button"
                onClick={() => setShowBanner(true)}
            >
              + Section Ảnh bìa
            </Button>

            <Button
                variant="outline-primary"
                type="button"
                onClick={() => setShowProduct(true)}
            >
              + Section Sản phẩm
            </Button>
          </Stack>
          <div className="col-2 d-flex justify-content-end my-3">
            <Button
                variant="success"
                type="button"
                onClick={handleSubmit}
            >
              Lưu cấu hình
            </Button>
          </div>
        </Row>
        {showProduct && (
          <FormBothModal
            show={showProduct}
            handleClose={() => setShowProduct(false)}
            onConfigChanged={onConfigChanged}
            handleAddConfig={handleAddConfig}
          />
        )}
        {showBanner && (
          <ModalFormBanner
            show={showBanner}
            handleClose={() => setShowBanner(false)}
            onConfigChanged={onConfigChanged}
            handleAddConfig={handleAddConfig}
          />
        )}
      </Container>
    </main>
  )
}

export default Views
