import React from 'react';
import Views from "./components/views";
import WebsiteMenuItemOptionsProvider from "./provider";

const WebsiteMenuItemOptions = () => {

  return (
    <WebsiteMenuItemOptionsProvider>
      <Views/>
    </WebsiteMenuItemOptionsProvider>
  );
};

export default WebsiteMenuItemOptions;