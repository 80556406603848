import React, {useState} from "react";
import {fromJS} from "immutable";
import {PAYMENT_METHOD} from "../../util/common_utils";
import {Form, FormControl, FormLabel, FormSelect, Stack} from "react-bootstrap";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {Api} from "../../repository/api";

interface MenuItemSourceCreatorProps {
  source: any
  setSource: React.Dispatch<any>
  disableTargetSelector?: boolean
  disableName?: boolean
}

const TemplateNames = ['Tại chỗ', 'Mang về', 'Grab', 'Shopee Food', 'Baemin', 'Go Food', 'Web', 'Facebook']

const MenuItemSourceCreator:React.FC<MenuItemSourceCreatorProps> = ({source, setSource, disableTargetSelector = false, disableName = false}) => {
  const app = useApp()
  const [stores, setStores] = useState<any>();
  const [templateNameSelected, setTemplateNameSelected] = useState<any>();
  const isEditing = !!source.get('_id')

  const onBrandChanged = (brand: string) => {
    setSource(source.updateIn(['brandId'], () => brand))
  }

  const onStoreChanged = (store: string) => {
    setSource(source.updateIn(['storeId'], () => store))
  }

  const onNameTemplateChanged = (name?: string) => {
    setTemplateNameSelected(name)
    if (name === 'custom') {
      setSource(source.updateIn(['name'], () => ''))
      return
    }
    setSource(source.updateIn(['name'], () => name))
  }

  const onNameChanged = (name: string) => {
    setSource(source.updateIn(['name'], () => name))
  }

  const onCommissionChanged = (com: any) => {
    setSource(source.updateIn(['commission'], () => Number.parseInt(com)))
  }

  const onPaymentMethodChanged = (method: string) => {
    setSource(source.updateIn(['paymentMethod'], () => method))
  }


  const _fetchStores = async () => {
    if (!source.getIn(['brandId'])) return

    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, source.getIn(['brandId']), 200
      )
      setStores(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    _fetchStores()
  }, [source.getIn(['brandId'])])

  React.useEffect(() => {
    if (source.get('commission') === undefined || source.get('commission') == null) {
      setSource(
        source.updateIn(['commission'], () => 0)
      )
    }
  }, [source.get('commission')])

  React.useEffect(() => {
    const name = source.get('name')
    if (!TemplateNames.includes(name)) {
      setTemplateNameSelected('custom')
    } else {
      setTemplateNameSelected(name)
    }
  }, [source.get('name')])

  return (
    <div>
      <Form autoComplete="off">
        <Stack gap={3}>
          <Form.Group>
            <Form.Label>
              Thương hiệu <span className="text-danger fs-5">*</span>
            </Form.Label>
            <Form.Select
              value={source.get('brandId', '')}
              disabled={isEditing || disableTargetSelector}
              onChange={(e) => onBrandChanged(e.target.value)}
            >
              <option value="">Chọn thương hiệu</option>
              {app.brands.map((b: any, i: number) => (
                <option key={i} value={b.get('_id')}>
                  {b.get('name')}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <FormLabel>
              Cửa hàng <span className="text-danger fs-5">*</span>
            </FormLabel>
            <FormSelect
              required
              value={source.getIn(['storeId'], '')}
              disabled={!stores || stores.size < 1 || isEditing || disableTargetSelector}
              onChange={(el) => onStoreChanged(el.target.value)}
            >
              <option value="">Chọn cửa hàng</option>
              { stores && stores.map((store: any, i: number) => (
                <option key={store.get('_id', '')} value={store.get('_id', '')}>
                  {store.get('name')}
                </option>
              ))}
            </FormSelect>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Chọn tên nguồn đơn <span className="text-danger fs-5">*</span>
            </Form.Label>
            <Form.Select
              value={templateNameSelected ?? ''}
              disabled={isEditing || disableTargetSelector}
              onChange={(e) => onNameTemplateChanged(e.target.value)}
            >
              <option value="">Chọn tên nguồn đơn</option>
              {TemplateNames.map((name: any, i: number) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
              <option value={'custom'}>
                Tên Khác
              </option>
            </Form.Select>
          </Form.Group>

          {
            templateNameSelected === 'custom' &&
            <Form.Group className="mb-3">
              <FormLabel>
                Tên nguồn đơn khác<span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormControl
                required={!source.get('name', '')}
                type="text"
                value={source.get('name', '') || ''}
                disabled={disableName}
                onChange={(el) => onNameChanged(el.target.value)}
              />
            </Form.Group>
          }

          <Form.Group className="mb-3 col-6">
            <FormLabel>
              Commission <span className="text-danger fs-5">*</span>
            </FormLabel>
            <FormControl
              required={!source.get('commission', 0)}
              type={"number"}
              aria-valuemin={0}
              min={0}
              max={100}
              value={source.get('commission', '') || ''}
              onChange={(el) => onCommissionChanged(el.target.value)}
            />
          </Form.Group>
          
          <Form.Group className="mb-3 col-6">
            <FormLabel>Hình thức thanh toán mặc định</FormLabel>
            <FormSelect
              required={!source.get('paymentMethod')}
              value={source.get('paymentMethod') || PAYMENT_METHOD.online.key}
              onChange={(el) => onPaymentMethodChanged(el.target.value)}
            >
              <option value={PAYMENT_METHOD.online.key}>Online</option>
              <option value={PAYMENT_METHOD.cash.key}>Cash</option>
              <option value={PAYMENT_METHOD.banking.key}>Banking</option>
            </FormSelect>
          </Form.Group>
        </Stack>
      </Form>
    </div>
  )
}

export default MenuItemSourceCreator
