import { fromJS } from 'immutable'
import { useEffect, useState } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'
import Chip from '../../../components/Chip/Chip'
import NormalModal from '../../../components/Modal/normalModal'
import { useModalState } from '../../../util/hook/useModalState'
import {useMenuItemCreator} from "../provider";

interface OptionProps {
  options: any
  brand: string
  onChange: (categories: any) => void
  onDelete: (categories: any) => void
}

const Options: React.FC<OptionProps> = ({ options, onChange, onDelete, brand }) => {
  let { queries, onOptionSearchChanged, initOptions, onCreateOption } = useMenuItemCreator()
  const [newOption, setNewOption] = useState<any>(fromJS({ brandId: brand }))
  const { isOpen, onClose, onOpen } = useModalState()
  const formOptions = initOptions.filter((cate: any) =>
    cate.get('name', '').toLowerCase().includes(queries.get('searchOption', '').toLowerCase())
  )

  const handleOpen = () => {
    setNewOption(newOption.updateIn(['name'], () => queries.get('searchOption', '')))
    onOpen()
  }

  const handleSubmit = () => {
    if (!newOption.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newOption.get('name', '')) {
      window.alert('Tên lựa chọn là bắt buộc!')
      return
    }
    onClose()
    onCreateOption(newOption.toJS())
  }

  useEffect(() => {
    if (brand) setNewOption(newOption.updateIn(['brandId'], () => brand))
  }, [brand])
  return (
    <>
      <Dropdown className="col-12 col-md-3">
        <Dropdown.Toggle
          className="filter text-start"
          variant={'light'}
          style={{ whiteSpace: 'normal' }}
        >
          <FormControl
            className="mb-2 w-auto"
            placeholder="Tìm kiếm..."
            onChange={(e: any) => onOptionSearchChanged(e.target.value)}
            value={queries.get('searchOption')}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {formOptions.size ? (
            formOptions.map((cate: any, i: number) => (
              <Dropdown.Item
                key={i}
                onClick={() => onChange(cate)}
                disabled={!!options.find((c: any) => c.get('_id') === cate.get('_id'))}
              >
                {cate.get('name')}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              {!queries.getIn(['brand', '_id'], '')
                ? 'Vui lòng chọn thương hiệu!'
                : 'Không có dữ liệu!'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex flex-wrap gap-3">
        {options.map((cate: any, i: number) => (
          <Chip key={i} text={cate.get('name')} onDeleteClicked={() => onDelete(cate)} />
        ))}
      </div>
      {/*<NormalModal*/}
      {/*  size="lg"*/}
      {/*  open={isOpen}*/}
      {/*  options={{*/}
      {/*    title: 'Thêm lựa chọn',*/}
      {/*    content: <OptionForm option={newOption} setOption={setNewOption} />,*/}
      {/*    confirmationText: 'Thêm'*/}
      {/*  }}*/}
      {/*  onCancel={onClose}*/}
      {/*  onConfirm={handleSubmit}*/}
      {/*/>*/}
    </>
  )
}

export default Options
