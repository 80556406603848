import {Button, Form, Stack} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";
import { useUsers } from "../provider";

interface CreatorFormType {
  onClose: () => void,
}

const CreatorForm = (props: CreatorFormType) => {
  const [name, setName] = useState<any>('');
  const [phone, setPhone] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const provider = useUsers();

  const _onNameChanged = (e: any) => {
    const value = e.target.value;
    console.log('---', value)
    setName(value)
  }

  const _onPhoneChanged = (e: any) => {
    const value = e.target.value;
    setPhone(value)
  }

  const _onPasswordChanged = (e: any) => {
    const value = e.target.value;
    setPassword(value)
  }

  const _onSubmitClicked = async () => {
    if (name.trim().length < 1) {
      window.alert('Vui lòng điền đầy đủ tên');
      return
    }
    if (phone.trim().length < 1) {
      window.alert('Vui lòng điền đầy đủ sdt');
      return
    }
    if (password.trim().length < 6) {
      window.alert('Số điện thoại không hợp lệ');
      return
    }
    setLoading(true);
    const rs = await provider.createUser(name, phone, password)
    setLoading(false)
    if (rs) {
      window.alert('Thêm tài khoản thành công')
      props.onClose();
    }
  }

  return (
    <Form autoComplete="off">
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            Tên <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Control
            placeholder="Tên người dùng"
            type="text"
            value={name}
            onChange={_onNameChanged}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Số điện thoại <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Control
            placeholder="09xxxxxxxx"
            type="text"
            value={phone}
            onChange={_onPhoneChanged}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>
            Mật khẩu <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Control
            placeholder="***"
            type="password"
            value={password}
            onChange={_onPasswordChanged}
          />
        </Form.Group>
        <div className="d-flex align-items-end justify-content-end">
          <Button variant="primary" onClick={_onSubmitClicked} disabled={loading}>
            Thêm
          </Button>
        </div>
      </Stack>
    </Form>
  )
}

export default CreatorForm;