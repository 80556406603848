import {Dropdown} from "react-bootstrap";
import {FaChevronDown} from "react-icons/fa";
import React from "react";
import SectionFilterHubItem from "./section_filter_hub_item";
import {useApp} from "../AdminLayout/admin_provider";

interface SectionHubFilterType {
  hubSelected?: any,
  onHubSelected: (hub?: any) => void,
}

const SectionHubFilter = (props: SectionHubFilterType) => {

  const app = useApp()

  return (
    <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
      <div className={'shadow-card'}>
        <h4 className={'section-title'}>
          Điểm bán
        </h4>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <div className={'hstack'}>
              <div className={'text-truncate'}>
                {props.hubSelected?.get('name') ?? 'Tất cả điểm bán'}
              </div>
              <FaChevronDown size={13} className={'ms-auto'}/>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <SectionFilterHubItem
              label={'Tất cả điểm bán'}
              hub={null}
              onSelect={props.onHubSelected}/>
            {
              app.hubs && app.hubs?.map((b: any) => {
                return (
                  <SectionFilterHubItem
                    key={`filter_hub_${b.get('_id')}`}
                    hub={b}
                    onSelect={props.onHubSelected}/>
                )
              })
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}

export default SectionHubFilter;