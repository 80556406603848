import {useOrderCreator} from "../provider";
import React from "react";
import {FaPlus} from "react-icons/fa";

const SectionMenuItemAddMore = () => {

  const orderCreator = useOrderCreator();

  const _onClick = () => {
    orderCreator.onAddMoreMenuItem();
  }

  return (
    <div className={'mt-2 card px-3 py-4'} style={{borderStyle: 'dashed'}} onClick={_onClick}>
      <div className={'hstack'}>
        <FaPlus size={16} color={'#aeadad'}/>
        <span style={{color: '#aeadad'}} className={'ms-2'}>Thêm món</span>
      </div>
    </div>
  )
}

export default SectionMenuItemAddMore;