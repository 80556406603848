import {AxiosResponse} from "axios";
import {_delete, _get, _post, _put} from "./request";
import {PromotionType} from "../model/Promotion";

type ParamsType = {
  limit: number
  brand?: string
  majorBrand?: string
  page: number
  search?: string
  brandFields: string
  itemType?: string
}

export const WebPromotionApi = {
  fetchPromotionList(params: ParamsType): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/promotions`, params)
  },
  deletePromotion(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/promotions/${id}`)
  },
  createPromotion(websiteId: string, promotion: PromotionType): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/promotions`, {
      website: websiteId,
      ...promotion
    })
  },
  editPromotion(promoId: string, promotion: PromotionType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/promotions/${promoId}`, promotion)
  },
  fetchPromotion(promoId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/promotions/${promoId}`)
  },
  activePromotion(itemId: string): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/promotions/${itemId}/active`)
  },
  inActivePromotion(itemId: string): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/promotions/${itemId}/inactive`)
  },
  updateTarget(promoId: string, brands?: any, stores?: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/promotions/${promoId}/target`, {
      brands, stores
    })
  },
}