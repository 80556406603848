import {Button, Col, Pagination, Row, Table} from 'react-bootstrap'
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaPlus} from "react-icons/fa";
import React from "react";
import {useNavigate} from "react-router";
import {useWebsites} from "../provider";
import TablePagination from "../../../components/table_pagination";
import WebsiteItem from "./WebsiteItem";

const WebsiteList: React.VFC = () => {
  const websitesProvider = useWebsites()
  const navigate = useNavigate();

  const _onCreateClicked = () => {
    navigate(`/websites/create`);
  }

  return (
    <div className={'mt-3'}>
      <div className={'shadow-card'}>
        <div className={'d-flex justify-content-between'}>
          <div>

          </div>
          <div>
            <LabelIconBtn
              label={'Tạo trang Web'}
              icon={<FaPlus size={12}/>}
              onClick={_onCreateClicked}
            />
          </div>
        </div>
        <Table striped hover bordered responsive className={'mt-2'}>
          <thead>
          <tr>
            <th>STT</th>
            <th>Website</th>
            <th>Logo</th>
            <th>Cover</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            websitesProvider.data?.map((w: any, index: number) => {
              return (
                <WebsiteItem
                  item={w}
                  index={index}
                  page={websitesProvider.queries.get('page', 1)}
                  key={`web_${w.get('_id')}`}/>
              )
            })
          }
          </tbody>
        </Table>
      </div>
      {
        (
          (!!websitesProvider.data.get('docs') && websitesProvider.data.get('docs').size >= 20) ||
          websitesProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={websitesProvider.queries.get('page', 1)}
          onFirstPage={websitesProvider.onFirstPage}
          onNextPage={websitesProvider.onNextPage}
          onPreviousPage={websitesProvider.onPreviousPage}/>
      }

    </div>
  )
}

export default WebsiteList
