import {Dropdown} from 'react-bootstrap'
import {FaEllipsisV} from 'react-icons/fa'
import '../../../assets/css/app.css'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import {useModal} from '../../../components/Modal/modalProvider'
import {useWebsitePromotion} from '../provider'
import {formatCurrency} from "../../../util/myUtil";
import {PROMOTION_TYPE, STATUS} from "../../../util/constant";
import {Link, useNavigate} from "react-router-dom";
import React from "react";
import {fromJS} from "immutable";

const moment = require('moment')

export interface ItemPropType {
  promotion: any
  index: number
  page: number
}

const PromotionItem: React.FC<ItemPropType> = ({promotion, index, page}) => {
  const provider = useWebsitePromotion()
  const [loading, setLoading] = React.useState<any>(false)
  const modal = useModal()
  const navigate = useNavigate()

  const handleDelete = async () => {
    await modal({title: 'Xác nhận xóa mã giảm giá này?'})
    provider.onDelete(promotion.get('_id'))
  }

  const handleEdit = () => {
    navigate(`${promotion.get('_id')}/edit`)
  }

  const renderDiscountValue = () => {
    if (promotion.get('type') === PROMOTION_TYPE.FIXED) {
      return formatCurrency(promotion.get('discountValue', ''))
    } else {
      return promotion.get('discountValue', '') + ' %'
    }
  }

  const _onUpdateStatusChanged = async () => {
    setLoading(true)
    await provider.updateStatusItem(promotion)
    setLoading(false)
  }

  const isAvailable = promotion.get('status') === STATUS.ACTIVE &&
    moment(promotion.get('endTime')).diff(new Date(), 'minutes') > 0

  const opacityClass = isAvailable ? '' : 'opacity-50'

  const renderTargetApplied = () => {
    if (promotion.get('stores', fromJS([])).size > 0 || promotion.get('brands', fromJS([])).size > 0) {
      return (
        <>
          {
            promotion.get('brands', fromJS([])).map((b: any) => {
              return <div key={b.get('_id')}>{b.get('name')}</div>
            })
          }
          {
            promotion.get('stores', fromJS([])).map((b: any) => {
              return <div key={b.get('_id')}>{b.getIn(['brand', 'name'])} - {b.get('name')}</div>
            })
          }
        </>
      )
    }
    return 'Tất cả'
  }

  return (
    <tr>
      <td className="w-cell">{(page - 1) * 20 + index + 1}</td>
      <td>
        <div className={opacityClass}>
          {promotion.get('name', '')}
        </div>
        <div className={`fw-bold ${opacityClass}`}>
          {promotion.get('code', '')}
        </div>
      </td>
      <td>
        <div className={opacityClass}>
          {renderDiscountValue()}
        </div>
      </td>
      <td>
        <div className={opacityClass}>
          {moment(promotion.get('startTime')).format('HH:mm DD/MM/YYYY') + ' - ' + moment(promotion.get('endTime')).format('HH:mm DD/MM/YYYY')}
        </div>
      </td>
      <td>
        <div className={`text-wrap ${opacityClass}`}>
          <strong>
            <Link to={`${promotion.get('_id')}/target`}>
              {renderTargetApplied()}
            </Link>
          </strong>
        </div>
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            disabled={loading}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={_onUpdateStatusChanged}
            checked={promotion.get('status') === 'active'}/>
        </div>
      </td>
      <td style={{width: 70}}>
        <div className="d-flex justify-content-around">
          <Dropdown align="end" className="config-btn">
            <DropdownToggle
              id="Units-button"
              variant="borderless-dark"
              bsPrefix="no-chevron"
              size="sm"
            >
              <FaEllipsisV fontSize={14} cursor="pointer"/>
            </DropdownToggle>
            <DropdownMenu
              popperConfig={{
                strategy: 'fixed'
              }}
            >
              <Dropdown.Item onClick={handleEdit}>Chỉnh sửa</Dropdown.Item>
              <Dropdown.Item onClick={handleDelete}>Xóa</Dropdown.Item>
            </DropdownMenu>
          </Dropdown>
        </div>
      </td>
    </tr>
  )
}

export {PromotionItem}
