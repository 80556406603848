import React from 'react'
import WebsitePromotionCreatorProvider from "./provider";
import {PromotionForm} from "./components/form";

const WebsitePromotionCreator = () => {
  return (
    <WebsitePromotionCreatorProvider>
      <PromotionForm/>
    </WebsitePromotionCreatorProvider>
  )
}

export default WebsitePromotionCreator
