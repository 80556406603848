import React, {useEffect, useState} from "react";
import {Dropdown} from 'react-bootstrap'
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import {useMenuItemUnits} from "../provider";
import MenuItemUnitCreator from "../../MenuItemUnitCreator";

interface MenuItemUnitType {
  item: any,
  index: number,
  page: number
}

const MenuItemUnit = (props: MenuItemUnitType) => {

  const useMenuItemUnitsProvider = useMenuItemUnits()
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newUnit, setNewUnit] = useState<any>(fromJS({}))


  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Đơn vị này?')
    if (!rs) return
    useMenuItemUnitsProvider.deleteMenuItemUnit(props.item.get('_id'))
  }

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewUnit(fromJS({}))
  }

  const handleSubmitUpdater = () => {
    if (!newUnit.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newUnit.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    useMenuItemUnitsProvider.onEditMenuItemUnit(props.item.get('_id'), newUnit.toJS())
  }

  useEffect(() => {
    if (!isCreatorOpen) {
      setNewUnit(fromJS({
        ...props.item?.toJS(),
        brandId: props.item.getIn(['brand', '_id'], ''),
      }))
    }
  }, [isCreatorOpen, props.item])

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <strong>
          {props.item.get('name')}
        </strong>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={onCreatorOpen}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Chỉnh sửa đơn vị',
          content: <MenuItemUnitCreator unit={newUnit} setUnit={setNewUnit} />,
          confirmationText: 'Sửa'
        }}
        onCancel={handleCloseCreator}
        onConfirm={handleSubmitUpdater}
      />
    </tr>
  )
}

export default MenuItemUnit;