import React, {createContext, useContext, useEffect, useState} from 'react'
import {fromJS} from 'immutable'
import {Api} from '../../repository/api'
import {useApp} from '../../components/AdminLayout/admin_provider'
import {useParams} from 'react-router'
import {useLocation, useNavigate} from "react-router-dom"
import {PromotionType} from "../../repository/model/Promotion"

interface WebsitePromotionCreatorContextType {
  website: any
  promotion: any
  appliedTargets: any
  loading: boolean
  refresh: () => void
  onCreate: (promotion: PromotionType) => void
  onEdit: (promotion: PromotionType) => void
  updateTarget: (brands: any, stores: any) => Promise<Boolean>
  removeBrandTarget: (brand: any) => Promise<Boolean>
}

let WebsitePromotionCreatorContext = createContext<WebsitePromotionCreatorContextType>(null!)

const useWebsitePromotionCreator = () => {
  return useContext(WebsitePromotionCreatorContext)
}

const WebsitePromotionCreatorProvider: React.FC = ({children}) => {
  const [website, setWebsite] = useState<any>(fromJS({}))
  const [loading, setLoading] = useState<boolean>(false)
  const [promotion, setPromotion] = useState<any>(fromJS({}))
  const [appliedTargets, setAppliedTarget] = useState<any>(fromJS([]))
  const {websiteId, promotionId} = useParams()
  const navigate = useNavigate()

  const _fetchWebsite = async () => {
    try {
      if (websiteId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(websiteId)
        setLoading(false)
        setWebsite(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchPromotion = async () => {
    try {
      if (!promotionId) return
      const res = await Api.webPromotion.fetchPromotion(promotionId)
      const data: any = fromJS(res.data.data)
      setPromotion(data)

      let newAppliedTargets: any = fromJS([])
      if (data.get('brands') && data.get('brands').size > 0) {
        for (let brand of data.get('brands')) {
          newAppliedTargets = newAppliedTargets.push(fromJS({
            brand: brand,
            stores: []
          }))
        }
      }
      if (data.get('stores') && data.get('stores').size > 0) {
        for (let store of data.get('stores')) {
          const brandId: any = store.getIn(['brand', '_id'])
          const existsTargetedBrandIndex = newAppliedTargets.findIndex((t: any) => t.getIn(['brand', '_id']) === brandId)
          if (existsTargetedBrandIndex >= 0) {
            newAppliedTargets = newAppliedTargets.updateIn([existsTargetedBrandIndex], (v: any) => {
              let currentStores = v.get('stores', fromJS([]))
              currentStores = currentStores.push(store)
              return v.updateIn(['stores'], () => currentStores)
            })
          } else {
            newAppliedTargets = newAppliedTargets.push(fromJS({
              brand: store.get('brand'),
              stores: [store]
            }))
          }
        }
      }

      setAppliedTarget(newAppliedTargets)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const refresh = () => {
    _fetchPromotion()
  }

  const onCreate = async (promotion: PromotionType) => {
    if (!websiteId) return
    try {
      await Api.webPromotion.createPromotion(websiteId!!, promotion)
      window.alert('Thêm mới thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onEdit = async (promotion: PromotionType) => {
    try {
      if (!promotionId) return
      await Api.webPromotion.editPromotion(promotionId, promotion)
      window.alert('Chỉnh sửa thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const updateTarget = async (brands: any, stores: any) => {
    if (!websiteId) return false
    try {
      await Api.webPromotion.updateTarget(promotionId!!, brands, stores)
      _fetchPromotion()
      return true
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return false
    }
  }

  const removeBrandTarget = async (brand: any) => {
    const targetedBrands: any = appliedTargets.filter((t: any) => {
      return t.getIn(['brand', '_id']) !== brand && (!t.get('stores') || t.get('stores').size < 1)
    }).map((t: any) => t.getIn(['brand', '_id'])).toJS()

    const targetedStores: any = []
    appliedTargets.filter((t: any) => {
      return t.get('stores') && t.get('stores').size > 0
    }).forEach((t: any) => {
      for (let s of t.get('stores')) {
        if (s.getIn(['brand', '_id']) !== brand) {
          targetedStores.push(s.get('_id'))
        }
      }
    })
    return await updateTarget(targetedBrands, targetedStores)
  }

  React.useEffect(() => {
    if (!loading && website.size < 1) {
      _fetchWebsite()
    }
  }, [websiteId])

  React.useEffect(() => {
    if (loading) return
    if (website.size < 1) {
      _fetchWebsite()
    }
    if (promotion.size < 1) {
      _fetchPromotion()
    }
  }, [promotionId])

  let value = {
    loading,
    website,
    refresh,
    appliedTargets,
    updateTarget,
    removeBrandTarget,
    promotion,
    onCreate,
    onEdit,
  }

  return <WebsitePromotionCreatorContext.Provider value={value}>{children}</WebsitePromotionCreatorContext.Provider>
}

export default WebsitePromotionCreatorProvider
export {useWebsitePromotionCreator}
