import React from 'react';
import Views from "./components/views";
import WebsiteMenuItemCategoriesProvider from "./provider";

const WebsiteMenuItemCategories = () => {

  return (
    <WebsiteMenuItemCategoriesProvider>
      <Views/>
    </WebsiteMenuItemCategoriesProvider>
  );
};

export default WebsiteMenuItemCategories;