import React from "react";

interface LabelIconBtnType {
  label: string;
  icon: any;
  onClick: () => void;
  color?: any;
  leftIcon?: boolean
}

const LabelIconBtn = (props: LabelIconBtnType) => {
  return (
    <button
      type="button"
      className="btn btn-sm ps-0 pe-2 btn-label primary-color"
      onClick={props.onClick}>
      { !!props.leftIcon && <small className={'me-2'} style={{color: props.color}}>{props.label}</small> }
      {props.icon}
      { !props.leftIcon && <small className={'ms-2'} style={{color: props.color}}>{props.label}</small> }
    </button>
  )
}

export default LabelIconBtn;