import {useUserProfile} from "../provider";
import {Container} from "react-bootstrap";
import React, { useState } from "react";
import Nav from "../../../components/Navbars/Nav";
import SectionProfile from "./section_profile";
import SectionPermissions from "./section_permissions";


const Views = () => {
  return (
    <main>
      <Nav title={'Người dùng'} />
      <Container className={'pb-4'}>
        <SectionProfile/>
        <SectionPermissions/>
      </Container>
    </main>
  );
}

export default Views;