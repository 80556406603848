import {Button, Form, FormControl, InputGroup, Modal} from "react-bootstrap";
import React, {ChangeEvent} from "react";
import {FaLock, FaUser} from "react-icons/fa";
import {useFoodAppConnect} from "../provider";

interface ModalGofood2ConnectorType {
  providerName: string;
  show: boolean;
  onClose: () => void;
}

const ModalGofood2Connector = (props: ModalGofood2ConnectorType) => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUserName] = React.useState<any>('');
  const [password, setPassword] = React.useState<any>('');
  const [pickingMerchant, setPickingMerchant] = React.useState<boolean>(false);
  const [merchant, setMerchant] = React.useState<any>(null);
  const foodAppConnectProvider = useFoodAppConnect();

  React.useEffect(() => {
    const account = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName);
    setUserName(account?.get('username', '') ?? '')
    setPassword(account?.get('password', '') ?? '')
  }, [foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName)])

  const _onClose = () => {
    if (loading) return;
    props.onClose();
  }

  const _onSubmitClicked = async () => {
    if (!username || username.trim().length < 1 || !password || password.trim().length < 1) {
      const confirm = window.confirm('Tên đăng nhập hoặc mật khẩu còn trống, bạn có chắc chắn muốn cập nhật thông tin tài khoản?')
      if (!confirm) return;
    }

    setLoading(true);
    let rs: any = await foodAppConnectProvider.connectGoFood(username, password)
    setLoading(false);
    if (rs) {
      setPickingMerchant(true)
    }
  }

  const _onConfirmMerchantClicked = async () => {
    setLoading(true);
    let rs = await foodAppConnectProvider.updateGofoodMerchant(merchant)
    setLoading(false);
    if (rs) {
      window.alert('Kết nối Go Food thành công.')
      props.onClose();
    }
  }

  const _onUserChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent: any = e.nativeEvent;
    if (!nativeEvent['inputType']) return;
    setUserName(e.currentTarget.value);
  }

  const _onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent: any = e.nativeEvent;
    if (!nativeEvent['inputType']) return;
    setPassword(e.currentTarget.value);
  }

  const _onRestaurantClicked = (e: React.MouseEvent<HTMLElement>) => {
    const res = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName)
      ?.get('restaurants', [])
      ?.find((restaurant: any) => restaurant.get('restaurant_id') == e.currentTarget.id)
    if (!res) {
      window.alert('Không thấy cửa hàng')
      return
    }
    setMerchant(res);
  }

  const _onClearSelectedRestaurantClicked = () => {
    setMerchant(null);
  }

  return (
    <Modal show={props.show} onHide={_onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Kết nối {props.providerName?.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          !pickingMerchant &&
          <Form onSubmit={_onSubmitClicked}  autoComplete='off'>
            <InputGroup className="mb-3 mt-3">
              <InputGroup.Text>
                <FaUser/>
              </InputGroup.Text>
              <FormControl
                placeholder="Tên đăng nhập"
                aria-label="Tên đăng nhập"
                disabled={loading}
                value={username}
                autoComplete="off"
                onChange={_onUserChanged}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <FaLock/>
              </InputGroup.Text>
              <FormControl
                placeholder="Mật khẩu"
                aria-label="Password"
                type={'password'}
                value={password}
                disabled={loading}
                onChange={_onPasswordChanged}
              />
            </InputGroup>
          </Form>
        }
        {
          pickingMerchant &&
          !merchant &&
          foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName)
            ?.get('restaurants', [])
            ?.map((restaurant: any, index: number) => {
              return (
                <div className="d-grid gap-2 mt-2" key={`account_${index}_${restaurant.get('restaurant_id')}`}>
                  <button className="btn btn-outline-primary" type="button" style={{textAlign: 'start'}} id={restaurant.get('restaurant_id')} onClick={_onRestaurantClicked}>
                    {restaurant.get('restaurant_name')}
                  </button>
                </div>
              )
            })
        }
        {
          pickingMerchant &&
          merchant &&
          <div className="d-grid gap-2 mt-2">
            <button className="btn btn-primary" type="button" style={{textAlign: 'start'}} onClick={_onClearSelectedRestaurantClicked}>
              {merchant.get('restaurant_name')}
            </button>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose} disabled={loading}>
          Đóng
        </Button>
        {
          !pickingMerchant &&
          <button className={`btn btn-${props.providerName}`} onClick={_onSubmitClicked} disabled={loading}>
            Kết nối
          </button>
        }
        {
          pickingMerchant &&
          <button className={`btn btn-${props.providerName}`} onClick={_onConfirmMerchantClicked} disabled={loading}>
            Xác nhận
          </button>
        }
      </Modal.Footer>
    </Modal>
  )
}

export default ModalGofood2Connector;