import {AxiosResponse} from "axios";
import {_get, _post} from "./request";

export const GoFoodApi = {
  sendOTP(store: string, phone: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/gofood/account/otp`, {
      phone
    })
  },
  updateAccount(store: string, username: string, password: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/gofood/account`, {
      username,
      password
    })
  },
  fetchMerchants(store: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/gofood/restaurants`, {})
  },
  updateMerchant(store: string, merchant: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/gofood/restaurants`, merchant)
  },
}