import {Container, Dropdown, Form, FormControl, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import {useMenuCloneToWebsite} from "../provider";
import Chip from "../../../components/Chip/Chip";


const Views = () => {

  const provider = useMenuCloneToWebsite()
  const app = useApp();

  const _onSubmitClicked = async () => {
    const fromStoreId = provider.dataFrom.get('storeId')
    if (!fromStoreId || fromStoreId.trim().length < 1) {
      window.alert('Vui lòng chọn Cửa hàng muốn sao chép Từ!')
      return
    }
    const fromSourceId = provider.dataFrom.get('sourceId')
    if (!fromSourceId || fromSourceId.trim().length < 1) {
      window.alert('Vui lòng chọn Nguồn đơn muốn sao chép Từ!')
      return
    }
    const toBrandId = provider.dataTo.get('brandId')
    if (!toBrandId || toBrandId.trim().length < 1) {
      window.alert('Vui lòng chọn Website muốn sao chép Đến!')
      return
    }
    await provider.submitCloneRequest()
  }

  return (
    <main>
      <Nav title={'Clone Website Menu'} />
      <Container className={'pb-4 mt-3'}>
        <Row>
          <div className={'col'}>
            <div className={'shadow-card h-100'}>
              <h3 className={'border-bottom pb-2'}>Từ</h3>
              <Form.Group className={'mt-4'}>
                <Form.Label>
                  Thương hiệu
                </Form.Label>
                <Form.Select
                  value={provider.dataFrom.get('brandId', '')}
                  disabled={provider.loading}
                  onChange={(e) => provider.onFromBrandChanged(e.target.value)}
                >
                  <option value="">Chọn thương hiệu</option>
                  {app.brands && app.brands.map((b: any, i: number) => (
                    <option key={`from_brand_${b.get('_id')}`} value={b.get('_id')}>
                      {b.get('name')}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className={'mt-2'}>
                <Form.Label>
                  Cửa hàng
                </Form.Label>
                <Form.Select
                  disabled={!provider.dataFrom.get('brandId') || provider.loading}
                  value={provider.dataFrom.get('storeId', '')}
                  onChange={(e) => provider.onFromStoreChanged(e.target.value)}
                >
                  <option value="">Chọn cửa hàng</option>
                  {
                    provider.dataFrom.get('stores') &&
                    provider.dataFrom.get('stores').map((b: any, i: number) => (
                      <option key={`from_store_${b.get('_id')}`} value={b.get('_id')}>
                        {b.get('name')}
                      </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className={'mt-2'}>
                <Form.Label>
                  Nguồn đơn
                </Form.Label>
                <Form.Select
                  disabled={!provider.dataFrom.get('storeId') || provider.loading}
                  value={provider.dataFrom.get('sourceId', '')}
                  onChange={(e) => provider.onFromSourceChanged(e.target.value)}
                >
                  <option value="">Chọn nguồn đơn</option>
                  {
                    provider.dataFrom.get('sources') &&
                    provider.dataFrom.get('sources').map((b: any, i: number) => (
                      <option key={`from_source_${b.get('_id')}`} value={b.get('_id')}>
                        {b.get('name')}
                      </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className={'col'}>
            <div className={'shadow-card'}>
              <h3 className={'border-bottom pb-2'}>Đến</h3>
              <Form.Group className={'mt-4'}>
                <Form.Label>
                  Website
                </Form.Label>
                <Form.Select
                  value={provider.dataTo.get('brandId', '')}
                  disabled={provider.loading}
                  onChange={(e) => provider.onToBrandChanged(e.target.value)}
                >
                  <option value="">Chọn Website</option>
                  {provider.dataTo.get('websites') && provider.dataTo.get('websites').map((w: any, i: number) => (
                    <option key={`to_web_${w.get('_id')}`} value={w.get('_id')}>
                      {w.get('name')}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Row>
        <div className={'shadow-card mt-3'}>
          <div className="d-grid gap-2 col-6 mx-auto">
            <button className="btn btn-success" type="button" disabled={provider.loading} onClick={_onSubmitClicked}>
              SAO CHÉP
            </button>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default Views;