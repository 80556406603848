import {Dropdown} from "react-bootstrap";

interface SectionFilterStoreItemType {
  store: any,
  label?: string,
  onSelect: (brand: any) => void
}

const SectionFilterStoreItem = (props: SectionFilterStoreItemType) => {

  const _onClick = () => {
    props.onSelect(props.store ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.store?.get('name') ?? props.label}
    </Dropdown.Item>
  )
}

export default SectionFilterStoreItem;