import React from "react";
import { numberFormat } from "../../../config";
import {useOrderCreator} from "../provider";

interface SectionMenuItemPriceType {
  item: any
}

const SectionMenuItemPrice = (props: SectionMenuItemPriceType) => {

  const [price, setPrice] = React.useState<number>(0);
  const [itemPrice, setItemPrice] = React.useState<number>(0);
  const orderCreator = useOrderCreator();

  React.useEffect(() => {
    let value = props.item.get('price');
    if (!!props.item.get('options')) {
      for (let option of props.item.get('options')) {
        for (let choice of option.get('choices')) {
          value += choice.get('price') * choice.get('quantity', 0);
        }
      }
    }

    setItemPrice(value)
    setPrice(value * props.item.get('quantity', 0));
  }, [props.item])

  const _onItemQuantityChanged = (e: any) => {
    const value = e.target.value;
    try {
      const quantity = Number.parseInt(value);
      if (isNaN(quantity)) {
        orderCreator.onMenuItemQuantityChanged(props.item, 0)
        return
      }
      if (quantity < 1) {
        window.alert('Số lượng phải lớn hơn 0');
        return;
      }
      orderCreator.onMenuItemQuantityChanged(props.item, quantity)
    } catch(e) {
      window.alert(e)
    }
  }

  return (
    <>
      <div className={'mt-2 row align-items-center border-top pt-2'}>
        <div className={'col'}>
          <strong className={'opacity-75'}>Số lượng: </strong>
        </div>
        <div className={'col-auto'}>
          <div className={'hstack'}>
            <div style={{width: 72}} className={'me-2'}>
              <input
                type={'number'}
                className="form-control form-control-sm"
                value={props.item.get('quantity', 0)}
                placeholder="1"
                onChange={_onItemQuantityChanged}/>
            </div>
            {` x `}
            <div style={{minWidth: 96}} className={'text-end'}>
              {!!price && numberFormat.format(itemPrice)}
            </div>
          </div>
        </div>
      </div>
      <div className={'mt-2 row border-top'}>
        <div className={'col'}></div>
        <div className={'col-auto text-end highlight-value pt-2'}>
          {price > 0 && '= '}{!!price && numberFormat.format(price)}
        </div>
      </div>
    </>
  )
}

export default SectionMenuItemPrice;