import { fromJS } from 'immutable'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useApp } from '../../components/AdminLayout/admin_provider'
import { HubApi } from '../../repository/api/hubs'

interface UserContextType {
  loading: boolean
  hub: any
  queries: any
  setQueries: React.Dispatch<any>
  _refresh: () => void
}
let UserContext = React.createContext<UserContextType>(null!)

const useUsers = () => {
  return React.useContext(UserContext)
}
const UserProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [hub, setHub] = React.useState<any>(fromJS({}))
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const app = useApp()
  const { hubId } = useParams()
  const navigate = useNavigate()

  const _fetchHub = async () => {
    try {
      if (!hubId) return
      setLoading(true)
      const res = await HubApi.fetchHubDetail(hubId)
      setLoading(false)
      setHub(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      navigate('/hubs')
    }
  }

  const _fetchUser = async () => {
    try {
      setLoading(true)
      const res = await HubApi.fetchUser(queries.get('phone').replace(/[^a-zA-Z1-9]/g, ''))
      setLoading(false)
      setQueries(queries.updateIn(['users'], () => fromJS(res.data.data)))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _refresh = async () => {
    setLoading(true)
    setHub(fromJS({}))
    await _fetchHub()
    setLoading(false)
  }

  const _initData = async () => {
    if (!app.brands) {
      app.fetchBrand()
    }
  }
  useEffect(() => {
    if (queries.get('phone')) {
      _fetchUser()
    }
  }, [queries.get('phone')])

  useEffect(() => {
    if (hubId) {
      _fetchHub()
    }
    _initData()
  }, [])

  const value = { loading, hub, queries, setQueries, _refresh }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
export { useUsers }
export default UserProvider
