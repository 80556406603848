import { memo } from 'react'
import { Button, Card, Stack } from 'react-bootstrap'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { TiDelete } from 'react-icons/ti'
import { useModal } from '../../../components/Modal/modalProvider'
import { useModalState } from '../../../util/hook/useModalState'
import FormBothModal from './formBothModal'
import MenuItemCard from './menuItemCard'
import {useWebsiteHomePageConfig} from "../provider";

interface SectionBothProps {
  index: number
  config: any
  onConfigChanged: (btnLabel: any, index: number) => void
  inCreasePos: (index: number) => void
  DeCreasePos: (index: number) => void
}

const SectionBothFn: React.FC<SectionBothProps> = (props) => {
  const settingProvider = useWebsiteHomePageConfig()
  const modal = useModal()

  const { isOpen, onOpen, onClose } = useModalState()

  const handleRemoveSecsion = async () => {
    try {
      await modal({ title: 'Bạn muốn xóa mục này?' })
      settingProvider.removeSection(props.config)
    } catch (error) {
      return
    }
  }

  return (
    <div className="border-bottom shadow-card p-0">
      <Card.Header className={'bg-transparent'}>
        <Stack direction="horizontal" className="justify-content-between">
          <div className="fw-bold fs-3">{props.config.get('title') || 'Product'}</div>

          <div className="d-flex align-items-center gap-3">
            <Button variant="outline-primary" onClick={onOpen}>
              Sửa
            </Button>
            <Button variant="outline-secondary" onClick={() => props.inCreasePos(props.index)}>
              <FaArrowUp/>
            </Button>

            <Button variant="outline-secondary" onClick={() => props.DeCreasePos(props.index)}>
              <FaArrowDown />
            </Button>
            <TiDelete fontSize={30} cursor="pointer" onClick={handleRemoveSecsion} />
          </div>
        </Stack>
      </Card.Header>

      <div className="p-3">
        <h5 className={'opacity-50'}>
          Banners
        </h5>
        <Stack
          gap={2}
          direction="horizontal"
          className="overflow-scroll mb-5"
          style={{ minHeight: 200 }}
        >
          {!!props.config.get('banners')?.size &&
            props.config.get('banners').map((banner: any, index: number) => (
              <div key={index} className="col-12 col-sm-6 col-lg-4 position-relative">
                <img
                  src={banner.get('desktop_image_url') || banner.get('mobile_image_url')}
                  alt={banner.get('name')}
                  style={{
                    width: '100%',
                    maxHeight: 200,
                    minHeight: 200,
                    height: ' auto',
                    objectFit: 'contain'
                  }}
                  className="img-thumbnail"
                />
              </div>
            ))}
        </Stack>

        <h5 className={'opacity-50'}>
          Thực đơn
        </h5>
        <Stack
          gap={2}
          direction="horizontal"
          className="overflow-scroll"
          style={{ minHeight: 270 }}
        >
          {!!props.config.get('menuItems') &&
            props.config
              .get('menuItems')
              .map((el: any, index: number) => (
                <MenuItemCard key={index} value={el} hasDelete={false} />
              ))}
        </Stack>
      </div>

      <FormBothModal key={props.index} show={isOpen} handleClose={onClose} {...props} />
    </div>
  )
}

const SectionBoth = memo(SectionBothFn) as typeof SectionBothFn

export default SectionBoth
