import {FaArrowLeft} from "react-icons/fa";
import React from "react";

interface BackBtnType {
  onBackClicked: () => void,
  label?: string
}

const BackBtn = (props: BackBtnType) => {
  return (
    <button
      type="button"
      className="btn btn-sm ps-0 pe-2 btn-label primary-color opacity-75"
      onClick={props.onBackClicked}>
      <FaArrowLeft size={14}/>
      <small className={'ms-2'}>{props.label ?? 'Quay lại'}</small>
    </button>
  )
}

export default BackBtn;