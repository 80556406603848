import { AxiosResponse } from 'axios'
import {_get, _getVietQr, _post, _put, _delete} from './request'
import {MenuCategoryType, MenuItem, MenuOptionType, MenuUnitType} from "../model/menuItem";

type ParamsType = {
  page: number
  search?: string
  itemType?: string
  majorBrand?: string
  brand?: string
  store?: string
  source?: string
  limit?: number

  brandFields?: any
}

export const MenuApi = {
  fetchMenuItems(params: ParamsType): Promise<AxiosResponse<any>> {
    if (!params.limit) params.limit = 20
    return _get(`brand/admin/v1/menu-items/items`, params)
  },
  deleteMenuItem(itemId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/menu-items/${itemId}`)
  },
  activeMenuItem(itemId: string): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-items/${itemId}/active`)
  },
  inActiveMenuItem(itemId: string): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-items/${itemId}/inactive`)
  },
  fetchMenuCategoryList(params: ParamsType): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/menu-categories`, params)
  },
  fetchMenuOptionList(params: ParamsType): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/menu-options`, params)
  },
  fetchMenuUnitList(params: ParamsType): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/units`, params)
  },
  deleteMenuOption(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/menu-options/${id}`)
  },
  deleteUnit(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/units/${id}`)
  },
  deleteItem(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/menu-items/${id}`)
  },
  createMenuCategory(category: MenuCategoryType): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/menu-categories`, category)
  },
  editMenuCategory(cateId: string, category: MenuCategoryType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-categories/${cateId}`, category)
  },
  deleteCategory(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/menu-categories/${id}`)
  },
  createMenuUnit(unit: MenuUnitType): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/units`, unit)
  },
  editMenuUnit(unitId: string, unit: MenuUnitType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/units/${unitId}`, unit)
  },
  createMenuOption(option: MenuOptionType, websiteId?: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/menu-options`, {...option, majorBrand: websiteId})
  },
  editMenuOption(optionId: string, option: MenuOptionType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-options/${optionId}`, option)
  },
  createMenuItem(item: MenuItem): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/menu-items`, item)
  },
  fetchItem(itemId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/menu-items/${itemId}`)
  },
  editMenuItem(id: string, item: MenuItem): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-items/${id}`, item)
  },
  cloneMenuItems(params: {fromStore: string, fromSource?: string, toStores: any, toSource?: string}): Promise<AxiosResponse<any>> {
    const requests: any = {
      fromStore: params.fromStore,
      toStores: params.toStores
    }
    if (params.fromSource) requests['fromSource'] = params.fromSource
    if (params.toSource) requests['toSource'] = params.toSource

    return _post(`brand/admin/v1/menu-items/clone`, requests)
  },
  cloneMenuItemsToWebsite(params: {fromStore: string, fromSource: string, toBrand: string}): Promise<AxiosResponse<any>> {
    const requests: any = {
      fromStore: params.fromStore,
      fromSource: params.fromSource,
      toBrand: params.toBrand
    }
    return _post(`brand/admin/v1/menu-items/clone-website-menu`, requests)
  },
  updateStores(itemId: string, stores: string[]): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/menu-items/${itemId}/stores`, {stores})
  },
  createWebsiteMenuCategory(websiteId: string, name: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/menu-categories/website/${websiteId}`, {name})
  },
  editWebsiteMenuCategory(cateId: string, name: string): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/menu-categories/${cateId}/website`, {name})
  },
  deleteWebsiteMenuItemCategory(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/menu-categories/${id}/website`)
  },
}
