import {AxiosResponse} from "axios";
import {_get, _post} from "./request";

export const ShopeeApi = {
  sendOTP(store: string, phone: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/shopee_food/account/otp`, {
      phone
    })
  },
  updateAccount(store: string, phone: string, otpToken: string, otp: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/shopee_food/account/otp/verify`, {
      phone,
      otpToken,
      otp
    })
  },
  merchants(store: string, phone: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/shopee_food/merchants`, {
      account: phone
    })
  },
  restaurants(store: string, phone: string, merchantId: number): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/shopee_food/restaurants`, {
      account: phone,
      merchantId: merchantId
    })
  },
  updateRestaurant(store: string, account: string, merchantId: string, restaurantId: string, restaurantName: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/shopee_food/restaurants`, {
      account, merchantId, restaurantId, restaurantName
    })
  },
  fetchOrders(store: string, from: number, to: number, page: number): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/shopee_food`, {
      from, to, page
    })
  },
  fetchOrderDetail(store: string, orderId: string, existingOrder: boolean = false): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/shopee_food/${orderId}${existingOrder ? '?existing-order=1' : ''}`)
  },
  createOrder(store: string, orderId: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/shopee_food/${orderId}`)
  },
}