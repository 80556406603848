import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useMenuItemUnits} from "../provider";
import MenuItemUnit from "./menu_item_unit";

const SectionMenuItemUnits = () => {
  const useMenuItemUnitsProvider = useMenuItemUnits();

  if (useMenuItemUnitsProvider.data.size < 1 && useMenuItemUnitsProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Đơn vị</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          useMenuItemUnitsProvider.data.map((item: any, index: number) => {
            return <MenuItemUnit
              key={`menu_item_unit_${item.get('_id')}`}
              index={index}
              page={useMenuItemUnitsProvider.queries.get('page')}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!useMenuItemUnitsProvider.data && useMenuItemUnitsProvider.data.size >= 20) ||
          useMenuItemUnitsProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={useMenuItemUnitsProvider.queries.get('page')}
          onPreviousPage={useMenuItemUnitsProvider.onPreviousPage}
          onNextPage={useMenuItemUnitsProvider.onNextPage}
          onFirstPage={useMenuItemUnitsProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionMenuItemUnits;