import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";

interface MenuCloneToWebsiteContextType {
  dataFrom: any,
  dataTo: any,
  loading: boolean,
  onFromBrandChanged: (brandId?: any) => void,
  onFromStoreChanged: (storeId?: any) => void,
  onFromSourceChanged: (sourceId?: any) => void,
  onToBrandChanged: (brandId?: any) => void,
  submitCloneRequest: () => void
}

let MenuCloneToWebsiteContext = React.createContext<MenuCloneToWebsiteContextType>(null!);

const useMenuCloneToWebsite = () => {
  return React.useContext(MenuCloneToWebsiteContext);
};

function MenuCloneToWebsiteProvider({ children }: { children: React.ReactNode }) {

  const [dataFrom, setDataFrom] = React.useState<any>(fromJS({}));
  const [dataTo, setDataTo] = React.useState<any>(fromJS({}));
  const [loading, setLoading] = React.useState(false);

  const _fetchStores = async (target: string) => {
    let brandId = target === 'from' ? dataFrom.get('brandId') : dataTo.get('brandId')
    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, brandId, 200
      )
      if (target === 'from') {
        setDataFrom(
          dataFrom.updateIn(['stores'], () => fromJS(rs.data.data))
        )
      } else {
        setDataTo(
          dataTo.updateIn(['stores'], () => fromJS(rs.data.data))
        )
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchSources = async (target: string) => {
    let storeId = dataFrom.get('storeId')
    if (target === 'to') {
      if (!dataTo.get('stores_selected') || dataTo.get('stores_selected').size < 1) return
      storeId = dataTo.getIn(['stores_selected', 0, '_id'])
    }
    try {
      const rs: any = await Api.store.fetchSources(storeId)
      if (target === 'from') {
        setDataFrom(
          dataFrom.updateIn(['sources'], () => fromJS(rs.data.data))
        )
      } else {
        setDataTo(
          dataTo.updateIn(['sources'], () => fromJS(rs.data.data))
        )
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchWebsites = async () => {
    try {
      const rs: any = await Api.brand.fetchMajorBrandList(1, undefined, 200)
      setDataTo(dataTo.updateIn(['websites'], () => fromJS(rs.data.data)))
    } catch (e: any) {
      console.log(e)
    }
  }

  const onFromBrandChanged = (brandId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['brandId'], () => brandId)
        .updateIn(['storeId'], () => undefined)
        .updateIn(['sourceId'], () => undefined)
    )
  }

  const onFromStoreChanged = (storeId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['storeId'], () => storeId)
        .updateIn(['sourceId'], () => undefined)
    )
  }

  const onFromSourceChanged = (sourceId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['sourceId'], () => sourceId)
    )
  }

  const onToBrandChanged = (brandId?: any) => {
    setDataTo(
      dataTo.updateIn(['brandId'], () => brandId)
    )
  }

  const submitCloneRequest = async () => {
    setLoading(true)

    const fromStoreId = dataFrom.get('storeId')
    const fromSourceId = dataFrom.get('sourceId')
    const toBrand = dataTo.get('brandId')

    try {
      const rs = await Api.menu.cloneMenuItemsToWebsite({
        fromStore: fromStoreId,
        fromSource: fromSourceId,
        toBrand: toBrand,
      })
      window.alert('Thực đơn sẽ được Sao chép trong khoảng vài phút. Vui lòng kiểm tra sau ít phút.')
      setDataFrom(
        dataFrom.deleteIn(['brandId'])
          .deleteIn(['storeId'])
          .deleteIn(['sourceId'])
      )
      setDataTo(
        dataTo.deleteIn(['brandId'])
      )
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    setLoading(false)
  }

  React.useEffect(() => {
    _fetchWebsites()
  }, [])

  React.useEffect(() => {
    if (dataFrom.get('brandId')) {
      _fetchStores('from')
    }
  }, [dataFrom.get('brandId')])

  React.useEffect(() => {
    if (dataFrom.get('storeId')) {
      _fetchSources('from')
    }
  }, [dataFrom.get('storeId')])

  React.useEffect(() => {
    if (dataTo.get('brandId')) {
      _fetchStores('to')
    }
  }, [dataTo.get('brandId')])

  let value = {
    dataFrom,
    dataTo,
    loading,
    onFromBrandChanged,
    onFromStoreChanged,
    onFromSourceChanged,
    onToBrandChanged,
    submitCloneRequest
  };

  return <MenuCloneToWebsiteContext.Provider value={value}>{children}</MenuCloneToWebsiteContext.Provider>;
}

export default MenuCloneToWebsiteProvider;
export { useMenuCloneToWebsite };