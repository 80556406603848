import {Container, Dropdown, Form, FormControl, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import {useMenuClone} from "../provider";
import Chip from "../../../components/Chip/Chip";


const Views = () => {

  const provider = useMenuClone()
  const app = useApp();

  const _onSubmitClicked = async () => {
    const fromStoreId = provider.dataFrom.get('storeId')
    if (!fromStoreId || fromStoreId.trim().length < 1) {
      window.alert('Vui lòng chọn Cửa hàng muốn sao chép Từ!')
      return
    }
    const toStores = provider.dataTo.get('stores_selected')
    if (!toStores || toStores.size < 1) {
      window.alert('Vui lòng chọn Cửa hàng muốn sao chép Đến!')
      return
    }
    await provider.submitCloneRequest()
  }

  return (
    <main>
      <Nav title={'Sao chép Thực đơn'} />
      <Container className={'pb-4 mt-3'}>
        <Row>
          <div className={'col'}>
            <div className={'shadow-card h-100'}>
              <h3 className={'border-bottom pb-2'}>Từ</h3>
              <Form.Group className={'mt-4'}>
                <Form.Label>
                  Thương hiệu
                </Form.Label>
                <Form.Select
                  value={provider.dataFrom.get('brandId', '')}
                  disabled={provider.loading}
                  onChange={(e) => provider.onFromBrandChanged(e.target.value)}
                >
                  <option value="">Chọn thương hiệu</option>
                  {app.brands && app.brands.map((b: any, i: number) => (
                    <option key={`from_brand_${b.get('_id')}`} value={b.get('_id')}>
                      {b.get('name')}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className={'mt-2'}>
                <Form.Label>
                  Cửa hàng
                </Form.Label>
                <Form.Select
                  disabled={!provider.dataFrom.get('brandId') || provider.loading}
                  value={provider.dataFrom.get('storeId', '')}
                  onChange={(e) => provider.onFromStoreChanged(e.target.value)}
                >
                  <option value="">Chọn cửa hàng</option>
                  {
                    provider.dataFrom.get('stores') &&
                    provider.dataFrom.get('stores').map((b: any, i: number) => (
                      <option key={`from_store_${b.get('_id')}`} value={b.get('_id')}>
                        {b.get('name')}
                      </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>

              <Form.Group className={'mt-2'}>
                <Form.Label>
                  Nguồn đơn
                </Form.Label>
                <Form.Select
                  disabled={!provider.dataFrom.get('storeId') || provider.loading}
                  value={provider.dataFrom.get('sourceId', '')}
                  onChange={(e) => provider.onFromSourceChanged(e.target.value)}
                >
                  <option value="">Tất cả nguồn đơn</option>
                  {
                    provider.dataFrom.get('sources') &&
                    provider.dataFrom.get('sources').map((b: any, i: number) => (
                      <option key={`from_source_${b.get('_id')}`} value={b.get('_id')}>
                        {b.get('name')}
                      </option>
                    ))
                  }
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className={'col'}>
            <div className={'shadow-card'}>
              <h3 className={'border-bottom pb-2'}>Đến</h3>
              <Form.Group className={'mt-4'}>
                <Form.Label>
                  Thương hiệu
                </Form.Label>
                <Form.Select
                  value={provider.dataTo.get('brandId', '')}
                  disabled={true}
                  onChange={(e) => provider.onToBrandChanged(e.target.value)}
                >
                  <option value="">Chọn thương hiệu</option>
                  {app.brands && app.brands.map((b: any, i: number) => (
                    <option key={`to_brand_${b.get('_id')}`} value={b.get('_id')}>
                      {b.get('name')}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className={'mt-2'}>
                <Form.Label className={'mb-0'}>
                  Cửa hàng
                </Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    className="filter text-start"
                    variant={'light'}
                    disabled={!provider.dataTo.get('brandId') || provider.loading}
                    style={{ whiteSpace: 'normal' }}
                  >
                    <FormControl
                      className="w-auto"
                      placeholder="Chọn cửa hàng"
                      disabled={provider.loading}
                      onChange={(e: any) => {}}
                      value={provider.dataTo.get('s')}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100">
                    {
                      provider.dataTo.get('stores') &&
                      provider.dataTo.get('stores').map((store: any, i: number) => (
                        <Dropdown.Item
                          key={`data_stores_${store.get('_id')}`}
                          onClick={() => provider.onToStoreChanged(store)}
                          disabled={!!provider.dataTo.get('stores_selected', []).find((s: any) => s.get('_id') === store.get('_id'))}>

                          {store.get('name')}
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <div className="d-flex flex-wrap gap-2">
                  {
                    provider.dataTo.get('stores_selected') &&
                    provider.dataTo.get('stores_selected').map((s: any, i: number) => (
                      <Chip
                        key={`to_selected_store_${s.get('_id')}`}
                        text={s.get('name')}
                        onDeleteClicked={() => provider.onToStoreRemoved(s)}/>
                    ))
                  }
                </div>
              </Form.Group>

              {
                (
                  !provider.dataTo.get('stores_selected') ||
                  provider.dataTo.get('stores_selected').size < 2
                ) && !!provider.dataFrom.get('sourceId') &&
                <Form.Group className={'mt-2'}>
                  <Form.Label>
                    Nguồn đơn
                  </Form.Label>
                  <Form.Select
                    value={provider.dataTo.get('sourceId', '')}
                    disabled={!provider.dataTo.get('stores_selected') || provider.dataTo.get('stores_selected').size < 1 || provider.loading}
                    onChange={(e) => provider.onToSourceChanged(e.target.value)}
                  >
                    <option value="">Tất cả nguồn đơn</option>
                    {
                      provider.dataTo.get('sources') &&
                      provider.dataTo.get('sources').map((b: any, i: number) => (
                        <option key={`to_source_${b.get('_id')}`} value={b.get('_id')}>
                          {b.get('name')}
                        </option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              }
            </div>
          </div>
        </Row>
        <div className={'shadow-card mt-3'}>
          <div className="d-grid gap-2 col-6 mx-auto">
            <button className="btn btn-success" type="button" disabled={provider.loading} onClick={_onSubmitClicked}>
              SAO CHÉP
            </button>
          </div>
        </div>
      </Container>
    </main>
  );
}

export default Views;