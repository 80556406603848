import Nav from "../../../components/Navbars/Nav";
import {Container} from "react-bootstrap";
import React from "react";
import BackBtn from "../../../components/Button/back_btn";
import {useLocation, useNavigate} from "react-router";
import SectionConfig from "./section_config";
import {useOrderCreator} from "../provider";
import SectionCreator from "./section_creator";

const Views = () => {

  const location: any = useLocation();
  const navigate = useNavigate();
  const orderCreator = useOrderCreator();

  React.useEffect(() => {
    if (!!location.state?.brand) {
      orderCreator.initConfig(location.state?.brand, location.state?.store);
    }
  }, [])

  const _onBackClicked = () => {
    navigate(-1)
  }

  return (
    <main>
      <Nav title={'Đơn hàng'}/>
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Tạo đơn'}/>
      </Container>
      <Container>
        <SectionConfig/>
        {
          !!orderCreator.config.getIn(['source', 'name'], '') &&
          <SectionCreator/>
        }
      </Container>
    </main>
  )
}

export default Views;