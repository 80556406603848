import { Dropdown, FormControl } from 'react-bootstrap'
import Chip from '../../../components/Chip/Chip'
import { dishplaceholder } from '../../../util/myUtil'
import {useMenuItemCreator} from "../provider";
import CustomDropdownItem from "../../../components/custom_dropdown_item";

interface ItemProps {
  items: any
  onChange: (categories: any) => void
  onDelete: (categories: any) => void
}

const Item: React.FC<ItemProps> = ({ items, onChange, onDelete }) => {
  let { queries, onItemSearchChanged, initItem, setQueries } = useMenuItemCreator()

  const menuOptions = initItem?.filter((item: any) => {
    let check = false
    if (items) {
      check = items.find((e: any) => e.get('_id') === item.get('_id'))
    }
    return (
      (!queries.getIn(['itemSearch', 'name'], '') ||
        item
          .get('name', '')
          .toLowerCase()
          .includes(queries.getIn(['itemSearch', 'name'], '').toLowerCase())) &&
      !check
    )
  })

  return (
    <>
      <Dropdown className="col-12 col-md-3">
        <Dropdown.Toggle
          className="filter text-start"
          variant={'light'}
          style={{ whiteSpace: 'normal' }}
        >
          <FormControl
            required={!items?.size}
            className="mb-2 w-auto"
            placeholder="Tìm kiếm..."
            onChange={(e: any) => onItemSearchChanged(e.target.value)}
            value={queries.getIn(['itemSearch', 'name'], '')}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {menuOptions.size ? (
            menuOptions.map((item: any, i: number) => (
              <CustomDropdownItem
                index={i}
                key={item.get('_id')}
                text={item.get('name')}
                imageName={item.get('name')}
                imageUrl={item.get('image', '') || dishplaceholder}
                thresholdIndex={menuOptions.size - 1}
                handlePageChanged={() =>
                  setQueries(queries.updateIn(['itemSearch', 'page'], (e: number) => ++e))
                }
                handleClick={() => onChange(item)}
              />
            ))
          ) : (
            <Dropdown.Item>
              {!queries.getIn(['brand', '_id'], '')
                ? 'Vui lòng chọn thương hiệu!'
                : 'Không có dữ liệu!'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex flex-wrap gap-3">
        {items.map((cate: any, i: number) => (
          <Chip key={i} text={cate.get('name')} onDeleteClicked={() => onDelete(cate)} />
        ))}
      </div>
    </>
  )
}

export default Item
