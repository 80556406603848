import React from 'react'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Login from './views/Login'
import Settings from './views/Settings'
import AdminLayout from './components/AdminLayout'
import './assets/css/app.css'
import AuthProvider from './components/Auth/auth_provider'
import RequireAuth from './components/Auth/auth_require'
import AppProvider from './components/AdminLayout/admin_provider'
import ReactDOM from 'react-dom'
import OrdersProvider from './views/Orders/provider'
import Orders from './views/Orders'
import OrderCreator from './views/OrderCreator'
import BaeminPlatformOrders from './views/BaeminPlatformOrders'
import Stores from './views/Stores'
import AutoCrawlOrderJobs from './views/AutoCrawlOrderJobs'
import GrabPlatformOrders from './views/GrabPlatformOrders'
import Hubs from './views/Hubs'
import HubCreator from './views/HubCreator'
import HubUsers from './views/hubUsers'
import ModalProvider from './components/Modal/modalProvider'
import FoodAppConnect from "./views/FoodAppConnect";
import Crm from "./views/Crm";
import ShopeePlatformOrders from "./views/ShopeePlatformOrders";
import Brands from "./views/Brands";
import BrandProvider from "./views/Brands/provider";
import BrandUpdater from "./views/BrandUpdator";
import StoreCreator from "./views/StoreCreator";
import WebsitesProvider from "./views/Websites/provider";
import Websites from "./views/Websites";
import WebsiteCreator from "./views/WebsiteCreator";
import BankConfigProvider from "./views/Stores/BankConfig/bank_config_provider";
import MenuItems from "./views/MenuItems";
import MenuItemCreator from "./views/MenuItemCreator";
import MenuItemOptions from "./views/MenuItemOptions";
import MenuItemCategories from "./views/MenuItemCategories";
import MenuItemUnits from "./views/MenuItemUnits";
import OrderDetail from "./views/OrderDetail";
import MenuItemSources from "./views/MenuItemSources";
import MenuItemsProvider from "./views/MenuItems/provider";
import MenuItemOptionsProvider from "./views/MenuItemOptions/provider";
import MenuItemCategoriesProvider from "./views/MenuItemCategories/provider";
import MenuItemUnitsProvider from "./views/MenuItemUnits/provider";
import StoresProvider from "./views/Stores/provider";
import AutoCrawlOrderJobsProvider from "./views/AutoCrawlOrderJobs/provider";
import MenuClone from "./views/MenuClone";
import MenuCloneToWebsite from "./views/MenuCloneToWebsite";
import WebsiteMenuItems from "./views/WebsiteMenuItems";
import WebsiteMenuItemCategories from "./views/WebsiteMenuItemCategories";
import WebsiteMenuItemOptions from "./views/WebsiteMenuItemOptions";
import WebsiteBanners from "./views/WebsiteBanners";
import WebsiteBannerCreator from "./views/WebsiteBannerCreator";
import WebsiteHomePageConfig from "./views/WebsiteHomePageConfig";
import WebsitePromotions from "./views/WebsitePromotions";
import WebsitePromotionCreator from "./views/WebsitePromotionCreator";
import WebsitePromotionTargetApplied from "./views/WebsitePromotionTargetApplied";
import ClosedLogs from './views/ClosedLogs'
import Users from './views/Users'
import UserProfile from './views/UserProfile'
import Tables from './views/Tables'

const rootElement = document.getElementById('root') as HTMLDivElement
const globalProviders = [
  RequireAuth, AppProvider, ModalProvider, OrdersProvider, BankConfigProvider, MenuItemsProvider,
  MenuItemOptionsProvider, MenuItemCategoriesProvider, MenuItemUnitsProvider, BrandProvider, StoresProvider,
  AutoCrawlOrderJobsProvider, WebsitesProvider
].reverse()
let AdminElement = <AdminLayout />;
for (let Provider of globalProviders) {
  AdminElement = <Provider>{AdminElement}</Provider>
}

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={AdminElement}>
          <Route path={'/settings'} element={<Settings />} />
          <Route path={'/order/create'} element={<OrderCreator />} />
          <Route path={'/order/baemin/platform'} element={<BaeminPlatformOrders />} />
          <Route path={'/order/grab/platform'} element={<GrabPlatformOrders />} />
          <Route path={'/order/shopee_food/platform'} element={<ShopeePlatformOrders />} />
          <Route path={'/order/:orderId'} element={<OrderDetail />} />
          <Route path={'/stores'} element={<Stores />} />
          <Route path={'/order-crawler-jobs'} element={<AutoCrawlOrderJobs />} />
          <Route path={'/*'} element={<Orders />} />
          <Route path={'/hubs'} element={<Hubs />} />
          <Route path={'/hubs/create'} element={<HubCreator />} />
          <Route path={'/hubs/:id'} element={<HubCreator />} />
          <Route path={'/hubs/:hubId/users'} element={<HubUsers />} />
          <Route path={"/stores/foodapp/connect"} element={<FoodAppConnect/>}/>
          <Route path={'/crm'} element={<Crm />} />
          <Route path={'/brands'} element={<Brands />} />
          <Route path={'/brands/:brandId/edit'} element={<BrandUpdater/>}/>
          <Route path={'/brands/create'} element={<BrandUpdater/>}/>
          <Route path={"/stores/create"} element={<StoreCreator/>}/>
          <Route path={"/stores/:storeId/edit"} element={<StoreCreator/>}/>
          <Route path={"/stores/:storeId/sources"} element={<MenuItemSources/>}/>
          <Route path={"/websites"} element={<Websites/>}/>
          <Route path={"/websites/create"} element={<WebsiteCreator/>}/>
          <Route path={"/websites/:websiteId"} element={<WebsiteCreator/>}/>
          <Route path={"/websites/:websiteId/homepage"} element={<WebsiteHomePageConfig/>}/>
          <Route path={"/websites/:websiteId/banner"} element={<WebsiteBanners/>}/>
          <Route path={"/websites/:websiteId/banner/create"} element={<WebsiteBannerCreator/>}/>
          <Route path={"/websites/:websiteId/banner/:bannerId/edit"} element={<WebsiteBannerCreator/>}/>
          <Route path={"/websites/:websiteId/menu"} element={<WebsiteMenuItems/>}/>
          <Route path={"/websites/:websiteId/menu/create"} element={<MenuItemCreator/>}/>
          <Route path={"/websites/:websiteId/menu/categories"} element={<WebsiteMenuItemCategories/>}/>
          <Route path={"/websites/:websiteId/menu/options"} element={<WebsiteMenuItemOptions/>}/>
          <Route path={"/websites/:websiteId/menu/:itemId/edit"} element={<MenuItemCreator/>}/>
          <Route path={"/websites/:websiteId/promotion"} element={<WebsitePromotions/>}/>
          <Route path={"/websites/:websiteId/promotion/create"} element={<WebsitePromotionCreator/>}/>
          <Route path={"/websites/:websiteId/promotion/:promotionId/edit"} element={<WebsitePromotionCreator/>}/>
          <Route path={"/websites/:websiteId/promotion/:promotionId/target"} element={<WebsitePromotionTargetApplied/>}/>
          <Route path={"/menu"} element={<MenuItems/>}/>
          <Route path={"/menu/create"} element={<MenuItemCreator/>}/>
          <Route path={"/menu/options"} element={<MenuItemOptions/>}/>
          <Route path={"/menu/categories"} element={<MenuItemCategories/>}/>
          <Route path={"/menu/units"} element={<MenuItemUnits/>}/>
          <Route path={"/menu/:itemId/edit"} element={<MenuItemCreator/>}/>
          <Route path={"/menu-clone"} element={<MenuClone/>}/>
          <Route path={"/menu-clone-website"} element={<MenuCloneToWebsite/>}/>
          <Route path={"/logs/closed"} element={<ClosedLogs/>}/>
          <Route path={"/users"} element={<Users/>}/>
          <Route path={"/users/:userId"} element={<UserProfile/>}/>
          <Route path={"/tables"} element={<Tables/>}/>
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  rootElement
)

reportWebVitals()
