import {Card, Col, Row} from 'react-bootstrap'
import '../../../assets/css/app.css'
import {MENU_ITEM_TYPE} from "../../../util/constant";

interface ItemTypeSelectProps {
  onChanged: (type: string) => void
}

const ItemTypeSelect = (props: ItemTypeSelectProps) => {

  return (
    <Row>
      <Col>
        <Card role="button" onClick={() => props.onChanged(MENU_ITEM_TYPE.SINGLE)}>
          <Card.Body>
            <Card.Title className="fw-bold">Món lẻ</Card.Title>
            <Card.Text>Là món khách có thể chọn mua từ menu.</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card role="button" onClick={() => props.onChanged(MENU_ITEM_TYPE.COMBO)}>
          <Card.Body>
            <Card.Title className="fw-bold">Combo</Card.Title>
            <Card.Text>Là món ghép gồm nhiều món lẻ.</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

export default ItemTypeSelect
