import {Form} from 'react-bootstrap'
import {FaCheck, FaPlus, FaTimes} from 'react-icons/fa'
import {Link} from "react-router-dom";
import React from "react";
import {useWebsitePromotionCreator} from "../../WebsitePromotionCreator/provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {fromJS} from "immutable";
import {useModalState} from "../../../util/hook/useModalState";
import StoreModalPicker from "../../../components/Modal/store_modal_picker";
import NormalModal from "../../../components/Modal/normalModal";
import {Api} from "../../../repository/api";
import {Colors} from "../../../util/color_utils";

const AddRow: React.FC = () => {
  const provider = useWebsitePromotionCreator()
  const [loading, setLoading] = React.useState<any>(false)
  const [adding, setAdding] = React.useState<Boolean>(false)
  const [target, setTarget] = React.useState<any>(fromJS({}))
  const [brands, setBrands] = React.useState<any>(fromJS([]))
  const [stores, setStores] = React.useState<any>(fromJS([]))
  const [selectedList, setSelectedList] = React.useState<any>(fromJS([]))
  const [storeSelectionList, setStoreSelectionList] = React.useState<any>(fromJS([]))
  const { isOpen: isOpenStores, onClose: onCloseStores, onOpen: onOpenStores } = useModalState()

  const _fetchStores = async () => {
    if (!target.getIn(['brand', '_id'])) return
    try {
      const res = await Api.store.fetchStores(1, undefined, target.getIn(['brand', '_id']), 100)
      setStores(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const onBrandChanged = (brandId: string) => {
    if (brandId === target.getIn(['brand', '_id'])) return
    const brand = provider.website?.get('subBrands')?.find((b: any) => b.get('_id') === brandId)
    if (!brand) return
    setTarget(
      target.updateIn(['brand'], () => brand)
    )
    setStoreSelectionList(storeSelectionList.clear())
  }

  const _onAddTargetClicked = () => {
    setAdding(true)
  }

  const _onStoreChanged = (item: any) => {
    const index = stores.findIndex((s: any) => s.get('_id') === item.get('_id'))
    if (index < 0) return

    setStoreSelectionList(
      storeSelectionList.updateIn([index, 'selected'], (v: any) => !v)
    )
  }

  const _handleSubmitStores = async () => {
    setSelectedList(
      storeSelectionList.filter((s: any) => s.get('selected'))
    )
    onCloseStores()
  }

  const _onSubmitAddTargetClicked = async () => {
    let targets = provider.appliedTargets
    if (targets.find((t: any) => t.getIn(['brand', '_id']) === target.getIn(['brand', '_id']))) {
      window.alert('Đã tồn tại đối tượng này. Vui lòng kiểm tra lại.')
      provider.refresh();
      return
    }
    targets = targets.push(target.updateIn(['stores'], () => selectedList))

    const targetedBrands: any = targets.filter((t: any) => {
      return !t.get('stores') || t.get('stores').size < 1
    }).map((t: any) => t.getIn(['brand', '_id'])).toJS()

    const targetedStores: any = []
    targets.filter((t: any) => {
      return t.get('stores') && t.get('stores').size > 0
    }).forEach((t: any) => {
      for (let s of t.get('stores')) {
        targetedStores.push(s.get('_id'))
      }
    })

    const rs = await provider.updateTarget(targetedBrands, targetedStores)
    if (!rs) return
    _onCancelAddTargetClicked()
  }

  const _onCancelAddTargetClicked = () => {
    setAdding(false)
    setSelectedList(
      selectedList.clear()
    )
    setTarget(
      target.clear()
    )
  }

  React.useEffect(() => {
    if (!target.getIn(['brand', '_id'])) return
    _fetchStores()
  }, [target.getIn(['brand', '_id'])])

  React.useEffect(() => {
    if (isOpenStores) {
      if (!stores || stores.size < 1) {
        _fetchStores()
        return
      }
      setStoreSelectionList(
        stores.map((s: any) => {
          const selected = selectedList.find((sl: any) => {
            return sl.get('_id') === s.get('_id')
          })
          return s.updateIn(['selected'], () => selected)
        })
      )
    }
  }, [isOpenStores, stores])

  React.useEffect(() => {
    if (!provider.website) return
    const targetedBrandIds: any = provider.appliedTargets.map((t: any) => t.getIn(['brand', '_id']))
    setBrands(
      provider.website.get('subBrands')?.filter((b: any) => !targetedBrandIds.find((id: any) => id === b.get('_id')))
    )
  }, [provider.website, provider.appliedTargets])

  return (
    <>
      {
        adding &&
        <tr>
          <td>
            #
          </td>
          <td>
            <Form.Group className={'col'}>
              <Form.Select
                value={target.getIn(['brand', '_id'])}
                disabled={loading}
                onChange={(e) => onBrandChanged(e.target.value)}
              >
                <option value="">Chọn thương hiệu</option>
                {
                  brands?.map((b: any, i: number) => (
                    <option key={b.get('_id')} value={b.get('_id')}>
                      {b.get('name')}
                    </option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </td>
          <td>
            <strong>
              {
                target.getIn(['brand', '_id']) &&
                <Link onClick={onOpenStores} to={''}>
                  {
                    selectedList.size < 1 && `Tất cả cửa hàng`
                  }
                  {
                    selectedList.size > 0 &&
                    selectedList.map((store: any) => {
                      return (
                        <div key={store.get('_id')}>{store.get('name')}</div>
                      )
                    })
                  }
                </Link>
              }
            </strong>
          </td>
          <td>
            <div>
              {
                target.size > 0 &&
                <LabelIconBtn
                  label={'Thêm'}
                  icon={<FaCheck color={Colors.Success}/>}
                  color={Colors.Success}
                  onClick={_onSubmitAddTargetClicked}/>
              }
            </div>
            <div>
              <LabelIconBtn
                label={'Đóng'}
                icon={<FaTimes/>}
                onClick={_onCancelAddTargetClicked}/>
            </div>
          </td>
        </tr>
      }
      {
        !adding &&
        <tr>
          <td colSpan={4}>
            <div className={'d-flex justify-content-center'}>
              <LabelIconBtn label={'Thêm đối tượng áp dụng'} icon={<FaPlus/>} onClick={_onAddTargetClicked}/>
            </div>
          </td>
        </tr>
      }
      <NormalModal
        open={isOpenStores}
        size={'xl'}
        options={{
          title: 'Cửa hàng áp dụng',
          content: (
            <StoreModalPicker
              storeList={storeSelectionList}
              onChanged={_onStoreChanged}/>
          ),
          confirmationText: 'Lưu'
        }}
        onCancel={onCloseStores}
        onConfirm={_handleSubmitStores}
      />
    </>
  )
}

export default AddRow
