import { Button, Container, Form, FormControl, FormLabel, FormSelect } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import BackBtn from '../../../components/Button/back_btn'
import { useNavigate, useParams } from 'react-router'
import Nav from '../../../components/Navbars/Nav'
import SelectStore from './selectStore'
import OpenTime, { openTimeType } from './openTime'
import { fromJS } from 'immutable'
import { Api } from '../../../repository/api'
import { useHubCreator } from '../provider'
import { TiDelete } from 'react-icons/ti'
import { deleteInputValue } from '../../../util/myUtil'
import { useModal } from '../../../components/Modal/modalProvider'
import GMap from './googleReactMap'
import { HubApi } from '../../../repository/api/hubs'

const FormHub = () => {
  const HubCreatorProvider = useHubCreator()
  const navigate = useNavigate()
  const params = useParams()
  const [validated, setValidated] = useState(false)
  const [hub, setHub] = useState<any>(
    fromJS({
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      logo: '',
      cover: '',
      status: 'active',
      openTimes: [],
      stores: []
    })
  )
  const modal = useModal()

  const _onBackClicked = () => {
    navigate('/hubs')
  }

  const onNameChanged = (name: string) => {
    setHub(hub.updateIn(['name'], () => name))
  }

  const onAddressChanged = (address: string) => {
    setHub(hub.updateIn(['address'], () => address))
  }

  const onLatitudeChanged = (latitude: number) => {
    setHub(hub.updateIn(['latitude'], () => latitude))
  }

  const onLongitudeChanged = (longitude: number) => {
    setHub(hub.updateIn(['longitude'], () => longitude))
  }

  const onLogoChanged = (logo: File) => {
    setHub(hub.updateIn(['logo'], () => logo))
  }

  const onCoverChanged = (cover: File) => {
    setHub(hub.updateIn(['cover'], () => cover))
  }

  const onStatusChanged = (status: string) => {
    setHub(hub.updateIn(['status'], () => status))
  }

  const onStoreChanged = (stores: any) => {
    setHub(hub.updateIn(['stores'], () => stores))
  }

  const onOpenTimeChanged = (openTimes: openTimeType[]) => {
    setHub(hub.updateIn(['openTimes'], () => openTimes))
  }

  const handlePickAddress = async () => {
    let temp = hub.toJS()
    await modal({
      type: 'normal',
      title: 'Chọn địa chỉ',
      cancellationText: 'Hủy',
      confirmationText: 'Xác nhận',
      content: (
        <GMap
          onPick={(address: string, lat: number, lng: number) => {
            temp.address = address
            temp.latitude = lat
            temp.longitude = lng
          }}
          defaultPos={{
            address: hub.get('address'),
            pos: {
              lat: Number(hub.get('latitude')),
              lng: Number(hub.get('longitude'))
            }
          }}
        />
      )
    })
    setHub(fromJS(temp))
  }

  const handleSubmit = async (event: any) => {
    try {
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setValidated(true)
        return
      }

      const data = { ...hub.toJS() }
      if (data.openTimes[0].times[0].length < 2) {
        window.alert('Vui lòng nhập tối thiểu 1 giờ mở cửa!')
        return
      }

      data.stores = data.stores && data.stores.map((el: any) => el._id)
      data.openTimes = data.openTimes.map((el: openTimeType) => ({
        ...el,
        times: el.times.filter((t) => !!t.length)
      }))

      if (typeof hub.get('logo') == 'object') {
        const resLogo = await Api.uploadImage(hub.get('logo'))
        data.logo = resLogo.data.data.url
      }

      if (typeof hub.get('cover') == 'object') {
        const resCover = await Api.uploadImage(hub.get('cover'))
        data.cover = resCover.data.data.url
      }

      params.id ? await HubApi.editHub(params.id, data) : await HubApi.createHub(data)

      window.alert((params.id ? 'Cập nhật' : 'Tạo') + ' hub thành công!')
      navigate('/hubs')
    } catch (e) {
      console.log(e)
      window.alert('Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  useEffect(() => {
    if (!hub.get('longitude') && !hub.get('latitude') && hub.get('stores')?.size) {
      setHub(
        hub
          .updateIn(['longitude'], () => hub.getIn(['stores', 0, 'longitude']))
          .updateIn(['latitude'], () => hub.getIn(['stores', 0, 'latitude']))
      )
    }
  }, [hub])

  useEffect(() => {
    if (params.id && HubCreatorProvider.hub) {
      setHub(HubCreatorProvider.hub)
    }
  }, [params.id, HubCreatorProvider.hub])
  return (
    <main>
      <Nav title={'Điểm bán'} />
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách'} />
      </Container>
      <Container className="mb-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
            <Form.Group className="mb-3">
              <FormLabel>
                Tên <span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormControl
                required={!hub.get('name')}
                type="text"
                value={hub.get('name')}
                onChange={(el) => onNameChanged(el.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>Địa chỉ</FormLabel>
              <FormControl
                type="text"
                value={hub.get('address')}
                onChange={(el) => onAddressChanged(el.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>
                Tọa độ <span className="text-danger fs-5">*</span>
              </FormLabel>
              <div className="col-12 col-sm-6 d-flex gap-2">
                <FormControl
                  type="text"
                  required={!hub.get('longitude')}
                  placeholder="Kinh độ"
                  value={hub.get('longitude')}
                  onChange={(el) => onLongitudeChanged(Number(el.target.value))}
                />
                <FormControl
                  type="text"
                  required={!hub.get('latitude')}
                  placeholder="Vĩ độ"
                  value={hub.get('latitude')}
                  onChange={(el) => onLatitudeChanged(Number(el.target.value))}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Button type="button" onClick={handlePickAddress}>
                Tìm trên bản đồ
              </Button>
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>Logo (tối đa 1MB)</FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadLogo"
                onChange={(el: any) => onLogoChanged(el.target?.files[0])}
              />
              {hub.get('logo') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof hub.get('logo') == 'string'
                        ? hub.get('logo')
                        : URL.createObjectURL(hub.get('logo'))
                    }
                    alt={hub.get('logo').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof hub.get('logo') == 'string'
                          ? hub.get('logo')
                          : URL.createObjectURL(hub.get('logo'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setHub(hub.updateIn(['logo'], () => undefined))
                      deleteInputValue('uploadLogo')
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>Ảnh bìa (tối đa 1MB)</FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadCover"
                onChange={(el: any) => onCoverChanged(el.target.files[0])}
              />

              {hub.get('cover') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof hub.get('cover') == 'string'
                        ? hub.get('cover')
                        : URL.createObjectURL(hub.get('cover'))
                    }
                    alt={hub.get('cover').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof hub.get('cover')
                          ? hub.get('cover')
                          : URL.createObjectURL(hub.get('cover'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setHub(hub.updateIn(['cover'], () => undefined))
                      deleteInputValue('uploadCover')
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-md-3">
              <FormLabel>Tình trạng</FormLabel>
              <FormSelect
                required={!hub.get('status')}
                value={hub.get('status') || 'active'}
                onChange={(el) => onStatusChanged(el.target.value)}
              >
                <option value="active">Đang hoạt động</option>
                <option value="inactive">Ngừng hoạt động</option>
              </FormSelect>
            </Form.Group>

            <Form.Group className="mb-3">
              <SelectStore onStoreChanged={onStoreChanged} />
            </Form.Group>

            <Form.Group className="mb-3">
              <OpenTime onOpenTimeChanged={onOpenTimeChanged} />
            </Form.Group>

            <div className="d-flex justify-content-start">
              <Button
                variant="primary"
                type="submit"
                className={'mt-4 d-grid gap-2 btn btn-primary'}
              >
                {params.id ? 'Cập nhật' : 'Xác nhận'}
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </main>
  )
}

export default FormHub
