export const bannerConfigs = [
    {
        id: 'cover',
        name: 'Cover',
        desktopSize: '1512:605',
        mobileSize: '390:390'
    },
    {
        id: 'inline',
        name: 'Inline',
        desktopSize: '1512:312',
        mobileSize: '390:480'
    },
    {
        id: 'section_small',
        name: 'Section Small',
        desktopSize: '1116:230',
        mobileSize: '390:80'
    },
    {
        id: 'section_medium',
        name: 'Section Medium',
        desktopSize: '1116:320',
        mobileSize: '390:112'
    }
]