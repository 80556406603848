import {Dropdown, Row} from "react-bootstrap";
import {FaChevronDown} from "react-icons/fa";
import {useApp} from "../AdminLayout/admin_provider";
import SectionFilterBrandItem from "./section_filter_brand_item";
import SectionFilterStoreItem from "./section_filter_store_item";
import React, {BaseSyntheticEvent, useState} from "react";
import {Api} from "../../repository/api";
import {fromJS} from "immutable";
import SectionFilterSourceItem from "./section_filter_source_item";
import { 
  Checkbox as MCheckbox, 
  FormControl as MFormControl, 
  ListItemText as MListItemText, 
  MenuItem as MMenuItem, 
  Select as MSelect,
  SelectChangeEvent,
  styled,
  InputBase} from "@mui/material";

interface SectionBrandFilterType {
  brands?: any,
  brandSelected?: any,
  storeSelected?: any,
  sourceSelected?: any,
  onBrandSelected: (brand?: any) => void,
  onStoreSelected?: (store?: any) => void,
  onStoresSelected?: (stores?: any) => void,
  onSourceSelected?: (source?: any) => void,
  columnClassNames?: string,
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #fff',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#57035d !important',
    padding: '.375rem .75rem .375rem 1px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const SectionBrandFilter = (props: SectionBrandFilterType) => {

  const app = useApp();
  const [stores, setStores] = useState<any>();
  const [sources, setSources] = useState<any>();
  const [storesSelected, setStorsSelected] = useState<any[]>([]);
  const [isStoresOpen, setStoreOpen] = useState<boolean>(false);

  const _fetchStores = async () => {
    if (!props.brandSelected?.get('_id') || (!props.onStoreSelected && !props.onStoresSelected)) return
    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, props.brandSelected?.get('_id'), 200
      )
      setStores(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchSources = async () => {
    if (!props.storeSelected?.get('_id') || !props.onSourceSelected) return
    try {
      const rs: any = await Api.store.fetchSources(props.storeSelected?.get('_id'))
      setSources(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    _fetchStores()
  }, [props.brandSelected?.get('_id')])

  React.useEffect(() => {
    _fetchSources()
  }, [props.storeSelected?.get('_id')])

  const onStoresChanged = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const hasAllSelected = storesSelected.find((s: any) => s == '') != null;
    const selectedAll = value.find((s: any) => s == '') != null;
    if (hasAllSelected != selectedAll) {
      setStorsSelected([])
      return
    }
    if (storesSelected.length < 1) {
      setStorsSelected(
        value
      )
      return
    }
    const values: any = fromJS(value)
    const selecteds: any = fromJS(storesSelected)
    const lastSelectedIndex = selecteds.findIndex((s: any) => s.get('_id') == values.last()?.get('_id'))
    if (lastSelectedIndex >= 0) {
      const list: any = selecteds.deleteIn([lastSelectedIndex]).toJS()
      setStorsSelected(list)
    }
    else {
      setStorsSelected(value)
    }
  };

  const _onStoresOpen = () => {
    setStoreOpen(true)
  }

  const _onStoresClose = () => {
    props.onStoresSelected && props.onStoresSelected(storesSelected)
    setStoreOpen(false)
  }

  return (
    <>
      <div className={props.columnClassNames ?? 'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>
            Thương hiệu
          </h4>
          <Dropdown>
            <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
              <div className={'hstack'}>
                <div className={'text-truncate'}>
                  {props.brandSelected?.get('name') ?? 'Tất cả thương hiệu'}
                </div>
                <FaChevronDown size={13} className={'ms-auto'}/>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <SectionFilterBrandItem
                label={'Tất cả thương hiệu'}
                brand={null}
                onSelect={props.onBrandSelected}/>
              {
                (props.brands ?? app.brands)?.map((b: any) => {
                  return (
                    <SectionFilterBrandItem
                      key={`filter_brand_${b.get('_id')}`}
                      brand={b}
                      onSelect={props.onBrandSelected}/>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {
        props.onStoreSelected &&
        <div className={props.columnClassNames ?? 'mt-3 col-6 col-xl-4 col-sm-4'}>
          <div className={'shadow-card'}>
            <h4 className={'section-title'}>
              Cửa hàng
            </h4>
            <Dropdown className={props.brandSelected!! ? '' : 'opacity-50'}>
              <Dropdown.Toggle className={'filter w-100'} variant={'light'} disabled={!props.brandSelected}>
                <div className={'hstack'}>
                  <div className={'text-truncate'}>
                    {props.storeSelected?.get('name') ?? 'Tất cả cửa hàng'}
                  </div>
                  <FaChevronDown size={13} className={'ms-auto'}/>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <SectionFilterStoreItem
                  label={'Tất cả cửa hàng'}
                  store={null}
                  onSelect={props.onStoreSelected}/>
                {
                  stores?.map((s: any, index: number) => {
                    return (
                      <SectionFilterStoreItem
                        key={`filter_store_${s.get('_id')}_${index}`}
                        store={s}
                        onSelect={props.onStoreSelected!}/>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      }
      {
        props.onStoresSelected &&
        <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
          <div className={'shadow-card'}>
            <h4 className={'section-title m-0'}>Cửa hàng</h4>
            <MFormControl sx={{ m: 1, width: '100%' }} size={'small'} className={'mt-2 m-0'} disabled={!props.brandSelected}>
              <MSelect
                multiple
                displayEmpty
                open={isStoresOpen}
                onOpen={_onStoresOpen}
                onClose={_onStoresClose}
                value={storesSelected}
                onChange={onStoresChanged}
                renderValue={(selected) => {
                  if (selected.length < 1) {
                    return 'Tất cả'
                  }
                  return selected.map((s: any) => s.name).join(', ')
                }}
                input={<BootstrapInput />}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 240,
                    },
                  },
                }}
                >
                  <MMenuItem value="">
                    Cửa hàng
                  </MMenuItem>
                  {
                    stores &&
                    stores.map((store: any) => {
                      const isSelected = !!storesSelected.find((s: any) => s._id == store.get('_id'))
                      return (
                        <MMenuItem 
                          value={store.toJS()} 
                          key={`store_s_${store.get('_id')}`} >
                          <MCheckbox checked={isSelected} />
                          <MListItemText primary={store.get('name', '')} />
                        </MMenuItem>
                      )
                    })
                  }
              </MSelect>
            </MFormControl>
          </div>
        </div>
      }
      {
        props.onSourceSelected &&
        <div className={props.columnClassNames ?? 'mt-3 col-6 col-xl-4 col-sm-4'}>
          <div className={'shadow-card'}>
            <h4 className={'section-title'}>
              Nguồn đơn
            </h4>
            <Dropdown className={props.storeSelected!! ? '' : 'opacity-50'}>
              <Dropdown.Toggle className={'filter w-100'} variant={'light'} disabled={!props.storeSelected}>
                <div className={'hstack'}>
                  <div className={'text-truncate'}>
                    {props.sourceSelected?.get('name') ?? 'Tất cả nguồn đơn'}
                  </div>
                  <FaChevronDown size={13} className={'ms-auto'}/>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <SectionFilterSourceItem
                  label={'Tất cả nguồn đơn'}
                  source={null}
                  onSelect={props.onSourceSelected}/>
                {
                  props.onSourceSelected &&
                  sources?.map((s: any, index: number) => {
                    return (
                      <SectionFilterSourceItem
                        key={`filter_source_${s.get('_id')}`}
                        source={s}
                        onSelect={props.onSourceSelected!}/>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      }
    </>
  )
}

export default SectionBrandFilter;