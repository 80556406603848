import {Dropdown} from "react-bootstrap";

interface SectionConfigOptionItemType {
  value: any,
  label: string,
  onSelect: (value: any) => void
}

const SectionConfigOptionItem = (props: SectionConfigOptionItemType) => {

  const _onClick = () => {
    props.onSelect(props.value ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.label}
    </Dropdown.Item>
  )
}

export default SectionConfigOptionItem;