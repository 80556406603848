import React from "react";
import GrabPlatformOrderImporterProvider from "./provider";
import {Modal} from "react-bootstrap";
import Views from "./views";

interface GrabPlatformOrderImporterType {
  onClose: () => void;
  show: boolean;
  code: any;
  orderId: string;
  storeId: string;
  provider: string;
  fromExistOrder: boolean
}

const GrabPlatformOrderImporter = (props: GrabPlatformOrderImporterType) => {

  return (
    <GrabPlatformOrderImporterProvider fromExistOrder={props.fromExistOrder}>
      <Modal show={props.show} onHide={props.onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Nhập đơn Grab { props.code }
          </Modal.Title>
        </Modal.Header>
        <Views
          id={props.orderId}
          show={props.show}
          close={props.onClose}
          storeId={props.storeId}
          provider={props.provider}/>
      </Modal>
    </GrabPlatformOrderImporterProvider>
  )
}

export default GrabPlatformOrderImporter;