import HubList from './components/hubList'
import HubProvider from './provider'

const Hubs: React.FC = () => {
  return (
    <HubProvider>
      <HubList />
    </HubProvider>
  )
}

export default Hubs
