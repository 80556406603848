import OrderDetailProvider from "./provider";
import Views from "./components/views";

const OrderDetail = () => {
  return (
    <OrderDetailProvider>
      <Views/>
    </OrderDetailProvider>
  )
}

export default OrderDetail