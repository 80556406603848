import { queries } from '@testing-library/react'
import { fromJS } from 'immutable'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useApp } from '../../components/AdminLayout/admin_provider'
import { useAuth } from '../../components/Auth/auth'
import { Api } from '../../repository/api'
import { HubApi } from '../../repository/api/hubs'
import { StoreApi } from '../../repository/api/stores'

interface HubCreatorContextType {
  loading: boolean
  hub: any
  stores: any
}
let HubCreatorContext = React.createContext<HubCreatorContextType>(null!)

const useHubCreator = () => {
  return React.useContext(HubCreatorContext)
}
const HubCreatorProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [stores, setStore] = React.useState(fromJS([]))
  const [hub, setHub] = React.useState<any>(fromJS({}))
  const app = useApp()
  const { id } = useParams()

  const _fetchHub = async () => {
    try {
      if (!id) return
      setLoading(true)
      const res = await HubApi.fetchHubDetail(id)
      setLoading(false)
      setHub(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _loadStore = async () => {
    try {
      const rs = await StoreApi.fetchStoreAll()
      setStore(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _initData = async () => {
    if (!app.brands) {
      app.fetchBrand()
    }
  }

  useEffect(() => {
    if (id) {
      _fetchHub()
    }
    _loadStore()
    _initData()
  }, [])

  const value = { loading, hub, stores }
  return <HubCreatorContext.Provider value={value}>{children}</HubCreatorContext.Provider>
}
export { useHubCreator }
export default HubCreatorProvider
