import React from 'react'
import WebsiteHomePageConfigProvider from "./provider";
import Views from "./components/views";

const WebsiteHomePageConfig = () => {
  return (
    <WebsiteHomePageConfigProvider>
      <Views/>
    </WebsiteHomePageConfigProvider>
  )
}

export default WebsiteHomePageConfig
