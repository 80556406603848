import Flatpickr from "react-flatpickr";
import {FaSearch} from "react-icons/fa";
import React from "react";
import {useGrabPlatformOrders} from "../provider";
import {Spinner} from "react-bootstrap";

const SectionFilter = () => {

  const provider = useGrabPlatformOrders();

  const _onTimeChanged = (e: any) => {
    if (!e || e.length < 1) return;

    provider.setTime(e[0])
  }

  const _onFetchClicked = async () => {
    await provider.fetchOrders();
  }

  return (
    <div className={'row align-items-end'}>
      <div className={'col-auto'}>
        <div>Thời gian</div>
        <div className={'mt-1'}>
          <Flatpickr
            className={'form-control form-control-sm'}
            options={{
              dateFormat: "d-m-Y",
              maxDate: "today",
            }}
            onChange={_onTimeChanged}
          />
        </div>
      </div>
      <div className={'col'}>

      </div>
      <div className={'col-auto'}>
        <button
          type="button"
          className="btn btn-primary"
          disabled={provider.loading}
          onClick={_onFetchClicked}>
          { !provider.loading && <FaSearch size={12}/> }
          { provider.loading && <Spinner animation="border" size={'sm'}/> }
          <small className={'ms-2'}>Lấy đơn</small>
        </button>
      </div>
    </div>
  )
}

export default SectionFilter;