import { useEffect, useState } from 'react'
import { FormControl, Row } from 'react-bootstrap'
import useDebounce from '../../../util/useDebounce'
import { useHubs } from '../provider'

const HubFilter: React.FC = () => {
  const [search, setSearch] = useState<string>('')
  const debouncedValue = useDebounce<string>(search)
  const { onSearchChanged } = useHubs()

  useEffect(() => {
    onSearchChanged(search)
  }, [debouncedValue])
  return (
    <Row>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>Tên điểm bán</h4>
          <FormControl
            type="text"
            placeholder="Nhập điểm bán"
            value={search}
            onChange={(el) => setSearch(el.target.value)}
          />
        </div>
      </div>
    </Row>
  )
}

export default HubFilter
