import React from 'react'
import { fromJS } from 'immutable'
import { Api } from '../../repository/api'

interface BrandContextType {
  queries: any
  data: any
  loading: boolean
  onNextPage: () => void
  onPreviousPage: () => void
  onFirstPage: () => void
  onSearchChanged: (brand?: any) => void
  meta: any
}

let BrandContext = React.createContext<BrandContextType>(null!)

const useBrands = () => {
  return React.useContext(BrandContext)
}

const BrandProvider: React.FC = ({ children }) => {
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)

  const _fetchBrands = async () => {
    try {
      console.log('-> _fetchBrands: ', queries.toJS())
      const rs: any = await Api.brand.fetchBrandList(queries.get('page'), queries.get('search'))
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    setLoading(true)

    const list: any = await _fetchBrands()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSearchChanged = (brand?: any) => {
    setQueries(queries.updateIn(['search'], () => brand))
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData()
    }
  }, [])

  React.useEffect(() => {
    if (queries.size < 1) return
    _refresh()
  }, [queries])

  let value = {
    queries,
    data,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSearchChanged,
    meta
  }

  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

export default BrandProvider
export { useBrands }
