import {AxiosResponse} from "axios";
import {_post, _get} from "./request";

export const OrderApi = {
  fetchOrders(
    brands: any, page: number, state: any, search?: string, hubs?: any, from?: number, to?: number, source?: string
  ): Promise<AxiosResponse<any>> {
    const filters: any = {
      brands
    }
    if (state) {
      filters['state'] = state
    }
    if (hubs) {
      filters['hubs'] = hubs
    }
    if (from) {
      filters['from'] = from
      filters['to'] = to
    }
    if (source) {
      filters['source'] = source
    }
    return _post(`brand/admin/v1/order/list`, {
      filters,
      page: page,
      limit: 20,
      search
    })
  },
  importOrder(order: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/order/import`, order)
  },
  createOrder(order: any): Promise<AxiosResponse<any>> {
    return _post(`brand/v1/order/save`, order)
  },
  fetchOrderDetail(orderId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/order/${orderId}`)
  },
}