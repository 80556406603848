import { Button, Card, Stack } from 'react-bootstrap'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { TiDelete } from 'react-icons/ti'
import { useModal } from '../../../components/Modal/modalProvider'
import { useModalState } from '../../../util/hook/useModalState'
import ModalFormBanner from './formBannerModal'
import {useWebsiteHomePageConfig} from "../provider";

interface TopBannerProps {
  index: number
  config: any
  onConfigChanged: (config: any, index: number) => void
  inCreasePos: (index: number) => void
  DeCreasePos: (index: number) => void
}

const TopBanner: React.FC<TopBannerProps> = (props) => {
  const settingProvider = useWebsiteHomePageConfig()
  const modal = useModal()

  const { isOpen, onOpen, onClose } = useModalState()

  const handleRemoveSecsion = async () => {
    try {
      await modal({ title: 'Bạn muốn xóa mục này?' })
      settingProvider.removeSection(props.config)
    } catch (error) {
      return
    }
  }

  return (
    <div className="border-bottom shadow-card p-0">
      <Card.Header className={'bg-transparent'}>
        <Stack direction="horizontal" className="justify-content-between">
          <div className="fw-bold fs-3">{props.config.get('title') || 'Banner'}</div>

          <div className="d-flex gap-3 align-items-center">
            <Button variant="outline-primary" onClick={onOpen}>
              Sửa
            </Button>
            <Button variant="outline-secondary" onClick={() => props.inCreasePos(props.index)}>
              <FaArrowUp/>
            </Button>

            <Button variant="outline-secondary" onClick={() => props.DeCreasePos(props.index)}>
              <FaArrowDown />
            </Button>

            <TiDelete fontSize={30} cursor="pointer" onClick={handleRemoveSecsion} />
          </div>
        </Stack>
      </Card.Header>

      <div>
        <Stack
          gap={2}
          direction="horizontal"
          className="overflow-scroll p-3"
          style={{ minHeight: 200 }}
        >
          {!!props.config.get('banners') &&
            props.config.get('banners').map((banner: any, index: number) => (
              <div key={index} className="col-12 col-sm-6 col-lg-4 position-relative">
                <img
                  src={banner.get('desktop_image_url') || banner.get('mobile_image_url')}
                  alt={banner.get('name')}
                  style={{
                    width: '100%',
                    maxHeight: 200,
                    minHeight: 200,
                    height: ' auto',
                    objectFit: 'contain'
                  }}
                />
              </div>
            ))}
        </Stack>
      </div>

      <ModalFormBanner
        show={isOpen}
        handleClose={onClose}
        {...props} />
    </div>
  )
}

export default TopBanner
