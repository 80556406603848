import React from 'react'
import {Form, Stack} from 'react-bootstrap'

interface WebsiteMenuItemCategoryCreatorPropType {
  category: any,
  setCategory: React.Dispatch<any>
}

const WebsiteMenuItemCategoryCreator: React.FC<WebsiteMenuItemCategoryCreatorPropType> = ({ category, setCategory }) => {
  const onNameChanged = (name: string) => {
    setCategory(category.updateIn(['name'], () => name))
  }

  return (
    <Form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            Tên danh mục <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={category.get('name')}
            onChange={(el) => onNameChanged(el.target.value)}
          />
        </Form.Group>
      </Stack>
    </Form>
  )
}

export default WebsiteMenuItemCategoryCreator
