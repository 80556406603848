import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation, useParams} from "react-router-dom";

interface UserProfileContextType {
  profile: any,
  brandPermissions: any,
  outletPermissions: any,
  loading: boolean,
  changeAccountStatus: (value: boolean) => void,
  updateName: (value: string) => Promise<boolean>,
  updatePassword: (value: string) => Promise<boolean>,
  loadOutletPermissions: () => Promise<void>,
  loadBrandPermissions: () => Promise<void>,
  removeOutletPermissions: (item: any) => Promise<boolean>,
}

let UserProfileContext = React.createContext<UserProfileContextType>(null!);

const useUserProfile = () => {
  return React.useContext(UserProfileContext);
};

function UserProfileProvider({ children }: { children: React.ReactNode }) {

  const [profile, setProfile] = React.useState<any>(fromJS([]))
  const [brandPermissions, setBrandPermissions] = React.useState<any>(fromJS([]))
  const [outletPermissions, setOutletPermissions] = React.useState<any>(fromJS([]))
  const [loading, setLoading] = React.useState(false);
  const { userId } = useParams()

  if (!userId) {
    window.alert('Not found user')
  }

  const _fetchUser = async() => {
    try {
      const rs: any = await Api.user.fetchUser(userId ?? '')
      setProfile(fromJS(rs.data.data))
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const _fetchBrandPermissions = async () => {
    try {
      const rs: any = await Api.user.fetchUserBrandPermissions(userId ?? '')
      setBrandPermissions(fromJS(rs.data.data))
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const _fetchOutletPermissions = async () => {
    try {
      const rs: any = await Api.user.fetchUserOutletPermissions(userId ?? '')
      setOutletPermissions(fromJS(rs.data.data))
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    await _fetchUser();
    await _fetchBrandPermissions();
    await _fetchOutletPermissions();

    setLoading(false)
  }

  const changeAccountStatus = async (value: boolean) => {
    try {
      const rs: any = await Api.user.updateStatus(userId ?? '', value)
      if (rs.data.data) {
        setProfile(
          profile.updateIn(['status'], () => value ? 'active' : 'inactive')
        )
      }
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const updateName = async (value: string) => {
    try {
      const rs: any = await Api.user.updateName(userId ?? '', value)
      if (rs.data.data) {
        setProfile(
          profile.updateIn(['username'], () => value)
        )
        return true;
      }
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    return false
  }

  const updatePassword = async (value: string) => {
    try {
      const rs: any = await Api.user.setPassword(userId ?? '', value)
      if (rs.data.data) {
        setProfile(
          profile.updateIn(['password'], () => '')
        )
        return true;
      }
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    return false
  }

  const loadOutletPermissions = async () => {
    await _fetchOutletPermissions();
  }

  const loadBrandPermissions = async () => {
    await _fetchBrandPermissions();
  }

  const removeOutletPermissions = async (item: any) => {
    try {
      const rs: any = await Api.user.removeOutletUserPermission(userId ?? '', item.get('_id'))
      await loadOutletPermissions();
      return true
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    return false
  }

  React.useEffect(() => {
    _refresh()
  }, []);

  let value = {
    profile,
    loading,
    brandPermissions,
    outletPermissions,
    changeAccountStatus,
    updateName,
    updatePassword,
    loadOutletPermissions,
    loadBrandPermissions,
    removeOutletPermissions
  };

  return <UserProfileContext.Provider value={value}>{children}</UserProfileContext.Provider>;
}

export default UserProfileProvider;
export { useUserProfile };