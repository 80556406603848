import {Dropdown} from "react-bootstrap";

interface SectionFilterStringItemType {
  value?: any,
  label?: string,
  onSelect: (string: any) => void
}

const SectionFilterStringItem = (props: SectionFilterStringItemType) => {

  const _onClick = () => {
    props.onSelect(props.value ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.label}
    </Dropdown.Item>
  )
}

export default SectionFilterStringItem;