import {FaTrashAlt} from 'react-icons/fa'
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {Colors} from "../../../util/color_utils";

interface SubBrandItemProps {
  brand: any
  index: number
  handleSubBrandDeleted: (index: number) => void
}

const SubBrandItem: React.FC<SubBrandItemProps> = ({ brand, index, handleSubBrandDeleted }) => {

  const onDeleceClicked = () => {
    handleSubBrandDeleted(index)
  }
  return (
    <tr>
      <td>
        <div>{brand.get('name')}</div>
      </td>
      <td>
        <div>{brand.get('slug')}</div>
      </td>
      <td>
        <div className="d-flex justify-content-around">
          <LabelIconBtn
            label={'Xóa'}
            icon={<FaTrashAlt color={Colors.Error}/>}
            color={Colors.Error}
            onClick={onDeleceClicked}/>
        </div>
      </td>
    </tr>
  )
}

export default SubBrandItem
