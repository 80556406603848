import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";
import moment from "moment";

interface ClosedLogsContextType {
  queries: any,
  locations: any,
  data: any,
  timelines: any,
  loading: boolean,
  isSingleDay: boolean,
  onBrandChanged: (brand?: any) => void,
  onStoresChanged: (stores?: any) => void,
  onAppChanged: (source?: any) => void,
  onLocationChanged: (name?: any) => void,
  onDateChanged: (from: any, to: any) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  fetchLogs: (page?: number, limit?: number) => any
}

let ClosedLogsContext = React.createContext<ClosedLogsContextType>(null!);

const useClosedLogs = () => {
  return React.useContext(ClosedLogsContext);
};

function ClosedLogsProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [timelines, setTimelines] = React.useState<any>(fromJS([]))
  const [loading, setLoading] = React.useState(false);
  const [locations, setLocations] = React.useState<any>(fromJS([]))
  const [isSingleDay, setSingleDay] = React.useState(false);
  const app = useApp()

  const _fetchLocations = async () => {
    try {
      const rs: any = await Api.log.fetchLocations()
      console.log(rs?.data);
      if (rs.data?.data) {
        setLocations(fromJS(rs.data.data))
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  const fetchLogs = async(page?: number, limit?: number) => {
    try {
      const rs: any = await Api.log.fetchClosedLogs({
        page: page ?? queries.get('page'),
        limit: limit ?? 10,
        brand: queries.getIn(['brand', '_id']),
        stores: queries.getIn(['stores'], []).map((s: any) => s._id).join(','),
        app: queries.getIn(['app']),
        from: queries.getIn(['from']),
        to: queries.getIn(['to']),
        location: queries.getIn(['location'])
      })
      return rs.data?.data
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _prepareTimelineData = async (list: any) => {
    const stores: any = [];
    for (let log of list) {
      const store = stores.find((s: any) => s.store === log.store && s.foodApp === log.foodApp)
      log.time = moment(log.created).local().valueOf()
      if (!store) {
        stores.push({
          store: log.store,
          name: log.name,
          foodApp: log.foodApp,
          logs: [log]
        })
      } else {
        store.logs.push(log)
      }
    }
    const startOfDate = queries.get('from')
    const timeStep = 5 * 60 * 1000
    for (let store of stores) {
      let chartData = [];
      let chartLabels = [];
      let chartColors = [];
      const logs = store.logs.sort((a: any, b: any) => {
        if (a.time > b.time) return 1
        if (a.time < b.time) return -1
        return 0
      })
      let time: number = startOfDate
      for (let i = 0; i < 288; i++) { //check on log per 15m
        const log = logs.findLast((l: any) => l.time <= time)
        const isAvailable = log?.status === 'open';
        chartData.push({
          label: i, 
          data: isAvailable ? 1 : 0.5, 
          time: moment(time).format('HH:mm'),
          note: log?.note
        })
        chartLabels.push(i)
        chartColors.push(isAvailable ? '#8bc34a' : '#f44336')

        time += timeStep
      }
      store.chart = {
        data: chartData,
        labels: chartLabels,
        colors: chartColors
      }
    }
    setTimelines(fromJS(stores))
    setLoading(false)
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await fetchLogs()

    if (!list) {
      setData(fromJS([]))
      setLoading(false)
      return
    }
    const singleDay = queries.getIn(['from']) > 0 && queries.getIn(['to'], 0) - queries.getIn(['from']) <= 24 * 60 * 60 * 1000
    setSingleDay(singleDay)
    if (singleDay) {
      _prepareTimelineData(list)   
      return
    }
    setData(fromJS(list))
    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['store'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onStoresChanged = (stores?: any) => {
    setQueries(
      queries.updateIn(['stores'], () => stores)
        .updateIn(['page'], () => 1)
    )
  }

  const onAppChanged = (app?: any) => {
    setQueries(
      queries.updateIn(['app'], () => app)
        .updateIn(['page'], () => 1)
    )
  }

  const onLocationChanged = (app?: any) => {
    setQueries(
      queries.updateIn(['location'], () => app)
        .updateIn(['page'], () => 1)
    )
  }

  const onDateChanged = (from: any, to: any) => {
    if (!from) return
    const fromTime = moment(from).startOf('day').valueOf()
    const toTime = moment(to).endOf('day').valueOf()
    setQueries(
      queries
        .updateIn(['from'], () => fromTime)
        .updateIn(['to'], () => toTime)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  React.useEffect(() => {
    if (queries.size < 1) return;
    _refresh()
  }, [queries]);

  React.useEffect(() => {
    if (!app.brands) {
      app.fetchBrand();
      return;
    }
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, [app.brands]);

  React.useEffect(() => {
    _fetchLocations();
  }, []);

  let value = {
    queries,
    data,
    locations,
    loading,
    isSingleDay,
    timelines,
    fetchLogs,
    onAppChanged,
    onLocationChanged,
    onBrandChanged,
    onStoresChanged,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onDateChanged
  };

  return <ClosedLogsContext.Provider value={value}>{children}</ClosedLogsContext.Provider>;
}

export default ClosedLogsProvider;
export { useClosedLogs };