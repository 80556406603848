import { useEffect, useState } from 'react'
import { Card, FormSelect, InputGroup } from 'react-bootstrap'
import { OpenTimeType } from './store_open_times'
import Time from './time'

type OpenTimeItemProps = {
  openTime: OpenTimeType[]
  index: number
  openTimeItem: OpenTimeType
  handleDayChanged: (day: string, index: number) => void
  handleTimeChanged: (times: Array<string[]>, index: number) => void
  dayOptions: string[]
}

function OpenTimeItem(props: OpenTimeItemProps) {
  const [day, setDay] = useState<string>('')
  const onChange = (day: string, index: number) => {
    props.handleDayChanged(day, index)
    setDay(day)
  }
  useEffect(() => {
    props.openTimeItem.day && setDay(props.openTimeItem.day)
  }, [])

  useEffect(() => {
    if (props.openTimeItem.day) {
      setDay(props.openTimeItem.day)
    }
  }, [props.openTimeItem])

  return (
    <Card className="col-xl-8 col-lg-12 col-xxl-6">
      <Card.Body>
        <div className="gap-3 d-flex flex-column flex-sm-row">
          <div className="col-12 col-sm-3 align-items-center d-flex">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Thứ : </InputGroup.Text>
              <FormSelect value={day} onChange={(el) => onChange(el.target.value, props.index)}>
                {props.dayOptions.map((el, index) => (
                  <option
                    key={`${el}_${index}`}
                    value={el}
                    disabled={props.openTime.map((o) => o.day).includes(el)}
                  >
                    {el}
                  </option>
                ))}
              </FormSelect>
            </InputGroup>
          </div>

          <Time
            handleTimeChanged={props.handleTimeChanged}
            openTimeIndex={props.index}
            timeData={props.openTimeItem.times}
          />
        </div>
      </Card.Body>
    </Card>
  )
}

export default OpenTimeItem
