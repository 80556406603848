function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}:${minutes}`;
}

export const chartUtils = {
  timeHitLineOptions: {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0
    },
    locale: 'en-US',
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function(context: any) {
            if (!Array.isArray(context)) return '';
            return context[0].raw < 1 ? 'Đóng' : 'Mở';
          },
          label: function(context: any) {
            const minutes = context.dataIndex * 5;
            return toHoursAndMinutes(minutes);
          }
        }
      },
    },
    scales: {
      y: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        }
      },
      x: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
      }
    },
    parsing: {
      xAxisKey: 'label',
      yAxisKey: 'data'
    }
  }
}