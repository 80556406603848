import {Pagination} from "react-bootstrap";
import React from "react";

interface TablePaginationType {
  page: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
  onFirstPage: () => void;
}

const TablePagination = (props: TablePaginationType) => {
  return (
    <Pagination size={'sm'}>
      <Pagination.Item disabled={props.page <= 1} onClick={props.onPreviousPage}>
        Trang trước
      </Pagination.Item>
      {
        props.page > 1 &&
			  <Pagination.Item onClick={props.onFirstPage}>1</Pagination.Item>
      }
      <Pagination.Ellipsis disabled/>
      <Pagination.Item active>{props.page}</Pagination.Item>
      <Pagination.Ellipsis disabled/>
      <Pagination.Item onClick={props.onNextPage}>
        Trang kế
      </Pagination.Item>
    </Pagination>
  )
}

export default TablePagination;