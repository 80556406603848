import {Dropdown} from "react-bootstrap";

interface SectionFilterBrandItemType {
  brand: any,
  label?: string,
  onSelect: (brand: any) => void
}

const SectionFilterBrandItem = (props: SectionFilterBrandItemType) => {

  const _onClick = () => {
    props.onSelect(props.brand ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.brand?.get('name') ?? props.label}
    </Dropdown.Item>
  )
}

export default SectionFilterBrandItem;