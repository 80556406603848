import { fromJS } from "immutable";
import { ReactElement, createContext, useContext, useEffect, useState } from "react";
import { TableModel } from "../../repository/model/TableModel";
import { Api } from "../../repository/api";
import { SourceTableConfigModel } from "../../repository/model/SourceTableConfigModel";


const TablesContext = createContext<TablesType>(null!)

interface TablesType {
    queries: any,
    config?: SourceTableConfigModel,
    isConfigDialogOpen: boolean,
    tables: TableModel[]
    onBrandChanged: (brand?: any) => void,
    onStoreChanged: (store?: any) => void,
    onSourceChanged: (source?: any) => void,
    onPageChanged: (pageIndex?: number) => void,
    getPageIndex: () => number,
    setOpenConfigDialog: (isOpen: boolean) => void,
    setLoading: (isLoading: boolean) => void,
    onSubmitConfig: () => void,
    setConfig: (config: SourceTableConfigModel) => void,
    setQueries: (queries: any) => void,
    getTableCount: () => Promise<number>,
    updatePickingTableEnabled: (isChecked: boolean) => void,
    updateInitData: (search: URLSearchParams) => void,
}

export function useTablesProvider() {
    return useContext(TablesContext)
}


export function TablesProvider({ children }: { children: ReactElement }) {
    const [queries, setQueries] = useState<any>(fromJS({}))
    const [tables, setTables] = useState<TableModel[]>([])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isConfigDialogOpen, setOpenConfigDialog] = useState<boolean>(false)
    // const [, ] = useState<SourceTableConfigModel>({} as SourceTableConfigModel)
    let config: SourceTableConfigModel | undefined

    const setConfig = (c?: SourceTableConfigModel) => {
        config = c
    }
    const onBrandChanged = (brand: any) => {

        setQueries(
            queries.updateIn(['brand'], () => brand)
                .updateIn(['store'], () => null)
                .updateIn(['source'], () => null)
                .updateIn(['pageIndex'], () => 1)
        )
    }

    const onStoreChanged = (store: any) => {
        setQueries(
            queries.updateIn(['store'], () => store)
                .updateIn(['source'], () => null)
                .updateIn(['pageIndex'], () => 1)
        )
    }

    const onSourceChanged = (source: any) => {
        setQueries(
            queries.updateIn(['source'], () => source)
                .updateIn(['pageIndex'], () => 1)
        )

        setLoading(true)

    }

    const onPageChanged = (pageIndex?: number) => {
        setQueries(
            queries.updateIn(['pageIndex'], () => pageIndex)
        )
        setLoading(true)
    }
    const _getBrandId = () => queries.get('brand')?.get('_id')
    const _getStoreId = () => queries.get('store')?.get('_id')
    const _getSourceId = () => queries.get('source')?.get('_id')
    const getPageIndex = () => queries.get('pageIndex')



    const _fetchTables = async () => {
        try {
            const response = await Api.table.fetchTables(_getStoreId(), _getSourceId(), getPageIndex(), 50)
            setTables(response.data.data)
            setLoading(false)
        } catch (err: any) {
            setLoading(false)
            window.alert(
                err?.response?.data?.error?.message ??
                'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
            )
            return fromJS([])
        }

    }

    const onSubmitConfig = async () => {
        try {
            if (!config?.sourceId || !config?.storeId) return

            const response = await Api.store.editSourceTableConfig(config.sourceId, config.pickingTableEnabled)
            if (response.data.error) {
                window.alert(
                    response?.data?.error?.message ??
                    'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
                )
                return
            }
            if (config.pickingTableEnabled) {
                const response = await Api.table.createTableByCount(config.storeId, config.sourceId, config.count)
                if (response.data.error) {
                    window.alert(
                        response?.data?.error?.message ??
                        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
                    )
                    return
                }
            }

            _fetchTables()

        } catch (err: any) {
            window.alert(
                err?.response?.data?.error?.message ??
                'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
            )
        }

    }

    const getTableCount = async () => {
        try {
            const response = await Api.table.fetchTableCount(_getStoreId(), _getSourceId())
            if (response.data.error) {
                return null
            }
            return response.data.data.countTable
        } catch (err: any) {
            return null
        }
    }

    const updatePickingTableEnabled = (isChecked: boolean) => {
        setQueries(queries.updateIn(['source', 'pickingTableEnabled'], () => isChecked))
    }

    const updateInitData = (search: URLSearchParams) => {
        
        setQueries(
            queries.updateIn(['brand'], () => fromJS({ _id: search.get('brand')?.split('.')[1], name: search.get('brand')?.split('.')[0] }))
                .updateIn(['store'], () => fromJS({ _id: search.get('store')?.split('.')[1], name: search.get('store')?.split('.')[0] }))
                .updateIn(['source'], () => fromJS({ _id: search.get('source')?.split('.')[1], name: search.get('source')?.split('.')[0], pickingTableEnabled: search.get('source')?.split('.')[2] === 'true' }))
                .updateIn(['pageIndex'], () => 1)
        )
    }


    useEffect(() => {
        if (isLoading) {
            _fetchTables()
        }
    }, [isLoading])


    const value = {
        queries,
        tables,
        isConfigDialogOpen,
        config,
        setLoading,
        onSubmitConfig,
        setConfig,
        setOpenConfigDialog,
        onPageChanged,
        getPageIndex,
        onBrandChanged,
        onStoreChanged,
        updatePickingTableEnabled,
        updateInitData,
        onSourceChanged,
        getTableCount,
        setQueries,
    }

    return (
        <TablesContext.Provider value={value}>
            {children}
        </TablesContext.Provider>
    )
}