import { fromJS } from 'immutable'
import { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import { FaCogs } from 'react-icons/fa'
import { useModal } from '../../../components/Modal/modalProvider'
import NormalModal from '../../../components/Modal/normalModal'
import { HubApi } from '../../../repository/api/hubs'
import { useUsers } from '../provider'
import UserFormModal from './UserFormModal'

interface UserItemProps {
  index: number
  user: any
}

const UserItem: React.FC<UserItemProps> = ({ user, index }) => {
  const { _refresh, hub } = useUsers()
  const modal = useModal()
  const [bodyRequest, setBody] = useState<any>(fromJS({}))
  const [openEditUser, setOpen] = useState<boolean>(false)

  const handleOpenEditUser = () => setOpen(true)
  const handleCloseEditUser = () => setOpen(false)

  const handleDelete = async () => {
    try {
      await modal({ title: 'Xác nhận xóa thành viên này?' })
      await HubApi.deleteUserOnHub(hub.get('_id'), user.get('_id'))
      window.alert('Xóa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const handleEditUser = async () => {
    try {
      if (!bodyRequest.get('role')?.length) {
        window.alert('Vui lòng chọn vai trò!')
        return
      }
      await HubApi.EditUserToHub(hub.get('_id'), user.get('_id'), bodyRequest.toJS())
      handleCloseEditUser()
      window.alert('Chỉnh sửa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  useEffect(() => {
    if (user.get('role') && !openEditUser) {
      setBody(bodyRequest.updateIn(['role'], () => user.get('role')).updateIn(['user'], () => user))
    }
  }, [bodyRequest, openEditUser, user])
  return (
    <tr key={index}>
      <td>
        <div>{user.get('username')}</div>
      </td>
      <td>
        <div>{user.get('phone')}</div>
      </td>
      <td>
        <div>{user.get('role').join(', ')}</div>
      </td>
      <td style={{ width: 70 }}>
        <Dropdown align="end" className="config-btn d-flex justify-content-around">
          <DropdownToggle
            id="options-button"
            variant="borderless-dark"
            bsPrefix="no-chevron"
            size="sm"
          >
            <FaCogs fontSize={20} cursor="pointer" />
          </DropdownToggle>
          <DropdownMenu
            popperConfig={{
              strategy: 'fixed'
            }}
          >
            <Dropdown.Item onClick={handleOpenEditUser}>Chỉnh sửa</Dropdown.Item>
            <Dropdown.Item onClick={handleDelete}>Xóa</Dropdown.Item>
          </DropdownMenu>
        </Dropdown>
      </td>
      <NormalModal
        open={openEditUser}
        options={{
          title: 'Chỉnh sửa thành viên',
          content: (
            <UserFormModal
              setBody={setBody}
              bodyRequest={bodyRequest}
              hasUser={!!user.get('_id')}
            />
          ),
          confirmationText: 'Chỉnh sửa'
        }}
        onCancel={handleCloseEditUser}
        onConfirm={handleEditUser}
      />
    </tr>
  )
}

export default UserItem
