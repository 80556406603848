import PageLoading from "../../../components/page_loading";
import {Container, Dropdown, Row, Spinner, Table} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import SectionFilterBrandItem from "../../../components/Filters/section_filter_brand_item";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import {useCrm} from "../provider";
import {useOrders} from "../../Orders/provider";
import {useNavigate} from "react-router";
import TablePagination from "../../../components/table_pagination";
import PlatformOrderBtn from "../../Orders/components/platform_order_btn";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import CustomerItem from "./customer_item";
import SectionOrders from "../../Orders/components/section_orders";


const Views = () => {

  const crmProvider = useCrm();
  const app = useApp();
  const navigate = useNavigate();

  if (crmProvider.data.size < 1 && crmProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <main>
      <Nav title={'Khách hàng'}/>
      {
        crmProvider.loading && crmProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'mt-3'}>
          <div className={'row justify-content-between'}>
            <div className={'col'}>
              {
                (
                  (!!crmProvider.data && crmProvider.data.size >= 20) ||
                  crmProvider.queries.get('page', 1) > 1
                ) &&
                <TablePagination
                  page={crmProvider.queries.get('page', 1)}
                  onFirstPage={crmProvider.onFirstPage}
                  onNextPage={crmProvider.onNextPage}
                  onPreviousPage={crmProvider.onPreviousPage}/>
              }
            </div>
          </div>
          <Table striped hover responsive bordered className={'shadow-card'}>
            <thead>
            <tr>
              <th>STT</th>
              <th>Số điện thoại</th>
              <th>Tên</th>
              <th>Tổng tiền món</th>
              <th>Đơn hàng</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {
              crmProvider.data.map((customer: any, index: number) => {
                return <CustomerItem key={customer.get('_id')} index={index} customer={customer} page={crmProvider.queries.get('page')}/>
              })
            }
            </tbody>
          </Table>
          {
            (
              (!!crmProvider.data && crmProvider.data.size >= 20) ||
              crmProvider.queries.get('page', 1) > 1
            ) &&
            <TablePagination
              page={crmProvider.queries.get('page', 1)}
              onFirstPage={crmProvider.onFirstPage}
              onNextPage={crmProvider.onNextPage}
              onPreviousPage={crmProvider.onPreviousPage}/>
          }
        </div>
      </Container>
    </main>
  )
}

export default Views;