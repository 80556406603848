import React, {useState} from 'react';
import {Container, Dropdown, Form, FormControl, Row} from 'react-bootstrap';
import './styles.css';
import Nav from "../../components/Navbars/Nav";
import {useOrders} from "./provider";
import PageLoading from "../../components/page_loading";
import SectionOrders from "./components/section_orders";
import {FaChevronDown} from "react-icons/fa";
import SectionFilterBrandItem from "../../components/Filters/section_filter_brand_item";
import SectionFilterOrderStateItem from "../../components/Filters/section_filter_order_state_item";
import {orderStateInfo} from "../../util/common_utils";
import SectionBrandFilter from "../../components/Filters/section_brand_filter";
import SectionHubFilter from "../../components/Filters/section_hub_filter";
import Flatpickr from "react-flatpickr";
import SectionFilterStringItem from "../../components/Filters/section_filter_string_item";

const Orders = () => {

  const ordersProvider = useOrders();
  const [search, setSearch] = useState<any>('');

  const _onBrandChanged = (brand?: any) => {
    ordersProvider.onBrandChanged(brand);
  }

  const _onStoreSelected = (store?: any) => {
    ordersProvider.onStoreChanged(store);
  }

  const _onHubChanged = (hub?: any) => {
    ordersProvider.onHubChanged(hub);
  }

  const _onSubmitSearch = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    ordersProvider.submitSearch(search)
  }

  const _onTimeChanged = (e: any) => {
    if (!e || e.length < 2) {
      return
    }
    const from = e[0]
    const to = e[1]
    ordersProvider.onTimeChanged(from, to)
  }

  const _onSourceChanged = (e: any) => {
    ordersProvider.onSourceChanged(e)
  }

  return (
    <main>
      <Nav title={'Đơn hàng'}/>
      {
        ordersProvider.loading && ordersProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <Row>
          {
            !ordersProvider.queries.get('brand') &&
            <SectionHubFilter
              hubSelected={ordersProvider.queries.get('hub')}
              onHubSelected={_onHubChanged}/>
          }
          {
            !ordersProvider.queries.get('hub') &&
            <SectionBrandFilter
              brandSelected={ordersProvider.queries.get('brand')}
              storeSelected={ordersProvider.queries.get('store')}
              onBrandSelected={_onBrandChanged}
              onStoreSelected={_onStoreSelected}/>
          }
          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Trạng thái
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {ordersProvider.queries.get('state') ? orderStateInfo(ordersProvider.queries.get('state'))?.label : 'Tất cả trạng thái'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterOrderStateItem
                    label={'Tất cả trạng thái'}
                    state={null}
                    onSelect={ordersProvider.onStateChanged}/>

                  <SectionFilterOrderStateItem
                    label={'Đơn mới'}
                    state={'new'}
                    onSelect={ordersProvider.onStateChanged}/>

                  <SectionFilterOrderStateItem
                    label={'Hoàn Thành'}
                    state={'done'}
                    onSelect={ordersProvider.onStateChanged}/>

                  <SectionFilterOrderStateItem
                    label={'Hủy'}
                    state={'canceled'}
                    onSelect={ordersProvider.onStateChanged}/>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Nguồn đơn
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {ordersProvider.queries.get('source') ?? 'Tất cả nguồn đơn'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterStringItem
                    label={'Tất cả'}
                    value={null}
                    onSelect={_onSourceChanged}/>
                  {
                    ordersProvider.availableSources?.map((s: any, index: number) => {
                      return (
                        <SectionFilterStringItem
                          key={`filter_source_${s.get('name')}`}
                          value={s.get('name')}
                          label={s.get('name')}
                          onSelect={_onSourceChanged}/>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>Tìm đơn</h4>
              <Form onSubmit={_onSubmitSearch}>
                <FormControl
                  type="text"
                  placeholder="Mã đơn"
                  value={search}
                  onChange={(el) => setSearch(el.target.value)}
                />
              </Form>
            </div>
          </div>
          <div className={'mt-3 col-6 col-xl-3 col-sm-3'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>Thời gian</h4>
              <div className={'mt-1'}>
                <Flatpickr
                  className={'form-control'}
                  options={{
                    mode: 'range',
                    dateFormat: "d-m-Y",
                    maxDate: "today",
                  }}
                  onChange={_onTimeChanged}
                />
              </div>
            </div>
          </div>
        </Row>

        <SectionOrders/>
      </Container>
    </main>
  );
};

export default Orders;