import { AxiosResponse } from 'axios'
import {_get, _post, _put, _delete, _patch} from './request'

export const UserApi = {
  fetchUsers(page: number, search?: string, limit: number = 20): Promise<AxiosResponse<any>> {
    return _get(`user/v2/users`, {
      page, search, limit
    })
  },
  fetchUser(userId: string): Promise<AxiosResponse<any>> {
    return _get(`user/v2/users/${userId}`)
  },
  createUser(phone: string, password: string, username: string): Promise<AxiosResponse<any>> {
    return _post(`user/v2/users`, { phone, password, username })
  },
  setPassword(userId: string, password: string): Promise<AxiosResponse<any>> {
    return _patch(`user/v2/users/${userId}/password`, {
      password
    })
  },
  deleteUser(userId: string): Promise<AxiosResponse<any>> {
    return _delete(`user/v2/users/${userId}`)
  },
  updateName(userId: string, name: string): Promise<AxiosResponse<any>> {
    return _patch(`user/v2/users/${userId}/name`, { name })
  },
  updateStatus(userId: string, status: boolean): Promise<AxiosResponse<any>> {
    if (!!status) {
      return _patch(`user/v2/users/${userId}/status/activate`)
    }
    return _patch(`user/v2/users/${userId}/status/deactivate`)
  },
  fetchUserBrandPermissions(userId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/users/${userId}/brands`)
  },
  fetchUserOutletPermissions(userId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/users/${userId}/stores`)
  },
  setOutletUserPermission(userId: string, storeId: string, permission: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/users/${userId}/stores/${storeId}`, {
      permission
    })
  },
  removeOutletUserPermission(userId: string, storeId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/users/${userId}/stores/${storeId}`)
  },
}
