import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";

interface MenuCloneContextType {
  dataFrom: any,
  dataTo: any,
  loading: boolean,
  onFromBrandChanged: (brandId?: any) => void,
  onFromStoreChanged: (storeId?: any) => void,
  onFromSourceChanged: (sourceId?: any) => void,
  onToBrandChanged: (brandId?: any) => void,
  onToStoreChanged: (storeId?: any) => void,
  onToStoreRemoved: (storeId?: any) => void,
  onToSourceChanged: (sourceId?: any) => void,
  submitCloneRequest: () => void
}

let MenuCloneContext = React.createContext<MenuCloneContextType>(null!);

const useMenuClone = () => {
  return React.useContext(MenuCloneContext);
};

function MenuCloneProvider({ children }: { children: React.ReactNode }) {

  const [dataFrom, setDataFrom] = React.useState<any>(fromJS({}));
  const [dataTo, setDataTo] = React.useState<any>(fromJS({}));
  const [loading, setLoading] = React.useState(false);

  const _fetchStores = async (target: string) => {
    let brandId = target === 'from' ? dataFrom.get('brandId') : dataTo.get('brandId')
    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, brandId, 200
      )
      if (target === 'from') {
        setDataFrom(
          dataFrom.updateIn(['stores'], () => fromJS(rs.data.data))
        )
      } else {
        setDataTo(
          dataTo.updateIn(['stores'], () => fromJS(rs.data.data))
        )
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchSources = async (target: string) => {
    let storeId = dataFrom.get('storeId')
    if (target === 'to') {
      if (!dataTo.get('stores_selected') || dataTo.get('stores_selected').size < 1) return
      storeId = dataTo.getIn(['stores_selected', 0, '_id'])
    }
    try {
      const rs: any = await Api.store.fetchSources(storeId)
      if (target === 'from') {
        setDataFrom(
          dataFrom.updateIn(['sources'], () => fromJS(rs.data.data))
        )
      } else {
        setDataTo(
          dataTo.updateIn(['sources'], () => fromJS(rs.data.data))
        )
      }
    } catch (e: any) {
      console.log(e)
    }
  }

  const onFromBrandChanged = (brandId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['brandId'], () => brandId)
        .updateIn(['storeId'], () => undefined)
        .updateIn(['sourceId'], () => undefined)
    )
    onToBrandChanged(brandId)
  }

  const onFromStoreChanged = (storeId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['storeId'], () => storeId)
        .updateIn(['sourceId'], () => undefined)
    )
    setDataTo(
      dataTo.updateIn(['sourceId'], () => undefined)
    )
  }

  const onFromSourceChanged = (sourceId?: any) => {
    setDataFrom(
      dataFrom.updateIn(['sourceId'], () => sourceId)
    )
  }

  const onToBrandChanged = (brandId?: any) => {
    setDataTo(
      dataTo.updateIn(['brandId'], () => brandId)
        .updateIn(['storeId'], () => undefined)
        .updateIn(['sourceId'], () => undefined)
    )
  }

  const onToStoreChanged = (store?: any) => {
    if (!dataTo.get('stores_selected')) {
      setDataTo(
        dataTo.updateIn(['stores_selected'], () => fromJS([store]))
          .updateIn(['sourceId'], () => undefined)
      )
    } else {
      setDataTo(
        dataTo.updateIn(['stores_selected'], (v: any) => v.push(store))
          .updateIn(['sourceId'], () => undefined)
      )
    }
  }

  const onToStoreRemoved = (store?: any) => {
    if (loading) return
    const index = dataTo.get('stores_selected').findIndex((s: any) => s.get('_id') === store.get('_id'))
    setDataTo(
      dataTo.updateIn(['stores_selected'], (v: any) => v.delete(index))
    )
  }

  const onToSourceChanged = (sourceId?: any) => {
    setDataTo(
      dataTo.updateIn(['sourceId'], () => sourceId)
    )
  }

  const submitCloneRequest = async () => {
    setLoading(true)

    const fromStoreId = dataFrom.get('storeId')
    const fromSourceId = dataFrom.get('sourceId')
    const toStoreIds = dataTo.get('stores_selected').map((s: any) => s.get('_id'))
    const toSourceId = dataTo.get('sourceId')

    try {
      const rs = await Api.menu.cloneMenuItems({
        fromStore: fromStoreId,
        fromSource: fromSourceId,
        toStores: toStoreIds,
        toSource: toSourceId
      })
      window.alert('Thực đơn sẽ được Sao chép trong khoảng vài phút. Vui lòng kiểm tra sau ít phút.')
      setDataFrom(
        dataFrom.clear()
      )
      setDataTo(
        dataTo.clear()
      )
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    setLoading(false)
  }

  React.useEffect(() => {
    if (dataFrom.get('brandId')) {
      _fetchStores('from')
    }
  }, [dataFrom.get('brandId')])

  React.useEffect(() => {
    if (dataFrom.get('storeId')) {
      _fetchSources('from')
    }
  }, [dataFrom.get('storeId')])

  React.useEffect(() => {
    if (dataTo.get('brandId')) {
      _fetchStores('to')
    }
  }, [dataTo.get('brandId')])

  React.useEffect(() => {
    if (dataTo.get('stores_selected') && dataTo.get('stores_selected').size > 0) {
      _fetchSources('to')
    }
  }, [dataTo.get('stores_selected')])

  let value = {
    dataFrom,
    dataTo,
    loading,
    onFromBrandChanged,
    onFromStoreChanged,
    onFromSourceChanged,
    onToBrandChanged,
    onToStoreChanged,
    onToStoreRemoved,
    onToSourceChanged,
    submitCloneRequest
  };

  return <MenuCloneContext.Provider value={value}>{children}</MenuCloneContext.Provider>;
}

export default MenuCloneProvider;
export { useMenuClone };