import WebsiteCreatorForm from './components/WebsiteCreatorForm'
import WebsiteCreatorProvider from "./provider";

const WebsiteCreator: React.FC = () => {
  return (
      <WebsiteCreatorProvider>
        <WebsiteCreatorForm />
      </WebsiteCreatorProvider>
  )
}

export default WebsiteCreator
