import {useUserProfile} from "../provider";
import {Form, FormControl, FormLabel, Row, Spinner, Button, InputGroup, Badge} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { fromJS } from "immutable";
import { FaCheck, FaRegUserCircle } from "react-icons/fa";

const SectionProfile = () => {
  const provider = useUserProfile();
  const [loading, setLoading] = useState<boolean>(false)
  const [profile, setProfile] = useState<any>(
    fromJS({
      phone: '',
      username: '',
      email: '',
      password: ''
    })
  )

  useEffect(() => {
    setProfile(
      profile.updateIn(['username'], () => provider.profile.get('username', ''))
      )
  }, [provider.profile.get('username')])

  if (provider.profile.size < 1 && provider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  const onNameChanged = (name: string) => {
    setProfile(profile.updateIn(['username'], () => name))
  }

  const onPasswordChanged = (pass: string) => {
    setProfile(profile.updateIn(['password'], () => pass))
  }

  const isActive = provider.profile.get('status') == 'active'

  const _onStatusChanged = async (e: any) => {
    if (isActive) {
      const rs = window.confirm('Bạn có chắc chắn muốn khóa tài khoản này?')
      if (!rs) return;
    }
    await provider.changeAccountStatus(!isActive)
  }

  const _onSaveNameClicked = async () => {
    if (profile.get('username').trim().length < 1) {
      window.alert('Tên không được để trống')
      return
    }
    setLoading(true)
    const rs = await provider.updateName(profile.get('username'))
    setLoading(false)
    if (rs) {
      window.alert('Cập nhật thành công')
    }
  }

  const _onSavePasswordClicked = async () => {
    if (profile.get('password').trim().length < 6) {
      window.alert('Mật khẩu không hợp lệ')
      return
    }
    setLoading(true)
    const rs = await provider.updatePassword(profile.get('password'))
    setLoading(false)
    setProfile(
      profile.updateIn(['password'], () => '')
    )
    if (rs) {
      window.alert('Cập nhật thành công')
    }
  }

  return (
    <Row className="mt-3">
      <div className={'col-12'}>
        <div className={'shadow-card'}>
          <Row>
            <div className={'col-12 col-xl-4 col-sm-4 text-center'}>
              <FaRegUserCircle
                color={'#dcdcdc'} 
                size={72}/>
              <div>
                <Badge bg={isActive ? 'success' : 'danger'}>
                  {isActive ? 'Hoạt động' : 'Khóa'}
                </Badge>
              </div>
              <InputGroup className="justify-content-center mt-3">
                <Form.Label>
                  Trạng thái
                </Form.Label>
                <Form.Check 
                  type="switch"
                  checked={isActive}
                  className="ms-2"
                  onChange={_onStatusChanged}
                />
              </InputGroup>
            </div>

            <div className={'col-12 col-xl-8 col-sm-8'}>
              <Row>
                <div className={'col-6'}>
                  <Form.Label>
                    Tên tài khoản
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      required={!profile.get('username')}
                      type="text"
                      value={profile.get('username', '')}
                      onChange={(el) => onNameChanged(el.target.value)}
                    />
                    {
                      provider.profile.get('username') != profile.get('username') &&
                      <Button variant="outline-success" onClick={_onSaveNameClicked}>
                        <FaCheck size={14}/>
                        {` `}
                        Lưu
                      </Button>
                    }
                  </InputGroup>
                </div>
                <div className={'col-6'}>
                  <Form.Label>
                    Mật khẩu
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="password"
                      placeholder="*****"
                      value={profile.get('password', '')}
                      onChange={(el) => onPasswordChanged(el.target.value)}
                    />
                    {
                      !!profile.get('password') &&
                      <Button variant="outline-success" onClick={_onSavePasswordClicked}>
                        <FaCheck size={14}/>
                        {` `}
                        Lưu
                      </Button>
                    }
                  </InputGroup>
                </div>
                <div className={'mt-3 col-6'}>
                  <Form.Group className="mb-3">
                    <FormLabel>
                      Số điện thoại
                    </FormLabel>
                    <FormControl
                      type="text"
                      value={provider.profile.get('phone', '')}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                <div className={'mt-3 col-6'}>
                  <Form.Group className="mb-3">
                    <FormLabel>
                      Email
                    </FormLabel>
                    <FormControl
                      type="text"
                      value={provider.profile.get('email', '')}
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              </Row>
            </div>
          </Row>
        </div>
      </div>
    </Row>
  )
}

export default SectionProfile;