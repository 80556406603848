import React from "react";
import Views from "./components/views";
import ShopeePlatformOrdersProvider from "./provider";

const ShopeePlatformOrders = () => {

  return (
    <ShopeePlatformOrdersProvider>
      <Views/>
    </ShopeePlatformOrdersProvider>
  );
};

export default ShopeePlatformOrders;

const PAGE_SIZE = 100;
export { PAGE_SIZE }