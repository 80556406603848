import {Dropdown} from "react-bootstrap";

interface SectionFilterHubItemType {
  hub: any,
  label?: string,
  onSelect: (hub: any) => void
}

const SectionFilterHubItem = (props: SectionFilterHubItemType) => {

  const _onClick = () => {
    props.onSelect(props.hub ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.hub?.get('name') ?? props.label}
    </Dropdown.Item>
  )
}

export default SectionFilterHubItem;