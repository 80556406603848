import React from "react";
import {Container, Row} from "react-bootstrap";
import {useWebsitePromotion} from "../provider";
import Nav from "../../../components/Navbars/Nav";
import {PromotionFilter} from "./filter";
import PageLoading from "../../../components/page_loading";
import {PromotionTable} from "./table";
import BackBtn from "../../../components/Button/back_btn";
import {useNavigate} from "react-router";


const Views: React.FC = () => {
  const provider = useWebsitePromotion()
  const navigate = useNavigate()

  const _onBackClicked = () => navigate(-1)

  return <main>
    <Nav title={'Mã giảm giá'}/>
    {provider.loading && provider.data?.size < 1 && <PageLoading/>}
    <Container>
      <BackBtn onBackClicked={_onBackClicked} label={'Danh sách Website'}/>
    </Container>
    <Container>
      <Row>
        <div className={'mt-3 col'}>
          <h6 className={'border-bottom pb-2'}>
            {provider.website?.getIn(['name'])}
          </h6>
        </div>
      </Row>
    </Container>
    <Container className={'pb-4'}>
      <PromotionFilter/>
      <PromotionTable/>
    </Container>
  </main>
}

export default Views