import React from "react";
import {Dropdown} from "react-bootstrap";
import {FaCloudDownloadAlt, FaEllipsisV} from "react-icons/fa";
import {useNavigate} from "react-router";
import {useModalState} from "../../../util/hook/useModalState";
import {BankConfigModal} from "../BankConfig";
import {useStores} from "../provider";
import {Colors} from "../../../util/color_utils";
import { BankModalType } from "../../../repository/model/enums/BankModalType";

interface StoreItemType {
  store: any,
  index: number,
  page: number
}

const StoreItem = (props: StoreItemType) => {
  const { onOpen, isOpen, onClose } = useModalState()
  const navigate = useNavigate();
  const storesProvider = useStores();

  const _onFoodAppConnectClicked = () => {
    navigate('/stores/foodapp/connect', {
      state: {
        store: props.store?.toJS(),
      }
    });
  }

  const _onEditClicked = () => {
    navigate(`/stores/${props.store?.get('_id')}/edit`);
  }

  const _onSourcesClicked = () => {
    navigate(`/stores/${props.store?.get('_id')}/sources`);
  }

  const _onBankInfoUpdated = (store: any) => {
    storesProvider.onBankInfoChanged(store)
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa cửa hàng này?')
    if (!rs) return
    storesProvider.deleteStore(props.store.get('_id'))
  }

  const _getCloudColor = (key: string) => {
    if (!props.store.getIn(['account', key, 'account'])) {
      return '#f44336'
    }
    if (!props.store.getIn(['account', key, 'autoPull'])) {
      return '#ffc107'
    }
    if (props.store.getIn(['account', key, 'autoPull'])) {
      return '#4caf50'
    }
    return '#cdcdcd'
  }

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        {props.store.getIn(['brand', 'name'])}
      </td>
      <td>
        {props.store.getIn(['name'])}
      </td>
      <td>
        <FaCloudDownloadAlt 
          color={_getCloudColor('shopee_food')} 
          width={14} 
          height={14}/>
      </td>
      <td>
        <FaCloudDownloadAlt 
          color={_getCloudColor('grab')} 
          width={14} 
          height={14}/>
      </td>
      <td>
        <FaCloudDownloadAlt 
          color={_getCloudColor('gofood')} 
          width={14} 
          height={14}/>
      </td>
      <td>
        <FaCloudDownloadAlt 
          color={_getCloudColor('befood')} 
          width={14} 
          height={14}/>
      </td>
      <td>
        <FaCloudDownloadAlt 
          color={_getCloudColor('baemin')} 
          width={14} 
          height={14}/>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onSourcesClicked}>
              Nguồn đơn
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={_onFoodAppConnectClicked}>
              Kết nối FoodApp
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={onOpen}>
              Thông tin Tài khoản ngân hàng
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <BankConfigModal
        data={props.store}
        type={BankModalType.store}
        isOpen={isOpen}
        onClose={onClose}
        onBankInfoUpdated={_onBankInfoUpdated}/>
    </tr>
  )
}

export default StoreItem;