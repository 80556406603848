import { createContext, useCallback, useContext, useState } from 'react'
import { Alert, Button, Col, Container, Modal, ModalBody, Row } from 'react-bootstrap'
import NormalModal from './normalModal'

export interface ModalOptions {
  type?: 'confirm' | 'normal'
  title?: string
  description?: React.ReactNode
  content?: React.ReactNode | null
  confirmationText?: string
  cancellationText?: string
  onCancel?(): void
  onConfirm?(): void,
  confirmBtn?: React.ReactNode | null,
  scrollable?: boolean | null
}

const DEFAULT_OPTIONS: ModalOptions = {
  type: 'confirm',
  title: 'Xác nhận xóa?',
  description: '',
  content: null
}

type ProviderProps = {
  children: React.ReactNode
  defaultOptions?: ModalOptions
}

type DialogContextValues = (options: ModalOptions) => Promise<unknown>

const ModalContext = createContext({} as DialogContextValues)

type ResolveReject =
  | [resolveFn: (value?: unknown) => void, rejectFn: (reason?: unknown) => void]
  | []

const ModalProvider: React.VFC<ProviderProps> = ({ children }) => {
  const [options, setOptions] = useState<ModalOptions>({
    ...DEFAULT_OPTIONS
  })
  const [resolveReject, setResolveReject] = useState<ResolveReject>([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback((options = {}) => {
    return new Promise((resolve, reject) => {
      setOptions({ ...DEFAULT_OPTIONS, ...options })
      setResolveReject([resolve, reject])
    })
  }, [])

  const modal = useCallback(
    (_options: ModalOptions) => {
      return confirm(_options)
    },
    [confirm]
  )

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    reject &&
      reject({
        reason: 'User reject confirm.'
      })
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve && resolve()
    handleClose()
  }, [resolve, handleClose])
  return (
    <>
      <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
      {options.type === 'normal' ? (
        <NormalModal
          open={resolveReject.length === 2}
          onCancel={handleClose}
          onConfirm={handleConfirm}
          options={options}
        />
      ) : (
        <ModalBase
          open={resolveReject.length === 2}
          onCancel={handleClose}
          onConfirm={handleConfirm}
          options={options}
        />
      )}
    </>
  )
}

export interface ModalBaseProps {
  open: boolean
  options: ModalOptions
  onCancel(): void
  onConfirm(): void
  size?: 'sm' | 'lg' | 'xl' | undefined
}

const ModalBase: React.VFC<ModalBaseProps> = ({ open, onCancel, options, onConfirm }) => {
  const { title } = options
  return (
    <Modal show={open} centered>
      <ModalBody>
        <Container>
          <Row style={{ justifyContent: 'center' }}>
            <Col style={{ textAlign: 'center' }}>
              <Alert variant="light">{title}</Alert>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>
              <Button variant="secondary" onClick={onCancel}>
                Hủy
              </Button>
            </Col>
            <Col>
              <Button type="submit" onClick={onConfirm}>
                Xóa
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  )
}

export const useModal = () => {
  const modal = useContext(ModalContext)
  return modal
}

export default ModalProvider
