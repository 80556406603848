import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {MenuOptionType} from "../../repository/model/menuItem";
import {useApp} from "../../components/AdminLayout/admin_provider";

interface MenuItemOptionsContextType {
  queries: any,
  data: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  onStoreChanged: (store?: any) => void,
  onSourceChanged: (source?: any) => void,
  deleteMenuItemOption: (itemId: any) => void,
  onCreateMenuItemOption: (option: MenuOptionType) => Promise<Boolean>,
  onEditMenuItemOption: (optionId: string, option: MenuOptionType) => Promise<Boolean>,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let MenuItemOptionsContext = React.createContext<MenuItemOptionsContextType>(null!);

const useMenuItemOptions = () => {
  return React.useContext(MenuItemOptionsContext);
};

function MenuItemOptionsProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const app = useApp();

  const _fetchMenuItems = async() => {
    try {
      const rs: any = await Api.menu.fetchMenuOptionList({
        brandFields: "_id name",
        limit: 20,
        page: queries.get('page'),
        brand: queries.getIn(['brand', '_id']),
        store: queries.getIn(['store', '_id']),
        source: queries.getIn(['source', '_id']),
        search: queries.get('search')
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading || !queries.getIn(['brand', '_id'])) return
    setLoading(true)

    const list: any = await _fetchMenuItems()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['store'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onStoreChanged = (store?: any) => {
    setQueries(
      queries.updateIn(['store'], () => store)
        .updateIn(['source'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onSourceChanged = (source?: any) => {
    setQueries(
      queries.updateIn(['source'], () => source)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onCreateMenuItemOption = async (option: MenuOptionType) => {
    try {
      const rs = await Api.menu.createMenuOption(option)
      window.alert('Thêm mới thành công!')
      _refresh()
      return true
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return false
    }
  }

  const deleteMenuItemOption = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const menuItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.menu.deleteMenuOption(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, menuItem))
      return null
    }
  }

  const onEditMenuItemOption = async (optionId: string, option: MenuOptionType) => {
    try {
      await Api.menu.editMenuOption(optionId, option)
      window.alert('Chỉnh sửa thành công!')
      _refresh()
      return true
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return false
    }
  }

  React.useEffect(() => {
    if (!app.brands) {
      app.fetchBrand();
      return;
    }
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, [app.brands]);

  React.useEffect(() => {
    if (queries.size < 2) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onSourceChanged,
    onBrandChanged,
    onStoreChanged,
    deleteMenuItemOption,
    onCreateMenuItemOption,
    onEditMenuItemOption,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <MenuItemOptionsContext.Provider value={value}>{children}</MenuItemOptionsContext.Provider>;
}

export default MenuItemOptionsProvider;
export { useMenuItemOptions };