import React, {useEffect, useState} from "react";
import {Dropdown} from 'react-bootstrap'
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useMenuItemOptions} from "../provider";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import OptionForm from "../../MenuItemOptionCreator/components/optionForm";
import {ChoiceType} from "../../../repository/model/menuItem";
import {useModalState} from "../../../util/hook/useModalState";

interface MenuItemOptionType {
  item: any,
  index: number,
  page: number
}

const MenuItemOption = (props: MenuItemOptionType) => {

  const menuItemOptionsProvider = useMenuItemOptions()
  const { isOpen: isOpenEditor, onClose: onCloseEditor, onOpen: onOpenEditor } = useModalState()
  const [newOption, setNewOption] = useState<any>(fromJS({}))

  const storeName = () => {
    return props.item.getIn(['store', 'name']) || props.item.getIn(['source', 'store', 'name'])
  }

  const sourceName = () => {
    return props.item.getIn(['source', 'name'])
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Món tùy chọn này?')
    if (!rs) return
    menuItemOptionsProvider.deleteMenuItemOption(props.item.get('_id'))
  }

  const handleSubmitUpdater = async () => {
    if (!newOption.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newOption.get('name', '')) {
      window.alert('Tùy chọn là bắt buộc!')
      return
    }
    if (newOption.get('choices', []).filter((c: any) => !c.get('name')).size) {
      window.alert('Lựa chọn là bắt buộc!')
      return
    }
    let optionReq = { ...newOption.toJS() }
    optionReq.choices = optionReq.choices.map((c: ChoiceType) => ({
      ...c,
      ...(!c.price && { price: 0 })
    }))
    const rs = await menuItemOptionsProvider.onEditMenuItemOption(optionReq._id, optionReq)
    if (rs) {
      onCloseEditor()
    }
  }

  useEffect(() => {
    if (!isOpenEditor) {
      setNewOption(fromJS({
        ...props.item?.toJS(),
        brandId: props.item.getIn(['source', 'store', 'brand', '_id']) ?? props.item.getIn(['brand', '_id'], ''),
        storeId: props.item.getIn(['source', 'store', '_id']),
        sourceId: props.item.getIn(['source', '_id'])
      }))
    }
  }, [isOpenEditor, props.item])

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <strong>
          {props.item.get('name')}
        </strong>
      </td>
      <td>
        {storeName()}
      </td>
      <td>
        {sourceName()}
      </td>
      <td>
        {props.item.get('maxCount', 0)}
      </td>
      <td>
        {props.item.get('choices', fromJS([])).size}
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={onOpenEditor}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <NormalModal
        size="lg"
        open={isOpenEditor}
        options={{
          title: 'Chỉnh sửa tùy chọn',
          content: <OptionForm option={newOption} setOption={setNewOption} />,
          confirmationText: 'Cập nhật'
        }}
        onCancel={onCloseEditor}
        onConfirm={handleSubmitUpdater}
      />
    </tr>
  )
}

export default MenuItemOption;