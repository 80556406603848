import React from "react";
import {Container, Row, Table} from "react-bootstrap";
import Nav from "../../../components/Navbars/Nav";
import PageLoading from "../../../components/page_loading";
import {useWebsitePromotionCreator} from "../../WebsitePromotionCreator/provider";
import BackBtn from "../../../components/Button/back_btn";
import {useNavigate} from "react-router";
import AddRow from "./add_row";
import TargetItem from "./target_item";

const Views: React.FC = () => {
  const provider = useWebsitePromotionCreator()
  const navigate = useNavigate()

  const handleCreate = () => {

  }

  const _onBackClicked = () => navigate(-1)

  return (
    <main>
      <Nav title={'Đối tượng áp dụng'}/>
      {provider.loading && provider.promotion?.size < 1 && <PageLoading/>}
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Mã giảm giá'}/>
      </Container>
      <Container>
        <Row>
          <div className={'mt-3 col'}>
            <h6 className={'border-bottom pb-2'}>
              <span className={'opacity-50'}>{provider.website?.getIn(['name'])} | </span><strong>{provider.promotion?.get('code')}</strong>
            </h6>
          </div>
        </Row>
      </Container>
      <Container className={'pb-4'}>
        <div className={'mt-3 shadow-card'}>
          <Table striped hover responsive bordered className={'mt-2'}>
            <thead>
            <tr>
              <th>STT</th>
              <th>Thương hiệu</th>
              <th>Cửa hàng</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {
              provider.appliedTargets?.map((target: any, index: number) => {
                return (
                  <TargetItem
                    key={`target_${target.getIn(['brand', '_id'])}`}
                    index={index}
                    target={target}/>
                )
              })
            }
              <AddRow />
            </tbody>
          </Table>
        </div>
      </Container>
    </main>
  )
}

export default Views