import * as React from 'react';
import {User} from "../../repository/model/User";

interface AuthContextType {
	user?: User;
	signin: (user: string, password: string) => void;
	signout: () => void;
	updateToken: (token: string) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

const useAuth = () => {
	return React.useContext(AuthContext);
};


export { useAuth, AuthContext };