import PageLoading from "../../../components/page_loading";
import {Container, Dropdown, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import {useAutoCrawlOrderJobs} from "../provider";
import SectionJobs from "./section_jobs";
import {FaChevronDown} from "react-icons/fa";
import SectionFilterBrandItem from "../../../components/Filters/section_filter_brand_item";
import SectionFilterStringItem from "../../../components/Filters/section_filter_string_item";
import { foodApps, sourceInfo } from "../../../util/common_utils";


const Views = () => {

  const provider = useAutoCrawlOrderJobs();
  const app = useApp();

  const _onBrandChanged = (brand?: any) => {
    provider.onBrandChanged(brand);
  }

  const _onPlatformChanged = (e: any) => {
    provider.onPlatformChanged(e);
  }

  return (
    <main>
      <Nav title={'Tự động lấy đơn'} />
      {
        provider.loading && provider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <Row>
          <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Thương hiệu
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {provider.queries.getIn(['brand', 'name']) ?? 'Tất cả thương hiệu'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterBrandItem
                    label={'Tất cả thương hiệu'}
                    brand={null}
                    onSelect={_onBrandChanged}/>
                  {
                    app.brands?.map((b: any) => {
                      return (
                        <SectionFilterBrandItem
                          key={`filter_brand_${b.get('_id')}`}
                          brand={b}
                          onSelect={_onBrandChanged}/>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Platform
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {provider.queries.get('platform') ? sourceInfo(provider.queries.get('platform'), 'Tất cả').label : 'Tất cả'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterStringItem
                    label={'Tất cả'}
                    value={null}
                    onSelect={_onPlatformChanged}/>

                  <SectionFilterStringItem
                    label={'Shopee Food'}
                    value={foodApps.shopee}
                    onSelect={_onPlatformChanged}/>

                  <SectionFilterStringItem
                    label={'Grab'}
                    value={foodApps.grab}
                    onSelect={_onPlatformChanged}/>

                  <SectionFilterStringItem
                    label={'GoFood'}
                    value={foodApps.gofood}
                    onSelect={_onPlatformChanged}/>

                  <SectionFilterStringItem
                    label={'BeFood'}
                    value={foodApps.befood}
                    onSelect={_onPlatformChanged}/>

                  <SectionFilterStringItem
                    label={'Baemin'}
                    value={foodApps.baemin}
                    onSelect={_onPlatformChanged}/>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Row>

        <SectionJobs/>
      </Container>
    </main>
  );
}

export default Views;