import React from 'react';
import {FaBars} from 'react-icons/fa';
import {Button, Container, Navbar, Row} from 'react-bootstrap';
import {useAuth} from '../../components/Auth/auth';
import {SidebarContext} from "../../components/AdminLayout";
import Nav from "../../components/Navbars/Nav";

const Settings = () => {

  const sidebarContext = React.useContext<any>(SidebarContext);
  const auth = useAuth();

  const _onLogoutClicked = async () => {
    const rs = await window.confirm('Bạn có chắc chắc muốn Đăng xuất?')
    if (!rs) return;

    auth.signout();
    window.location.href = '/'
  }

  return (
    <main>
      <Nav title={'Cài đặt'}/>
      <Container>
        <Row className="block">
          <div className="d-grid gap-2">
            <button className="btn btn-outline-dark" type="button" onClick={_onLogoutClicked}>
              Đăng xuất
            </button>
          </div>
        </Row>
      </Container>
      <footer>
        <small>
          © 2020 Still
        </small>
      </footer>
    </main>
  );
};

export default Settings;