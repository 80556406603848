import { Button, Container, Form, FormControl, FormLabel, FormSelect } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import BackBtn from '../../../components/Button/back_btn'
import { useNavigate, useParams } from 'react-router'
import Nav from '../../../components/Navbars/Nav'
import { Api } from '../../../repository/api'
import { useBannerCreator } from '../provider'
import { fromJS } from 'immutable'
import { TiDelete } from 'react-icons/ti'
import { deleteInputValue } from '../../../util/myUtil'
import {bannerConfigs} from "../../../util/banner_utils";

const FormBanner = () => {
  const bannerCreatorProvider = useBannerCreator()
  const navigate = useNavigate()
  const {websiteId, bannerId} = useParams()
  const [validated, setValidated] = useState(false)
  const [banner, setBanner] = useState<any>(
    fromJS({
      name: undefined,
      mobile_image_url: undefined,
      desktop_image_url: undefined,
      type: 'cover',
      link: undefined,
      brandId: websiteId
    })
  )

  useEffect(() => {
    if (bannerId && bannerCreatorProvider.banner) {
      setBanner(bannerCreatorProvider.banner)
    }
  }, [bannerId, bannerCreatorProvider.banner])

  const _onBackClicked = () => {
    navigate(-1)
  }

  const onNameChanged = (name: string) => {
    setBanner(banner.updateIn(['name'], () => name))
  }

  const onTypeChanged = (type: string) => {
    setBanner(banner.updateIn(['type'], () => type))
  }

  const onLinkChanged = (name: string) => {
    setBanner(banner.updateIn(['link'], () => name))
  }

  const onDesktopChanged = async (desktop: File) => {
    setBanner(banner.updateIn(['desktop_image_url'], () => desktop))
  }

  const onMobileChanged = async (mobile: File) => {
    setBanner(banner.updateIn(['mobile_image_url'], () => mobile))
  }

  const handleSubmit = async (event: any) => {
    try {
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setValidated(true)
        return
      }

      const data = { ...banner.toJS() }

      if (typeof banner.get('desktop_image_url') == 'object') {
        const resDesktop = await Api.uploadImage(banner.get('desktop_image_url'))
        data.desktop_image_url = resDesktop.data.data.url
      }

      if (typeof banner.get('mobile_image_url') == 'object') {
        const resMobile = await Api.uploadImage(banner.get('mobile_image_url'))
        data.mobile_image_url = resMobile.data.data.url
      }

      const res = bannerId ? await Api.banner.editBanner(bannerId, data) : await Api.banner.createBanner(data)

      window.alert(
        (bannerId ? 'Cập nhật ' : 'Tạo ') + 'banner ' + res.data.data.name + ' thành công!'
      )
      navigate(-1)
    } catch (e) {
      console.log(e)
      window.alert('Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  return (
    <main>
      <Nav title={'Tạo Banner'} />
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách'} />
      </Container>
      <Container className="mb-4">
        <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <FormLabel>
                Tên <span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormControl
                type="text"
                value={banner.get('name')}
                onChange={(el) => onNameChanged(el.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>Đường dẫn</FormLabel>
              <FormControl
                type="text"
                value={banner.get('link')}
                onChange={(el) => onLinkChanged(el.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3 col-md-3 col-sm-12">
              <FormLabel>Loại hình banner</FormLabel>
              <FormSelect
                value={banner.get('type')}
                onChange={(el) => onTypeChanged(el.target.value)}
              >
                <option value={''}>
                  Chọn loại hình banner
                </option>
                {
                  bannerConfigs.map((banner: any) => {
                    return (
                      <option value={banner.id} key={banner.id}>
                        {banner.name} ({banner.desktopSize} | {banner.mobileSize})
                      </option>
                    )
                  })
                }
              </FormSelect>
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>
                Trên desktop {` `}
                <span className={'opacity-50'}>({bannerConfigs.find((b: any) => b.id === banner.get('type'))?.desktopSize} tối đa 1MB)</span>
              </FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadDesktop"
                onChange={(el: any) => onDesktopChanged(el.target?.files[0])}
              />
              {banner.get('desktop_image_url') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof banner.get('desktop_image_url') == 'string'
                        ? banner.get('desktop_image_url')
                        : URL.createObjectURL(banner.get('desktop_image_url'))
                    }
                    alt={banner.get('desktop_image_url').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof banner.get('desktop_image_url') == 'string'
                          ? banner.get('desktop_image_url')
                          : URL.createObjectURL(banner.get('desktop_image_url'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setBanner(banner.updateIn(['desktop_image_url'], () => undefined))
                      deleteInputValue('uploadDesktop')
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>
                Trên mobile {` `}
                <span className={'opacity-50'}>({bannerConfigs.find((b: any) => b.id === banner.get('type'))?.mobileSize} tối đa 1MB)</span>
              </FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadMobile"
                onChange={(el: any) => onMobileChanged(el.target.files[0])}
              />
              {banner.get('mobile_image_url') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof banner.get('mobile_image_url') == 'string'
                        ? banner.get('mobile_image_url')
                        : URL.createObjectURL(banner.get('mobile_image_url'))
                    }
                    alt={banner.get('mobile_image_url').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof banner.get('mobile_image_url') == 'string'
                          ? banner.get('mobile_image_url')
                          : URL.createObjectURL(banner.get('mobile_image_url'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setBanner(banner.updateIn(['mobile_image_url'], () => undefined))
                      deleteInputValue('uploadMobile')
                    }}
                  />
                </div>
              )}
            </Form.Group>
            <div className="d-flex justify-content-start">
              <Button
                variant="primary"
                type="submit"
                className={'mt-4 d-grid gap-2 btn btn-primary'}
              >
                {bannerId ? 'Cập nhật' : 'Tạo Banner'}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </main>
  )
}

export default FormBanner
