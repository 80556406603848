import {useUserProfile} from "../provider";
import {Form, FormControl, FormLabel, Row, Spinner, Button, InputGroup, Badge, Table, Dropdown} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { fromJS } from "immutable";
import { FaCheck, FaDoorClosed, FaEllipsisV, FaRecycle, FaRegUserCircle, FaRegWindowClose, FaRemoveFormat, FaTimes, FaWindowClose } from "react-icons/fa";
import { permissionClass } from "../../../util/common_utils";
import { Colors } from "../../../util/color_utils";
import LabelIconBtn from "../../../components/Button/label_icon_btn";

interface SectionPermissionsOutletItemType {
  store: any,
  index: number,
}

const SectionPermissionsOutletItem = (props: SectionPermissionsOutletItemType) => {
  const provider = useUserProfile();
  const [loading, setLoading] = useState<boolean>(false)

  const _onDeleteClicked = async () => {
    const cf = window.confirm(`Bạn có chắc chắn muốn xóa truy cập tài khoản cho ${props.store.get('name')}?`)
    if (!cf) return;
    setLoading(true);
    const rs = await provider.removeOutletPermissions(props.store);
    setLoading(false);
  }

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>
        {
          props.store.get('name')
        }
        <p className="mb-0">
          <small className="text-dark text-opacity-25">
          {
            props.store.getIn(['brand', 'name'])
          }
          </small>
        </p>
      </td>
      <td>
        {props.store.getIn(['phone'])}
      </td>
      <td>
        <Badge bg={permissionClass(props.store.getIn(['permission', 'label']))}>
          {props.store.getIn(['permission', 'label'])}
        </Badge>
      </td>
      <td>
        <button 
          type="button" 
          className="btn btn-outline-light btn-sm"
          onClick={_onDeleteClicked}>
          <FaTimes color={Colors.Error}/>
        </button>
      </td>
    </tr>
  )
}

export default SectionPermissionsOutletItem;