import {fromJS} from 'immutable'
import {useEffect, useState} from 'react'
import {Dropdown, Form, FormControl, Row, Stack} from 'react-bootstrap'
import {FaChevronDown} from 'react-icons/fa'
import {dishplaceholder} from '../../../util/myUtil'
import DropdownItem from './dropdownItem'
import {STATUS} from "../../../util/constant";
import {useWebsiteHomePageConfig} from "../provider";

interface CustomPickProps {
  menuItems: any
  onMenuItemChanged: (menuItem: any) => void
}

interface FilterPickProps {
  onMenuItemChanged: (menuItem: any) => void
}

type SelectPickProps = CustomPickProps &
  FilterPickProps & {
  onPickTypeChanged: (key: string, value: string) => void
  initType?: string
}

const SelectPick: React.FC<SelectPickProps> = (props) => {
  const [pickType, setPickType] = useState<string>()

  useEffect(() => {
    if (props.initType) {
      setPickType(props.initType)
    }
  }, [props.initType])

  const handleTypePickChanged = (value: string) => {
    setPickType(value)
  }

  const renderSelectPick = () => {
    switch (pickType) {
      case 'CUSTOM':
        return <CustomPick {...props} />
      case 'TAG':
        return <TagPick {...props} />
      case 'FILTER':
        return <FilterPick {...props} />
    }
  }

  useEffect(() => {
    if (pickType) {
      props.onPickTypeChanged('pickType', pickType)
    }
  }, [pickType])

  return (
    <Row>
      <div className="col-4 col-sm-4 col-lg-4">
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Chọn sản phẩm từ?</Form.Label>
          <Form.Select value={pickType} onChange={(el) => handleTypePickChanged(el.target.value)}>
            <option>Chọn...</option>
            <option value="CUSTOM">Tự chọn</option>
            <option value="TAG" disabled>
              Tag
            </option>
            <option value="FILTER" disabled>
              Filter
            </option>
          </Form.Select>
        </Form.Group>
      </div>
      {renderSelectPick()}
    </Row>
  )
}

const CustomPick: React.FC<CustomPickProps> = (props) => {
  const {menuItems, onMenuItemChanged} = props
  const {initItem, queries, onItemBrandChanged, onItemNameChanged, setQueries, website} = useWebsiteHomePageConfig()

  const menuOptions = initItem?.filter((item: any) => {
    let check = false
    if (menuItems) {
      check = menuItems.includes(item)
    }
    return (
      (!queries.getIn(['itemSearch', 'name'], '') ||
        item
          .get('name', '')
          .toLowerCase()
          .includes(queries.getIn(['itemSearch', 'name'], '').toLowerCase())) &&
      !check
    )
  })

  const handleAddMenuItem = (menuItem: any) => {
    onMenuItemChanged(menuItems.push(menuItem))
  }

  const _onBrandFilterSelected = (brandId?: string) => {
    const brand = website.get('subBrands')?.find((b: any) => b.get('_id') === brandId)
    if (!brand) return
    onItemBrandChanged(brand)
  }

  return (
    <>
      <div className={'col-4 col-md-4'}>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Thương hiệu</Form.Label>
          <Form.Select value={queries.getIn(['itemSearch', 'brand', '_id'])} onChange={(el) => _onBrandFilterSelected(el.target.value)}>
            <option>Chọn...</option>

            {website.get('subBrands')?.map((brand: any, i: number) => {
              return (
                <option key={`brand_filter_${brand.get('_id')}`} value={brand.get('_id')}>{brand.get('name')}</option>
              )
            })}
          </Form.Select>
        </Form.Group>
      </div>

      {!!queries.getIn(['itemSearch', 'brand', '_id'], '') && (
        <div className="col-4 col-md-4">
          <Form.Label className={'mb-0'}>Chọn sản phẩm</Form.Label>
          <Dropdown>
            <Dropdown.Toggle
              className={'filter w-100 text-start p-0'}
              variant={'light'}
              style={{whiteSpace: 'normal'}}
            >
              <FormControl
                className="my-2 w-auto"
                placeholder="Tìm sản phẩm..."
                onChange={(e: any) => onItemNameChanged(e.target.value)}
                value={queries.getIn(['itemSearch', 'name'], '')}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              {!menuOptions.size ? (
                <Dropdown.Item>{'Không có dữ liệu!'}</Dropdown.Item>
              ) : (
                menuOptions.map((item: any, index: number) => (
                  <DropdownItem
                    key={index}
                    index={index}
                    disabled={item.get('status', '') === STATUS.INACTIVE || item.get('stores', fromJS([])).size < 1}
                    text={item.get('name')}
                    imageName={item.get('name')}
                    imageUrl={item.get('image', '') || dishplaceholder}
                    thresholdIndex={menuOptions.size - 1}
                    handlePageChanged={() =>
                      setQueries(queries.updateIn(['itemSearch', 'page'], (e: number) => ++e))
                    }
                    handleClick={() => handleAddMenuItem(item)}
                  />
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  )
}

const TagPick: React.FC = (props) => {
  return (
    <div className="col-12 col-sm-6 col-lg-4">
      <Form.Select>
        <option value="HOT">Hot</option>
        <option value="BEST_SELLER">Best Seller</option>
      </Form.Select>
    </div>
  )
}

const FilterPick: React.FC<FilterPickProps> = (props) => {
  const {website, loadMenu} = useWebsiteHomePageConfig()
  const {onMenuItemChanged} = props
  const onOptionChanged = async (option: string) => {
    const type = option.split('/')
    if (type[0] === 'store') {
      const menu = loadMenu(type[1])
      onMenuItemChanged(fromJS(menu))
    } else {
      let stores = website
        .get('subBrands')
        .find((brand: any) => brand.get('_id') === type[1])
        .get('stores')
        .toJS()

      const arrMenu: any[] = []
      for (let ele of stores) {
        let res = await loadMenu(ele.source)
        arrMenu.push(res)
      }
      onMenuItemChanged(fromJS(arrMenu.flat(2).slice(0, 20)))
    }
  }

  const storeOptions = website
    .get('subBrands')
    .reduce((prev: any, cur: any) => prev.concat(cur.get('stores')), fromJS([]))

  return (
    <div className="col-12 col-sm-6 col-lg-4">
      <Form.Select onChange={(el) => onOptionChanged(el.target.value)}>
        <option>Chọn thương hiệu & cửa hàng</option>
        <optgroup id="brand" label="Thương hiệu">
          {!!website.get('subBrands') &&
          website.get('subBrands').map((brand: any, index: number) => (
              <option key={index} value={`brand/${brand.get('_id')}`}>
                {brand.get('name')}
              </option>
            ))}
        </optgroup>
        <optgroup id="store" label="Cửa hàng">
          {!!storeOptions &&
            storeOptions.map((store: any, index: number) => (
              <option key={index} value={`store/${store.get('source')}`}>
                {store.get('name')}
              </option>
            ))}
        </optgroup>
      </Form.Select>
    </div>
  )
}

export default SelectPick
