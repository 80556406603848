import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import NormalModal from "../../../components/Modal/normalModal";
import { useModalState } from "../../../util/hook/useModalState";
import { useUserProfile } from "../provider";
import { useUserOutletPermission } from "../user_outlet_permission_provider";
import ModalOutletSelection from "./modal_outlet_selection";
import SectionPermissionsOutletItem from "./section_permissions_outlet_item";

const SectionPermissionsOutlet = () => {
  const { isOpen: isOpenSelection, onOpen: onSelectionOpen, onClose: onSelectionClose } = useModalState()
  const provider = useUserProfile()
  const outletProvider = useUserOutletPermission();

  const _onCreateClicked = () => {
    onSelectionOpen();
  }

  React.useEffect(() => {
    outletProvider.checkSelected();
  }, [isOpenSelection])

  return (
    <div className={'mt-3 col-12 col-xl-6 col-sm-6'}>
      <div className={'shadow-card position-relative'}>
        <div className={'d-flex justify-content-between'}>
          <div>
            <h5>CỬA HÀNG/OUTLET</h5>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm'}
              icon={<FaPlus size={12} />}
              onClick={_onCreateClicked}
            />
          </div>
        </div>
        <Table striped hover responsive>
          <thead>
          <tr>
            <th>STT</th>
            <th>Tên</th>
            <th>SĐT</th>
            <th>Quyền</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
            {
              provider.outletPermissions?.map((b: any, index: number) => {
                return (
                  <SectionPermissionsOutletItem
                    key={`store_${index}_${b.get('_id')}`}
                    store={b}
                    index={index}/>
                )
              })
            }
          </tbody>
        </Table>
      </div>
      <NormalModal
        open={isOpenSelection}
        size={'xl'}
        options={{
          title: 'Thêm Outlet',
          scrollable: false,
          content: (
            <ModalOutletSelection onClose={onSelectionClose}/>
          ),
        }}
        onCancel={outletProvider.loading ? ()=> {} : onSelectionClose}
        onConfirm={() => {}}
      />
    </div>
  )
}

export default SectionPermissionsOutlet;