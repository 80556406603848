import PageLoading from "../../../components/page_loading";
import {Container} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaPlus} from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import BackBtn from "../../../components/Button/back_btn";
import {useMenuItemSources} from "../provider";
import SectionMenuItemSources from "./section_menu_item_sources";
import MenuItemSourceCreator from "../../MenuItemSourceCreator";

const Views = () => {

  const useMenuItemSourcesProvider = useMenuItemSources();
  const navigate = useNavigate();
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newSource, setNewSource] = useState<any>(fromJS({}))

  const _onBackClicked = () => navigate(-1)

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewSource(fromJS({}))
  }

  const handleSubmitNewUnit = () => {
    if (!newSource.get('storeId', '')) {
      window.alert('Cửa hàng là bắt buộc!')
      return
    }
    if (!newSource.get('name', '')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    useMenuItemSourcesProvider.onCreateMenuItemSource(newSource.toJS())
  }

  React.useEffect(() => {
    if (isCreatorOpen) {
      setNewSource(fromJS({
        brandId: useMenuItemSourcesProvider.storeDetail.getIn(['brand', '_id']),
        storeId: useMenuItemSourcesProvider.storeDetail.getIn(['_id'])
      }))
    }
  }, [isCreatorOpen])

  return (
    <main>
      <Nav title={'Nguồn đơn'} />
      {
        useMenuItemSourcesProvider.loading && useMenuItemSourcesProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <BackBtn onBackClicked={_onBackClicked} label={'Cửa hàng'}/>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm nguồn đơn mới'}
              icon={<FaPlus size={12} />}
              onClick={onCreatorOpen}
            />
          </div>
        </div>
        <SectionMenuItemSources/>
      </Container>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Thêm nguồn đơn',
          content: <MenuItemSourceCreator source={newSource} setSource={setNewSource} disableTargetSelector={true}/>,
          confirmationText: 'Thêm'
        }}
        onCancel={onCreatorClose}
        onConfirm={handleSubmitNewUnit}
      />
    </main>
  );
}

export default Views;