import View from "./components/view";
import { TablesProvider } from "./provider";

const Tables = () => {
    return (
        <TablesProvider>
            <View />
        </TablesProvider>
    );
}

export default Tables