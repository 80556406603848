import React, {ChangeEvent, useState} from "react";
import {fromJS} from "immutable";
import { useUserOutletPermission } from "../user_outlet_permission_provider";
import { Form } from "react-bootstrap";

interface ModalOutletSelectionItemType {
  outlet: any,
  index: number
}

const ModalOutletSelectionItem = (props: ModalOutletSelectionItemType) => {

  const provider = useUserOutletPermission();

  const _onSelectChanged = () => {
    provider.onSelectItem(props.outlet)
  }

  return (
    <tr className={!!props.outlet.get('activated', false) ? 'opacity-25' : ''}>
      <td>{(provider.queries.get('page', 1) - 1) * 20 + props.index + 1}</td>
      <td>
        {
          props.outlet.get('name')
        }
        <p className="mb-0">
          <small className="text-dark text-opacity-25">
          {
            props.outlet.getIn(['brand', 'name'])
          }
          </small>
        </p>
      </td>
      <td>
        {
          !props.outlet.get('activated', false) &&
          <Form.Check 
            checked={!!props.outlet.get('selected', false)}
            disabled={!!props.outlet.get('activated', false) || provider.loading}
            type={'checkbox'}
            onChange={_onSelectChanged}
            />
        }
      </td>
    </tr>
  )
}

export default ModalOutletSelectionItem;