import PageLoading from "../../../components/page_loading";
import {Button, Container, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaPlus} from "react-icons/fa";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate, useParams} from "react-router";
import {useWebsiteMenuItems} from "../provider";
import SectionMenuItems from "./section_menu_items";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import BackBtn from "../../../components/Button/back_btn";
import {fromJS} from "immutable";


const Views = () => {

  const menuItemsProvider = useWebsiteMenuItems();
  const navigate = useNavigate();
  const {websiteId} = useParams()

  const _onBrandChanged = (brand?: any) => {
    menuItemsProvider.onBrandChanged(brand);
  }

  const _onCreateClicked = () => {
    navigate(`/websites/${websiteId}/menu/create`)
  }

  const _onMenuItemOptionsClicked = async () => {
    navigate(`/websites/${websiteId}/menu/options`)
  }

  const _onMenuItemCategoriesClicked  = () => {
    navigate(`/websites/${websiteId}/menu/categories`)
  }

  const _onBackClicked = () => navigate(-1)

  return (
    <main>
      <Nav title={'Thực đơn Website'} />
      {
        menuItemsProvider.loading && menuItemsProvider.data.size < 1 && <PageLoading/>
      }
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách Website'}/>
      </Container>
      <Container>
        <Row>
          <div className={'mt-3 col'}>
            <h6 className={'border-bottom pb-2'}>
              {menuItemsProvider.website?.getIn(['name'])}
            </h6>
          </div>
        </Row>
      </Container>
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <Button variant="outline-primary" size="sm" onClick={_onMenuItemOptionsClicked}>
              Món tùy chọn
            </Button>
            <Button variant="outline-dark" size="sm" className={'mx-2'} onClick={_onMenuItemCategoriesClicked}>
              Danh mục món
            </Button>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm món mới'}
              icon={<FaPlus size={12} />}
              onClick={_onCreateClicked}
            />
          </div>
        </div>
        <Row>
          <SectionBrandFilter
            brands={menuItemsProvider.website?.get('subBrands') ?? fromJS([])}
            brandSelected={menuItemsProvider.queries.getIn(['brand'])}
            onBrandSelected={_onBrandChanged}/>
        </Row>
        <SectionMenuItems/>
      </Container>
    </main>
  );
}

export default Views;