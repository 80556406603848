import React from "react";
import {Card, Dropdown} from 'react-bootstrap'
import {dishplaceholder} from "../../../util/myUtil";
import {numberFormat} from "../../../config";
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useWebsiteMenuItems} from "../provider";
import {useNavigate, useParams} from "react-router";
import {fromJS} from "immutable";
import {Link} from "react-router-dom";
import {useModalState} from "../../../util/hook/useModalState";
import NormalModal from "../../../components/Modal/normalModal";
import StoreModalPicker from "../../../components/Modal/store_modal_picker";
import {Api} from "../../../repository/api";

interface MenuItemType {
  item: any,
  index: number,
  page: number,
}

const MenuItem = (props: MenuItemType) => {

  const menuItemsProvider = useWebsiteMenuItems()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const { isOpen: isOpenStores, onClose: onCloseStores, onOpen: onOpenStores } = useModalState()
  const [stores, setStores] = React.useState<any>(fromJS([]))
  const [selectedList, setSelectedList] = React.useState<any>(fromJS([]))
  const {websiteId} = useParams()

  const _fetchStores = async () => {
    if (!props.item.getIn(['brand', '_id'])) return
    try {
      const res = await Api.store.fetchStores(1, undefined, props.item.getIn(['brand', '_id']), 100)
      setStores(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const sourceName = () => {
    return props.item.getIn(['source', 'name'])
  }

  const _onEditClicked = () => {
    navigate(`/websites/${websiteId}/menu/${props.item.get('_id')}/edit`)
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Món này?')
    if (!rs) return
    menuItemsProvider.deleteMenuItem(props.item.get('_id'))
  }

  const _onUpdateStatusChanged = async () => {
    setLoading(true)
    await menuItemsProvider.updateStatusItem(props.item)
    setLoading(false)
  }

  const _onStoreChanged = (item: any) => {
    const index = stores.findIndex((s: any) => s.get('_id') === item.get('_id'))
    if (index < 0) return

    setSelectedList(
      selectedList.updateIn([index, 'selected'], (v: any) => !v)
    )
  }

  const _handleSubmitStores = async () => {
    setLoading(true)
    try {
      const res = await Api.menu.updateStores(
        props.item.get('_id'),
        selectedList.filter((s: any) => !!s.get('selected'))
          .map((s: any) => s.get('_id')).toJS()
      )
      menuItemsProvider.onItemStoresChanged(props.item, fromJS(res.data.data.stores))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    setLoading(false)
    onCloseStores()
  }

  React.useEffect(() => {
    if (isOpenStores) {
      if (!stores || stores.size < 1) {
        _fetchStores()
        return
      }
      const storeList = props.item.get('stores', fromJS([]))
      setSelectedList(
        stores
          .filter((s: any) => s.get('status') !== 'inactive' && s.get('status') !== 'deleted')
          .map((s: any) => {
          const selected = storeList.find((sl: any) => {
            return sl.get('_id') === s.get('_id')
          })
          return s.updateIn(['selected'], () => selected)
        })
      )
    }
  }, [isOpenStores, stores])

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td className={'text-wrap'}>
        <Card.Img
          variant="top"
          src={props.item.get('image') || dishplaceholder}
          className="img-fluid"
          style={{
            maxHeight: 100,
            minHeight: 100,
            width: 'auto',
            objectFit: 'contain'
          }}
        />
      </td>
      <td>
        {props.item.get('name')}
      </td>
      <td>
        <div className={'opacity-50 fw-lighter'}>
          {props.item.getIn(['brand', 'name'])}
        </div>
        <strong>
          <Link className={props.item.get('stores', fromJS([])).size > 0 ? 'text-success'  : 'text-danger'}
                  onClick={onOpenStores} to={''}>
            {props.item.get('stores', fromJS([])).size} cửa hàng
          </Link>
        </strong>
      </td>
      <td>
        {sourceName()}
      </td>
      <td>
        {numberFormat.format(props.item.get('price'))}
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            disabled={loading}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={_onUpdateStatusChanged}
            checked={props.item.get('status') === 'active'}/>
        </div>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked} disabled={loading}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <NormalModal
        open={isOpenStores}
        size={'xl'}
        options={{
          title: 'Điểm bán',
          content: (
            <StoreModalPicker
              storeList={selectedList}
              onChanged={_onStoreChanged}/>
          ),
          confirmationText: 'Lưu'
        }}
        onCancel={onCloseStores}
        onConfirm={_handleSubmitStores}
      />
    </tr>
  )
}

export default MenuItem;