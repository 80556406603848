import { Container, Pagination, Table } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import LabelIconBtn from '../../../components/Button/label_icon_btn'
import Nav from '../../../components/Navbars/Nav'
import PageLoading from '../../../components/page_loading'
import { useHubs } from '../provider'
import HubFilter from './hubFillter'
import HubItem from './hubItem'

const HubList: React.VFC = () => {
  const hubProvider = useHubs()
  const navigate = useNavigate()

  const _onCreateClicked = () => {
    navigate('/hubs/create')
  }

  return (
    <main>
      <Nav title={'Điểm bán'} />
      {hubProvider.loading && hubProvider.data.size < 1 && <PageLoading />}
      <Container className={'pb-4'}>
        <HubFilter />
        <div className={'mt-3 shadow-card'}>
          <div className={'d-flex justify-content-between'}>
            <Pagination size={'sm'}>
              <Pagination.Item
                disabled={hubProvider.queries.get('page') <= 1}
                onClick={hubProvider.onPreviousPage}
              >
                Trang trước
              </Pagination.Item>
              {hubProvider.queries.get('page') > 1 && (
                <Pagination.Item onClick={hubProvider.onFirstPage}>1</Pagination.Item>
              )}
              <Pagination.Ellipsis disabled />
              <Pagination.Item active>{hubProvider.queries.get('page')}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              <Pagination.Item
                disabled={hubProvider?.meta?.totalPage <= hubProvider.queries.get('page')}
                onClick={hubProvider.onNextPage}
              >
                Trang kế
              </Pagination.Item>
            </Pagination>
            <div>
              <LabelIconBtn
                label={'Thêm mới'}
                icon={<FaPlus size={12} />}
                onClick={_onCreateClicked}
              />
            </div>
          </div>
          <Table striped hover responsive bordered>
            <thead>
              <tr>
                <th>STT</th>
                <th>Điểm bán</th>
                <th>Slug</th>
                <th>Giờ mở cửa</th>
                <th>Cửa hàng</th>
                <th>Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {hubProvider.data.map((hub: any, index: number) => {
                return (
                  <HubItem
                    key={index}
                    index={index}
                    hub={hub}
                    page={hubProvider.queries.get('page')}
                    onDelete={hubProvider.deleteHub}
                  />
                )
              })}
            </tbody>
          </Table>
          <Pagination size={'sm'} className="mt-3">
            <Pagination.Item
              disabled={hubProvider.queries.get('page') <= 1}
              onClick={hubProvider.onPreviousPage}
            >
              Trang trước
            </Pagination.Item>
            {hubProvider.queries.get('page') > 1 && (
              <Pagination.Item onClick={hubProvider.onFirstPage}>1</Pagination.Item>
            )}
            <Pagination.Ellipsis disabled />
            <Pagination.Item active>{hubProvider.queries.get('page')}</Pagination.Item>
            <Pagination.Ellipsis disabled />
            <Pagination.Item
              disabled={hubProvider?.meta?.totalPage <= hubProvider.queries.get('page')}
              onClick={hubProvider.onNextPage}
            >
              Trang kế
            </Pagination.Item>
          </Pagination>
        </div>
      </Container>
    </main>
  )
}

export default HubList
