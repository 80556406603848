import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Api } from '../../repository/api'

interface BrandContextType {
  loading: boolean
  setQueries: React.Dispatch<any>
  queries: any
  brand: any
}
let BrandUpdaterContext = React.createContext<BrandContextType>(null!)

const useBrandUpdater = () => {
  return React.useContext(BrandUpdaterContext)
}
const BrandUpdaterProvider: React.FC = ({ children }) => {
  const [brand, setBrand] = useState<any>(fromJS({}))
  const [loading, setLoading] = React.useState(false)
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const params = useParams()
  const _fetchBrand = async () => {
    try {
      if (params.brandId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(params.brandId)
        setLoading(false)
        setBrand(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  useEffect(() => {
    if (params.brandId) {
      _fetchBrand()
    }
  }, [params.brandId])
  const value = { setQueries, queries, brand, loading }
  return <BrandUpdaterContext.Provider value={value}>{children}</BrandUpdaterContext.Provider>
}
export { useBrandUpdater }
export default BrandUpdaterProvider
