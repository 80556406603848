import { Container } from "react-bootstrap";
import PageLoading from "../../components/page_loading";
import Nav from "../../components/Navbars/Nav";
import BrandList from "./components/brandList";
import { useBrands } from "./provider";
import Brandilter from "./components/brandFillter";

const Brands = () => {
  const brandProvider = useBrands();
  const _onSearchChanged = (brand?: any) => {
    brandProvider.onSearchChanged(brand);
  };

  return (
    <main>
      <Nav title={"Thương hiệu"} />
      {brandProvider.loading && brandProvider.data.size < 1 && <PageLoading />}
      <Container className={"pb-4"}>
        <Brandilter onChange={_onSearchChanged} />
        <BrandList />
      </Container>
    </main>
  );
};

export default Brands;
