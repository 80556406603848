import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {MenuUnitType} from "../../repository/model/menuItem";

interface MenuItemUnitsContextType {
  queries: any,
  data: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  deleteMenuItemUnit: (itemId: any) => void,
  onCreateMenuItemUnit: (unit: MenuUnitType) => any,
  onEditMenuItemUnit: (unitId: string, unit: MenuUnitType) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let MenuItemUnitsContext = React.createContext<MenuItemUnitsContextType>(null!);

const useMenuItemUnits = () => {
  return React.useContext(MenuItemUnitsContext);
};

function MenuItemUnitsProvider({ children }: { children: React.ReactNode }) {

  const location: any = useLocation();
  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);

  const _fetchMenuItemUnits = async() => {
    try {
      const rs: any = await Api.menu.fetchMenuUnitList({
        brandFields: "_id name",
        limit: 20,
        page: queries.get('page'),
        brand: queries.getIn(['brand', '_id']),
        search: queries.get('search')
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading || !queries.getIn(['brand', '_id'])) return
    setLoading(true)

    const list: any = await _fetchMenuItemUnits()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onCreateMenuItemUnit = async (unit: MenuUnitType) => {
    try {
      const rs = await Api.menu.createMenuUnit(unit)
      window.alert('Thêm mới thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const deleteMenuItemUnit = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const unitItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.menu.deleteUnit(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, unitItem))
      return null
    }
  }

  const onEditMenuItemUnit = async (unitId: string, unit: MenuUnitType) => {
    try {
      await Api.menu.editMenuUnit(unitId, unit)
      window.alert('Chỉnh sửa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, []);

  React.useEffect(() => {
    if (queries.size < 2) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onBrandChanged,
    deleteMenuItemUnit,
    onCreateMenuItemUnit,
    onEditMenuItemUnit,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <MenuItemUnitsContext.Provider value={value}>{children}</MenuItemUnitsContext.Provider>;
}

export default MenuItemUnitsProvider;
export { useMenuItemUnits };