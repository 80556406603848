import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import { sourceInfo } from "../../util/common_utils";

interface AutoCrawlOrderJobsContextType {
  queries: any,
  data: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  onPlatformChanged: (platform?: any) => void,
  saveCrawler: (crawler: any, autoPull: boolean) => Promise<boolean>
}

let AutoCrawlOrderJobsContext = React.createContext<AutoCrawlOrderJobsContextType>(null!);

const useAutoCrawlOrderJobs = () => {
  return React.useContext(AutoCrawlOrderJobsContext);
};

function AutoCrawlOrderJobsProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]));
  const [loading, setLoading] = React.useState(false);

  const _refresh = async() => {
    setLoading(true);

    const rs = await Api.store.fetchOrderCrawlers(queries.getIn(['brand', '_id']))
    setData(fromJS(rs.data.data))

    setLoading(false)
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
      .updateIn(['platform'], () => null)
    )
  }

  const onPlatformChanged = (platform?: any) => {
    setQueries(
      queries.updateIn(['platform'], () => platform)
    )
  }

  const saveCrawler = async (crawler: any, autoPull: boolean) => {
    try {
      const rs = await Api.store.saveOrderCrawler(crawler.getIn(['store', '_id']), crawler.get('name'), autoPull)
      if (!!rs.data?.data) {
        window.alert('Cập nhật thành công!')
        const updatedItemIndex = data.findIndex((item: any) => item.get('name') === crawler.get('name') && item.getIn(['store', '_id']) === crawler.getIn(['store', '_id']))
        if (updatedItemIndex >= 0) {
          setData(
            data.updateIn([updatedItemIndex, 'autoPull'], () => autoPull)
          )
        }
        return true
      }
    } catch (e) {
      console.log(e)
      window.alert('Có lỗi xảy ra trong quá trình xử lý, vui lòng thử lại sau')
    }
    return false
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _refresh()
    }
  }, []);

  React.useEffect(() => {
    console.log('->>> queries update', queries.size)
    if (queries.size < 1) return;
    _refresh()
  }, [queries?.get('brand')]);

  let value = {
    queries,
    data,
    loading,
    onBrandChanged,
    onPlatformChanged,
    saveCrawler
  };

  return <AutoCrawlOrderJobsContext.Provider value={value}>{children}</AutoCrawlOrderJobsContext.Provider>;
}

export default AutoCrawlOrderJobsProvider;
export { useAutoCrawlOrderJobs };