import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useMenuItems} from "../provider";
import MenuItem from "./menu_item";

const SectionMenuItems = () => {
  const menuItemsProvider = useMenuItems();

  if (menuItemsProvider.data.size < 1 && menuItemsProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover bordered responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Ảnh</th>
          <th>Sản phẩm</th>
          <th>Cửa hàng</th>
          <th>Nguồn đơn</th>
          <th>Giá</th>
          <th>Mở bán</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          menuItemsProvider.data.map((item: any, index: number) => {
            return <MenuItem
              key={`menu_item_${item.get('_id')}`}
              index={index}
              page={menuItemsProvider.queries.get('page')}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!menuItemsProvider.data && menuItemsProvider.data.size >= 20) ||
          menuItemsProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={menuItemsProvider.queries.get('page')}
          onPreviousPage={menuItemsProvider.onPreviousPage}
          onNextPage={menuItemsProvider.onNextPage}
          onFirstPage={menuItemsProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionMenuItems;