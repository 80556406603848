import { fromJS } from 'immutable'
import React, {useEffect, useState} from 'react'
import {Col, Form, FormLabel, FormSelect, Row, Stack} from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import { useApp } from '../../../components/AdminLayout/admin_provider'
import Choices from './choices'
import {Api} from "../../../repository/api";

interface OptionFormPropType {
  option: any
  setOption: React.Dispatch<any>
  target?: string
  brands?: any
}

export const initChoice = { name: '', isDefault: false, maxCount: 1, price: 0 }

const OptionForm: React.FC<OptionFormPropType> = ({ option, setOption, target, brands }) => {
  const app = useApp()
  const [stores, setStores] = useState<any>();
  const [sources, setSources] = useState<any>();
  const isEditing = !!option.get('_id')

  const onNameChanged = (name: string) => {
    setOption(option.updateIn(['name'], () => name))
  }

  const onBrandChanged = (brand: string) => {
    setOption(option.updateIn(['brandId'], () => brand))
  }

  const onStoreChanged = (store: string) => {
    setOption(option.updateIn(['storeId'], () => store))
  }

  const onSourceChanged = (source: string) => {
    setOption(option.updateIn(['sourceId'], () => source))
  }

  const onMinCountChanged = (minCount: number) => {
    setOption(option.updateIn(['minCount'], () => (!minCount ? 0 : minCount)))
  }

  const onMaxCountChanged = (maxCount: number) => {
    setOption(option.updateIn(['maxCount'], () => (!maxCount ? 9999 : maxCount)))
  }

  const handleAddChoice = () => {
    setOption(option.updateIn(['choices'], (e: any) => e.push(fromJS(initChoice))))
  }

  useEffect(() => {
    if (!option.get('choices')?.size) {
      setOption(
        option
          .updateIn(['choices'], () => fromJS([initChoice]))
          .updateIn(['maxCount'], (e: number) => e ?? 1)
          .updateIn(['minCount'], (e: number) => e ?? 0)
      )
    }
  }, [])

  const _fetchStores = async () => {
    if (!option.getIn(['brandId'])) return
    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, option.getIn(['brandId']), 200
      )
      setStores(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchSources = async () => {
    if (!option.getIn(['storeId'])) return
    try {
      const rs: any = await Api.store.fetchSources(option.getIn(['storeId']))
      setSources(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    if (!option.getIn(['brandId'])) return
    _fetchStores()
  }, [option.getIn(['brandId'])])

  React.useEffect(() => {
    if (!option.getIn(['storeId'])) return
    _fetchSources()
  }, [option.getIn(['storeId'])])

  return (
    <Form autoComplete="off">
      <Stack gap={3}>
        <Row>
          <Form.Group className={'col'}>
            <Form.Label>
              Thương hiệu <span className="text-danger fs-5">*</span>
            </Form.Label>
            <Form.Select
              value={option.get('brandId', '')}
              disabled={isEditing}
              onChange={(e) => onBrandChanged(e.target.value)}
            >
              <option value="">Chọn thương hiệu</option>
              {(brands ?? app.brands).map((b: any, i: number) => (
                <option key={i} value={b.get('_id')}>
                  {b.get('name')}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {
            target !== 'website' &&
            <>
              <Form.Group  className={'col'}>
                <FormLabel>
                  Cửa hàng <span className="text-danger fs-5">*</span>
                </FormLabel>
                <FormSelect
                  required
                  value={option.getIn(['storeId'])}
                  disabled={!stores || stores.size < 1 || isEditing}
                  onChange={(el) => onStoreChanged(el.target.value)}
                >
                  <option value="">Chọn cửa hàng</option>
                  { stores && stores.map((store: any, i: number) => (
                    <option key={store.get('_id', '')} value={store.get('_id', '')}>
                      {store.get('name')}
                    </option>
                  ))}
                </FormSelect>
              </Form.Group>
              <Form.Group  className={'col'}>
                <FormLabel>
                  Nguồn đơn <span className="text-danger fs-5">*</span>
                </FormLabel>
                <FormSelect
                  required
                  value={option.getIn(['sourceId'])}
                  disabled={!sources || sources.size < 1 || isEditing}
                  onChange={(el) => onSourceChanged(el.target.value)}
                >
                  <option value="">Chọn nguồn đơn</option>
                  {sources && sources.map((source: any, i: number) => (
                    <option key={source.get('_id', '')} value={source.get('_id', '')}>
                      {source.get('name')}
                    </option>
                  ))}
                </FormSelect>
              </Form.Group>
            </>
          }
        </Row>

        {
          ((target !== 'website' && option.get('sourceId')) || (target === 'website' && option.get('brandId'))) &&
          <>
            <Form.Group>
              <Form.Label>
                Tên Tùy chọn <span className="text-danger fs-5">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={option.get('name')}
                onChange={(el) => onNameChanged(el.target.value)}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Giới hạn tối thiểu</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="9999"
                    value={option.get('minCount') !== 9999 ? option.get('minCount') : 0}
                    onChange={(el) => onMinCountChanged(Number(el.target.value))}
                  />
                  <Form.Text className="text-muted">Chọn 0 nếu là không giới hạn</Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Giới hạn tối đa</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    max="9999"
                    value={option.get('maxCount') !== 9999 ? option.get('maxCount') : 0}
                    onChange={(el) => onMaxCountChanged(Number(el.target.value))}
                  />
                  <Form.Text className="text-muted">Chọn 0 nếu là không giới hạn</Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>
                Lựa chọn <span className="text-danger fs-5">*</span>
                <FaPlus
                  size="20"
                  className="btn text-primary p-0 ps-1 ms-1"
                  color="red"
                  onClick={handleAddChoice}
                />
              </Form.Label>
              <Choices option={option} setOption={setOption} />
            </Form.Group>
          </>
        }
      </Stack>
    </Form>
  )
}

export default OptionForm
