import { useEffect, useRef } from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import useIntersectionObserver from "../util/hook/useIntersectionObserver";

type CustomDropdownItemPropType = {
  index: number
  imageUrl: string
  imageName: string
  text: string
  thresholdIndex: number
  isBanner?: boolean
  handlePageChanged: () => void
  handleClick: () => void
  disabled?: boolean
}

const CustomDropdownItem: React.FC<CustomDropdownItemPropType> = (
  {
    index,
    imageUrl,
    imageName,
    text,
    thresholdIndex,
    isBanner = false,
    handlePageChanged,
    handleClick,
    disabled = false
  }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const isVisible = !!entry?.isIntersecting

  useEffect(() => {
    if (isVisible && thresholdIndex === index) {
      handlePageChanged()
    }
  }, [isVisible])
  return (
    <Dropdown.Item ref={ref} className="p-1" onClick={handleClick} disabled={disabled}>
      <Card>
        <Card.Body className="d-flex gap-3 p-1" style={{maxHeight: 120}}>
          {isBanner ? (
            <div className="w-auto h-auto">
              <Card.Img
                variant="top"
                src={imageUrl}
                alt={imageName}
                className="img-fluid"
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  width: 80,
                  height: 80,
                  flex: '0 0 auto'
                }}
              >
                <Card.Img
                  variant="top"
                  src={imageUrl}
                  alt={imageName}
                  className="img-fluid img-thumbnail"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <Card.Text className="text-wrap app-btn-small w-100 align-items-center d-flex">
                {text}
              </Card.Text>
            </>
          )}
        </Card.Body>
      </Card>
    </Dropdown.Item>
  )
}

export default CustomDropdownItem
