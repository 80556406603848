import "flatpickr/dist/themes/material_green.css";
import {Table} from "react-bootstrap";
import React from "react";
import {useOrderCreator} from "../provider";
import MenuItem from "./menu_item";
import {numberFormat} from "../../../config";
import {fromJS} from "immutable";
import Flatpickr from "react-flatpickr";
import {Api} from "../../../repository/api";
import {useNavigate} from "react-router";
import {useOrders} from "../../Orders/provider";

const OrderSummaries = () => {

  const orderCreator = useOrderCreator();
  const [summaries, setSummaries] = React.useState<any>(fromJS({
    totalPrice: 0,
    quantity: 0,
    promotionValue: 0,
    promoCode: '',
    commission: undefined,
    commissionFee: 0,
    deliveryFee: 0,
    deliveryPromotionValue: 0,
    directDiscounted: 0
  }));
  const [orderTime, setOrderTime] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const ordersProvider = useOrders();

  React.useEffect(() => {
    let totalPrice = 0;
    let quantity = 0;
    for (let item of orderCreator.order.get('items')) {
      let price = item.get('price');
      if (!!item.get('options')) {
        for (let option of item.get('options')) {
          for (let choice of option.get('choices')) {
            price += choice.get('price') * choice.get('quantity', 0);
          }
        }
      }
      totalPrice += price * item.get('quantity');
      quantity += item.get('quantity');
    }

    setSummaries(
      summaries.updateIn(['totalPrice'], () => totalPrice)
        .updateIn(['quantity'], () => quantity)
        .updateIn(['commission'], () => orderCreator.config.getIn(['source', 'commission'], 0))
    )

  }, [orderCreator.order]);

  React.useEffect(() => {
    let com = summaries.get('commission')
    if (com === undefined || com === null) return;
    if (!com) com = 0;

    let price = summaries.get('totalPrice', 0) - summaries.get('promotionValue', 0) +
      summaries.get('deliveryFee', 0) - summaries.get('deliveryPromotionValue', 0) - summaries.get('directDiscounted', 0);
    let commissionFee = price * com/100 ;

    setSummaries(
      summaries.updateIn(['commissionFee'], () => commissionFee)
        .updateIn(['net'], () => price - commissionFee)
    )

  }, [
    summaries.get('commission'), summaries.get('promotionValue'),
    summaries.get('deliveryFee'), summaries.get('deliveryPromotionValue'),
    summaries.get('directDiscounted')
  ])

  const _onEditClicked = () => {
    orderCreator.updateViewState('');
  }

  const _onCreateClicked = async () => {
    if (!orderTime) {
      window.alert('Vui lòng chọn thời gian được đặt')
      return;
    }

    const orderRequest = {
      orderTime: orderTime.getTime(),
      sourceId: orderCreator.config.getIn(['source', '_id']),
      deliveryFee: summaries.get('deliveryFee', 0),
      deliveryPromotionValue: summaries.get('deliveryPromotionValue', 0),
      deliveryPromotionType: 'fixed',
      commissionValue: summaries.get('commission', 0),
      promoCode: summaries.get('promoCode'),
      promotionValue: summaries.get('promotionValue', 0),
      promotionType: 'fixed',
      paymentMethod: orderCreator.config.getIn(['source', 'paymentMethod'], 'online'),
      directPromotionValue: summaries.get('directDiscounted', 0),
      directPromotionType: 'fixed',
      paymentState: 'paid',
      items: orderCreator.order.get('items', [])?.map((item: any) => {
        let options: any = [];
        if (!!item.get('options')) {
          for (let option of item.get('options')) {
            if (!option.get('choices')) continue;
            let choices: any = [];
            for (let choice of option.get('choices')) {
              if (choice.get('quantity', 0) > 0) {
                choices.push({
                  _id: choice.get('_id'),
                  quantity: choice.get('quantity')
                })
              }
            }

            if (choices.length > 0) {
              options.push({
                _id: option.get('_id'),
                choices
              })
            }
          }
        }

        return {
          itemId: item.get('_id'),
          quantity: item.get('quantity', 0),
          itemPrice: item.get('price'),
          note: item.get('note'),
          options: options
        }
      }).toJS()
    }
    try {
      setLoading(true);
      const rs = await Api.order.importOrder(orderRequest)
      setLoading(false);
      const orderResult = rs.data.data;
      ordersProvider.onOrderCreated(orderResult);
      orderCreator.clearOrder();
      window.alert('Tạo đơn ' + orderResult.code + ' thành công!');
    } catch (e: any) {
      setLoading(false);
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  const _onPromotionValueChanged = (e: any) => {
    const value = e.target.value;
    try {
      const promotionValue = Number.parseInt(value);
      if (isNaN(promotionValue)) {
        setSummaries(summaries.updateIn(['promotionValue'], () => ''))
        return;
      }
      if (promotionValue < 0) {
        window.alert('Giá trị phải lớn hơn 0');
        return;
      }
      setSummaries(summaries.updateIn(['promotionValue'], () => promotionValue ?? 0))
    } catch(e) {
      window.alert(e)
    }
  }

  const _onPromotionCodeChanged = (e: any) => {
    setSummaries(summaries.updateIn(['promoCode'], () => e.target.value))
  }

  const _onCommissionChanged = (e: any) => {
    const value = e.target.value;
    try {
      const com = Number.parseInt(value);
      if (isNaN(com)) {
        setSummaries(summaries.updateIn(['commission'], () => ''))
        return;
      }
      if (com < 0) {
        window.alert('Giá trị phải lớn hơn 0');
        return;
      }
      setSummaries(summaries.updateIn(['commission'], () => com))
    } catch(e) {
      window.alert(e)
    }
  }

  const _onDeliveryFeeChanged = (e: any) => {
    const value = e.target.value;
    try {
      const com = Number.parseInt(value);
      if (isNaN(com)) {
        setSummaries(summaries.updateIn(['deliveryFee'], () => ''))
        return;
      }
      if (com < 0) {
        window.alert('Giá trị phải lớn hơn 0');
        return;
      }
      setSummaries(summaries.updateIn(['deliveryFee'], () => com))
    } catch(e) {
      window.alert(e)
    }
  }

  const _onDeliveryPromotionValueChanged = (e: any) => {
    const value = e.target.value;
    try {
      const promotion = Number.parseInt(value);
      if (isNaN(promotion)) {
        setSummaries(summaries.updateIn(['deliveryPromotionValue'], () => ''))
        return;
      }
      if (promotion > summaries.get('deliveryFee', 0)) {
        setSummaries(summaries.updateIn(['deliveryPromotionValue'], () => summaries.get('deliveryFee', 0)))
        return;
      }
      if (promotion < 0) {
        window.alert('Giá trị phải lớn hơn 0');
        return;
      }
      setSummaries(summaries.updateIn(['deliveryPromotionValue'], () => promotion))
    } catch(e) {
      window.alert(e)
    }
  }

  const _onOrderTimeChanged = (e: any) => {
    setOrderTime(e[0]);
  }

  const _onDirectDiscountedValueChanged = (e: any) => {
    const value = e.target.value;
    try {
      const discounted = Number.parseInt(value);
      if (isNaN(discounted)) {
        setSummaries(summaries.updateIn(['directDiscounted'], () => ''))
        return;
      }
      if (discounted < 0) {
        window.alert('Giá trị phải lớn hơn 0');
        return;
      }
      setSummaries(summaries.updateIn(['directDiscounted'], () => discounted))
    } catch(e) {
      window.alert(e)
    }
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'row'}>
        <div className={'col'}>
          <h5>Xác nhận</h5>
        </div>
        <div className={'col-auto'}>
          <div className={'row align-items-center'}>
            <div className={'col'}>Đặt lúc</div>
            <div className={'col-auto'}>
              <Flatpickr
                className={'form-control form-control-sm'}
                options={{
                  dateFormat: "H:i d-m-Y",
                  maxDate: "today",
                  time_24hr: true,
                  minTime: "07:00",
                  enableTime: true,
                  defaultHour: 7,
                  // defaultMinute: 0
                }}
                // value={[orderTime]}
                onChange={_onOrderTimeChanged}
              />
            </div>
          </div>
        </div>
      </div>
      <Table striped hover responsive bordered className={'mt-3'}>
        <thead>
        <tr>
          <th>STT</th>
          <th>Món</th>
          <th>Số lượng</th>
          <th>Giá bán</th>
          <th>Giá khuyến mãi</th>
          <th>Thành tiền</th>
        </tr>
        </thead>
        <tbody>
        {
          orderCreator.order.get('items')?.map((item: any, index: number) => {
            return (
              <MenuItem key={`menu_item_${item.get('_id')}_${item.get('key')}`} item={item} index={index}/>
            )
          })
        }
        <tr><td colSpan={6}></td></tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Tổng {summaries.get('quantity', 0)} món
            </strong>
          </td>
          <td>
          </td>
          <td>
            <strong>{numberFormat.format(summaries.get('totalPrice', 0))}</strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Giảm giá trực tiếp
            </strong>
          </td>
          <td>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={summaries.get('promotionValue', 0)}
              placeholder="Giá trị khuyến mãi"
              min={0}
              disabled={loading}
              style={{maxWidth: 240}}
              onChange={_onPromotionValueChanged}/>
            <input
              type={'text'}
              className="form-control form-control-sm mt-1"
              value={summaries.get('promoCode', '')}
              placeholder="Coupon"
              style={{maxWidth: 240}}
              onChange={_onPromotionCodeChanged}/>
          </td>
          <td>
            <strong className={'text-danger'}>
              {summaries.get('promotionValue', 0) > 0 && '-'}{numberFormat.format(summaries.get('promotionValue', 0))}
            </strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Phí giao hàng
            </strong>
          </td>
          <td>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={summaries.get('deliveryFee', 0)}
              placeholder="0"
              min={0}
              disabled={loading}
              style={{maxWidth: 244}}
              onChange={_onDeliveryFeeChanged}/>
          </td>
          <td>
            <strong>
              {numberFormat.format(summaries.get('deliveryFee', 0))}
            </strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Khuyến mãi Phí giao hàng
            </strong>
          </td>
          <td>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={summaries.get('deliveryPromotionValue', 0)}
              placeholder="0"
              min={0}
              disabled={loading}
              style={{maxWidth: 244}}
              onChange={_onDeliveryPromotionValueChanged}/>
          </td>
          <td>
            <strong className={'text-danger'}>
              {summaries.get('deliveryPromotionValue', 0) > 0 && '-'}{numberFormat.format(summaries.get('deliveryPromotionValue', 0))}
            </strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Chiết khấu trực tiếp
            </strong>
          </td>
          <td>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={summaries.get('directDiscounted', 0)}
              placeholder="0"
              min={0}
              disabled={loading}
              style={{maxWidth: 240}}
              onChange={_onDirectDiscountedValueChanged}/>
          </td>
          <td>
            <strong className={'text-danger'}>
              {summaries.get('directDiscounted', 0) > 0 && '-'}{numberFormat.format(summaries.get('directDiscounted', 0))}
            </strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Phí hoa hồng(%)
            </strong>
          </td>
          <td>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={summaries.get('commission', 0) ?? 0}
              placeholder="0"
              min={0}
              disabled={loading}
              style={{maxWidth: 244}}
              onChange={_onCommissionChanged}/>
          </td>
          <td>
            <strong className={'text-danger'}>
              {summaries.get('commissionFee', 0) > 0 && '-'}{numberFormat.format(summaries.get('commissionFee', 0))}
            </strong>
          </td>
        </tr>
        <tr>
          <td colSpan={4} className={'text-end'}>
            <strong className={'me-3'}>
              Net
            </strong>
          </td>
          <td>
          </td>
          <td>
            <strong className={'text-success'}>{numberFormat.format(summaries.get('net', 0))}</strong>
          </td>
        </tr>
        </tbody>
      </Table>

      <div className={'mt-3'}>
        <div className={'row'}>
          <div className={'col'}>
            <div className="d-grid gap-2">
              <button className="btn btn-light" type="button" onClick={_onEditClicked} disabled={loading}>
                Chọn lại
              </button>
            </div>
          </div>
          <div className={'col'}>
            <div className="d-grid gap-2">
              <button className="btn btn-primary" type="button" onClick={_onCreateClicked} disabled={loading}>
                Tạo đơn
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummaries;