import {numberFormat} from "../../../config";
import React from "react";
import moment from "moment";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaArrowRight} from "react-icons/fa";
import GrabPlatformOrderImporter from "../importer";

interface OrderItemType {
  order: any;
  index: number;
  page: number;
  storeId: string;
  provider: string;
}

const OrderItem = (props: OrderItemType) => {

  const [showImporter, setShowImporter] = React.useState<boolean>(false);

  const _onCreateOrderClicked = async () => {
    setShowImporter(true);
  }

  const _onCloseImporter = () => {
    setShowImporter(false);
  }

  const _getOrderStatus = () => {
    switch (props.order.get('status', '').toLowerCase()) {
      case 'failed':
        return {
          label: 'Đã hủy',
          color: '#ee1414'
        };
      case 'completed':
        return {
          label: 'Đã giao',
          color: '#0fab05'
        };
      case 'order_executing':
        return {
          label: 'Đơn mới',
          color: '#f3bf05'
        };
    }
    return {
      label: '',
      color: '#000'
    };
  }

  const status: any = _getOrderStatus();

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        <div className={'order-code'}>
          {props.order.get('code')}
        </div>
        <div className={'note-info'}>
          {props.order.get('toName')}
        </div>
        <div className={'note-info'}>
          {props.order.get('toPhone')}
        </div>
        <div className={'note-info text-wrap'}>
          {props.order.get('toAddress')}
        </div>
      </td>
      <td>
        <div className={'highlight-value'}>
          {numberFormat.format(props.order.get('price'))}
        </div>
      </td>
      <td>
        <div className={'highlight-value'} style={{color: status.color }}>
          {status.label}
        </div>
      </td>
      <td>
        <div>
          {moment(props.order.get('created')).local().format('YYYY/MM/DD - HH:mm')}
        </div>
        <LabelIconBtn
          label={'Nhập đơn'}
          icon={<FaArrowRight size={12}/>}
          onClick={_onCreateOrderClicked}
          leftIcon={true}/>

        <GrabPlatformOrderImporter
          fromExistOrder={false}
          orderId={props.order.get('id')}
          provider={props.provider}
          storeId={props.storeId}
          code={props.order.get('code')}
          onClose={_onCloseImporter}
          show={showImporter}/>
      </td>
    </tr>
  )
}

export default OrderItem;