import React from "react";
import FormHub from "./components/formHub";
import HubCreatorProvider from "./provider";

const HubCreator = () => {
  return (
    <HubCreatorProvider>
      <FormHub />
    </HubCreatorProvider>
  );
};

export default HubCreator;
