import PageLoading from "../../../components/page_loading";
import {Button, Container, Dropdown, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import SectionFilterBrandItem from "../../../components/Filters/section_filter_brand_item";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import {useMenuItems} from "../provider";
import SectionMenuItems from "./section_menu_items";
import SectionFilterStoreItem from "../../../components/Filters/section_filter_store_item";
import SectionFilterSourceItem from "../../../components/Filters/section_filter_source_item";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";


const Views = () => {

  const menuItemsProvider = useMenuItems();
  const app = useApp();
  const navigate = useNavigate();

  const _onBrandChanged = (brand?: any) => {
    menuItemsProvider.onBrandChanged(brand);
  }

  const _onStoreSelected = (store?: any) => {
    menuItemsProvider.onStoreChanged(store);
  }

  const _onSourceSelected = (source?: any) => {
    menuItemsProvider.onSourceChanged(source);
  }

  const _onCreateClicked = () => {
    navigate('/menu/create')
  }

  const _onMenuItemOptionsClicked = async () => {
    navigate('/menu/options')
  }

  const _onMenuItemCategoriesClicked  = () => {
    navigate('/menu/categories')
  }

  const _onMenuItemUnitsClicked = () => {
    navigate('/menu/units')
  }

  return (
    <main>
      <Nav title={'Thực đơn'} />
      {
        menuItemsProvider.loading && menuItemsProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <Button variant="outline-primary" size="sm" onClick={_onMenuItemOptionsClicked}>
              Món tùy chọn
            </Button>
            <Button variant="outline-dark" size="sm" className={'mx-2'} onClick={_onMenuItemCategoriesClicked}>
              Danh mục món
            </Button>
            <Button variant="outline-primary" size="sm" className={'me-3'} onClick={_onMenuItemUnitsClicked}>
              Đơn vị món
            </Button>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm món mới'}
              icon={<FaPlus size={12} />}
              onClick={_onCreateClicked}
            />
          </div>
        </div>
        <Row>
          <SectionBrandFilter
            brandSelected={menuItemsProvider.queries.getIn(['brand'])}
            storeSelected={menuItemsProvider.queries.getIn(['store'])}
            sourceSelected={menuItemsProvider.queries.getIn(['source'])}
            onBrandSelected={_onBrandChanged}
            onStoreSelected={_onStoreSelected}
            onSourceSelected={_onSourceSelected}/>
        </Row>
        <SectionMenuItems/>
      </Container>
    </main>
  );
}

export default Views;