import React from "react";
import {Dropdown} from "react-bootstrap";

interface SectionMenuItemSelectionType {
  item: any,
  onClick: (item?: any) => void
}

const SectionMenuItemSelection = (props: SectionMenuItemSelectionType) => {

  const _onClicked = () => {
    props.onClick(props.item);
  }

  return (
    <Dropdown.Item onClick={_onClicked}>
      {props.item.get('name')}
    </Dropdown.Item>
  )
}

export default SectionMenuItemSelection;