import PageLoading from "../../../components/page_loading";
import {Container, Row} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {FaPlus} from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import {fromJS} from "immutable";
import NormalModal from "../../../components/Modal/normalModal";
import {useModalState} from "../../../util/hook/useModalState";
import BackBtn from "../../../components/Button/back_btn";
import {useWebsiteMenuItemCategories} from "../provider";
import SectionMenuItemCategories from "./section_menu_item_categories";
import MenuItemCategoryCreator from "../../MenuItemCategoryCreator";
import WebsiteMenuItemCategoryCreator from "./website_menu_item_category_creator";

const Views = () => {

  const menuItemCategoriesProvider = useWebsiteMenuItemCategories();
  const navigate = useNavigate();
  const { isOpen: isCreatorOpen, onOpen: onCreatorOpen, onClose: onCreatorClose } = useModalState()
  const [newCat, setNewCat] = useState<any>(fromJS({}))

  const _onBackClicked = () => navigate(-1)

  const handleCloseCreator = () => {
    onCreatorClose()
    setNewCat(fromJS({}))
  }

  const handleSubmitNewCat = () => {
    if (!newCat.get('name')) {
      window.alert('Tên là bắt buộc!')
      return
    }
    handleCloseCreator()
    menuItemCategoriesProvider.onCreateMenuItemCategory(newCat.toJS())
  }

  return (
    <main>
      <Nav title={'Danh mục món'} />
      {
        menuItemCategoriesProvider.loading && menuItemCategoriesProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <div className={'d-flex justify-content-between mt-4'}>
          <div>
            <BackBtn onBackClicked={_onBackClicked} label={'Thực đơn'}/>
          </div>
          <div>
            <LabelIconBtn
              label={'Thêm danh mục mới'}
              icon={<FaPlus size={12} />}
              onClick={onCreatorOpen}
            />
          </div>
        </div>
        <SectionMenuItemCategories/>
      </Container>
      <NormalModal
        open={isCreatorOpen}
        options={{
          title: 'Thêm danh mục',
          content: <WebsiteMenuItemCategoryCreator category={newCat} setCategory={setNewCat} />,
          confirmationText: 'Thêm'
        }}
        onCancel={onCreatorClose}
        onConfirm={handleSubmitNewCat}
      />
    </main>
  );
}

export default Views;