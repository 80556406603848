import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {MenuCategoryType} from "../../repository/model/menuItem";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {useParams} from "react-router";

interface WebsiteMenuItemCategoriesContextType {
  queries: any,
  data: any,
  loading: boolean,
  deleteMenuItemCategory: (itemId: any) => void,
  onCreateMenuItemCategory: (cat: MenuCategoryType) => any,
  onEditMenuItemCategory: (catId: string, cat: MenuCategoryType) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let WebsiteMenuItemCategoriesContext = React.createContext<WebsiteMenuItemCategoriesContextType>(null!);

const useWebsiteMenuItemCategories = () => {
  return React.useContext(WebsiteMenuItemCategoriesContext);
};

function WebsiteMenuItemCategoriesProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({page: 1}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const {websiteId} = useParams();
  const app = useApp()

  const _fetchMenuItemCategories = async() => {
    try {
      const rs: any = await Api.menu.fetchMenuCategoryList({
        brandFields: "_id name",
        limit: 20,
        page: queries.get('page'),
        search: queries.get('search'),
        majorBrand: websiteId
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await _fetchMenuItemCategories()
    setData(list)

    setLoading(false)
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onCreateMenuItemCategory = async (cat: MenuCategoryType) => {
    try {
      const rs = await Api.menu.createWebsiteMenuCategory(websiteId!!, cat.name)
      window.alert('Thêm mới thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const deleteMenuItemCategory = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const catItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.menu.deleteWebsiteMenuItemCategory(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, catItem))
      return null
    }
  }

  const onEditMenuItemCategory = async (catId: string, cat: MenuCategoryType) => {
    try {
      await Api.menu.editWebsiteMenuCategory(catId, cat.name)
      window.alert('Chỉnh sửa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  React.useEffect(() => {
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    deleteMenuItemCategory,
    onCreateMenuItemCategory,
    onEditMenuItemCategory,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <WebsiteMenuItemCategoriesContext.Provider value={value}>{children}</WebsiteMenuItemCategoriesContext.Provider>;
}

export default WebsiteMenuItemCategoriesProvider;
export { useWebsiteMenuItemCategories };