import { Button, Container, Form, FormControl, FormLabel, FormSelect } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import BackBtn from '../../../components/Button/back_btn'
import { useNavigate, useParams } from 'react-router'
import Nav from '../../../components/Navbars/Nav'
import { fromJS } from 'immutable'
import { useStoreCreator } from '../provider'
import { TiDelete } from 'react-icons/ti'
import { deleteInputValue } from '../../../util/myUtil'
import { useModal } from '../../../components/Modal/modalProvider'
import GMap from "../../HubCreator/components/googleReactMap";
import StoreOpenTimes, {OpenTimeType} from "../../../components/StoreOpenTimes/store_open_times";
import {useApp} from "../../../components/AdminLayout/admin_provider";

const StoreCreatorForm = () => {
  const storeCreatorProvider = useStoreCreator()
  const navigate = useNavigate()
  const params = useParams()
  const app = useApp();
  const [validated, setValidated] = useState(false)
  const [store, setStore] = useState<any>(
    fromJS({
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      logo: '',
      cover: '',
      status: 'active',
      openTimes: []
    })
  )
  const modal = useModal()

  const _onBackClicked = () => {
    navigate(-1)
  }

  const onNameChanged = (name: string) => {
    setStore(store.updateIn(['name'], () => name))
  }

  const onAddressChanged = (address: string) => {
    setStore(store.updateIn(['address'], () => address))
  }

  const onLatitudeChanged = (latitude: number) => {
    setStore(store.updateIn(['latitude'], () => latitude))
  }

  const onLongitudeChanged = (longitude: number) => {
    setStore(store.updateIn(['longitude'], () => longitude))
  }

  const onLogoChanged = (logo: File) => {
    setStore(store.updateIn(['logo'], () => logo))
  }

  const onCoverChanged = (cover: File) => {
    setStore(store.updateIn(['cover'], () => cover))
  }

  const onStatusChanged = (status: string) => {
    setStore(store.updateIn(['status'], () => status))
  }

  const onOpenTimeChanged = (openTimes: OpenTimeType[]) => {
    setStore(store.updateIn(['openTimes'], () => openTimes))
  }

  const handlePickAddress = async () => {
    let temp = store.toJS()
    await modal({
      type: 'normal',
      title: 'Chọn địa chỉ',
      cancellationText: 'Hủy',
      confirmationText: 'Xác nhận',
      content: (
        <GMap
          onPick={(address: string, lat: number, lng: number) => {
            temp.address = address
            temp.latitude = lat
            temp.longitude = lng
          }}
          defaultPos={{
            address: store.get('address'),
            pos: {
              lat: Number(store.get('latitude')),
              lng: Number(store.get('longitude'))
            }
          }}
        />
      )
    })
    setStore(fromJS(temp))
  }

  const handleBrandChanged = (brandId: string) => {
    const brand = app.brands.find((b: any) => b.get('_id') === brandId)
    if (!brand) return

    setStore(
      store.updateIn(['brand'], () => brand)
    )
  }

  const handleSubmit = async (event: any) => {
    try {
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setValidated(true)
        return
      }

      const data = { ...store.toJS() }
      const rs = await storeCreatorProvider.createStore(data)
      if (rs) {
        navigate(-1)
      }
    } catch (e) {
      console.log(e)
      window.alert('Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  useEffect(() => {
    if (!store.get('longitude') && !store.get('latitude')) {
      setStore(
        store
          .updateIn(['longitude'], () => store.getIn(['stores', 0, 'longitude']))
          .updateIn(['latitude'], () => store.getIn(['stores', 0, 'latitude']))
      )
    }
  }, [store])

  useEffect(() => {
    if (params.storeId && storeCreatorProvider.store) {
      setStore(storeCreatorProvider.store)
    }
  }, [params.storeId, storeCreatorProvider.store])

  if (!app.brands || app.brands.size < 1) {
    return (
      <main></main>
    )
  }

  return (
    <main>
      <Nav title={params.storeId ? 'Sửa Cửa hàng' : 'Tạo Cửa hàng'} />
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Quay lại'} />
      </Container>
      <Container className="mb-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
            <Form.Group className="mb-3 col-12 col-md-3">
              <FormLabel>
                Thương hiệu <span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormSelect
                required
                disabled={!!params.storeId}
                value={store.getIn(['brand', '_id'], '')}
                onChange={(el) => handleBrandChanged(el.target.value)}
              >
                <option value="">Chọn thương hiệu</option>
                {app.brands.map((brand: any, i: number) => (
                  <option key={i} value={brand.get('_id', '')}>
                    {brand.get('name')}
                  </option>
                ))}
              </FormSelect>
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>
                Tên Cửa hàng <span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormControl
                required={!store.get('name')}
                type="text"
                value={store.get('name', '')}
                onChange={(el) => onNameChanged(el.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>Địa chỉ</FormLabel>
              <FormControl
                type="text"
                value={store.get('address', '')}
                onChange={(el) => onAddressChanged(el.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <FormLabel>
                Tọa độ <span className="text-danger fs-5">*</span>
                <Button type="button" variant="link" size="sm" onClick={handlePickAddress}>
                  (Tìm trên bản đồ)
                </Button>
              </FormLabel>
              <div className="col-12 col-sm-6 d-flex gap-2">
                <FormControl
                  type="text"
                  required={!store.get('longitude', -1)}
                  placeholder="Kinh độ"
                  value={store.get('longitude', 0) ?? ''}
                  onChange={(el) => onLongitudeChanged(Number(el.target.value))}
                />
                <FormControl
                  type="text"
                  required={!store.get('latitude', -1)}
                  placeholder="Vĩ độ"
                  value={store.get('latitude', 0) ?? ''}
                  onChange={(el) => onLatitudeChanged(Number(el.target.value))}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>Logo (tối đa 1MB)</FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadLogo"
                value={''}
                onChange={(el: any) => onLogoChanged(el.target?.files[0])}
              />
              {store.get('logo') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof store.get('logo') == 'string'
                        ? store.get('logo')
                        : URL.createObjectURL(store.get('logo'))
                    }
                    alt={store.get('logo').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof store.get('logo') == 'string'
                          ? store.get('logo')
                          : URL.createObjectURL(store.get('logo'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setStore(store.updateIn(['logo'], () => undefined))
                      deleteInputValue('uploadLogo')
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-lg-4 col-sm-12">
              <FormLabel>Ảnh bìa (tối đa 1MB)</FormLabel>
              <FormControl
                type="file"
                className="mb-3"
                id="uploadCover"
                value={''}
                onChange={(el: any) => onCoverChanged(el.target.files[0])}
              />

              {store.get('cover') && (
                <div className="position-relative">
                  <img
                    src={
                      typeof store.get('cover') == 'string'
                        ? store.get('cover')
                        : URL.createObjectURL(store.get('cover'))
                    }
                    alt={store.get('cover').name}
                    className="btn p-0"
                    style={{ width: '100%', height: 'auto' }}
                    onClick={() =>
                      window.open(
                        typeof store.get('cover')
                          ? store.get('cover')
                          : URL.createObjectURL(store.get('cover'))
                      )
                    }
                  />
                  <TiDelete
                    fontSize={25}
                    cursor="pointer"
                    className="position-absolute"
                    style={{ right: 1 }}
                    onClick={() => {
                      setStore(store.updateIn(['cover'], () => undefined))
                      deleteInputValue('uploadCover')
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3 col-md-3">
              <FormLabel>Tình trạng</FormLabel>
              <FormSelect
                required={!store.get('status')}
                value={store.get('status') || 'active'}
                onChange={(el) => onStatusChanged(el.target.value)}
              >
                <option value="active">Đang hoạt động</option>
                <option value="inactive">Ngừng hoạt động</option>
              </FormSelect>
            </Form.Group>

            <Form.Group className="mb-3">
              <StoreOpenTimes
                onOpenTimeChanged={onOpenTimeChanged}
                openTimes={store.get('openTimes')}
              />
            </Form.Group>

            <div className="d-flex justify-content-start">
              <Button
                variant="primary"
                type="submit"
                className={'mt-4 d-grid gap-2 btn btn-primary'}
              >
                {params.storeId ? 'Cập nhật' : 'Tạo Cửa hàng'}
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </main>
  )
}

export default StoreCreatorForm
