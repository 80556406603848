import GoogleMapReact, { Coords } from 'google-map-react'
import { useEffect, useState } from 'react'
import { Dropdown, Form, Image } from 'react-bootstrap'
import { Api } from '../../../repository/api'
import { GOOGLE_MAP_KEY } from '../../../util/constant'
import useDebounce from '../../../util/useDebounce'

interface GMapProps {
  onPick: (address: string, lat: number, lng: number) => void
  defaultPos: defaultPosition
}

interface defaultPosition {
  address: string
  pos: Coords
}

export interface GoogleMapPlace {
  [key: string]: any
  place_id: string
  description: string
}
export interface GoogleMapPlaceDetail {
  [key: string]: any
  formatted_address: string
  place_id: string
  geometry: {
    [key: string]: any
    location: {
      lat: number
      lng: number
    }
  }
}

const GMap: React.FC<GMapProps> = ({ onPick, defaultPos }) => {
  const [currentPosition, setCurrentPosition] = useState<defaultPosition>({
    address: '',
    pos: {
      lat: 21.038841478968774,
      lng: 105.83430278169783
    }
  })
  const [listAddress, setList] = useState<GoogleMapPlace[]>([])
  const [searchingAddress, setSearchAddress] = useState<any>(null);

  const debouncedValue = useDebounce<string>(searchingAddress)

  const [mapApi, setMapApi] = useState<any>()

  const handleApiLoaded = (map: any, maps: any) => {
    setMapApi(maps)
  }

  const handleAddressChanged = (address: string) => {
    // setCurrentPosition((prev) => ({ ...prev, address: address }))
    setSearchAddress(address);
  }

  const _loadAddressList = async () => {
    try {
      if (!searchingAddress) return
      const result = await Api.fetchLocationFromAddress(
        searchingAddress,
        Date.now().toString()
      )

      setList(result.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onAddressItemClicked = async (placeId: string) => {
    try {
      const { data } = await Api.fetchLocationFromId(placeId)

      setSearchAddress(null)
      setCurrentPosition({
        address: data.data.formatted_address,
        pos: {
          lat: data.data?.geometry?.location?.lat,
          lng: data.data?.geometry?.location?.lng
        }
      })
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  useEffect(() => {
    _loadAddressList()
  }, [debouncedValue])

  useEffect(() => {
    if (defaultPos.pos.lat && defaultPos.pos.lng) {
      setCurrentPosition(defaultPos)
    }
  }, [defaultPos])

  useEffect(() => {
    onPick(currentPosition.address, currentPosition.pos.lat, currentPosition.pos.lng)
  }, [currentPosition])

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          className="filter text-start w-100"
          variant={'light'}
          style={{ whiteSpace: 'normal' }}
        >
          <Form.Control
            type="text"
            placeholder="Nhập địa chỉ"
            className="my-2"
            value={searchingAddress ?? currentPosition.address}
            onChange={(e) => handleAddressChanged(e.target.value)}
          />
        </Dropdown.Toggle>
        {!!listAddress.length && (
          <Dropdown.Menu className="w-100">
            {listAddress.map((item: GoogleMapPlace, index: number) => (
              <Dropdown.Item key={index} onClick={() => onAddressItemClicked(item.place_id)}>
                {item.description}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>

      <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
          defaultCenter={currentPosition.pos}
          center={currentPosition.pos}
          defaultZoom={16}
          options={{ zoomControlOptions: { position: 7 } }}
          onChange={({ center }) => {
            if (!mapApi) return
            const geocoder = new mapApi.Geocoder()

            geocoder.geocode(
              { location: { lat: center?.lat, lng: center?.lng } },
              (results: any, status: any) => {
                if (status === 'OK') {
                  if (results[0]) {
                    setSearchAddress(null)
                    setCurrentPosition({
                      address: results[0]?.formatted_address,
                      pos: {
                        lat: results[0].geometry.location?.lat(),
                        lng: results[0].geometry.location?.lng()
                      }
                    })
                  } else {
                    console.log('No results found')
                  }
                } else {
                  console.log('Geocoder failed due to: ' + status)
                }
              }
            )
          }}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        />
        <Image
          className="position-absolute top-50 start-50"
          style={{ transform: 'translate(-50%, -50%)' }}
          src={require('../../../assets/img/pin.svg').default}
        />
      </div>
    </div>
  )
}

export default GMap
