import {useStores} from "../provider";
import {Spinner, Table} from "react-bootstrap";
import React from "react";
import StoreItem from "./store_item";
import TablePagination from "../../../components/table_pagination";

const SectionStores = () => {
  const storesProvider = useStores();

  if (storesProvider.data.size < 1 && storesProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  console.log('-> s', storesProvider.data.toJS())

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Thương hiệu</th>
          <th>Cửa hàng</th>
          <th>Shopee</th>
          <th>Grab</th>
          <th>GoFood</th>
          <th>BeFood</th>
          <th>Baemin</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          storesProvider.data.map((store: any, index: number) => {
            console.log('-> render: ', store.get('_id'), store.get('name'))
            return <StoreItem
              key={`store_${store.get('_id')}`}
              index={index}
              page={storesProvider.queries.get('page')}
              store={store}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!storesProvider.data && storesProvider.data.size >= 20) ||
          storesProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={storesProvider.queries.get('page')}
          onPreviousPage={storesProvider.onPreviousPage}
          onNextPage={storesProvider.onNextPage}
          onFirstPage={storesProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionStores;