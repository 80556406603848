import { fromJS } from 'immutable'
import React, { createContext, Dispatch, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import useDebounce from '../../util/hook/useDebounce'
import {Api} from "../../repository/api";

interface WebsiteHomePageContextType {
  loading: boolean
  website: any
  homeConfig: any
  banners: any
  initItem: any
  queries: any
  setQueries: Dispatch<any>
  loadMenu: (sourceId?: any) => void
  homeConfigChanged: (config: any) => void
  submitHomeConfig: () => void
  addSectionBoth: () => void
  addSectionBanner: () => void
  removeSection: (config: any) => void
  onItemBrandChanged: (brand?: any) => void
  onBannerNameChanged: (name: string) => void
  onItemNameChanged: (name: string) => void
}
let WebsiteHomePageConfigContext = createContext<WebsiteHomePageContextType>(null!)

const useWebsiteHomePageConfig = () => {
  return useContext(WebsiteHomePageConfigContext)
}

const WebsiteHomePageConfigProvider: React.FC = ({ children }) => {
  const [homeConfig, setHomeConfig] = useState<any>(fromJS([]))
  const [loading, setLoading] = useState(false)
  const [website, setWebsite] = useState<any>(fromJS({}))
  const [banners, setBanner] = useState<any>(fromJS([]))
  const [initItem, setItem] = useState<any>(fromJS([]))
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const { websiteId } = useParams()
  const navigate = useNavigate()

  const _fetchBanners = async () => {
    try {
      if (!websiteId) return
      setLoading(true)
      const res = await Api.banner.fetchBanners(
        websiteId,
        queries.getIn(['bannerSearch', 'name']),
        queries.getIn(['bannerSearch', 'page'], 1),
        20,
        queries.getIn(['bannerSearch', 'types'])
        )
      setLoading(false)

      if (queries.getIn(['bannerSearch', 'page'], 1) > 1) {
        setBanner(banners.merge(fromJS(res.data.data)))
      } else {
        setBanner(fromJS(res.data.data))
        setQueries(
          queries
            .updateIn(['bannerSearch', 'totalPage'], () => res.data.meta.totalPage)
            .updateIn(['bannerSearch', 'page'], () => res.data.meta.page)
        )
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchHomeConfig = async () => {
    try {
      console.log('-> fetch home config', websiteId)
      if (!websiteId) return

      setLoading(true)
      const res = await Api.brand.fetchHomePageConfig(websiteId)
      setLoading(false)
      setHomeConfig(fromJS(res.data.data?.homeConfig))
      setWebsite(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuItems = async () => {
    try {
      if (!websiteId) return
      setLoading(true)
      const res = await Api.menu.fetchMenuItems({
        search: queries.getIn(['itemSearch', 'name'], ''),
        majorBrand: websiteId,
        brand: queries.getIn(['itemSearch', 'brand', '_id'], ''),
        page: queries.getIn(['itemSearch', 'page'], 1),
        limit: 20,
        brandFields: 'name,slug'
      })
      setLoading(false)
      if (queries.getIn(['itemSearch', 'page'], 1) > 1) {
        setItem(initItem.merge(fromJS(res.data.data)))
      } else {
        setItem(fromJS(res.data.data))
        setQueries(queries.updateIn(['itemSearch', 'totalPage'], () => res.data.meta.totalPage))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const loadMenu = async (sourceId: any) => {
    try {
      // const res = await Api.fetchMenuList(sourceId)
      // return res.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  const submitHomeConfig = async () => {
    try {
      if (!websiteId) return

      const dataHomeConfig = homeConfig.map((el: any, index: number) => ({
        ...el.toJS(),
        banners: el.get('banners').map((banner: any) => banner.get('_id')),
        menuItems: el.get('menuItems').map((menuItem: any) => menuItem.get('_id')),
        pos: el.get('pos') || index + 1
      }))

      setLoading(true)
      await Api.brand.updateHomePageConfig(websiteId, {
        homeConfig: dataHomeConfig
      })
      setLoading(false)

      window.alert('Cài đặt trang chủ cho website ' + website.get('name') + ' thành công!')

      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const homeConfigChanged = (config: any) => {
    setHomeConfig(config)
  }

  const addSectionBanner = () => {
    let newConfig = homeConfig.push(
      fromJS({
        ui: 'BANNER',
        title: '',
        link: '',
        menuItems: [],
        status: 'active',
        banners: []
      })
    )
    setHomeConfig(newConfig)
  }

  const addSectionBoth = () => {
    let newConfig = homeConfig.push(
      fromJS({
        ui: 'BOTH',
        title: '',
        link: '',
        menuItems: [],
        status: 'active',
        banners: []
      })
    )
    setHomeConfig(newConfig)
  }

  const removeSection = (config: any) => {
    setHomeConfig(homeConfig.filter((el: any) => el !== config))
  }

  const onBannerNameChanged = (name: string) => {
    setQueries(
      queries
        .updateIn(['bannerSearch', 'name'], () => name)
        .updateIn(['bannerSearch', 'page'], () => 1)
        .updateIn(['bannerSearch', 'totalPage'], () => undefined)
    )
  }

  const onItemBrandChanged = (brand?: any) => {
    setQueries(
      queries
        .updateIn(['itemSearch', 'brand'], () => brand || undefined)
        .updateIn(['itemSearch', 'page'], () => 1)
        .updateIn(['itemSearch', 'totalPage'], () => undefined)
    )
  }

  const onItemNameChanged = (name: string) => {
    setQueries(
      queries
        .updateIn(['itemSearch', 'name'], () => name)
        .updateIn(['itemSearch', 'page'], () => 1)
        .updateIn(['itemSearch', 'totalPage'], () => undefined)
    )
  }

  useEffect(() => {
    if (queries.getIn(['itemSearch', 'totalPage']) < queries.getIn(['itemSearch', 'page'])) return

    if (queries.getIn(['itemSearch', 'brand', '_id'])) {
      _fetchMenuItems()
    }
  }, [useDebounce(queries.getIn(['itemSearch']))])

  useEffect(() => {
    if (!queries.getIn(['bannerSearch']) || (!queries.getIn(['bannerSearch', 'name']) && banners.size > 0)) return

    if (queries.getIn(['bannerSearch', 'totalPage']) < queries.getIn(['bannerSearch', 'page'])) {
      return
    }

    _fetchBanners()
  }, [useDebounce(queries.getIn(['bannerSearch']))])

  useEffect(() => {
    if (!websiteId || website.size > 0) return
    _fetchHomeConfig()
  }, [websiteId])

  useEffect(() => {
    if (!website.get('_id')) return
    _fetchBanners()
  }, [website.get('_id')])

  const value = {
    loading,
    website,
    homeConfig,
    loadMenu,
    banners,
    homeConfigChanged,
    submitHomeConfig,
    addSectionBoth,
    addSectionBanner,
    removeSection,
    onItemBrandChanged,
    onBannerNameChanged,
    onItemNameChanged,
    initItem,
    queries,
    setQueries
  }
  return <WebsiteHomePageConfigContext.Provider value={value}>{children}</WebsiteHomePageConfigContext.Provider>
}
export { useWebsiteHomePageConfig }
export default WebsiteHomePageConfigProvider
