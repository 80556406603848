import {Link} from "react-router-dom";
import React from "react";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaTrashAlt} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useWebsitePromotionCreator} from "../../WebsitePromotionCreator/provider";

interface TargetItemPropType {
  target: any
  index: number
}

const TargetItem: React.FC<TargetItemPropType> = (props: TargetItemPropType) => {

  const provider = useWebsitePromotionCreator()

  const _onRemoveClicked = async () => {
    const rs = window.confirm('Bạn có chắc chắn muốn gỡ bỏ đối tượng áp dụng này?')
    if (!rs) return
    await provider.removeBrandTarget(props.target.getIn(['brand', '_id']))
  }

  return (
    <tr>
      <td>
        {props.index + 1}
      </td>
      <td>
        {props.target.getIn(['brand', 'name'])}
      </td>
      <td>
        <strong>
          {
            (!props.target.get('stores') || props.target.get('stores').size < 1) && `Tất cả cửa hàng`
          }
          <ul>
            {
              props.target.get('stores') &&
              props.target.get('stores').size > 0 &&
              props.target.get('stores').map((store: any) => {
                return (
                  <li key={store.get('_id')}>{store.get('name')}</li>
                )
              })
            }
          </ul>
        </strong>
      </td>
      <td>
        <LabelIconBtn
          label={'Gỡ bỏ'}
          icon={<FaTrashAlt color={Colors.Error} size={14}/>}
          color={Colors.Error}
          onClick={_onRemoveClicked}/>
      </td>
    </tr>
  )
}

export default TargetItem
