import React from 'react';
import Views from "./components/views";
import WebsiteMenuItemsProvider from "./provider";

const WebsiteMenuItems = () => {

  return (
    <WebsiteMenuItemsProvider>
      <Views/>
    </WebsiteMenuItemsProvider>
  );
};

export default WebsiteMenuItems;