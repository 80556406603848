import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useMenuItemSources} from "../provider";
import MenuItemSource from "./menu_item_source";

const SectionMenuItemSources = () => {
  const useMenuItemSourcesProvider = useMenuItemSources();

  if (useMenuItemSourcesProvider.data.size < 1 && useMenuItemSourcesProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Cửa hàng</th>
          <th>Nguồn đơn</th>
          <th>Commission</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          useMenuItemSourcesProvider.data.map((item: any, index: number) => {
            return <MenuItemSource
              key={`menu_item_source_${item.get('_id')}`}
              index={index}
              page={useMenuItemSourcesProvider.queries.get('page')}
              item={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!useMenuItemSourcesProvider.data && useMenuItemSourcesProvider.data.size >= 20) ||
          useMenuItemSourcesProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={useMenuItemSourcesProvider.queries.get('page')}
          onPreviousPage={useMenuItemSourcesProvider.onPreviousPage}
          onNextPage={useMenuItemSourcesProvider.onNextPage}
          onFirstPage={useMenuItemSourcesProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionMenuItemSources;