import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {foodApps} from "../../util/common_utils";

interface FoodAppConnectContextType {
  loading: boolean,
  store: any,
  accounts: any,
  sources: any,
  initData: (store: any) => void,
  connectBaeminAccount: (username: string, password: string) => Promise<boolean>,
  connectGrabAccount: (username: string, password: string) => Promise<boolean>,
  connectShopee: (phone: string) => Promise<boolean>,
  merchantShopeeSelected: (phone: string, merchant: any) => Promise<boolean>,
  updateShopeeRestaurant: (phone: string, merchant: any, restaurant: any) => Promise<boolean>,
  sourceAdded: (source: any) => void,
  sendGoFoodOTP: (phone: string) => Promise<string>,
  connectGoFood: (email: string, password: string) => Promise<boolean>,
  updateGofoodMerchant: (merchant: any) => Promise<boolean>,
  connectBeFood: (email: string, password: string) => Promise<boolean>,
  updateBefoodMerchant: (merchant: any) => Promise<boolean>,
}

let FoodAppConnectContext = React.createContext<FoodAppConnectContextType>(null!);

const useFoodAppConnect = () => {
  return React.useContext(FoodAppConnectContext);
};

function FoodAppConnectProvider({ children }: { children: React.ReactNode }) {

  const [store, setStore] = React.useState<any>(fromJS({}));
  const [accounts, setAccounts] = React.useState<any>(fromJS([]));
  const [loading, setLoading] = React.useState(false);
  const [sources, setSources] = React.useState<any>(fromJS([]));

  const _fetchSources = async (storeId: any) => {
    try {
      const rs = await Api.store.fetchSources(storeId)
      setSources(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  const _refresh = async(storeId: any) => {
    setLoading(true)

    try {
      _fetchSources(storeId);
      const rs = await Api.store.fetchPlatforms(storeId)
      setAccounts(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }

    setLoading(false)
  }

  const initData = (store: any) => {
    setStore(fromJS(store));
    _refresh(store['_id']);
  }

  const connectBaeminAccount = async (username: string, password: string) => {
    try {
      const rs = await Api.baemin.updateAccount(store.get('_id'), username, password);
      if (rs.data.data) {
        const baeminAccountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.baemin);
        if (baeminAccountIndex >= 0) {
          setAccounts(
            accounts.updateIn([baeminAccountIndex, 'username'], () => username)
              .updateIn([baeminAccountIndex, 'password'], () => password)
              .updateIn([baeminAccountIndex, 'connected'], () => true)
          )
        } else {
          setAccounts(
            accounts.push(fromJS({
              name: foodApps.baemin,
              username,
              password,
              connected: true
            }))
          )
        }

      }
      return true
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const connectGrabAccount = async (username: string, password: string) => {
    try {
      const rs = await Api.grab.updateAccount(store.get('_id'), username, password);
      if (rs.data.data) {
        const grabAccountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.grab);
        if (grabAccountIndex >= 0) {
          setAccounts(
            accounts.updateIn([grabAccountIndex, 'username'], () => username)
              .updateIn([grabAccountIndex, 'password'], () => password)
              .updateIn([grabAccountIndex, 'connected'], () => true)
          )
        } else {
          setAccounts(
            accounts.push(fromJS({
              name: foodApps.grab,
              username,
              password,
              connected: true
            }))
          )
        }

      }
      return true
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const connectShopee = async (phone: string) => {
    try {
      const rs = await Api.shopee.merchants(store.get('_id') ?? '', phone);
      if (rs.data.data) {
        const merchants = fromJS(rs.data.data).sortBy((res: any) => res.get('merchantName'))
        let accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.shopee);
        let shopeeAccount: any;
        if (accountIndex >= 0) {
          shopeeAccount = accounts.get(accountIndex)
          console.log('->> accountIndex', accountIndex, shopeeAccount, accounts)
          shopeeAccount = shopeeAccount.updateIn(['username'], () => phone)
            .updateIn(['merchants'], () => merchants)
          setAccounts(
            accounts.updateIn([accountIndex], () => shopeeAccount)
          )
        } else {
          shopeeAccount = fromJS({
            name: foodApps.shopee,
            username: phone,
            merchants: merchants
          })
          const newAccounts = accounts.push(shopeeAccount)
          setAccounts(newAccounts)
          accountIndex = newAccounts.size - 1
        }
        return true
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const merchantShopeeSelected = async (phone: string, merchant: any) => {
    try {
      let accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.shopee);
      if (accountIndex < 0) {
        window.alert('Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau!')
          return false
      }
      let newAccounts = accounts;
      if (accounts.getIn([accountIndex, 'restaurants'])) {
        newAccounts = newAccounts.updateIn([accountIndex, 'restaurants'], () => null);
        setAccounts(newAccounts);
      }

      const rs = await Api.shopee.restaurants(store.get('_id') ?? '', phone, merchant.get('merchantId'));
      console.log(rs.data)
      if (rs.data.data?.store_list) {
        const restaurants = fromJS(rs.data.data.store_list).sortBy((res: any) => res.get('store_name'))
        setAccounts(
          newAccounts.updateIn([accountIndex, 'restaurants'], () => restaurants)
        )
        return true
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const updateShopeeRestaurant = async (phone: string, merchant: any, restaurant: any) => {
    try {
      const rs = await Api.shopee.updateRestaurant(
        store.get('_id'), 
        phone,
        merchant.get('merchantId'),
        restaurant.get('store_id'), 
        restaurant.get('store_name')
      );
      if (!!rs.data.data) {
        const accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.shopee);
        if (accountIndex >= 0) {
          setAccounts(
            accounts.updateIn([accountIndex, 'restaurantId'], () => restaurant.get('store_id'))
              .updateIn([accountIndex, 'restaurantName'], () => restaurant.get('store_name'))
          )
        }
      }
      return !!rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const sourceAdded = (source: any) => {
    setSources(
      sources.push(source)
    )
  }

  const sendGoFoodOTP = async (phone: string) => {
    try {
      const rs = await Api.gofood.sendOTP(store.get('_id'), phone);
      return rs.data?.data
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return null
    }
  }

  const fetchGofoodRestaurants = async (goFoodAccountIndex: number, gofoodAccount: any) => {
    try {
      const rs = await Api.gofood.fetchMerchants(store.get('_id'));
      if (rs.data.data) {
        const restaurants = fromJS(rs.data.data).sortBy((res: any) => res.get('restaurant_name'))
        const accountIndex = accounts.findIndex((account: any) => account.get('name').toLowerCase() === foodApps.gofood.toLowerCase());
        if (accountIndex >= 0) {
          setAccounts(
            accounts.updateIn([accountIndex, 'restaurants'], () => restaurants)
          )
          return true
        } else if (goFoodAccountIndex >= 0) {
          const newUpdated = gofoodAccount.updateIn(['restaurants'], () => restaurants)
          setAccounts(
            accounts.updateIn([goFoodAccountIndex], () => newUpdated)
          )
          return true
        }
        window.alert('Không thấy danh sách cửa hàng của tài khoản này')
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const updateGofoodMerchant = async (merchant: any) => {
    try {
      const rs = await Api.gofood.updateMerchant(store.get('_id'), merchant.toJS());
      if (!!rs.data.data) {
        const accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.gofood);
        if (accountIndex >= 0) {
          setAccounts(
            accounts.updateIn([accountIndex, 'restaurantId'], () => merchant.getIn(['restaurant_id']))
              .updateIn([accountIndex, 'restaurantName'], () => merchant.get('restaurant_name'))
          )
        }
      }
      return !!rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const connectGoFood = async (email: string, password: string) => {
    try {
      const rs = await Api.gofood.updateAccount(store.get('_id'), email, password);
      if (rs.data.data?.status === 1) {
        let accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.gofood);
        let gofoodAccount: any;
        if (accountIndex >= 0) {
          gofoodAccount = accounts.get(accountIndex)
          gofoodAccount = gofoodAccount.updateIn(['username'], () => email)
            .updateIn(['connected'], () => true)
          setAccounts(
            accounts.updateIn([accountIndex], () => gofoodAccount)
          )
        } else {
          gofoodAccount = fromJS({
            name: foodApps.gofood,
            username: email,
            connected: true
          })
          const newAccounts = accounts.push(gofoodAccount)
          setAccounts(newAccounts)
          accountIndex = newAccounts.size - 1
        }

        await fetchGofoodRestaurants(accountIndex, gofoodAccount)
        return true
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const fetchBefoodRestaurants = async (beFoodAccountIndex: number, befoodAccount: any) => {
    try {
      const rs = await Api.befood.fetchMerchants(store.get('_id'));
      if (rs.data.data) {
        const restaurants = fromJS(rs.data.data).sortBy((res: any) => res.get('restaurant_name'))
        const accountIndex = accounts.findIndex((account: any) => account.get('name').toLowerCase() === foodApps.befood.toLowerCase());
        if (accountIndex >= 0) {
          setAccounts(
            accounts.updateIn([accountIndex, 'restaurants'], () => restaurants)
          )
          return true
        } else if (beFoodAccountIndex >= 0) {
          const newUpdated = befoodAccount.updateIn(['restaurants'], () => restaurants)
          setAccounts(
            accounts.updateIn([beFoodAccountIndex], () => newUpdated)
          )
          return true
        }
        window.alert('Không thấy danh sách cửa hàng của tài khoản này')
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const connectBeFood = async (email: string, password: string) => {
    try {
      const rs = await Api.befood.updateAccount(store.get('_id'), email, password);
      if (rs.data.data?.status === 1) {
        let accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.befood);
        let befoodAccount: any;
        if (accountIndex >= 0) {
          befoodAccount = accounts.get(accountIndex)
          befoodAccount = befoodAccount.updateIn(['username'], () => email)
            .updateIn(['connected'], () => true)
          setAccounts(
            accounts.updateIn([accountIndex], () => befoodAccount)
          )
        } else {
          befoodAccount = fromJS({
            name: foodApps.befood,
            username: email,
            connected: true
          })
          const newAccounts = accounts.push(befoodAccount)
          setAccounts(newAccounts)
          accountIndex = newAccounts.size - 1
        }

        await fetchBefoodRestaurants(accountIndex, befoodAccount)
        return true
      }
      return false
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  const updateBefoodMerchant = async (merchant: any) => {
    try {
      const rs = await Api.befood.updateMerchant(store.get('_id'), merchant.toJS());
      if (!!rs.data.data) {
        const accountIndex = accounts.findIndex((account: any) => account.get('name') === foodApps.befood);
        if (accountIndex >= 0) {
          setAccounts(
            accounts.updateIn([accountIndex, 'restaurantId'], () => merchant.getIn(['restaurant_id']))
              .updateIn([accountIndex, 'restaurantName'], () => merchant.get('restaurant_name'))
          )
        }
      }
      return !!rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
      return false
    }
  }

  let value = {
    loading,
    store,
    accounts,
    sources,
    initData,
    connectBaeminAccount,
    connectGrabAccount,
    connectShopee,
    merchantShopeeSelected,
    updateShopeeRestaurant,
    sourceAdded,
    sendGoFoodOTP,
    connectGoFood,
    updateGofoodMerchant,
    connectBeFood,
    updateBefoodMerchant,
  };

  return <FoodAppConnectContext.Provider value={value}>{children}</FoodAppConnectContext.Provider>;
}

export default FoodAppConnectProvider;
export { useFoodAppConnect };