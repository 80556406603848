import {Dropdown, Row} from "react-bootstrap";
import {FaChevronDown} from "react-icons/fa";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import {useOrderCreator} from "../provider";
import SectionConfigOptionItem from "./section_config_option_item";

const SectionConfig = () => {

  const app = useApp();
  const orderCreator = useOrderCreator();

  const _onBrandSelected = (brand?: any) => {
    orderCreator.onBrandChanged(brand);
  }

  const _onStoreSelected = (store?: any) => {
    orderCreator.onStoreChanged(store);
  }

  const _onSourceSelected = (source?: any) => {
    orderCreator.onSourceChanged(source);
  }

  return (
    <Row>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>
            Thương hiệu
          </h4>
          <Dropdown>
            <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
              <div className={'hstack'}>
                <div>
                  {orderCreator.config.getIn(['brand', 'name']) ?? 'Chọn thương hiệu'}
                </div>
                <FaChevronDown size={13} className={'ms-auto'}/>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {
                app.brands?.map((b: any) => {
                  return (
                    <SectionConfigOptionItem
                      key={`config_brand_${b.get('_id')}`}
                      value={b}
                      label={b.get('name')}
                      onSelect={_onBrandSelected}/>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>
            Cửa hàng
          </h4>
          <Dropdown className={!!orderCreator.config.get('brand') ? '' : 'opacity-50'}>
            <Dropdown.Toggle className={'filter w-100'} variant={'light'} disabled={!orderCreator.config.get('brand')}>
              <div className={'hstack'}>
                <div>
                  {orderCreator.config.getIn(['store', 'name'], 'Chọn cửa hàng')}
                </div>
                <FaChevronDown size={13} className={'ms-auto'}/>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {
                orderCreator.config.getIn(['brand', 'stores'], [])?.map((s: any, index: number) => {
                  return (
                    <SectionConfigOptionItem
                      key={`config_store_${s.get('_id')}_${index}`}
                      value={s}
                      label={s.get('name')}
                      onSelect={_onStoreSelected}/>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>
            Nguồn đơn
          </h4>
          <Dropdown className={!!orderCreator.config.get('sources') ? '' : 'opacity-50'}>
            <Dropdown.Toggle className={'filter w-100'} variant={'light'} disabled={!orderCreator.config.get('sources')}>
              <div className={'hstack'}>
                <div>
                  {orderCreator.config.getIn(['source', 'name']) ?? 'Chọn nguồn đơn'}
                </div>
                <FaChevronDown size={13} className={'ms-auto'}/>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {
                orderCreator.config.getIn(['sources'], [])?.map((s: any) => {
                  return (
                    <SectionConfigOptionItem
                      key={`config_source_${s.get('_id')}`}
                      value={s}
                      label={s.get('name')}
                      onSelect={_onSourceSelected}/>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Row>
  )
}

export default SectionConfig;