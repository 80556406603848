import { fromJS } from 'immutable'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useApp } from '../../components/AdminLayout/admin_provider'
import { Api } from '../../repository/api'

interface BannerCreatorContextType {
  loading: boolean
  banner: any
}
let BannerCreatorContext = React.createContext<BannerCreatorContextType>(null!)

const useBannerCreator = () => {
  return React.useContext(BannerCreatorContext)
}
const BannerCreatorProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [banner, setBanner] = React.useState<any>(fromJS({}))
  const app = useApp()
  const {bannerId} = useParams()

  const _fetchBanner = async () => {
    try {
      if (!bannerId) return
      setLoading(true)
      const res = await Api.banner.fetchBannerDetail(bannerId)
      setLoading(false)
      setBanner(fromJS({ ...res.data.data, brandId: res.data.data?.brand?._id }))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _initData = async () => {
    if (!app.brands) {
      await app.fetchBrand()
    }
  }

  useEffect(() => {
    if (bannerId && !banner.size) {
      _fetchBanner()
    }
  }, [bannerId])

  React.useEffect(() => {
    if (!banner.size && !loading) {
      _initData()
    }
  }, [])

  const value = { loading, banner }
  return <BannerCreatorContext.Provider value={value}>{children}</BannerCreatorContext.Provider>
}
export { useBannerCreator }
export default BannerCreatorProvider
