import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { useApp } from "../../../components/AdminLayout/admin_provider"
import { Api } from "../../../repository/api"
import { BankInfoType } from "./bank_config_modal"
import { BankModalType } from '../../../repository/model/enums/BankModalType'


interface BankConfigContextType {
  loading: boolean
  banks: any
  onSubmit: (bankInfo: BankInfoType, type?: BankModalType) => Promise<any>
}

let BankConfigContext = React.createContext<BankConfigContextType>(null!)

const useBankConfig = () => {
  return React.useContext(BankConfigContext)
}
const BankConfigProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [banks, setBank] = useState<any>(fromJS([]))

  const _getBankList = async () => {
    try {
      setLoading(true)
      const res = await Api.store.fetchBankList()
      setLoading(false)
      setBank(fromJS(res.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onSubmit = async (bankInfo: BankInfoType, type?: BankModalType,) => {
    try {
      const rs = type === BankModalType.hub
        ? await Api.hub.updateBankInfo(bankInfo.id, bankInfo)
        : await Api.store.updateBankInfo(bankInfo.id, bankInfo)

      window.alert('Cập nhật thành công!')
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return null
    }
  }

  useEffect(() => {
    _getBankList()
  }, [])

  const value = { loading, banks, onSubmit }
  return <BankConfigContext.Provider value={value}>{children}</BankConfigContext.Provider>
}
export { useBankConfig }
export default BankConfigProvider
