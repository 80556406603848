import { useState } from "react";
import { TableMode, TableModel, TableStatus } from "../../../repository/model/TableModel";

interface TableItemType {
    table: TableModel
    index: number
    page: number
}

const TableItem = (props: TableItemType) => {
    const [isActive, setActive] = useState<boolean>(props.table.status == TableStatus.active)

    return (
        <tr>
            <td>{(props.page - 1) * 50 + props.index}</td>
            <td>{props.table.name}</td>
            <td>{props.table.tableMode == TableMode.occupied ? 'Đang phục vụ' : 'Bàn trống'}</td>
            <td>
                <div className="form-check form-switch">
                    <input
                        disabled
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={isActive} />
                </div>
            </td>

        </tr>
    );
}

export default TableItem