import { useEffect, useState } from 'react'
import { FormControl, InputGroup, Stack } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'

type TimeProps = {
  handleTimeChanged: (times: Array<string[]>, index: number) => void
  openTimeIndex: number
  timeData: Array<string[]>
}

function Time(props: TimeProps) {
  const [times, setTime] = useState<Array<string[]>>([[]])

  const handleAddTime = () => {
    setTime((prev) => [...prev, []])
  }

  const handleDeleteTime = (index: number) => {
    const newTime = times.filter((el: Array<string>, i: number) => i !== index && el)
    setTime(newTime)
  }

  const onTimeChanged = (index: number, index2: number, value: string) => {
    times[index][index2] = value
    setTime([...times])
  }

  useEffect(() => {
    props.handleTimeChanged(times, props.openTimeIndex)
  }, [times])

  useEffect(() => {
    if (props.timeData.length) {
      setTime(props.timeData)
    }
  }, [])
  return (
    <Stack gap={3}>
      {times.map((item, index) => (
        <InputGroup key={index}>
          <InputGroup.Text id="basic-addon1">Ca: </InputGroup.Text>
          <FormControl
            type="time"
            value={item[0] ?? ''}
            pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
            onChange={(el) => onTimeChanged(index, 0, el.target.value)}
          />
          <div className="mx-2 d-flex align-items-center">{' ~ '}</div>
          <FormControl
            type="time"
            value={item[1] ?? ''}
            pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
            onChange={(el) => onTimeChanged(index, 1, el.target.value)}
          />

          {times.length - 1 > index ? (
            <FaPlus
              className="ms-2 mt-2 btn p-0"
              style={{ transform: 'rotate(45deg)' }}
              color="red"
              onClick={() => handleDeleteTime(index)}
            />
          ) : (
            <FaPlus className="btn text-primary p-0 ms-2 mt-2" onClick={handleAddTime} />
          )}
        </InputGroup>
      ))}
    </Stack>
  )
}

export default Time
