import UserTable from './components/userTable'
import UserProvider from './provider'

const HubUsers: React.FC = () => {
  return (
    <UserProvider>
      <UserTable />
    </UserProvider>
  )
}

export default HubUsers
