import {Pagination, Table} from "react-bootstrap";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaPlus} from "react-icons/fa";
import {useWebsitePromotion} from "../provider";
import {useNavigate} from "react-router-dom";
import {PromotionItem} from "./item";

const PromotionTable: React.FC = () => {
  const {queries, meta, data, onPreviousPage, onFirstPage, onNextPage} = useWebsitePromotion()
  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('create')
  }
  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'d-flex justify-content-between'}>
        <div></div>
        <div>
          <LabelIconBtn label={'Thêm mới'} icon={<FaPlus size={12}/>} onClick={handleCreate}/>
        </div>
      </div>
      <Table striped hover responsive bordered className={'mt-2'}>
        <thead>
        <tr>
          <th>STT</th>
          <th>Mã giảm giá</th>
          <th>Giá trị</th>
          <th>Thời gian áp dụng</th>
          <th>Đối tượng áp dụng</th>
          <th>Trạng thái</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {data?.map((promotion: any, index: number) => {
          return (
            <PromotionItem
              key={promotion.get('_id')}
              index={index}
              promotion={promotion}
              page={queries.get('page')}
            />
          )
        })}
        </tbody>
      </Table>
      <Pagination size={'sm'} className="mt-3">
        <Pagination.Item disabled={queries.get('page') <= 1} onClick={onPreviousPage}>
          Trang trước
        </Pagination.Item>
        {queries.get('page') > 1 && <Pagination.Item onClick={onFirstPage}>1</Pagination.Item>}
        <Pagination.Ellipsis disabled/>
        <Pagination.Item active>{queries.get('page')}</Pagination.Item>
        <Pagination.Ellipsis disabled/>
        <Pagination.Item disabled={meta?.totalPage <= queries.get('page')} onClick={onNextPage}>
          Trang kế
        </Pagination.Item>
      </Pagination>
    </div>
  )
}

export {PromotionTable}