import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { Button, Container, Form, FormControl, Stack } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useNavigate, useParams } from 'react-router'
import BackBtn from '../../../components/Button/back_btn'
import Nav from '../../../components/Navbars/Nav'
import { Api } from '../../../repository/api'
import { deleteInputValue } from '../../../util/myUtil'
import { useBrandUpdater } from '../provider'

const BrandUpdaterForm = () => {
  const {brandId} = useParams()
  const navigate = useNavigate()
  const { brand: editBrand } = useBrandUpdater()
  const [validated, setValidated] = useState(false)

  const [brand, setBrand] = useState<any>(
    fromJS({
      name: '',
      slug: '',
      domain: '',
      logo: '',
      cover: '',
      intro: '',
    })
  )
  const _onBackClicked = () => {
    navigate(-1)
  }

  const onNameChanged = (name: string) => {
    setBrand(brand.updateIn(['name'], () => name))
  }

  const onSlugChanged = (slug: string) => {
    setBrand(brand.updateIn(['slug'], () => slug))
  }

  const onDomainChanged = (domain: string) => {
    setBrand(brand.updateIn(['domain'], () => domain))
  }

  const onIntroChanged = (intro: string) => {
    setBrand(brand.updateIn(['intro'], () => intro))
  }

  const onLogoChanged = (logo: File) => {
    setBrand(brand.updateIn(['logo'], () => logo))
  }

  const onCoverChanged = (cover: File) => {
    setBrand(brand.updateIn(['cover'], () => cover))
  }

  const handleSubmit = async (event: any) => {
    try {
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setValidated(true)
        return
      }

      const requestBody = { ...brand.toJS() }

      if (typeof brand.get('logo') == 'object') {
        const resLogo = await Api.uploadImage(brand.get('logo'))
        requestBody.logo = resLogo.data.data.url
      }

      if (typeof brand.get('cover') == 'object') {
        const resCover = await Api.uploadImage(brand.get('cover'))
        requestBody.cover = resCover.data.data.url
      }

      brandId ? await Api.brand.editBrand(requestBody) : await Api.brand.createBrand(requestBody)

      window.alert((brandId ? 'Cập nhật' : 'Tạo') + ' Thương hiệu thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  useEffect(() => {
    if (brandId) {
      setBrand(editBrand)
    }
  }, [editBrand, brandId])

  return (
    <main>
      <Nav hideToggle={!!brandId} title={`${brandId ? 'Sửa' : 'Tạo'} Thương hiệu`} />
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Quay lại'} />
      </Container>
      <Container className="mb-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
            <Stack className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>
                  Tên Thương hiệu <span className="text-danger fs-5">*</span>
                </Form.Label>
                <FormControl
                  required
                  type="text"
                  value={brand.get('name', '')}
                  onChange={(el) => onNameChanged(el.target.value)}
                />
              </Form.Group>

              {brandId && (
                <Form.Group className="mb-3">
                  <Form.Label>Slug</Form.Label>
                  <FormControl
                    type="text"
                    value={brand.get('slug', '')}
                    onChange={(el) => onSlugChanged(el.target.value)}
                  />
                </Form.Group>
              )}

              <Form.Group className="mb-3">
                <Form.Label>Domain</Form.Label>
                <FormControl
                  type="text"
                  value={brand.get('domain', '')}
                  onChange={(el) => onDomainChanged(el.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Giới thiệu</Form.Label>
                <FormControl
                  type="text"
                  as="textarea"
                  rows={3}
                  value={brand.get('intro')}
                  onChange={(el) => onIntroChanged(el.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3 col-lg-4 col-sm-12">
                <Form.Label>Logo (tối đa 1MB)</Form.Label>
                <FormControl
                  className="mb-3"
                  type="file"
                  id="uploadLogo"
                  onChange={(el: any) => onLogoChanged(el.target?.files[0])}
                />
                {brand.get('logo') && (
                  <div className="position-relative">
                    <img
                      src={
                        typeof brand.get('logo') == 'string'
                          ? brand.get('logo')
                          : URL.createObjectURL(brand.get('logo'))
                      }
                      alt={brand.get('logo').name}
                      className="btn p-0"
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() =>
                        window.open(
                          typeof brand.get('logo') == 'string'
                            ? brand.get('logo')
                            : URL.createObjectURL(brand.get('logo'))
                        )
                      }
                    />
                    <TiDelete
                      fontSize={25}
                      cursor="pointer"
                      className="position-absolute"
                      style={{ right: 1 }}
                      onClick={() => {
                        setBrand(brand.updateIn(['logo'], () => undefined))
                        deleteInputValue('uploadLogo')
                      }}
                    />
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3 col-lg-4 col-sm-12">
                <Form.Label>Cover (tối đa 1MB)</Form.Label>

                <FormControl
                  className="mb-3"
                  type="file"
                  id="uploadCover"
                  onChange={(el: any) => onCoverChanged(el.target.files[0])}
                />
                {brand.get('cover') && (
                  <div className="position-relative">
                    <img
                      src={
                        typeof brand.get('cover') == 'string'
                          ? brand.get('cover')
                          : URL.createObjectURL(brand.get('cover'))
                      }
                      alt={brand.get('cover').name}
                      className="btn p-0"
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() =>
                        window.open(
                          typeof brand.get('cover') == 'string'
                            ? brand.get('cover')
                            : URL.createObjectURL(brand.get('cover'))
                        )
                      }
                    />
                    <TiDelete
                      fontSize={25}
                      cursor="pointer"
                      className="position-absolute"
                      style={{ right: 1 }}
                      onClick={() => {
                        setBrand(brand.updateIn(['cover'], () => undefined))
                        deleteInputValue('uploadCover')
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </Stack>

            <div className="d-flex justify-content-start">
              <Button
                variant="primary"
                type="submit"
                className={'mt-4 d-grid gap-2 btn btn-primary'}
              >
                {brandId ? 'Cập nhật' : 'Tạo Thương hiệu'}
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </main>
  )
}

export default BrandUpdaterForm
