import React from "react";
import {sourceInfo} from "../../../util/common_utils";
import {useAutoCrawlOrderJobs} from "../provider";

interface CrawlerItemType {
  crawler: any,
  index: number
}

const CrawlerItem = (props: CrawlerItemType) => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const provider = useAutoCrawlOrderJobs();

  const source: any = sourceInfo(props.crawler.get('name'), props.crawler.get('name'));
  const isAuto: boolean = !!props.crawler.get('autoPull')

  const _onAutoPullChanged = async (e: any) => {
    setLoading(true)
    const rs = await provider.saveCrawler(props.crawler, !isAuto)
    setLoading(false)
  }

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>
        {props.crawler.getIn(['brand', 'name'])}
      </td>
      <td>
        {props.crawler.getIn(['store', 'name'])}
      </td>
      <td>
        <div className={'highlight-value'} style={{color: source.color}}>
          {source.label}
        </div>
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            disabled={loading}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={_onAutoPullChanged}
            checked={isAuto}/>
        </div>
      </td>
    </tr>
  )
}

export default CrawlerItem;