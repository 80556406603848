import moment from "moment";
import React from "react";
import {useNavigate} from "react-router";

interface ClosedLogItemType {
  log: any,
  index: number,
  page: number
}

const ClosedLogItem = (props: ClosedLogItemType) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        {props.log.getIn(['foodApp'])}
      </td>
      <td>
        {props.log.getIn(['name'])}
      </td>
      <td>
        {props.log.getIn(['status'])}
      </td>
      <td>
        {props.log.getIn(['note'])}
      </td>
      <td>
        {moment(props.log.get('created')).local().format('HH:mm - DD/MM/YYYY')}
      </td>
    </tr>
  )
}

export default ClosedLogItem;