import {fromJS} from 'immutable'
import {useEffect, useState} from 'react'
import {
  Button,
  Container,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
} from 'react-bootstrap'
import {TiDelete} from 'react-icons/ti'
import {useNavigate, useParams} from 'react-router'
import {useMenuItemCreator} from "../provider";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import BackBtn from "../../../components/Button/back_btn";
import Nav from "../../../components/Navbars/Nav";
import {MENU_ITEM_TYPE, STATUS, STATUS_LABEL} from "../../../util/constant";
import Item from "../components/childMenuItem";
import Categories from "../components/category";
import Options from "../components/option";
import {deleteInputValue} from "../../../util/myUtil";
import {Api} from "../../../repository/api";
import ItemTypeSelect from "./item_type_select";

const Views: React.FC = () => {
  const [menuItem, setMenuItem] = useState<any>(fromJS({}))
  const [validated, setValidated] = useState<boolean>(false)
  const useCreatorProvider = useMenuItemCreator()
  const params = useParams();
  const app = useApp()
  const navigate = useNavigate()
  const isEditing = !!params.itemId
  const isWebsiteItem = !!params.websiteId

  const _onBackClicked = () => {
    navigate(-1)
  }

  const handleBrandChanged = (brandId: string) => {
    useCreatorProvider.onBrandChanged({_id: brandId})
    if (brandId === useCreatorProvider.menuItem.getIn(['brand', '_id'])) {
      setMenuItem(
        menuItem.updateIn(['brandId'], () => brandId)
          .updateIn(['items'], () => useCreatorProvider.menuItem.get('items') ?? fromJS([]))
          .updateIn(['categories'], () => useCreatorProvider.menuItem.get('categories', fromJS([])))
          .updateIn(['options'], () => useCreatorProvider.menuItem.get('options', fromJS([])))
          .updateIn(['unit'], () => useCreatorProvider.menuItem.get('unit', ''))
      )
    } else {
      setMenuItem(
        menuItem.updateIn(['brandId'], () => brandId)
          .updateIn(['items'], () => fromJS([]))
          .updateIn(['categories'], () => fromJS([]))
          .updateIn(['options'], () => fromJS([]))
          .updateIn(['unit'], () => '')
      )
    }
  }

  const handleStoreChanged = (storeId: string) => {
    useCreatorProvider.onStoreChanged({_id: storeId})
    if (storeId === useCreatorProvider.menuItem.getIn(['store', '_id'])) {
      setMenuItem(
        menuItem.updateIn(['storeId'], () => storeId)
          .updateIn(['items'], () => useCreatorProvider.menuItem.get('items') ?? fromJS([]))
          .updateIn(['categories'], () => useCreatorProvider.menuItem.get('categories', fromJS([])))
          .updateIn(['options'], () => useCreatorProvider.menuItem.get('options', fromJS([])))
          .updateIn(['unit'], () => useCreatorProvider.menuItem.get('unit', ''))
      )
    } else {
      setMenuItem(
        menuItem.updateIn(['storeId'], () => storeId)
          .updateIn(['items'], () => fromJS([]))
          .updateIn(['categories'], () => fromJS([]))
          .updateIn(['options'], () => fromJS([]))
          .updateIn(['unit'], () => '')
      )
    }
  }

  const handleSourceChanged = (sourceId: string) => {
    useCreatorProvider.onSourceChanged({_id: sourceId})
    if (sourceId === useCreatorProvider.menuItem.getIn(['source', '_id'])) {
      setMenuItem(
        menuItem.updateIn(['sourceId'], () => sourceId)
          .updateIn(['items'], () => useCreatorProvider.menuItem.get('items') ?? fromJS([]))
          .updateIn(['categories'], () => useCreatorProvider.menuItem.get('categories', fromJS([])))
          .updateIn(['options'], () => useCreatorProvider.menuItem.get('options', fromJS([])))
          .updateIn(['unit'], () => useCreatorProvider.menuItem.get('unit', ''))
      )
    } else {
      setMenuItem(
        menuItem.updateIn(['sourceId'], () => sourceId)
          .updateIn(['items'], () => fromJS([]))
          .updateIn(['categories'], () => fromJS([]))
          .updateIn(['options'], () => fromJS([]))
          .updateIn(['unit'], () => '')
      )
    }
  }

  const onNameChanged = (name: string) => {
    setMenuItem(menuItem.updateIn(['name'], () => name))
  }

  const onItemTypeChanged = (type: string) => {
    useCreatorProvider.setItemType(type)
    setMenuItem(menuItem.updateIn(['itemType'], () => type))
  }

  const onImageChanged = (image: File) => {
    setMenuItem(menuItem.updateIn(['image'], () => image))
  }

  const onDescriptionChanged = (description: string) => {
    setMenuItem(menuItem.updateIn(['description'], () => description))
  }

  const onPriceChanged = (price: string) => {
    setMenuItem(menuItem.updateIn(['price'], () => price))
  }

  const onUnitChanged = (unit: string) => {
    setMenuItem(menuItem.updateIn(['unit'], () => unit))
  }

  const onStatusChanged = (status: string) => {
    setMenuItem(menuItem.updateIn(['status'], () => status))
  }

  const onNoteChanged = (note: string) => {
    setMenuItem(menuItem.updateIn(['note'], () => note))
  }

  const onSKUChanged = (sku: string) => {
    setMenuItem(menuItem.updateIn(['sku'], () => sku))
  }

  const handleAddCategory = (category: any) => {
    setMenuItem(
      menuItem.updateIn(['categories'], () => menuItem.get('categories', fromJS([])).push(category))
    )
  }

  const handleDeleteCategory = (category: any) => {
    setMenuItem(
      menuItem.updateIn(['categories'], (e: any) =>
        e.filter((c: any) => c.get('_id') !== category.get('_id'))
      )
    )
  }

  const handleAddOption = (option: any) => {
    setMenuItem(
      menuItem.updateIn(['options'], () => menuItem.get('options', fromJS([])).push(option))
    )
  }

  const handleDeleteOption = (option: any) => {
    setMenuItem(
      menuItem.updateIn(['options'], (e: any) =>
        e.filter((c: any) => c.get('_id') !== option.get('_id'))
      )
    )
  }

  const handleAddItem = (item: any) => {
    setMenuItem(menuItem.updateIn(['items'], () => menuItem.get('items', fromJS([])).push(item)))
  }

  const handleDeleteItem = (item: any) => {
    setMenuItem(
      menuItem.updateIn(['items'], (e: any) =>
        e.filter((c: any) => c.get('_id') !== item.get('_id'))
      )
    )
  }

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
      return
    }

    const data = {...menuItem.toJS()}

    if (isWebsiteItem) {
      if (!useCreatorProvider.websiteData.getIn(['source', '_id'])) {
        window.alert('Không tìm thấy Nguồn đơn cho website, vui lòng thử lại')
        return
      }
      data.sourceId = useCreatorProvider.websiteData.getIn(['source', '_id'])
    }

    data.categoryIds = data.categories?.map((c: any) => c._id) || []
    data.optionIds = data.options?.map((o: any) => o._id) || []
    data.itemIds = data.items?.map((it: any) => it._id) || []

    if (data.itemIds.length < 2 && data.itemType === MENU_ITEM_TYPE.COMBO) {
      window.alert('Chọn tối thiểu 2 món trong combo!')
      return
    }

    if (typeof menuItem.get('image') == 'object') {
      const res = await Api.uploadImage(menuItem.get('image'))
      data.image = res.data.data.url
      setMenuItem(menuItem.updateIn(['image'], () => res.data.data.url))
    }

    useCreatorProvider.menuItem?.size ? useCreatorProvider.onEdit(data) : useCreatorProvider.onCreate(data)
  }

  useEffect(() => {
    if (useCreatorProvider.menuItem.size) {
      setMenuItem(
        useCreatorProvider.menuItem.updateIn(['brandId'], () => useCreatorProvider.menuItem.getIn(['brand', '_id']))
          .updateIn(['storeId'], () => useCreatorProvider.menuItem.getIn(['store', '_id']))
          .updateIn(['sourceId'], () => useCreatorProvider.menuItem.getIn(['source', '_id']))
      )
    }
  }, [useCreatorProvider.menuItem])

  return (
    <main>
      <Nav title={isEditing ? 'Sửa thực đơn' : 'Thêm thực đơn'}/>
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Thực đơn'}/>
        {!menuItem.get('itemType', '') ?
          (
          <ItemTypeSelect onChanged={onItemTypeChanged}/>
        ) : (
          <div className="mt-2 border-bottom pt-3 pb-3 shadow-card mb-3">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

              <div className="gap-3 d-flex flex-sm-row flex-column">
                <Form.Group className="mb-3 col-12 col-md-3">
                  <FormLabel>
                    Thương hiệu <span className="text-danger fs-5">*</span>
                  </FormLabel>
                  <FormSelect
                    required
                    value={menuItem.get('brandId')}
                    disabled={isEditing}
                    onChange={(el) => handleBrandChanged(el.target.value)}
                  >
                    <option value="">Chọn thương hiệu</option>
                    {(isWebsiteItem ? useCreatorProvider.websiteData.getIn(['website', 'subBrands']) : app.brands)?.map((brand: any, i: number) => (
                      <option key={brand.get('_id', '')} value={brand.get('_id', '')}>
                        {brand.get('name')}
                      </option>
                    ))}
                  </FormSelect>
                </Form.Group>

                {
                  !isWebsiteItem &&
                  <>
                    <Form.Group className="mb-3 col-12 col-md-3">
                      <FormLabel>
                        Cửa hàng <span className="text-danger fs-5">*</span>
                      </FormLabel>
                      <FormSelect
                        required
                        value={menuItem.get('storeId')}
                        disabled={useCreatorProvider.initStores.size < 1 || isEditing}
                        onChange={(el) => handleStoreChanged(el.target.value)}
                      >
                        <option value="">Chọn cửa hàng</option>
                        {useCreatorProvider.initStores.map((store: any, i: number) => (
                          <option key={store.get('_id', '')} value={store.get('_id', '')}>
                            {store.get('name')}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-md-3">
                      <FormLabel>
                        Nguồn đơn <span className="text-danger fs-5">*</span>
                      </FormLabel>
                      <FormSelect
                        required
                        value={menuItem.get('sourceId')}
                        disabled={useCreatorProvider.initSources.size < 1 || isEditing}
                        onChange={(el) => handleSourceChanged(el.target.value)}
                      >
                        <option value="">Chọn nguồn đơn</option>
                        {useCreatorProvider.initSources.map((source: any, i: number) => (
                          <option key={source.get('_id', '')} value={source.get('_id', '')}>
                            {source.get('name')}
                          </option>
                        ))}
                      </FormSelect>
                    </Form.Group>
                  </>
                }
                {
                  !!isWebsiteItem &&
                  <Form.Group className="mb-3 col-12 col-md-3">
                    <FormLabel>
                      Nguồn đơn <span className="text-danger fs-5">*</span>
                    </FormLabel>
                    <FormSelect
                      required
                      value={''}
                      disabled={true}
                    >
                      <option value="">{useCreatorProvider.websiteData.getIn(['source', 'name'])}</option>
                    </FormSelect>
                  </Form.Group>
                }
              </div>

              {
                (
                  (!isWebsiteItem && menuItem.get('sourceId')) || (isWebsiteItem && menuItem.get('brandId'))
                )
                &&
                <>
                  <Form.Group className="mb-3">
                    <FormLabel>
                      Tên món <span className="text-danger fs-5">*</span>
                    </FormLabel>
                    <FormControl
                      type="text"
                      required
                      value={menuItem.get('name', '')}
                      onChange={(e) => onNameChanged(e.target.value)}
                    />
                  </Form.Group>

                  <div className="gap-3 d-flex flex-sm-row flex-column">
                    <Form.Group className="mb-3 col-12 col-md-3">
                      <FormLabel>
                        Đơn giá <span className="text-danger fs-5">*</span>
                      </FormLabel>
                      <FormControl
                        required
                        type="number"
                        value={menuItem.get('price', '')}
                        onChange={(e) => onPriceChanged(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-md-3">
                      <FormLabel>
                        Đơn vị <span className="text-danger fs-5">*</span>
                      </FormLabel>
                      <FormSelect required value={menuItem.get('unit')} onChange={(e) => onUnitChanged(e.target.value)}>
                        <option value="">Chọn đơn vị</option>
                        {useCreatorProvider.initUnit.size ? (
                          useCreatorProvider.initUnit.map((unit: any, i: number) => (
                            <option key={i} value={unit.get('name', '')}>
                              {unit.get('name')}
                            </option>
                          ))
                        ) : (
                          <option disabled>
                            {!useCreatorProvider.queries.getIn(['brand', '_id'], '')
                              ? 'Vui lòng chọn thương hiệu!'
                              : 'Không có dữ liệu!'}
                          </option>
                        )}
                      </FormSelect>
                    </Form.Group>

                    <Form.Group className="mb-3 col-12 col-md-3">
                      <FormLabel>
                        Trạng thái <span className="fs-5"/>
                      </FormLabel>
                      <FormSelect required value={menuItem.get('status', STATUS.ACTIVE)}
                                  onChange={(e) => onStatusChanged(e.target.value)}>
                        <option value="">Chọn đơn vị</option>
                        <option value={STATUS.ACTIVE}>
                          {STATUS_LABEL.ACTIVE}
                        </option>
                        <option value={STATUS.INACTIVE}>
                          {STATUS_LABEL.INACTIVE}
                        </option>
                      </FormSelect>
                    </Form.Group>
                  </div>

                  {menuItem.get('itemType', '') === MENU_ITEM_TYPE.COMBO && (
                    <Form.Group className="mb-3">
                      <FormLabel>
                        Các món trong Combo <span className="text-danger fs-5">*</span>
                      </FormLabel>
                      <Item
                        items={menuItem.get('items', [])}
                        onChange={handleAddItem}
                        onDelete={handleDeleteItem}
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="mb-3">
                    <FormLabel>Mô tả</FormLabel>
                    <FormControl
                      as="textarea"
                      rows={3}
                      value={menuItem.get('description', '')}
                      onChange={(e) => onDescriptionChanged(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <FormLabel>Nhóm món</FormLabel>
                    <Categories
                      brand={menuItem.get('brandId', '')}
                      store={menuItem.get('storeId')}
                      source={menuItem.get('sourceId')}
                      categories={menuItem.get('categories', [])}
                      onChange={handleAddCategory}
                      onDelete={handleDeleteCategory}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 mt-4">
                    <FormLabel>Tùy chọn</FormLabel>
                    <Options
                      brand={menuItem.get('brandId', '')}
                      options={menuItem.get('options', [])}
                      onChange={handleAddOption}
                      onDelete={handleDeleteOption}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3 col-lg-4 col-sm-12">
                    <FormLabel>Ảnh món (tối đa 1MB)</FormLabel>
                    <FormControl
                      type="file"
                      className="mb-3"
                      id="uploadImage"
                      onChange={(e: any) => onImageChanged(e.target.files[0])}
                    />
                    {menuItem.get('image') && (
                      <div className="position-relative">
                        <img
                          src={
                            typeof menuItem.get('image') == 'string'
                              ? menuItem.get('image')
                              : URL.createObjectURL(menuItem.get('image'))
                          }
                          alt={menuItem.get('image').name}
                          className="btn p-0"
                          style={{width: '100%', height: 'auto'}}
                          onClick={() =>
                            window.open(
                              typeof menuItem.get('image') == 'string'
                                ? menuItem.get('image')
                                : URL.createObjectURL(menuItem.get('image'))
                            )
                          }
                        />
                        <TiDelete
                          fontSize={25}
                          cursor="pointer"
                          className="position-absolute"
                          style={{right: 1}}
                          onClick={() => {
                            setMenuItem(menuItem.updateIn(['image'], () => undefined))
                            deleteInputValue('uploadImage')
                          }}
                        />
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <FormLabel>Ghi chú</FormLabel>
                    <FormControl
                      as="textarea"
                      rows={3}
                      value={menuItem.get('note', '')}
                      onChange={(e) => onNoteChanged(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <FormLabel>Mã món (SKU)</FormLabel>
                    <FormControl
                      type="text"
                      value={menuItem.get('sku', '')}
                      onChange={(e) => onSKUChanged(e.target.value)}
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-start">
                    <Button
                      variant="success"
                      type="submit"
                      className={'mt-4 d-grid gap-2 btn btn-primary'}
                    >
                      { isEditing ? 'Cập nhật' : 'Tạo món' }
                    </Button>
                  </div>
                </>
              }
            </Form>
          </div>
        )}
      </Container>
    </main>
  )
}

export default Views
