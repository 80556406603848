import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";

interface CrmContextType {
  queries: any,
  data: any,
  loading: boolean,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let CrmContext = React.createContext<CrmContextType>(null!);

const useCrm = () => {
  return React.useContext(CrmContext);
};

function CrmProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({
    page: 1
  }));
  const [data, setData] = React.useState<any>(fromJS([]));
  const [loading, setLoading] = React.useState(false);

  const _refresh = async() => {
    setLoading(true);

    const rs = await Api.crm.fetchCustomers(queries.get('page', 1));

    setData(fromJS(rs.data.data))

    setLoading(false)
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  React.useEffect(() => {
    _refresh();
  }, []);

  React.useEffect(() => {
    if (queries.size < 1) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <CrmContext.Provider value={value}>{children}</CrmContext.Provider>;
}

export default CrmProvider;
export { useCrm };