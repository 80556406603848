import {Button, Form, FormControl, InputGroup, Modal} from "react-bootstrap";
import React, {ChangeEvent} from "react";
import {FaLock, FaUser} from "react-icons/fa";
import {useFoodAppConnect} from "../provider";
import {foodApps} from "../../../util/common_utils";

interface ModalUpdateAccountType {
  providerName: string;
  show: boolean;
  onClose: () => void;
}

const ModalUpdateAccount = (props: ModalUpdateAccountType) => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUserName] = React.useState<any>('');
  const [password, setPassword] = React.useState<any>('');
  const foodAppConnectProvider = useFoodAppConnect();

  React.useEffect(() => {
    const account = foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName);
    setUserName(account?.get('username', '') ?? '')
    setPassword(account?.get('password', '') ?? '')
  }, [foodAppConnectProvider.accounts?.find((acc: any) => acc.get('name') === props.providerName)])

  const _onClose = () => {
    if (loading) return;
    props.onClose();
  }

  const _onSubmitClicked = async () => {
    if (!username || username.trim().length < 1 || !password || password.trim().length < 1) {
      const confirm = window.confirm('Tên đăng nhập hoặc mật khẩu còn trống, bạn có chắc chắn muốn cập nhật thông tin tài khoản?')
      if (!confirm) return;
    }

    setLoading(true);
    let rs: any
    if (props.providerName === foodApps.baemin) {
      rs = await foodAppConnectProvider.connectBaeminAccount(username, password)
    } else if (props.providerName === foodApps.grab) {
      rs = await foodAppConnectProvider.connectGrabAccount(username, password)
    }
    setLoading(false);
    if (rs) {
      window.alert(`Cập nhật tài khoản ${props.providerName?.toUpperCase()} cho cửa hàng ` + foodAppConnectProvider.store?.get('name') + ' thành công!')
      props.onClose();
    }
  }

  const _onUserChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent: any = e.nativeEvent;
    if (!nativeEvent['inputType']) return;
    setUserName(e.currentTarget.value);
  }

  const _onPasswordChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent: any = e.nativeEvent;
    if (!nativeEvent['inputType']) return;
    setPassword(e.currentTarget.value);
  }

  return (
    <Modal show={props.show} onHide={_onClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Kết nối {props.providerName?.toUpperCase()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={_onSubmitClicked}  autoComplete='off'>
          <InputGroup className="mb-3 mt-3">
            <InputGroup.Text>
              <FaUser/>
            </InputGroup.Text>
            <FormControl
              placeholder="Tên đăng nhập"
              aria-label="Tên đăng nhập"
              disabled={loading}
              value={username}
              autoComplete="off"
              onChange={_onUserChanged}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaLock/>
            </InputGroup.Text>
            <FormControl
              placeholder="Mật khẩu"
              aria-label="Password"
              type={'password'}
              value={password}
              disabled={loading}
              onChange={_onPasswordChanged}
            />
          </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose} disabled={loading}>
          Đóng
        </Button>
        <button className={`btn btn-${props.providerName}`} onClick={_onSubmitClicked} disabled={loading}>
          Kết nối
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUpdateAccount;