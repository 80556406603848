import React from "react";
import {FaDatabase} from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {Colors} from "../../../util/color_utils";
import {useNavigate} from "react-router";
import {useOrders} from "../provider";

interface PlatformOrderBtnType {
  provider: any;
}

const PlatformOrderBtn = (props: PlatformOrderBtnType) => {

  const ordersProvider = useOrders();
  const navigate = useNavigate();

  const _onClicked = () => {
    const name = props.provider.get('name', '').toLowerCase();
    navigate(`/order/${name}/platform`, {
      state: {
        brand: ordersProvider.queries.get('brand')?.toJS(),
        store: ordersProvider.queries.get('store')?.toJS(),
        provider: props.provider.get('name', '')
      }
    });
  }

  const _getInfo = () => {
    const name = props.provider.get('name', '').toLowerCase();
    if (name.includes('grab')) {
      return {
        label: 'Grab',
        color: Colors.Grab
      }
    }
    if (name.includes('shopee') || name.includes('now')) {
      return {
        label: 'Shopee Food',
        color: Colors.Shopee
      }
    }
    if (name.includes('baemin')) {
      return {
        label: 'Baemin',
        color: Colors.Baemin
      }
    }
    return null
  }

  const _info = _getInfo();

  if (!_info) return null;

  return (
    <span className={'me-2'}>
      <LabelIconBtn
        label={`Lấy đơn ${_info.label}`}
        icon={<FaDatabase size={12} color={_info.color}/>}
        color={_info.color}
        onClick={_onClicked}/>
    </span>
  )
}

export default PlatformOrderBtn;