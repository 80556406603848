import { AxiosResponse } from 'axios'
import { _delete, _get, _post, _put } from './request'
import { BankInfoType } from '../../views/Stores/BankConfig/bank_config_modal'

export const HubApi = {
  fetchHubList(page: number, hub?: string, limit: number = 20): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/hubs`, {
      search: hub,
      page: page,
      limit
    })
  },
  createHub(hub: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/hubs`, hub)
  },
  editHub(hubId: string, hub: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/hubs/${hubId}`, hub)
  },
  updateBankInfo(id: string, bankInfo: BankInfoType): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/hubs/${id}/bank`, bankInfo)
  },
  deleteHub(hubId: any): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/hubs/${hubId}`)
  },
  fetchHubDetail(hubId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/hubs/${hubId}`)
  },
  deleteUserOnHub(hubId: string, userId: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/hubs/${hubId}/user/${userId}`)
  },
  fetchUser(phone?: string): Promise<AxiosResponse<any>> {
    return _get(`user/v1/users`, {
      search: phone
    })
  },
  addUserToHub(hubId: string, body: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/hubs/${hubId}/user`, body)
  },
  EditUserToHub(
    hubId: string,
    userId: string,
    body: { role: string[] }
  ): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/hubs/${hubId}/user/${userId}`, body)
  }
}
