import {
  Button,
  Container, Dropdown,
  DropdownButton,
  Form,
  FormControl,
  FormLabel,
  FormSelect,
  InputGroup, Row,
} from "react-bootstrap";
import Nav from "../../../components/Navbars/Nav";
import BackBtn from "../../../components/Button/back_btn";
import React, {FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {fromJS} from "immutable";
import {PROMOTION_TYPE, PROMOTION_TYPE_LABEL, STATUS, STATUS_LABEL} from "../../../util/constant";
import {useWebsitePromotionCreator} from "../provider";
import moment from "moment/moment";

const PromotionForm: React.FC = () => {
  const navigate = useNavigate()
  const {websiteId, promotionId} = useParams()
  const [validated, setValidated] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<any>(fromJS({
    type: 'fixed',
    minPrice: 0
  }))
  const isEditing = !!promotionId

  const provider = useWebsitePromotionCreator()

  const _onBackClicked = () => {
    navigate(-1)
  }

  const onNameChanged = (name: string) => {
    setInputValue(inputValue.updateIn(['name'], () => name))
  }

  const onCodeChanged = (code: string) => {
    setInputValue(inputValue.updateIn(['code'], () => code.toUpperCase()))
  }

  const onStatusChanged = (status: string) => {
    setInputValue(inputValue.updateIn(['status'], () => status))
  }

  const onTypeChanged = (type: string) => {
    setInputValue(inputValue.updateIn(['type'], () => type)
      .updateIn(['discountValue'], (e: number) => type === PROMOTION_TYPE.PERCENTAGE && e > 100 ? 0 : e))
  }

  const onDiscountValueChanged = (discountValue: number) => {
    if (inputValue.get('type') === PROMOTION_TYPE.PERCENTAGE && discountValue > 100) {
      return
    }
    setInputValue(inputValue.updateIn(['discountValue'], () => Number(discountValue.toString().replace(/\D/g, ''))))
  }

  const onQuantityChanged = (quantity: number) => {
    setInputValue(inputValue.updateIn(['quantity'], () => Number(quantity.toString().replace(/\D/g, ''))))
  }

  const onStartTimeChanged = (time: string) => {
    setInputValue(inputValue.updateIn(['startTime'], () => time))
  }

  const onEndTimeChanged = (time: string) => {
    if (inputValue.get('startTime') && moment(time).diff(inputValue.get('startTime')) < 0) {
      window.alert('Thời gian không đúng!')
      return
    }
    setInputValue(inputValue.updateIn(['endTime'], () => time))
  }

  const onMinPriceChanged = (minPrice: number) => {
    setInputValue(inputValue.updateIn(['minPrice'], () => Number(minPrice.toString().replace(/\D/g, ''))))
  }

  const onMaxDiscountPriceChanged = (price: number) => {
    setInputValue(inputValue.updateIn(['maxDiscount'], () => Number(price.toString().replace(/\D/g, ''))))
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (!form.checkValidity()) {
      setValidated(true)
      return
    }

    const bodyReq = inputValue.toJS()

    promotionId ? provider.onEdit(bodyReq) : provider.onCreate(bodyReq)
  }

  useEffect(() => {
    if (provider.promotion.size > 0 && promotionId) {
      setInputValue(
        provider.promotion
      )
    }
  }, [promotionId, provider.promotion])

  return <main>
    <Nav title="Tạo Mã giảm giá"/>
    <Container>
      <BackBtn onBackClicked={_onBackClicked} label={'Mã giảm giá'}/>
    </Container>
    <Container className='mb-4'>
      <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
        <Form autoComplete="off" noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <FormLabel>
              Tên mã giảm giá <span className="text-danger fs-5">*</span>
            </FormLabel>
            <FormControl
              type="text"
              required
              value={inputValue.get('name', '')}
              disabled={provider.loading}
              onChange={(e) => onNameChanged(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <FormLabel>
              Code <span className="text-danger fs-5">*</span>
            </FormLabel>
            <FormControl
              type="text"
              required
              disabled={isEditing || provider.loading}
              value={inputValue.get('code', '')}
              onChange={(e) => onCodeChanged(e.target.value)}
            />
          </Form.Group>

          <Row className={'mt-4'}>
            <Form.Group className="mb-3 col-12 col-md-4">
              <FormLabel>
                Giá trị giảm giá <span className="text-danger">*</span>
              </FormLabel>
              <InputGroup>
                <DropdownButton
                  title={inputValue.get('type', PROMOTION_TYPE.FIXED) === PROMOTION_TYPE.FIXED ? PROMOTION_TYPE_LABEL.FIXED : PROMOTION_TYPE_LABEL.PERCENTAGE}
                  variant="outline-secondary">
                  <Dropdown.Item href="#" onClick={() => onTypeChanged(PROMOTION_TYPE.FIXED)}>
                    {PROMOTION_TYPE_LABEL.FIXED}
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => onTypeChanged(PROMOTION_TYPE.PERCENTAGE)}>
                    {PROMOTION_TYPE_LABEL.PERCENTAGE}
                  </Dropdown.Item>
                </DropdownButton>
                <FormControl
                  type="text"
                  required
                  disabled={provider.loading}
                  value={inputValue.get('discountValue', '')}
                  onChange={(e) => onDiscountValueChanged(Number(e.target.value))}
                />
                <InputGroup.Text>{inputValue.get('type') === PROMOTION_TYPE.FIXED ? 'đ' : '%'}</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group 
              className={
                inputValue.get('type') === PROMOTION_TYPE.FIXED ? "mb-3 col-12 col-md-4" : "mb-3 col-12 col-md-3"
              }>
              <FormLabel>
                Giá trị Đơn hàng tối thiểu
              </FormLabel>
              <InputGroup>
                <FormControl
                  type="text"
                  required
                  disabled={provider.loading}
                  value={inputValue.get('minPrice')}
                  onChange={(e) => onMinPriceChanged(Number(e.target.value))}
                />
                <InputGroup.Text>đ</InputGroup.Text>
              </InputGroup>
            </Form.Group>

            {
              inputValue.get('type') !== PROMOTION_TYPE.FIXED &&
              <Form.Group className={"mb-3 col-12 col-md-3"}>
                <FormLabel>
                  Giảm tối đa
                </FormLabel>
                <InputGroup>
                  <FormControl
                    type="text"
                    required
                    disabled={provider.loading}
                    value={inputValue.get('maxDiscount')}
                    onChange={(e) => onMaxDiscountPriceChanged(Number(e.target.value))}
                  />
                  <InputGroup.Text>đ</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            }

            <Form.Group className="mb-3 col-12 col-md-4">
              <FormLabel>
                Số lượng mã <span className="text-danger">*</span>
              </FormLabel>
              <FormControl
                type="text"
                required
                disabled={provider.loading}
                value={inputValue.get('quantity')}
                onChange={(e) => onQuantityChanged(Number(e.target.value))}
              />
            </Form.Group>
          </Row>

          <Row className={'mt-4'}>
            <Form.Group className="mb-3 col-12 col-md-8">
              <FormLabel>
                Thời gian hiệu lực <span className="text-danger fs-5">*</span>
              </FormLabel>
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputGroup>
                    <InputGroup.Text>Bắt đầu</InputGroup.Text>
                    <FormControl
                      required
                      disabled={provider.loading}
                      type='datetime-local'
                      min={provider.promotion?.get('startTime') ? moment(provider.promotion.get('startTime')).format('YYYY-MM-DDTHH:mm') : moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                      value={promotionId ? moment(inputValue.get('startTime')).format('YYYY-MM-DDTHH:mm') : inputValue.get('startTime')}
                      onChange={(e) => onStartTimeChanged(e.target.value)}
                    />
                  </InputGroup>
                </div>

                <div className="col-12 col-md-6">
                  <InputGroup>
                    <InputGroup.Text>Kết thúc</InputGroup.Text>
                    <FormControl
                      required
                      disabled={provider.loading}
                      type='datetime-local'
                      min={moment(inputValue.get('startTime') ?? new Date()).format('YYYY-MM-DDTHH:mm')}
                      value={promotionId ? moment(inputValue.get('endTime')).format('YYYY-MM-DDTHH:mm') : inputValue.get('endTime')}
                      onChange={(e) => onEndTimeChanged(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 col-12 col-md-4">
              <FormLabel>
                Trạng thái <span className="text-danger fs-5">*</span>
              </FormLabel>
              <FormSelect
                required
                disabled={provider.loading}
                value={inputValue.get('status')}
                onChange={(e) => onStatusChanged(e.target.value)}>
                <option value="">Chọn trạng thái</option>
                <option value={STATUS.ACTIVE}>
                  {STATUS_LABEL.ACTIVE}
                </option>
                <option value={STATUS.INACTIVE}>
                  {STATUS_LABEL.INACTIVE}
                </option>
              </FormSelect>
            </Form.Group>
          </Row>

          <div className="d-flex justify-content-start">
            <Button
              variant="primary"
              type="submit"
              className={'mt-4 d-grid gap-2 btn btn-primary'}
            >
              {promotionId ? 'Cập nhật' : 'Tạo Mã giảm giá'}
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  </main>
}

export {PromotionForm}