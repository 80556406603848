import { AxiosResponse } from 'axios'
import { _delete, _get, _post, _put } from './request'

type ParamsType = {
  page: number
  brand?: string
  stores?: string
  app?: string
  limit?: number
  from?: number
  to?: number
  location?: string
}

export const LogsApi = {
  fetchClosedLogs(params: ParamsType): Promise<AxiosResponse<any>> {
    if (!params.limit) params.limit = 10
    return _get(`brand/admin/v1/logs/closed`, params)
  },
  fetchLocations(): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/logs/locations`)
  },
}
