import { Card, Col } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { dishplaceholder } from '../../../util/myUtil'

interface MenuItemCardProps {
  value: any
  handleCardDelete?: (card: any) => void
  hasDelete?: boolean
}
const MenuItemCard: React.FC<MenuItemCardProps> = (props) => {
  const { value, handleCardDelete, hasDelete = true } = props

  const onCardDeleted = () => {
    !!handleCardDelete && handleCardDelete(value)
  }
  return (
    <>
      {!!value && (
        <Col xs={6} sm={4} md={3} lg={2} className="my-3">
          <Card style={{height: 270}}>
            <Card.Img
              variant="top"
              src={value.get('image', '') || dishplaceholder}
              className="img-fluid"
              style={{
                width: 'auto',
                maxHeight: 180,
                height: ' 100%',
                objectFit: 'fill'
              }}
            />
            {hasDelete && (
              <TiDelete
                fontSize={25}
                cursor="pointer"
                color="#d8d8d8"
                className="position-absolute end-0"
                onClick={onCardDeleted}
              />
            )}
            <Card.Body className="d-flex flex-column justify-content-between p-2">
              <Card.Title className="fs-6 fw-bold mb-1" style={{ height: 30 }}>
                {value.get('name').length > 20
                  ? value.get('name').substring(0, 20) + '...'
                  : value.get('name')}
              </Card.Title>
              <Card.Text className="fs-6 fw-bolder">
                {value
                  .get('price')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' đ'}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  )
}

export default MenuItemCard
