import {Dropdown} from "react-bootstrap";

interface SectionFilterSourceItemType {
  source: any,
  label?: string,
  onSelect: (brand: any) => void
}

const SectionFilterSourceItem = (props: SectionFilterSourceItemType) => {

  const _onClick = () => {
    props.onSelect(props.source ?? null);
  }

  return (
    <Dropdown.Item as={'button'} onClick={_onClick}>
      {props.source?.get('name') ?? props.label}
    </Dropdown.Item>
  )
}

export default SectionFilterSourceItem;