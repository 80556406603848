import React from 'react';
import Views from "./components/views";
import UserProfileProvider from './provider';

const UserProfile = () => {

  return (
    <UserProfileProvider>
      <Views/>
    </UserProfileProvider>
  );
};

export default UserProfile;