import React from 'react';
import Views from "./components/views";
import WebsiteBannersProvider from "./provider";

const WebsiteBanners = () => {

  return (
    <WebsiteBannersProvider>
      <Views/>
    </WebsiteBannersProvider>
  );
};

export default WebsiteBanners;