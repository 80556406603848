import BrandUpdaterForm from './components/BrandUpdaterForm'
import React from "react";
import BrandUpdaterProvider from "./provider";

const BrandUpdater: React.FC = () => {
  return (
    <BrandUpdaterProvider>
      <BrandUpdaterForm />
    </BrandUpdaterProvider>
  )
}

export default BrandUpdater
