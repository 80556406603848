import { fromJS } from 'immutable'
import { useEffect, useState } from 'react'
import { Button, Container, Form, FormControl, Stack } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useNavigate, useParams } from 'react-router'
import BackBtn from '../../../components/Button/back_btn'
import Nav from '../../../components/Navbars/Nav'
import { Api } from '../../../repository/api'
import { deleteInputValue } from '../../../util/myUtil'
import SubBrandTable from './subBrandTable'
import {useWebsiteCreatorForm} from "../provider";

const WebsiteCreatorForm: React.FC = () => {
  const {websiteId} = useParams()
  const navigate = useNavigate()
  const websiteCreatorFormProvider = useWebsiteCreatorForm()
  const [validated, setValidated] = useState(false)

  const [website, setWebsite] = useState<any>(
    fromJS({
      name: '',
      slug: '',
      domain: '',
      logo: '',
      cover: '',
      intro: '',
      type: 'major',
      subBrands: []
    })
  )
  const _onBackClicked = () => {
    navigate(-1)
  }

  const onNameChanged = (name: string) => {
    setWebsite(website.updateIn(['name'], () => name))
  }

  const onSlugChanged = (slug: string) => {
    setWebsite(website.updateIn(['slug'], () => slug))
  }

  const onDomainChanged = (domain: string) => {
    setWebsite(website.updateIn(['domain'], () => domain))
  }

  const onIntroChanged = (intro: string) => {
    setWebsite(website.updateIn(['intro'], () => intro))
  }

  const onLogoChanged = (logo: File) => {
    setWebsite(website.updateIn(['logo'], () => logo))
  }

  const onCoverChanged = (cover: File) => {
    setWebsite(website.updateIn(['cover'], () => cover))
  }

  const handleSubBrandDeleted = (index: number) => {
    setWebsite(website.deleteIn(['subBrands', index]))
  }

  const handleSubBrandAdd = (brand: any) => {
    setWebsite(
      website.updateIn(['subBrands'], () => website.get('subBrands').merge(brand))
    )
  }

  const handleSubmit = async (event: any) => {
    try {
      const form = event.currentTarget
      event.preventDefault()
      event.stopPropagation()
      if (form.checkValidity() === false) {
        setValidated(true)
        return
      }

      const requestBody = { ...website.toJS() }

      if (!requestBody.subBrands.length) {
        window.alert('Vui lòng chọn tối thiểu 1 thương hiệu con!')
        return
      }

      if (typeof website.get('logo') == 'object') {
        const resLogo = await Api.uploadImage(website.get('logo'))
        requestBody.logo = resLogo.data.data.url
      }

      if (typeof website.get('cover') == 'object') {
        const resCover = await Api.uploadImage(website.get('cover'))
        requestBody.cover = resCover.data.data.url
      }

      websiteId ? await Api.brand.editMajorBrand(requestBody) : await Api.brand.createMajorBrand(requestBody)

      window.alert((websiteId ? 'Cập nhật' : 'Tạo') + ' website thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
  }

  useEffect(() => {
    if (websiteId) {
      setWebsite(websiteCreatorFormProvider.website)
    }
  }, [websiteCreatorFormProvider.website, websiteId])

  return (
    <main>
      <Nav hideToggle={!websiteId} title={`${websiteId ? 'Sửa' : 'Tạo'} website`} />
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách Website'} />
      </Container>
      <Container className="mb-4">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className={'mt-2 border-bottom pt-3 pb-3 shadow-card'}>
            <Stack className="mb-3">
              <Form.Group className="mb-3">
                <Form.Label>
                  Tên website <span className="text-danger fs-5">*</span>
                </Form.Label>
                <FormControl
                  required
                  type="text"
                  value={website.get('name')}
                  onChange={(el) => onNameChanged(el.target.value)}
                />
              </Form.Group>
      
              {websiteId && (
                <Form.Group className="mb-3">
                  <Form.Label>Slug</Form.Label>
                  <FormControl
                    type="text"
                    value={website.get('slug')}
                    onChange={(el) => onSlugChanged(el.target.value)}
                  />
                </Form.Group>
              )}
      
              <Form.Group className="mb-3">
                <Form.Label>Domain</Form.Label>
                <FormControl
                  type="text"
                  value={website.get('domain')}
                  onChange={(el) => onDomainChanged(el.target.value)}
                />
              </Form.Group>
      
              <Form.Group className="mb-3">
                <Form.Label>Giới thiệu</Form.Label>
                <FormControl
                  type="text"
                  as="textarea"
                  rows={3}
                  value={website.get('intro')}
                  onChange={(el) => onIntroChanged(el.target.value)}
                />
              </Form.Group>
      
              <Form.Group className="mb-3 col-lg-4 col-sm-12">
                <Form.Label>Logo (tối đa 1MB)</Form.Label>
                <FormControl
                  className="mb-3"
                  type="file"
                  id="uploadLogo"
                  onChange={(el: any) => onLogoChanged(el.target?.files[0])}
                />
                {website.get('logo') && (
                  <div className="position-relative">
                    <img
                      src={
                        typeof website.get('logo') == 'string'
                          ? website.get('logo')
                          : URL.createObjectURL(website.get('logo'))
                      }
                      alt={website.get('logo').name}
                      className="btn p-0"
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() =>
                        window.open(
                          typeof website.get('logo') == 'string'
                            ? website.get('logo')
                            : URL.createObjectURL(website.get('logo'))
                        )
                      }
                    />
                    <TiDelete
                      fontSize={25}
                      cursor="pointer"
                      className="position-absolute"
                      style={{ right: 1 }}
                      onClick={() => {
                        setWebsite(website.updateIn(['logo'], () => undefined))
                        deleteInputValue('uploadLogo')
                      }}
                    />
                  </div>
                )}
              </Form.Group>
      
              <Form.Group className="mb-3 col-lg-4 col-sm-12">
                <Form.Label>Cover (tối đa 1MB)</Form.Label>
      
                <FormControl
                  className="mb-3"
                  type="file"
                  id="uploadCover"
                  onChange={(el: any) => onCoverChanged(el.target.files[0])}
                />
                {website.get('cover') && (
                  <div className="position-relative">
                    <img
                      src={
                        typeof website.get('cover') == 'string'
                          ? website.get('cover')
                          : URL.createObjectURL(website.get('cover'))
                      }
                      alt={website.get('cover').name}
                      className="btn p-0"
                      style={{ width: '100%', height: 'auto' }}
                      onClick={() =>
                        window.open(
                          typeof website.get('cover') == 'string'
                            ? website.get('cover')
                            : URL.createObjectURL(website.get('cover'))
                        )
                      }
                    />
                    <TiDelete
                      fontSize={25}
                      cursor="pointer"
                      className="position-absolute"
                      style={{ right: 1 }}
                      onClick={() => {
                        setWebsite(website.updateIn(['cover'], () => undefined))
                        deleteInputValue('uploadCover')
                      }}
                    />
                  </div>
                )}
              </Form.Group>
      
              <SubBrandTable
                handleSubBrandAdd={handleSubBrandAdd}
                brandList={website.get('subBrands')}
                handleSubBrandDeleted={handleSubBrandDeleted}
              />
            </Stack>
      
            <div className="d-flex justify-content-start">
              <Button
                variant="success"
                type="submit"
                className={'mt-4 d-grid gap-2 btn btn-primary'}
              >
                {websiteId ? 'Cập nhật' : 'Tạo website'}
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </main>
  )
}

export default WebsiteCreatorForm
