import { Container } from "react-bootstrap";
import PageLoading from "../../components/page_loading";
import Nav from "../../components/Navbars/Nav";
import { useWebsites } from "./provider";
import WebsiteList from "./components/WebsiteList";
import './styles.css';

const Websites = () => {
  const websitesProvider = useWebsites();

  return (
    <main>
      <Nav title={"Danh sách Website"} />
      {websitesProvider.loading && websitesProvider.data.size < 1 && <PageLoading />}
      <Container className={"pb-4"}>
        <WebsiteList />
      </Container>
    </main>
  );
};

export default Websites;
