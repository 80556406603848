import React from "react";
import {Badge, Card, Dropdown} from 'react-bootstrap'
import {dishplaceholder} from "../../../util/myUtil";
import {numberFormat} from "../../../config";
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useMenuItems} from "../provider";
import {useNavigate} from "react-router";
import {Simulate} from "react-dom/test-utils";

interface MenuItemType {
  item: any,
  index: number,
  page: number
}

const MenuItem = (props: MenuItemType) => {

  const menuItemsProvider = useMenuItems()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)

  const storeName = () => {
    return props.item.getIn(['store', 'name']) || props.item.getIn(['source', 'store', 'name'])
  }

  const sourceName = () => {
    return props.item.getIn(['source', 'name'])
  }

  const _onEditClicked = () => {
    navigate(`/menu/${props.item.get('_id')}/edit`)
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Món này?')
    if (!rs) return
    menuItemsProvider.deleteMenuItem(props.item.get('_id'))
  }

  const _onUpdateStatusChanged = async () => {
    setLoading(true)
    await menuItemsProvider.updateStatusItem(props.item)
    setLoading(false)
  }

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td className={'text-wrap'}>
        <Card.Img
          variant="top"
          src={props.item.get('image') || dishplaceholder}
          className="img-fluid"
          style={{
            maxHeight: 100,
            minHeight: 100,
            width: 'auto',
            objectFit: 'contain'
          }}
        />
      </td>
      <td>
        {props.item.get('name')}
      </td>
      <td>
        {storeName()}
      </td>
      <td>
        {sourceName()}
      </td>
      <td>
        {numberFormat.format(props.item.get('price'))}
      </td>
      <td>
        <div className="form-check form-switch">
          <input
            disabled={loading}
            className="form-check-input"
            type="checkbox"
            role="switch"
            onChange={_onUpdateStatusChanged}
            checked={props.item.get('status') === 'active'}/>
        </div>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked} disabled={loading}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  )
}

export default MenuItem;