import React, {useState} from 'react'
import {Form, FormLabel, FormSelect, Row, Stack} from 'react-bootstrap'
import {useApp} from "../../components/AdminLayout/admin_provider";
import {Api} from "../../repository/api";
import {fromJS} from "immutable";

interface MenuItemCategoryCreatorPropType {
  category: any
  setCategory: React.Dispatch<any>
  disableTargetSelector?: boolean
}

const MenuItemCategoryCreator: React.FC<MenuItemCategoryCreatorPropType> = ({ category, setCategory, disableTargetSelector = false }) => {
  const app = useApp()
  const [stores, setStores] = useState<any>();
  const [sources, setSources] = useState<any>();
  const isEditing = !!category.get('_id')

  const onNameChanged = (name: string) => {
    setCategory(category.updateIn(['name'], () => name))
  }

  const onBrandChanged = (brand: string) => {
    setCategory(category.updateIn(['brandId'], () => brand))
  }

  const onStoreChanged = (store: string) => {
    setCategory(category.updateIn(['storeId'], () => store))
  }

  const onSourceChanged = (source: string) => {
    setCategory(category.updateIn(['sourceId'], () => source))
  }

  const _fetchStores = async () => {
    if (!category.getIn(['brandId'])) return
    try {
      const rs: any = await Api.store.fetchStores(
        1, undefined, category.getIn(['brandId']), 200
      )
      setStores(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  const _fetchSources = async () => {
    if (!category.getIn(['storeId'])) return
    try {
      const rs: any = await Api.store.fetchSources(category.getIn(['storeId']))
      setSources(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    _fetchStores()
  }, [category.getIn(['brandId'])])

  React.useEffect(() => {
    _fetchSources()
  }, [category.getIn(['storeId'])])

  return (
    <Form autoComplete="off">
      <Stack gap={3}>
        <Form.Group>
          <Form.Label>
            Thương hiệu <span className="text-danger fs-5">*</span>
          </Form.Label>
          <Form.Select
            value={category.get('brandId', '')}
            disabled={isEditing || disableTargetSelector}
            onChange={(e) => onBrandChanged(e.target.value)}
          >
            <option value="">Chọn thương hiệu</option>
            {app.brands.map((b: any, i: number) => (
              <option key={i} value={b.get('_id')}>
                {b.get('name')}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <FormLabel>
            Cửa hàng <span className="text-danger fs-5">*</span>
          </FormLabel>
          <FormSelect
            required
            value={category.getIn(['storeId'])}
            disabled={!stores || stores.size < 1 || isEditing || disableTargetSelector}
            onChange={(el) => onStoreChanged(el.target.value)}
          >
            <option value="">Chọn cửa hàng</option>
            { stores && stores.map((store: any, i: number) => (
              <option key={store.get('_id', '')} value={store.get('_id', '')}>
                {store.get('name')}
              </option>
            ))}
          </FormSelect>
        </Form.Group>

        <Form.Group>
          <FormLabel>
            Nguồn đơn <span className="text-danger fs-5">*</span>
          </FormLabel>
          <FormSelect
            required
            value={category.getIn(['sourceId'])}
            disabled={!sources || sources.size < 1 || isEditing || disableTargetSelector}
            onChange={(el) => onSourceChanged(el.target.value)}
          >
            <option value="">Chọn nguồn đơn</option>
            {sources && sources.map((source: any, i: number) => (
              <option key={source.get('_id', '')} value={source.get('_id', '')}>
                {source.get('name')}
              </option>
            ))}
          </FormSelect>
        </Form.Group>

        {
          category.get('sourceId') &&
          <Form.Group>
            <Form.Label>
              Tên danh mục <span className="text-danger fs-5">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              value={category.get('name')}
              onChange={(el) => onNameChanged(el.target.value)}
            />
          </Form.Group>
        }
      </Stack>
    </Form>
  )
}

export default MenuItemCategoryCreator
