import {useUsers} from "../provider";
import PageLoading from "../../../components/page_loading";
import {Container, Form, FormControl, Row} from "react-bootstrap";
import React, { useState } from "react";
import Nav from "../../../components/Navbars/Nav";
import SectionUsers from "./section_users";


const Views = () => {
  const [search, setSearch] = useState<any>('');
  const provider = useUsers();

  const _onSubmitSearch = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    provider.onSubmitSearch(search)
  }

  return (
    <main>
      <Nav title={'Danh sách người dùng'} />
      {
        provider.loading && provider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <Row>
          <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>Tìm kiếm</h4>
              <Form onSubmit={_onSubmitSearch}>
                <FormControl
                  type="text"
                  placeholder="Tìm theo SĐT"
                  value={search}
                  onChange={(el) => setSearch(el.target.value)}
                />
              </Form>
            </div>
          </div>
        </Row>
        <SectionUsers/>
      </Container>
    </main>
  );
}

export default Views;