import React from "react";
import {numberFormat} from "../../../config";
import {useOrderCreator} from "../provider";

interface SectionMenuItemOptionChoiceType {
  itemKey: any,
  optionId: any,
  choice: any
}

const SectionMenuItemOptionChoice = (props: SectionMenuItemOptionChoiceType) => {

  const orderCreator = useOrderCreator();

  const _onQuantityChanged = (e: any) => {
    const value = e.target.value;
    try {
      const quantity = Number.parseInt(value);
      if (isNaN(quantity)) {
        orderCreator.onMenuItemOptionChoiceQuantityChanged(
          props.itemKey,
          props.optionId,
          props.choice.get('_id'),
          0
        )
        return
      }
      if (quantity < 0) {
        window.alert('Số lượng không thể nhỏ hơn 0');
        return;
      }
      orderCreator.onMenuItemOptionChoiceQuantityChanged(
        props.itemKey,
        props.optionId,
        props.choice.get('_id'),
        quantity
      )
    } catch(e) {
      window.alert(e)
    }
  }

  return (
    <div className={'row align-items-center'}>
      <div className={'col'}>
        ● {props.choice.get('name')}
      </div>
      <div className={'col-auto'}>
        <div className={'hstack'}>
          <div style={{width: 72}} className={'me-2'}>
            <input
              type={'number'}
              className="form-control form-control-sm"
              value={props.choice.get('quantity', 0)}
              placeholder="1"
              onChange={_onQuantityChanged}/>
          </div>
          {` x `}
          <div style={{minWidth: 96}} className={'text-end'}>
            { numberFormat.format(props.choice.get('price', 0)) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionMenuItemOptionChoice;