import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";

interface UsersContextType {
  queries: any,
  data: any,
  loading: boolean,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  onSubmitSearch: (search?: string) => void,
  createUser: (name: string, phone: string, password: string) => Promise<boolean>,
}

let UsersContext = React.createContext<UsersContextType>(null!);

const useUsers = () => {
  return React.useContext(UsersContext);
};

function UsersProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({
    page: 1
  }));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);

  const _fetchUsers = async() => {
    try {
      const rs: any = await Api.user.fetchUsers(queries.get('page', 1), queries.get('search'))
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await _fetchUsers()
    setData(list)

    setLoading(false)
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onSubmitSearch = (search?: string) => {
    setQueries(
      queries
        .updateIn(['search'], () => search)
        .updateIn(['page'], () => 1)
    )
  }

  const createUser = async (name: string, phone: string, password: string) => {
    try {
      const rs: any = await Api.user.createUser(phone, password, name)
      if (rs?.data?.data) {
        _refresh();
      }
      return rs.data.data
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return false;
    }
  }

  React.useEffect(() => {
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onSubmitSearch,
    createUser
  };

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
}

export default UsersProvider;
export { useUsers };