import React from 'react';
import './styles.css';
import Views from "./components/views";
import CrmProvider from "./provider";

const Crm = () => {

  return (
    <CrmProvider>
      <Views/>
    </CrmProvider>);
};

export default Crm;