import React from "react";
import {Table} from "react-bootstrap";
import StorePickerItem from "./store_picker_item";

interface StoreModalPickerProps {
  storeList: any
  onChanged: (item: any) => void
}

const StoreModalPicker:React.FC<StoreModalPickerProps> = (props: StoreModalPickerProps) => {

  return (
    <div>
      <Table striped hover bordered responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Điểm bán</th>
          <th>Địa chỉ</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {
          props.storeList &&
          props.storeList.map((item: any, index: number) => {
            return <StorePickerItem
              key={`store_picker_item_${item.get('_id')}`}
              index={index}
              item={item}
              onChanged={props.onChanged}/>
          })
        }
        </tbody>
      </Table>
    </div>
  )
}

export default StoreModalPicker
