import React from 'react';
import Views from "./components/views";
import ClosedLogsProvider from './provider';

const ClosedLogs = () => {

  return (
    <ClosedLogsProvider>
      <Views/>
    </ClosedLogsProvider>
  );
};

export default ClosedLogs;