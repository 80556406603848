import {Button, Form, FormControl, InputGroup, Modal, Table} from "react-bootstrap";
import React, {ChangeEvent, useState} from "react";
import { useUserOutletPermission } from "../user_outlet_permission_provider";
import ModalOutletSelectionItem from "./modal_outlet_selection_item";
import TablePagination from "../../../components/table_pagination";

interface ModalOutletSelectionType {
  onClose: () => void,
}

const ModalOutletSelection = (props: ModalOutletSelectionType) => {
  const [search, setSearch] = useState<any>('');
  const [permission, setPermission] = useState<any>('');
  const provider = useUserOutletPermission();

  const _onSubmitSearch = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    provider.onSubmitSearch(search)
  }

  const _onSubmitClicked = async () => {
    const selectedCount = provider.stores.count((s: any) => !!s.get('selected'))
    if (!permission || permission.length < 1) {
      window.alert('Vui lòng chọn quyền muốn thêm cho user này.')
      return
    }
    if (!selectedCount) {
      window.alert('Vui lòng chọn outlet muốn thêm quyền cho user này.')
      return
    }
    const rs = window.confirm(`Bạn có chắc chắn muốn thêm quyền "${permission}" cho ${selectedCount} outlet?`)
    if (!rs) return

    const result = await provider.onSubmit(permission);
    if (!result) return
    window.alert('Thêm thành công!')
    props.onClose();
  }

  const _onPermissionChanged = (e: any) => {
    const value = e.target.value;
    setPermission(value)
  }

  const _onSelectAllChanged = (e: any) => {
    provider.onSelectAll();
  }

  return (
    <div>
      <Form.Select onChange={_onPermissionChanged} value={permission}  disabled={provider.loading}>
        <option>Chọn quyền</option>
        <option value="view">View</option>
        <option value="edit">Edit</option>
        <option value="create">Create</option>
        <option value="delete">Delete</option>
      </Form.Select>
      <Form onSubmit={_onSubmitSearch} className="mt-3">
        <FormControl
          type="text"
          placeholder="Tìm cửa hàng"
          value={search}
          disabled={provider.loading}
          onChange={(el) => setSearch(el.target.value)}
        />
      </Form>
      <Table striped hover responsive>
        <thead>
        <tr>
          <th>STT</th>
          <th>Tên</th>
          <th>
            <Form.Check 
              type={'checkbox'}
              label={'Chọn'}
              disabled={provider.loading}
              onChange={_onSelectAllChanged}
            />
          </th>
        </tr>
        </thead>
        <tbody>
          {
            provider.stores?.map((s: any, index: number) => {
              return (
                <ModalOutletSelectionItem
                  key={`store_selection_${index}_${s.get('_id')}`}
                  outlet={s}
                  index={index}/>
              )
            })
          }
        </tbody>
      </Table>
      <div className={'d-flex justify-content-between'}>
        <div>
        {
          (
            (!!provider.stores && provider.stores.size >= 20) ||
            provider.queries.get('page', 1) > 1
          ) &&
          <TablePagination
            page={provider.queries.get('page')}
            onPreviousPage={provider.onPreviousPage}
            onNextPage={provider.onNextPage}
            onFirstPage={provider.onFirstPage}/>
        }
        </div>
        <div>
          <Button variant="primary" onClick={_onSubmitClicked} disabled={provider.loading}>
            Thêm
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModalOutletSelection;