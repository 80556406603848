import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../../repository/api";

interface GrabPlatformOrderImporterContextType {
  loading: boolean;
  data: any;
  config: any;
  fetchOrder: (storeId: string, provider: string, orderId: string) => void;
  createOrder: () => Promise<boolean>;
}

let GrabPlatformOrderImporterContext = React.createContext<GrabPlatformOrderImporterContextType>(null!);

const useGrabPlatformOrderImporter = () => {
  return React.useContext(GrabPlatformOrderImporterContext);
};

function GrabPlatformOrderImporterProvider({ children, fromExistOrder }: { children: React.ReactNode, fromExistOrder: boolean }) {

  const [config, setConfig] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS({}));
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchOrder = async (storeId: string, provider: string, orderId: string) => {
    let newConfig = config.updateIn(['storeId'], () => storeId)
      .updateIn(['orderId'], () => orderId)
      .updateIn(['provider'], () => provider)

    setLoading(true);

    try {
      const rs = await Api.grab.fetchOrderDetail(storeId, orderId, fromExistOrder)
      setData(fromJS(rs.data.data))
      if (fromExistOrder) {
        newConfig = newConfig.updateIn(['orderId'], () => rs.data.data?.platformOrderId)
      }
    } catch (e) {
      console.log(e)
    }

    setConfig(newConfig);
    setLoading(false);
  }

  const createOrder = async () => {

    setLoading(true);

    try {
      const rs = await Api.grab.createOrder(config.get('storeId'), config.get('orderId'))
      setLoading(false);
      const response = rs.data?.data
      if (!!response?._id) {
        window.alert(`Nhập đơn ${response.code} thành công!`)
      }
      return true;
    } catch (e: any) {
      setLoading(false);
      const errorResponse = e.response?.data?.error?.message
      window.alert(errorResponse ?? 'Có lỗi xảy ra trong quá trình xử lý vui lòng thử lại sau! ' + e.message)
      return false;
    }
  }

  let value = {
    loading,
    data,
    config,
    fetchOrder,
    createOrder
  };

  return <GrabPlatformOrderImporterContext.Provider value={value}>{children}</GrabPlatformOrderImporterContext.Provider>;
}

export default GrabPlatformOrderImporterProvider;
export { useGrabPlatformOrderImporter };
