import PageLoading from "../../../components/page_loading";
import {Container, Form, FormControl, Row} from "react-bootstrap";
import React, {useState} from "react";
import Nav from "../../../components/Navbars/Nav";
import {useNavigate, useParams} from "react-router";
import {useWebsiteBanners} from "../provider";
import BackBtn from "../../../components/Button/back_btn";
import SectionBanners from "./section_banners";
import {FaPlus} from "react-icons/fa";
import LabelIconBtn from "../../../components/Button/label_icon_btn";


const Views = () => {

  const bannersProvider = useWebsiteBanners();
  const navigate = useNavigate();
  const {websiteId} = useParams()
  const [search, setSearch] = useState<any>(bannersProvider.queries.get('search'))

  const _onCreateClicked = () => {
    navigate(`/websites/${websiteId}/banner/create`)
  }

  const _onSubmitSearch = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    bannersProvider.submitSearch(search)
  }

  const _onBackClicked = () => navigate(-1)

  return (
    <main>
      <Nav title={'Banner Website'} />
      {
        bannersProvider.loading && bannersProvider.data.size < 1 && <PageLoading/>
      }
      <Container>
        <BackBtn onBackClicked={_onBackClicked} label={'Danh sách Website'}/>
      </Container>
      <Container>
        <Row>
          <div className={'mt-3 col'}>
            <h6 className={'border-bottom pb-2'}>
              {bannersProvider.website?.getIn(['name'])}
            </h6>
          </div>
        </Row>
      </Container>
      <Container className={'pb-4 mt-3'}>
        <div className={'shadow-card'}>
          <div className={'row mb-2'}>
            <div className={'col-auto'}>
              <Form onSubmit={_onSubmitSearch}>
                <FormControl
                  type="text"
                  placeholder="Tên banner"
                  value={search}
                  onChange={(el) => setSearch(el.target.value)}
                />
              </Form>
            </div>
            <div className={'col'}/>
            <div className={'col-auto'}>
              <LabelIconBtn
                label={'Thêm Banner mới'}
                icon={<FaPlus size={12} />}
                onClick={_onCreateClicked}
              />
            </div>
          </div>
          <SectionBanners/>
        </div>
      </Container>
    </main>
  );
}

export default Views;