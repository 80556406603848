import React from 'react';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu} from 'react-pro-sidebar';
import {
  FaBoxes,
  FaChevronLeft,
  FaChevronRight, FaChrome,
  FaCloud,
  FaCogs, FaDatabase,
  FaRegChartBar, FaStore,
  FaStream, FaToolbox, FaTools, FaUsers, FaWarehouse
} from 'react-icons/fa';
import {Link} from "react-router-dom";

import './sidebar.css'
import {Button} from "react-bootstrap";
import {useSideBar} from "../AdminLayout";
import {appAxios} from "../../repository/api/request";
import {useAuth} from "../Auth/auth";
import logo from '../../assets/img/logo.svg';
import {MdOutlineRestaurantMenu} from "react-icons/md";

interface SideBarType {
  toggled?: boolean,
  collapsed?: boolean
}

const SideBar = (props: SideBarType) => {

  const sideBar = useSideBar();
  const auth = useAuth();

  React.useEffect(() => {
    const refreshInterceptor = appAxios.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      console.log('error', error.response.status, error.response.data.error)
      if (error.response) {
        if (error.response?.data?.error?.message === 'token_expired') {
          if (!!error.response?.data?.error?.token) {
            auth.updateToken(error.response?.data?.error?.token);

            const originalRequest = error.config;
            originalRequest._retry = true;
            return appAxios(originalRequest);

          } else {
            window.alert('Hết hạn đăng nhập. Vui lòng đăng nhập lại!')
          }
        }

        return Promise.reject(error);
      }
      return Promise.reject(error);
    })
    return () => {
      appAxios.interceptors.response.eject(refreshInterceptor);
    }
  }, []);

  return (
    <ProSidebar
      image={undefined}
      collapsed={props.collapsed}
      toggled={props.toggled}
      breakPoint={"xl"}
      onToggle={sideBar.onToggleClicked}
    >
      <SidebarHeader>
        <div className={'sidebar-header'}>
          <span className="brand-logo me-2">
            <img src={logo} alt="logo"/>
          </span>
          { !props.collapsed && 'HUB'}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <SubMenu
            title={"Bán hàng"}
            icon={<FaBoxes size={20}/>}>
            <MenuItem>
              Đơn hàng
              <Link to="/" />
            </MenuItem>
            <MenuItem>
              Thực đơn
              <Link to="/menu" />
            </MenuItem>
            <MenuItem>
              Quản lý bàn
              <Link to="/tables" />
            </MenuItem>
          </SubMenu>

          <SubMenu
            title={"Websites"}
            icon={<FaChrome size={20}/>}>
            <MenuItem>
              Danh sách Website
              <Link to="/websites" />
            </MenuItem>
          </SubMenu>

          <SubMenu
            title={"Thương hiệu"}
            icon={<FaStore size={20}/>}>
            <MenuItem>
              Danh sách Thương hiệu
              <Link to="/brands" />
            </MenuItem>
            <MenuItem>
              Cửa hàng
              <Link to="/stores" />
            </MenuItem>
            <MenuItem>
              Điểm bán
              <Link to="/hubs" />
            </MenuItem>
          </SubMenu>

          <SubMenu
            title={"CRM"}
            className={'opacity-25'}
            icon={<FaUsers size={20}/>}>
            <MenuItem>
              Người dùng
              {/*<Link to="/" />*/}
            </MenuItem>
          </SubMenu>

          <SubMenu
            title={"Công cụ"}
            icon={<FaToolbox size={18}/>}>
            <MenuItem>
              Danh sách tài khoản
              <Link to="/users"/>
            </MenuItem>
            <MenuItem>
              Tự động lấy đơn
              <Link to="/order-crawler-jobs"/>
            </MenuItem>
            <MenuItem>
              Clone Menu
              <Link to="/menu-clone"/>
            </MenuItem>
            <MenuItem>
              Clone Website Menu
              <Link to="/menu-clone-website"/>
            </MenuItem>
            <MenuItem>
              Đóng Food App Logs
              <Link to="/logs/closed"/>
            </MenuItem>
            <MenuItem>
              Cài đặt
              <Link to="/settings"/>
            </MenuItem>
          </SubMenu>
        </Menu>
      </SidebarContent>
      <SidebarFooter style={{textAlign: 'center'}}>
        <div className="sidebar-btn-wrapper">
          <button onClick={sideBar.onCollapseClicked} className={'btn-label'}>
            { props.collapsed && <FaChevronRight color={'#6B0772'} size={14}/> }
            { !props.collapsed && <FaChevronLeft color={'#6B0772'} size={14}/> }
          </button>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBar;