import { AxiosResponse } from "axios";
import { _get, _post } from "./request";

export const TableApi = {
    fetchTableCount(storeId: string, sourceId: string): Promise<AxiosResponse<any>> {
        return _get("brand/admin/v1/tables/count", { storeId: storeId, sourceId: sourceId })
    },
    fetchTables( storeId: string, sourceId: string,pageIndex?:number,pageSize?: number): Promise<AxiosResponse<any>> {
        return _get("brand/admin/v1/tables", { store: storeId,  source: sourceId ,page: pageIndex,limit: pageSize})
    },

    createTableByCount(storeId: string, sourceId: string, count: number): Promise<AxiosResponse<any>> {
        return _post("brand/admin/v1/tables/table-by-count", { storeId: storeId, sourceId: sourceId, count: count })
    }
}