import {numberFormat} from "../../../config";
import React from "react";
import moment from "moment";
import {Colors} from "../../../util/color_utils";
import {fromJS} from "immutable";
import {FaDatabase, FaInfo} from "react-icons/fa";
import BaeminPlatformOrderImporter from "../../BaeminPlatformOrders/importer";
import {orderStateInfo, sourceInfo, sourceInfoFromOrder} from "../../../util/common_utils";
import GrabPlatformOrderImporter from "../../GrabPlatformOrders/importer";
import {Link} from "react-router-dom";

interface OrderItemType {
  order: any,
  index: number,
  page: number
}

const OrderItem = (props: OrderItemType) => {

  const sourceNameCode = props.order.getIn(['source', 'name'], '').toLowerCase();

  const [showImporter, setShowImporter] = React.useState<boolean>(false);

  const _onCloseImporter = () => {
    setShowImporter(false);
  }

  const _getOrderStatus = () => {
    return orderStateInfo(props.order.get('state', ''))
  }

  const _getPaymentState = () => {
    switch (props.order.get('paymentState').toLowerCase()) {
      case 'paid':
        return 'Đã thanh toán';
    }
    return 'Chưa thanh toán';
  }

  const _onErrorsClicked = () => {
    const errors = props.order.getIn(['provider', 'errors'], fromJS([]))
    let message = '';
    for (let err of errors) {
      message += '- ' + err + '\n\n';
    }
    window.alert(message)
  }

  const _onReImportClicked = () => {
    setShowImporter(true);
  }

  const status: any = _getOrderStatus();
  const source: any = sourceInfoFromOrder(props.order);
  const errorCount = props.order.getIn(['provider', 'errors'], fromJS([])).size;

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td className={'text-wrap'}>
        <Link to={`/order/${props.order.get('_id')}`} target={'_blank'}>
          <strong className={'order-code p-0'}>
            {props.order.get('code')}
          </strong>
        </Link>
        <div className={'note-info fw-lighter'}>
          {props.order.getIn(['customer', 'address'], '')}
        </div>
      </td>
      <td>
        <div className={'highlight-value'} style={{color: source.color}}>
          {source.label}
        </div>
        <div className={'fw-lighter'}>
          {
            props.order.getIn(['store', 'name']) &&
            props.order.getIn(['store', 'brand', 'name'], '') + ` - ` + props.order.getIn(['store', 'name'], '')
          }
          {
            !props.order.getIn(['store', 'name']) &&
            props.order.getIn(['hub', 'name'])
          }
        </div>
      </td>
      <td>
        <div>
          <strong>{numberFormat.format(props.order.get('netPrice'))}</strong>
        </div>
        <div>
          <span className={'item-count'}>{props.order.get('items').map((item: any) => item.get('quantity')).reduce((i1: any, i2: any) => i1 + i2, 0)}</span> món
        </div>
      </td>
      <td>
        <div className={'highlight-value'} style={{color: status.color }}>
          {status.label}
        </div>
        <div className={'note-info'}>
          {_getPaymentState()}
        </div>
        {numberFormat.format(props.order.get('finalPrice'))}
      </td>
      <td>
        {moment(props.order.get('created')).local().format('YYYY/MM/DD - HH:mm')}
        <br/>
        {
          errorCount > 0 &&
          <>
	          <button
		          type="button"
		          className="btn btn-sm p-0 btn-label"
		          onClick={_onErrorsClicked}>
		          <FaInfo size={10} color={Colors.Error} className={'mb-1'}/>
		          <small className={'ms-1'} style={{color: Colors.Error}}>Có {errorCount} lỗi</small>
	          </button>
            <br/>
          </>
        }

        {
          (sourceNameCode.includes('baemin') || sourceNameCode.includes('grab')) &&
          <button
            type="button"
            className="btn btn-sm p-0 btn-label"
            onClick={_onReImportClicked}>
            <FaDatabase size={10} color={Colors.primaryBtn} className={'mb-1'}/>
            <small className={'ms-1'} style={{color: Colors.primaryBtn}}>
              Nhập lại đơn
            </small>
          </button>
        }

        {
          sourceNameCode.includes('baemin') &&
          <BaeminPlatformOrderImporter
            orderId={props.order.get('_id')}
            fromExistOrder={true}
            provider={'baemin'}
            storeId={props.order.getIn(['store', '_id'])}
            code={props.order.get('code')}
            onClose={_onCloseImporter}
            show={showImporter}/>
        }
        {
          sourceNameCode.includes('grab') &&
          <GrabPlatformOrderImporter
            orderId={props.order.get('_id')}
            fromExistOrder={true}
            provider={'grab'}
            storeId={props.order.getIn(['store', '_id'])}
            code={props.order.get('code')}
            onClose={_onCloseImporter}
            show={showImporter}/>
        }
      </td>
    </tr>
  )
}

export default OrderItem;