import {Colors} from "./color_utils";

export const sourceInfo = (sourceNameCode: string, defaultName: string) => {
    if (sourceNameCode.includes('grab')) {
        return {
            label: 'Grab',
            color: Colors.Grab
        }
    }
    if (sourceNameCode.includes('shopee') || sourceNameCode.includes('now')) {
        return {
            label: 'Shopee Food',
            color: Colors.Shopee
        }
    }
    if (sourceNameCode.includes('baemin')) {
        return {
            label: 'Baemin',
            color: Colors.Baemin
        }
    }
    if (sourceNameCode.includes('gofood') || sourceNameCode.includes('go food')) {
        return {
            label: 'Go Food',
            color: Colors.Gofood
        }
    }
    if (sourceNameCode.includes('befood') || sourceNameCode.includes('be food')) {
        return {
            label: 'Be Food',
            color: Colors.Befood
        }
    }
    return {
        label: defaultName,
        color: Colors.primaryBtn
    }
}

export const sourceInfoFromOrder = (order: any) => {
    if (order.getIn(['source', 'name'])) {
        return sourceInfo(order.getIn(['source', 'name']).toLowerCase(), order.getIn(['source', 'name']));
    }
    if (order.get('sources') && order.get('sources').size > 0) {
        return sourceInfo(order.getIn(['sources', 0, 'name']).toLowerCase(), order.getIn(['sources', 0, 'name']));
    }
    return sourceInfo('', '')
}

export const foodApps = {
    grab: 'grab',
    baemin: 'baemin',
    shopee: 'shopee_food',
    gofood: 'gofood',
    befood: 'befood'
}

export const orderStateInfo = (state: any) => {
    switch (state.toLowerCase()) {
        case 'new':
            return {
                label: 'Đơn mới',
                color: '#f3bf05'
            };
        case 'canceled':
            return {
                label: 'Đã hủy',
                color: '#ee1414'
            };
        case 'done':
            return {
                label: 'Hoàn thành',
                color: '#0fab05'
            };
    }
    return {
        label: '',
        color: '#000'
    };
}

export const PAYMENT_METHOD = {
    banking: {
        key: 'banking',
        label: 'Banking'
    },
    cash: {
        key: 'cash',
        label: 'Cash'
    },
    online: {
        key: 'online',
        label: 'Online'
    }
}

export const GoodsTicketTypes = [
    {
        value: 1,
        label: 'Nhập',
        color: '#1976d2'
    },
    {
        value: 2,
        label: 'Xuất',
        color: '#ed6c02'
    },
    {
        value: 3,
        label: 'Hủy',
        color: '#d32f2f'
    },
    {
        value: 4,
        label: 'Kiểm',
        color: '#2e7d32'
    },
    {
        value: 5,
        label: 'Tiêu thụ',
        color: '#000'
    }
]

export const permissionClass = (type: string) => {
    switch (type) {
        case 'view':
            return 'secondary'
        case 'edit':
            return 'info'
        case 'create':
            return 'primary'
        case 'delete':
            return 'success'
        default: 
            return ''
    }
}