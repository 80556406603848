import { AxiosResponse } from 'axios'
import {_get, _post, _put, _delete} from './request'

export const BannerApi = {
  fetchBanners(majorBrand: string, search: string | undefined, page: number, limit: number = 20, types?: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/banners`, {
      majorBrand,
      search,
      page,
      limit,
      types
    })
  },
  deleteBanner(id: string): Promise<AxiosResponse<any>> {
    return _delete(`brand/admin/v1/banners/${id}`)
  },
  createBanner(banner: any): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/banners`, banner)
  },
  fetchBannerDetail(bannerId: string): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/banners/${bannerId}`)
  },
  editBanner(bannerId: string, banner: any): Promise<AxiosResponse<any>> {
    return _put(`brand/admin/v1/banners/${bannerId}`, banner)
  },
}
