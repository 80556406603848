import React, {Dispatch, useEffect, useState} from 'react'
import {fromJS} from 'immutable'
import {useNavigate, useParams} from 'react-router'
import {MenuCategoryType, MenuItem, MenuItemType, MenuOptionType} from "../../repository/model/menuItem";
import useDebounce from "../../util/useDebounce";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {MENU_ITEM_TYPE} from "../../util/constant";

interface MenuItemCreatorContextType {
  queries: any
  initCategories: any
  initOptions: any
  initUnit: any
  initItem: any
  menuItem: any
  initStores: any
  initSources: any
  loading: boolean
  websiteData: any
  onCateSearchChanged: (cate?: string) => void
  onOptionSearchChanged: (option?: string) => void
  onItemSearchChanged: (option?: string) => void
  onBrandChanged: (brand?: any) => void
  onStoreChanged: (store?: any) => void
  onSourceChanged: (source?: any) => void
  onItemTypeChanged: (itemType: MenuItemType) => void
  onCreateCategory: (category: MenuCategoryType) => any
  onCreateOption: (option: MenuOptionType) => void
  onCreate: (item: any) => void
  onEdit: (item: any) => void
  setQueries: Dispatch<any>
  setItemType: (type: any) => void
}

let MenuItemCreatorContext = React.createContext<MenuItemCreatorContextType>(null!)

const useMenuItemCreator = () => {
  return React.useContext(MenuItemCreatorContext)
}

const MenuItemCreatorProvider: React.FC = ({ children }) => {
  const [queries, setQueries] = React.useState<any>(fromJS({}))
  const [loading, setLoading] = React.useState(false)
  const {itemId, websiteId} = useParams()
  const [initCategories, setCategories] = useState<any>(fromJS([]))
  const [initOptions, setOptions] = useState<any>(fromJS([]))
  const [initUnit, setUnit] = useState<any>(fromJS([]))
  const [initItem, setItem] = useState<any>(fromJS([]))
  const [menuItem, setMenuItem] = useState<any>(fromJS({}))
  const [initStores, setStores] = useState<any>(fromJS([]))
  const [initSources, setSources] = useState<any>(fromJS([]))
  const [initItemType, setItemType] = useState<any>(undefined)
  const [websiteData, setWebsiteData] = React.useState<any>(fromJS({}))
  const isWebsiteItem = !!websiteId
  const navigate = useNavigate()
  const app = useApp()

  const _fetchWebsite = async () => {
    try {
      if (websiteId) {
        const res = await Api.brand.fetchBrand(websiteId)
        setWebsiteData(
          websiteData.updateIn(['website'], () => fromJS(res.data.data))
        )
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuCategories = async () => {
    try {
      setLoading(true)
      const rs = await Api.menu.fetchMenuCategoryList({
        search: queries.get('searchCate', ''),
        brand: queries.getIn(['brand', '_id']),
        store: queries.getIn(['itemSearch', 'store', '_id'], ''),
        source: queries.getIn(['itemSearch', 'source', '_id'], ''),
        page: queries.get('page'),
        limit: 0,
        majorBrand: websiteId,
        brandFields: 'name,slug'
      })
      setLoading(false)
      setCategories(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuOptions = async () => {
    try {
      setLoading(true)
      const rs = await Api.menu.fetchMenuOptionList({
        search: queries.get('searchOption'),
        brand: queries.getIn(['brand', '_id']),
        store: queries.getIn(['itemSearch', 'store', '_id'], ''),
        source: queries.getIn(['itemSearch', 'source', '_id'], ''),
        page: queries.get('page'),
        limit: 0,
        majorBrand: websiteId,
        brandFields: 'name,slug'
      })
      setLoading(false)
      setOptions(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuUnits = async () => {
    try {
      setLoading(true)
      const rs = await Api.menu.fetchMenuUnitList({
        search: queries.get('searchOption'),
        brand: queries.getIn(['brand', '_id']),
        page: queries.get('page'),
        limit: 0,
        majorBrand: websiteId,
        brandFields: 'name,slug'
      })
      setLoading(false)
      setUnit(fromJS(rs.data.data))
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuItems = async () => {
    if (initItemType !== MENU_ITEM_TYPE.COMBO) return
    try {
      setLoading(true)
      const res = await Api.menu.fetchMenuItems(
        {
        search: queries.getIn(['itemSearch', 'name'], ''),
        brand: queries.getIn(['itemSearch', 'brand', '_id'], ''),
        store: queries.getIn(['itemSearch', 'store', '_id'], ''),
        source: queries.getIn(['itemSearch', 'source', '_id'], ''),
        page: queries.getIn(['itemSearch', 'page'], 1),
        limit: 20,
          majorBrand: websiteId,
        itemType: MENU_ITEM_TYPE.SINGLE
      })
      setLoading(false)

      if (queries.getIn(['itemSearch', 'page'], 1) > 1) {
        setItem(initItem.merge(fromJS(res.data.data)))
      } else {
        setItem(fromJS(res.data.data))
        setQueries(
          queries
            .updateIn(['itemSearch', 'totalPage'], () => res.data.meta.totalPage)
            .updateIn(['itemSearch', 'page'], () => res.data.meta.page)
        )
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchItem = async () => {
    try {
      if (!itemId) return
      setLoading(true)
      const res = await Api.menu.fetchItem(itemId)
      setLoading(false)

      let itemDetail: any = fromJS(res.data.data)
      if (!itemDetail.get('brand')) {
        itemDetail = itemDetail.updateIn(['brand'], () => itemDetail.getIn(['source', 'store', 'brand']))
      }
      if (!itemDetail.get('store')) {
        itemDetail = itemDetail.updateIn(['store'], () => itemDetail.getIn(['source', 'store']))
      }
      setMenuItem(itemDetail)
      setItemType(itemDetail.get('itemType'))

      console.log(itemDetail.toJS())

      setQueries(
        queries.updateIn(['brand'], () => itemDetail.get('brand') ?? itemDetail.getIn(['source', 'store', 'brand']))
          .updateIn(['store'], () => itemDetail.get('store') ?? itemDetail.getIn(['source', 'store']))
          .updateIn(['source'], () => itemDetail.get('source'))
          .updateIn(['itemSearch'], () => fromJS({
            brand: itemDetail.get('brand'),
            store: itemDetail.get('store'),
            source: itemDetail.get('source'),
          }))
      )
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchStores = async () => {
    try {
      if (websiteId) return
      const rs = await Api.store.fetchStores(1, undefined, queries.getIn(['brand', '_id']), 200)
      setStores(fromJS(rs.data.data))
    } catch (e) {
      console.log(e)
    }
  }

  const _fetchSources = async () => {
    try {
      const rs = await Api.store.fetchSources(queries.getIn(['store', '_id']))
      setSources(fromJS(rs.data.data))
    } catch (e) {
      console.log(e)
    }
  }

  const _fetchWebsiteSource = async () => {
    try {
      if (!websiteId) return
      const res = await Api.store.fetchSources(undefined, 1, 1, websiteId)
      if (res.data.data.length > 0) {
        setWebsiteData(
          websiteData.updateIn(['source'], () => fromJS(res.data.data[0]))
        )
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onCreateCategory = async (category: MenuCategoryType) => {
    try {
      setLoading(true)
      let rs;
      if (isWebsiteItem) {
        rs = await Api.menu.createWebsiteMenuCategory(
          websiteId!!,
          category.name
        )
      } else {
        rs = await Api.menu.createMenuCategory(
          {
            ...category,
            source: isWebsiteItem ? websiteData.getIn(['source', '_id']) : queries.getIn(['itemSearch', 'source', '_id'], '')
          }
        )
      }
      setLoading(false)
      onCateSearchChanged('')
      window.alert('Thêm mới thành công!')
      const cat = fromJS(rs.data.data)
      console.log('-->>> ', cat.toJS(), rs.data.data)
      setCategories(initCategories.push(cat))
      return cat
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return null
    }
  }

  const onCreateOption = async (option: MenuOptionType) => {
    try {
      setLoading(true)
      await Api.menu.createMenuOption(option)
      onOptionSearchChanged('')
      setLoading(false)
      window.alert('Thêm mới thành công!')
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onCreate = async (item: MenuItem) => {
    try {
      await Api.menu.createMenuItem(item)
      window.alert('Thêm mới thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const onEdit = async (item: MenuItem) => {
    try {
      await Api.menu.editMenuItem(menuItem.get('_id'), item)
      window.alert('Chỉnh sửa thành công!')
      navigate(-1)
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _initData = async () => {
    if (!app.brands) {
      await app.fetchBrand()
    }

    if (itemId) {
      await _fetchItem()
    }

    if (!!queries.getIn(['brand', '_id'])) {
      await _fetchMenuCategories()

      await _fetchMenuOptions()

      await _fetchMenuUnits()

      await _fetchMenuItems()
    }
  }

  const onBrandChanged = (brand?: any) => {
    let newQueries = queries.updateIn(['brand'], () => brand)
    if (isWebsiteItem && websiteData.get('source')) {
      newQueries = newQueries.updateIn(['sourceId'], () => websiteData.getIn(['source', '_id']))
    }
    setQueries(newQueries)
  }

  const onStoreChanged = (store?: any) => {
    setQueries(
      queries.updateIn(['store'], () => store)
    )
  }

  const onSourceChanged = (source?: any) => {
    setQueries(
      queries.updateIn(['source'], () => source).updateIn(['itemSearch'], () => fromJS({
        brand: queries.get('brand'),
        store: queries.get('store'),
        source: source,
      }))
    )
  }

  const onItemTypeChanged = (itemType: MenuItemType) => {
    setQueries(queries.updateIn(['itemType'], () => fromJS(itemType)))
  }

  const onCateSearchChanged = (cate?: string) => {
    setQueries(queries.updateIn(['searchCate'], () => cate))
  }

  const onOptionSearchChanged = (option?: string) => {
    setQueries(queries.updateIn(['searchOption'], () => option))
  }

  const onItemSearchChanged = (name?: string) => {
    setQueries(
      queries
        .updateIn(['itemSearch', 'name'], () => name)
        .updateIn(['itemSearch', 'page'], () => 1)
        .updateIn(['itemSearch', 'totalPage'], () => undefined)
    )
  }

  React.useEffect(() => {
    if (queries.size < 1) return

    if (!!queries.getIn(['brand', '_id'])) {
      if (isWebsiteItem) {
        _fetchMenuUnits()
        _fetchMenuCategories()
        _fetchMenuItems()
        _fetchMenuOptions()
        return
      }
      _fetchStores();
      return
    }
    setCategories(fromJS([]))
    setOptions(fromJS([]))
    setUnit(fromJS([]))
    setItem(fromJS([]))
  }, [queries.getIn(['brand', '_id'])])

  React.useEffect(() => {
    if (!!queries.getIn(['store', '_id'])) {
      _fetchSources()
    }
  }, [queries.getIn(['store', '_id'])])

  React.useEffect(() => {
    if (!!queries.getIn(['brand', '_id'])) {
      _fetchMenuUnits()
      _fetchMenuCategories()
      _fetchMenuItems()
      _fetchMenuOptions()
    }
  }, [queries.getIn(['source', '_id'])])

  useEffect(() => {
    if (queries.getIn(['itemSearch', 'totalPage']) < queries.getIn(['itemSearch', 'page'])) return

    if (queries.getIn(['itemSearch', 'brand', '_id'])) {
      _fetchMenuItems()
    }
  }, [useDebounce(queries.getIn(['itemSearch']))])

  useEffect(() => {
    if (!app.brands) {
      app.fetchBrand()
    } else if (!loading) {
      _initData()
    }
  }, [app.brands])

  useEffect(() => {
    if (!websiteId) return
    _fetchWebsite()
  }, [websiteId])

  useEffect(() => {
    if (!websiteId || !websiteData.get('website')) return
    _fetchWebsiteSource()
  }, [websiteData.get('website')])

  let value = {
    queries,
    loading,
    onBrandChanged,
    onStoreChanged,
    onSourceChanged,
    onItemTypeChanged,
    onCateSearchChanged,
    onOptionSearchChanged,
    onItemSearchChanged,
    initCategories,
    initOptions,
    initUnit,
    initItem,
    initStores,
    initSources,
    onCreateCategory,
    onCreateOption,
    onCreate,
    setQueries,
    menuItem,
    onEdit,
    setItemType,
    websiteData
  }

  return <MenuItemCreatorContext.Provider value={value}>{children}</MenuItemCreatorContext.Provider>
}

export default MenuItemCreatorProvider
export { useMenuItemCreator }
