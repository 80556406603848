import PageLoading from "../../../components/page_loading";
import {Badge, Container, Row, Table} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import {useNavigate} from "react-router";
import BackBtn from "../../../components/Button/back_btn";
import {useOrderDetail} from "../provider";
import moment from "moment";
import {orderStateInfo} from "../../../util/common_utils";
import {numberFormat} from "../../../config";
import DishItem from "./dish_item";

const Views = () => {

  const useOrderDetailProvider = useOrderDetail();
  const navigate = useNavigate();

  const _getOrderStatus = () => {
    return orderStateInfo(useOrderDetailProvider.data.get('state', ''))
  }

  const _getPaymentState = () => {
    switch (useOrderDetailProvider.data.get('paymentState', '').toLowerCase()) {
      case 'paid':
        return 'Đã thanh toán';
    }
    return 'Chưa thanh toán';
  }

  const orderStatus = _getOrderStatus()

  const sourceName = () => {
    return useOrderDetailProvider.data.getIn(['source', 'name'])
  }

  return (
    <main>
      <Nav title={'Chi tiết đơn hàng'} />
      {
        useOrderDetailProvider.loading && useOrderDetailProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4 mt-3'}>
        <div className={'shadow-card'}>
          <div className="row">
            <div className="col">
              <h5 className="mb-0 fw-light">
                Đơn hàng {sourceName()}: <strong>#{useOrderDetailProvider.data.get('code')}</strong>
              </h5>
              <p className="mt-1 opacity-50 fw-lighter">
                {moment(useOrderDetailProvider.data.get('created')).local().format('YYYY/MM/DD - HH:mm')}
              </p>
            </div>
            <div className={'col-auto'}>
              <div className={'fw-light'} style={{color: orderStatus.color }}>
                {orderStatus.label}
              </div>
              <Badge bg="success">
                {_getPaymentState()}
              </Badge>
            </div>
          </div>
          <div>
            {
              useOrderDetailProvider.data.get('note') &&
              <>
                <span className={'fw-light opacity-50'}>GHI CHÚ: </span>
                {useOrderDetailProvider.data.get('note')}
              </>
            }
          </div>
        </div>

        <div className={'shadow-card mt-3'}>
          <Row>
            <div className={'col'}>
              <h5>CỬA HÀNG</h5>
              <div className={'fw-light mt-3'}>
                {useOrderDetailProvider.data.getIn(['store', 'brand', 'name'])}
              </div>
              <div className={'fw-light'}>
                {useOrderDetailProvider.data.getIn(['store', 'name'])}
              </div>
            </div>
            <div className={'col'}>
              <h5>KHÁCH HÀNG</h5>
              <div className={'mt-3'}>
                <span className={'fw-light opacity-50'}>Tên KH: </span>
                {useOrderDetailProvider.data.getIn(['customer', 'name'])}
              </div>
              <div>
                <span className={'fw-light opacity-50'}>SĐT: </span>
                {useOrderDetailProvider.data.getIn(['customer', 'phone'])}
              </div>
              <div>
                <span className={'fw-light opacity-50'}>Địa chỉ: </span>
                {useOrderDetailProvider.data.getIn(['customer', 'address'])}
              </div>
            </div>
          </Row>
        </div>

        <div className={'shadow-card mt-3'}>
          <Table striped hover responsive className={'mt-3'}>
            <thead>
            <tr>
              <th>STT</th>
              <th>Món</th>
              <th>Số lượng</th>
              <th>Đơn giá</th>
              <th>Thành tiền</th>
            </tr>
            </thead>
            <tbody>
            {
              useOrderDetailProvider.data.getIn(['items'])?.map((dish: any, index: number) => {
                return (
                  <DishItem key={`${dish.get('name')}_${index}`} index={index} dish={dish}/>
                )
              })
            }
            <tr>
              <td colSpan={4} className={'text-end'}>
                <strong className={'me-3'}>Tổng tiền</strong>
              </td>
              <td>
                <strong>
                  {numberFormat.format(useOrderDetailProvider.data.getIn(['totalPrice']))}
                </strong>
              </td>
            </tr>
            <tr>
              <td colSpan={4} className={'text-end'}>
                <strong className={'me-3'}>
                  Phí khuyến mãi {
                  !!useOrderDetailProvider.data.getIn(['promotion', 'promoCode']) ?
                  `(${useOrderDetailProvider.data.getIn(['promotion', 'promoCode'])})` : ''
                }
                </strong>
              </td>
              <td>
                <strong className={'text-danger'}>
                  {useOrderDetailProvider.data.getIn(['promotion', 'promotionFee']) > 0 && '-'}
                  {numberFormat.format(useOrderDetailProvider.data.getIn(['promotion', 'promotionFee']))}
                </strong>
              </td>
            </tr>
            <tr>
              <td colSpan={4} className={'text-end'}>
                <strong className={'me-3'}>Phí hoa hồng ({useOrderDetailProvider.data.getIn(['commissionValue'])}%)</strong>
              </td>
              <td>
                <strong className={'text-danger'}>
                  {useOrderDetailProvider.data.getIn(['commissionFee']) > 0 && '-'}{numberFormat.format(useOrderDetailProvider.data.getIn(['commissionFee']))}
                </strong>
              </td>
            </tr>
            <tr>
              <td colSpan={4} className={'text-end'}>
                <strong className={'me-3'}>Net</strong>
              </td>
              <td>
                <strong className={'text-success'}>
                  {numberFormat.format(useOrderDetailProvider.data.getIn(['netPrice']))}
                </strong>
              </td>
            </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </main>
  );
}

export default Views;