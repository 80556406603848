import { useEffect, useState } from 'react'
import { FormLabel, Stack } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import { useParams } from 'react-router'
import { useHubCreator } from '../provider'
import OpenTimeItem from './openTimeItem'

const DAYS = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN']

export type openTimeType = {
  times: Array<string[]>
  day: string
}

type OpenTimeProps = {
  onOpenTimeChanged: (openTimes: openTimeType[]) => void
}

const OpenTime: React.FC<OpenTimeProps> = (props) => {
  const [openTime, setOpenTime] = useState<openTimeType[]>([])
  const hubCreatorProvider = useHubCreator()
  const { id } = useParams()

  const handleAdd = () => {
    if (openTime.length >= 7) {
      window.alert('Không thể  thực hiện!')
      return
    }
    setOpenTime([
      ...openTime,
      { day: DAYS.find((e) => !openTime.find((o) => o.day === e)) || 'T2', times: [[]] }
    ])
  }

  const handleDelete = (index: number) => {
    setOpenTime([...openTime.filter((el, i) => i !== index)])
  }

  const handleDaychanged = (value: string, index: number) => {
    if (openTime.map((el) => el.day).includes(value)) {
      window.alert(`${value} đã tồn tại.`)
      return
    }
    openTime[index].day = value
    setOpenTime([...openTime])
  }

  const handleTimeChanged = (times: Array<string[]>, index: number) => {
    openTime[index].times = times
    setOpenTime([...openTime])
  }

  useEffect(() => {
    props.onOpenTimeChanged(openTime)
  }, [openTime])

  useEffect(() => {
    if (hubCreatorProvider.hub.get('openTimes')) {
      let openTimes = hubCreatorProvider.hub.toJS().openTimes || []
      setOpenTime(openTimes)
    }
  }, [hubCreatorProvider.hub])

  useEffect(() => {
    if (!id) {
      setOpenTime([{ day: 'T2', times: [[]] }])
    }
  }, [])

  return (
    <>
      <div>
        <FormLabel>
          Giờ mở cửa <span className="text-danger fs-5">*</span>
        </FormLabel>
        <FaPlus size="25" className="btn text-primary p-0 ps-1" color="red" onClick={handleAdd} />
      </div>
      <div>
        <Stack gap={2}>
          {!!openTime &&
            openTime.map((item, index) => (
              <div className="d-flex" key={index}>
                {openTime.length > 1 && (
                  <div className="m-2 align-items-center d-flex justijy-content-center">
                    <FaPlus
                      className="ms-2 mt-2 btn p-0"
                      style={{ transform: 'rotate(45deg)' }}
                      size="30"
                      color="red"
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                )}
                <OpenTimeItem
                  openTime={openTime}
                  openTimeItem={item}
                  key={index}
                  index={index}
                  dayOptions={DAYS}
                  handleDayChanged={handleDaychanged}
                  handleTimeChanged={handleTimeChanged}
                />
              </div>
            ))}
        </Stack>
      </div>
    </>
  )
}

export default OpenTime
