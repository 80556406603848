import React from 'react';
import Views from "./components/views";
import MenuCloneToWebsiteProvider from "./provider";

const MenuCloneToWebsite = () => {

  return (
    <MenuCloneToWebsiteProvider>
      <Views/>
    </MenuCloneToWebsiteProvider>
  );
};

export default MenuCloneToWebsite;