import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";

interface OrderCreatorContextType {
  loading: boolean,
  config: any,
  order: any,
  menu: any,
  initConfig: (brand?: any, store?: any) => void,
  onBrandChanged: (brand?: any) => void,
  onStoreChanged: (store?: any) => void,
  onSourceChanged: (source?: any) => void,
  removeMenuItem: (removeItem: any) => void,
  onMenuItemSelected: (currentItem: any, newItem?: any) => void,
  onAddMoreMenuItem: () => void,
  onMenuItemQuantityChanged: (itemChanged: any, quantity: number) => void,
  onMenuItemOptionChoiceQuantityChanged: (itemKey: any, optionId: any, choiceId: any, quantity: number) => void,
  onMenuItemNoteChanged: (itemKey: any, note: string) => void,
  onMenuItemPriceChanged: (itemKey: any, price: number) => void,
  updateViewState: (state: string) => void,
  clearOrder: () => void
}

let OrderCreatorContext = React.createContext<OrderCreatorContextType>(null!);

const useOrderCreator = () => {
  return React.useContext(OrderCreatorContext);
};

function OrderCreatorProvider({ children }: { children: React.ReactNode }) {

  const [config, setConfig] = React.useState<any>(fromJS({}));
  const [order, setOrder] = React.useState<any>(fromJS({
    items: [{
      _id: new Date().getTime(),
      key: `item_0_${new Date().getTime()}`
    }]
  }));
  const [loading, setLoading] = React.useState(false);
  const [menu, setMenu] = React.useState(fromJS([]));
  const app = useApp();

  const _initData = async() => {
    if (!app.brands) {
      await app.fetchBrand();
    }
  }

  const _loadSources = async(storeId: any) => {
    try {
      const rs = await Api.fetchSources(storeId);
      setConfig(
        config.updateIn(['sources'], () => fromJS(rs.data.data))
      )
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  const _loadMenu = async(sourceId: any) => {
    try {
      const rs = await Api.fetchMenuList(sourceId);
      setMenu(
        fromJS(rs.data.data)
      )
    } catch (e: any) {
      console.log(e)
      window.alert(e?.response?.data?.error?.message ?? 'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau')
    }
  }

  const initConfig = (brand?: any, store?: any) => {
    if (!brand) return
    let newConfig = config.updateIn(['brand'], () => fromJS(brand))
    if (!!store) newConfig = newConfig.updateIn(['store'], () => fromJS(store))
    setConfig(
      newConfig.updateIn(['sources'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['view_state'], () => undefined)
    )
  }

  const onBrandChanged = async (brand?: any) => {
    setConfig(
      config.updateIn(['brand'], () => brand)
        .updateIn(['store'], () => undefined)
        .updateIn(['sources'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['view_state'], () => undefined)
    )
  }

  const onStoreChanged = async (store?: any) => {
    setConfig(
      config.updateIn(['store'], () => store)
        .updateIn(['sources'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['view_state'], () => undefined)
    )
  }

  const onSourceChanged = async (source?: any) => {
    setConfig(
      config.updateIn(['source'], () => source)
        .updateIn(['view_state'], () => undefined)
    )
    setMenu(fromJS([]));
    setOrder(fromJS({
      items: [{
        _id: new Date().getTime(),
        key: `item_0_${new Date().getTime()}`
      }]
    }));
  }

  const onMenuItemSelected = async (currentItem: any, newItem?: any) => {
    const currentItemIndex = order.get('items', []).findIndex((item: any) => item.get('key') === currentItem.get('key'));
    if (currentItemIndex < 0) return;

    setOrder(
      order.updateIn(['items', currentItemIndex], () =>
        newItem.updateIn(['quantity'], () => 1)
          .updateIn(['originalPrice'], () => newItem.get('price', 0))
          .updateIn(['key'], () => currentItem.get('key'))
      )
    )
  }

  const onMenuItemQuantityChanged = async (itemChanged: any, quantity: number) => {
    console.log('-> onMenuItemQuantityChanged: ', itemChanged.toJS())
    const currentItemIndex = order.get('items', []).findIndex((item: any) => item.get('key') === itemChanged.get('key'));
    if (currentItemIndex < 0) return;

    setOrder(
      order.updateIn(['items', currentItemIndex, 'quantity'], () => quantity)
    )
  }

  const onMenuItemNoteChanged = async (itemKey: any, note: string) => {
    const currentItemIndex = order.get('items', []).findIndex((item: any) => item.get('key') === itemKey);
    if (currentItemIndex < 0) return;

    setOrder(
      order.updateIn(['items', currentItemIndex, 'note'], () => note)
    )
  }

  const onMenuItemOptionChoiceQuantityChanged = async (itemKey: any, optionId: any, choiceId: any, quantity: number) => {
    const currentItemIndex = order.get('items', [])
      .findIndex((item: any) => item.get('key') === itemKey);
    if (currentItemIndex < 0) return;

    const optionIndex = order.getIn(['items', currentItemIndex, 'options'], [])
      .findIndex((option: any) => option.get('_id') === optionId)
    if (optionIndex < 0) return;

    const choiceIndex = order.getIn(['items', currentItemIndex, 'options', optionIndex, 'choices'], [])
      .findIndex((choice: any) => choice.get('_id') === choiceId)
    if (choiceIndex < 0) return;

    setOrder(
      order.updateIn(['items', currentItemIndex, 'options', optionIndex, 'choices', choiceIndex, 'quantity'], () => quantity)
    )
  }

  const onAddMoreMenuItem = () => {
    setOrder(
      order.updateIn(['items'], (items: any) => items.push(fromJS({
        _id: new Date().getTime(),
        key: `item_${items.size}_${new Date().getTime()}`
      })))
    )
  }

  const onMenuItemPriceChanged = async (itemKey: any, price: number) => {
    const currentItemIndex = order.get('items', []).findIndex((item: any) => item.get('key') === itemKey);
    if (currentItemIndex < 0) return;

    setOrder(
      order.updateIn(['items', currentItemIndex, 'price'], () => price)
    )
  }

  const removeMenuItem = (removeItem: any) => {
    const currentItemIndex = order.get('items', []).findIndex((item: any) => item.get('key') === removeItem.get('key'));
    if (currentItemIndex < 0) return;
    setOrder(
      order.deleteIn(['items', currentItemIndex])
    )
  }

  const updateViewState = (state: string) => {
    setConfig(
      config.updateIn(['view_state'], () => state)
    )
  }

  const clearOrder = () => {
    setConfig(
      config.updateIn(['view_state'], () => undefined)
    )
    setOrder(
      fromJS({
        items: [{
          _id: new Date().getTime(),
          key: `item_0_${new Date().getTime()}`
        }]
      })
    )
  }

  React.useEffect(() => {
    _initData();
  }, []);

  React.useEffect(() => {
  }, [app.brands]);

  React.useEffect(() => {
    if (!config.getIn(['store', '_id'])) return;
    _loadSources(config.getIn(['store', '_id']))
  }, [config.get('store')]);

  React.useEffect(() => {
    if (!config.get('source')) return;
    _loadMenu(config.getIn(['source', '_id']))
  }, [config.get('source')]);

  let value = {
    config,
    loading,
    order,
    menu,
    initConfig,
    onBrandChanged,
    onStoreChanged,
    onSourceChanged,
    onMenuItemSelected,
    onAddMoreMenuItem,
    onMenuItemQuantityChanged,
    onMenuItemOptionChoiceQuantityChanged,
    onMenuItemNoteChanged,
    onMenuItemPriceChanged,
    removeMenuItem,
    updateViewState,
    clearOrder
  };

  return <OrderCreatorContext.Provider value={value}>{children}</OrderCreatorContext.Provider>;
}

export default OrderCreatorProvider;
export { useOrderCreator };