import {Box, Stack} from "@mui/material";
import {Container, Row} from "react-bootstrap";
import SectionBrandFilter from "../../../components/Filters/section_brand_filter";
import NormalModal from "../../../components/Modal/normalModal";
import Nav from "../../../components/Navbars/Nav";
import {useTablesProvider} from "../provider";
import SectionTables from "./section_tables";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {fromJS} from "immutable";

const View = () => {
    const tableProvider = useTablesProvider()
    const [searchParams, setSearchParams] = useSearchParams()


    const _onBrandChanged = (brand?: any) => {
        tableProvider.onBrandChanged(brand);
    }

    const _onStoreSelected = (store?: any) => {
        tableProvider.onStoreChanged(store);
    }

    const _onSourceSelected = (source?: any) => {
        tableProvider.onSourceChanged(source);
    }

    const _onCheckedChange = async () => {
        const isChecked = !tableProvider.queries.getIn(['source', 'pickingTableEnabled'])

        if (!isChecked) {
            _onSubmit(false)
            tableProvider.updatePickingTableEnabled(isChecked)
            return
        }

        const numberCount = await tableProvider.getTableCount()
        const value = window.prompt('Số lượng bàn', `${numberCount ?? ''}`)
        if (!value) return
        _onSubmit(true, parseInt(value))
        tableProvider.updatePickingTableEnabled(isChecked)
    }

    const _onSubmit = (isCheck: boolean, count?: number) => {

        tableProvider.setConfig({
            count: count ?? 0,
            sourceId: tableProvider.queries.getIn(['source', '_id']),
            storeId: tableProvider.queries.getIn(['store', '_id']),
            pickingTableEnabled: isCheck
        })
        tableProvider.onSubmitConfig()
    }

    useEffect(() => {
        if (!searchParams.get('brand')) return
        if (tableProvider.queries.getIn(['brand', '_id'])) return

        tableProvider.updateInitData(searchParams)
        tableProvider.setLoading(true)

    }, [tableProvider.queries.getIn(['brand'])])

    return (
        <main>

            <Container className={'pb-4'}>
                <Nav title={'Quản lý bàn'}/>
                <Row>
                    <SectionBrandFilter
                        brandSelected={tableProvider.queries.getIn(['brand'])}
                        storeSelected={tableProvider.queries.getIn(['store'])}
                        sourceSelected={tableProvider.queries.getIn(['source'])}
                        onBrandSelected={_onBrandChanged}
                        onStoreSelected={_onStoreSelected}
                        onSourceSelected={_onSourceSelected}/>
                </Row>
                <Box height={'16px'}/>


                <Stack alignItems={'start'}>
                    {
                        tableProvider.queries.get('source')?.get('_id') &&
                        <Stack direction={'row'}>
                            <div className="form-check form-switch">
                                <input
                                    disabled={false}
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    onChange={_onCheckedChange}
                                    checked={tableProvider.queries?.getIn(['source', 'pickingTableEnabled'])}/>
                            </div>
                            <div>Bật tắt chọn bàn</div>
                        </Stack>
                    }
                </Stack>
                {
                    tableProvider.queries?.getIn(['source', 'pickingTableEnabled']) && <SectionTables/>
                }

            </Container>

        </main>
    );
}

export default View