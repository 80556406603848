import React from 'react';
import Views from "./components/views";
import MenuCloneProvider from "./provider";

const MenuClone = () => {

  return (
    <MenuCloneProvider>
      <Views/>
    </MenuCloneProvider>
  );
};

export default MenuClone;