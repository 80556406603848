import {AxiosResponse} from "axios";
import {_get, _post} from "./request";

export const GrabApi = {
  fetchOrders(store: string, from: number, to: number, page: number): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/grab`, {
      from, to, page
    })
  },
  fetchOrderDetail(store: string, orderId: string, existingOrder: boolean = false): Promise<AxiosResponse<any>> {
    return _get(`brand/admin/v1/stores/${store}/platform/grab/${orderId}${existingOrder ? '?existing-order=1' : ''}`)
  },
  createOrder(store: string, orderId: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/grab/${orderId}`)
  },
  updateAccount(store: string, username: string, password: string): Promise<AxiosResponse<any>> {
    return _post(`brand/admin/v1/stores/${store}/platform/grab/account`, {
      username,
      password
    })
  },
}