import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'
import Chip from '../../../components/Chip/Chip'
import NormalModal from '../../../components/Modal/normalModal'
import { useModalState } from '../../../util/hook/useModalState'
import {useMenuItemCreator} from "../provider";
import MenuItemCategoryCreator from "../../MenuItemCategoryCreator";
import {useParams} from "react-router";
import WebsiteMenuItemCategoryCreator
  from "../../WebsiteMenuItemCategories/components/website_menu_item_category_creator";

interface CategoryProps {
  categories: any
  brand: string
  store: string
  source: string
  onChange: (categories: any) => void
  onDelete: (categories: any) => void
}

const Categories: React.FC<CategoryProps> = ({ categories, onChange, onDelete, brand, store, source }) => {
  let { queries, onCateSearchChanged, initCategories, onCreateCategory } = useMenuItemCreator()
  const [newCate, setNewCate] = useState<any>(fromJS({}))
  const { isOpen, onClose, onOpen } = useModalState()
  const {websiteId} = useParams()
  const isWebsiteItem = !!websiteId

  const cateOptions = initCategories.filter((cate: any) =>
    cate.get('name', '').toLowerCase().includes(queries.get('searchCate', '').toLowerCase())
  )

  const _createCategory = async () => {
    if (!newCate.get('brandId', '')) {
      window.alert('Thương hiệu là bắt buộc!')
      return
    }
    if (!newCate.get('name', '')) {
      window.alert('Tên danh mục là bắt buộc!')
      return
    }
    onClose()
    const cat = await onCreateCategory(newCate.toJS())
    if (cat) {
      onChange(cat)
    }
  }

  const _createWebsiteCategory = async () => {
    if (!newCate.get('name')) {
      window.alert('Tên danh mục là bắt buộc!')
      return
    }
    onClose()
    const cat = await onCreateCategory(newCate.toJS())
    if (cat) {
      onChange(cat)
    }
  }

  const handleOpen = () => {
    setNewCate(newCate.updateIn(['name'], () => queries.get('searchCate', '')))
    onOpen()
  }

  const handleSubmit = async () => {
    if (isWebsiteItem) {
      _createWebsiteCategory()
    } else {
      _createCategory()
    }
  }

  useEffect(() => {
    if (brand) {
      setNewCate(
        newCate.updateIn(['brandId'], () => brand)
          .updateIn(['storeId'], () => store)
          .updateIn(['sourceId'], () => source)
      )
    }
  }, [brand])
  return (
    <>
      <Dropdown className="col-12 col-md-3">
        <Dropdown.Toggle
          className="filter text-start"
          variant={'light'}
          style={{ whiteSpace: 'normal' }}
        >
          <FormControl
            className="mb-2 w-auto"
            placeholder="Tìm kiếm..."
            onChange={(e: any) => onCateSearchChanged(e.target.value)}
            value={queries.get('searchCate')}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {!!queries.get('searchCate', '') && (
            <Dropdown.Item onClick={handleOpen} className="text-decoration-underline text-primary">
              {'Thêm mới "' + queries.get('searchCate', '') + '"'}
            </Dropdown.Item>
          )}
          {cateOptions.size ? (
            cateOptions.map((cate: any, i: number) => (
              <Dropdown.Item
                key={i}
                onClick={() => onChange(cate)}
                disabled={!!categories.find((c: any) => c.get('_id') === cate.get('_id'))}
              >
                {cate.get('name')}
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              {!queries.getIn(['brand', '_id'], '')
                ? 'Vui lòng chọn thương hiệu!'
                : 'Không có dữ liệu!'}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <div className="d-flex flex-wrap gap-3">
        {categories.map((cate: any, i: number) => (
          <Chip key={i} text={cate.get('name')} onDeleteClicked={() => onDelete(cate)} />
        ))}
      </div>
      {
        !isWebsiteItem &&
        <NormalModal
          open={isOpen}
          options={{
            title: 'Thêm danh mục',
            content: <MenuItemCategoryCreator category={newCate} setCategory={setNewCate} disableTargetSelector={true}/>,
            confirmationText: 'Thêm'
          }}
          onCancel={onClose}
          onConfirm={handleSubmit}
        />
      }
      {
        !!isWebsiteItem &&
        <NormalModal
          open={isOpen}
          options={{
            title: 'Thêm danh mục',
            content: <WebsiteMenuItemCategoryCreator category={newCate} setCategory={setNewCate} />,
            confirmationText: 'Thêm'
          }}
          onCancel={onClose}
          onConfirm={handleSubmit}
        />
      }
    </>
  )
}

export default Categories
