import React from 'react';
import './styles.css';
import Views from "./components/views";
import FoodAppConnectProvider from "./provider";

const FoodAppConnect = () => {

  return (
    <FoodAppConnectProvider>
      <Views/>
    </FoodAppConnectProvider>
  );
};

export default FoodAppConnect;