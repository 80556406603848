import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";

interface OrderDetailContextType {
  data: any,
  loading: boolean
}

let OrderDetailContext = React.createContext<OrderDetailContextType>(null!);

const useOrderDetail = () => {
  return React.useContext(OrderDetailContext);
};

function OrderDetailProvider({ children }: { children: React.ReactNode }) {

  const location: any = useLocation();
  const [data, setData] = React.useState<any>(fromJS([]))
  const [loading, setLoading] = React.useState(false);
  const {orderId} = useParams();

  const _fetchOrderDetail = async() => {
    if (!orderId) return
    setLoading(true)
    try {
      const rs: any = await Api.order.fetchOrderDetail(orderId!!)
      setData(fromJS(rs.data.data))
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
    }
    setLoading(false)
  }

  React.useEffect(() => {
    _fetchOrderDetail()
  }, [])

  let value = {
    data,
    loading
  };

  return <OrderDetailContext.Provider value={value}>{children}</OrderDetailContext.Provider>;
}

export default OrderDetailProvider;
export { useOrderDetail };