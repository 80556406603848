import React from 'react'
import { Dropdown } from 'react-bootstrap'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import { FaCogs } from 'react-icons/fa'
import { useNavigate } from 'react-router'
import { useModal } from '../../../components/Modal/modalProvider'
import { BankConfigModal } from '../../Stores/BankConfig'
import { useModalState } from '../../../util/hook/useModalState'
import { fromJS } from 'immutable'
import { BankModalType } from '../../../repository/model/enums/BankModalType'
import { useHubs } from '../provider'

export interface hubItemType {
  hub: any
  index: number
  page: number
  onDelete: (hubId: string) => void
}

const HubItem = (props: hubItemType) => {
  const { hub, index, page } = props
  const navigate = useNavigate()
  const modal = useModal()
  const provider = useHubs()
  const { onOpen, isOpen, onClose } = useModalState()

  const onEditClicked = () => {
    navigate(`/hubs/${hub.get('_id')}`)
  }

  const onUserListClicked = () => {
    navigate(`/hubs/${hub.get('_id')}/users`)
  }

  const handleDelete = async () => {
    try {
      await modal({ title: 'Xác nhận xóa hub này?' })
      props.onDelete(hub.get('_id'))
    } catch (error) {
      console.log(error)
    }
  }
  const _onBankInfoUpdated = (hub: any) => {
    provider.onBankInfoChanged(hub)
  }

  const renderOpentimes = () => {
    const openTimes = hub.get('openTimes')
    return (
      <>
        {!!openTimes &&
          openTimes.map((el: any, index: number) => {
            const times = el
              .get('times')
              .map((el: string[]) => el.join('-'))
              .join(' ~ ')
            return <div key={index}>{!!times && el.get('day') + ' : ' + times}</div>
          })}
      </>
    )
  }

  const getStatus = () => {
    return hub.get('status') === 'active' ? (
      <div className="text-success fw-bold">Đang hoạt đông</div>
    ) : (
      <div className="text-danger fw-bold">Ngừng hoạt động</div>
    )
  }

  return (
    <tr>
      <td>{(page - 1) * 20 + index + 1}</td>
      <td>
        <div className="order-code">{hub.get('name')}</div>
        <div className="text-wrap note-info">{hub.get('address')}</div>
      </td>
      <td>
        <div>{hub.get('slug')}</div>
      </td>
      <td>
        <div>{renderOpentimes()}</div>
      </td>

      <td>
        <div className="text-wrap" style={{ minWidth: 250 }}>
          {hub.get('stores').map((el: any, i: number) => (
            <p key={i}>
              {el.getIn(['brand', 'name']) + ' - ' + el.get('name')} <br />
            </p>
          ))}
        </div>
      </td>

      <td>
        <div>{getStatus()}</div>
      </td>
      <td style={{ width: 70 }}>
        <Dropdown align="end" className="config-btn d-flex justify-content-around">
          <DropdownToggle
            id="options-button"
            variant="borderless-dark"
            bsPrefix="no-chevron"
            size="sm"
          >
            <FaCogs fontSize={20} cursor="pointer" />
          </DropdownToggle>
          <DropdownMenu
            popperConfig={{
              strategy: 'fixed'
            }}
          >
            <Dropdown.Item onClick={onUserListClicked}>Thành viên</Dropdown.Item>
            <Dropdown.Item onClick={onEditClicked}>Chỉnh sửa</Dropdown.Item>
            <Dropdown.Item onClick={onOpen}>Chỉnh sửa thông tin ngân hàng</Dropdown.Item>
            <Dropdown.Item onClick={handleDelete}>Xóa</Dropdown.Item>
          </DropdownMenu>
        </Dropdown>
      </td>
      <BankConfigModal
        data={props.hub}
        isOpen={isOpen}
        type={BankModalType.hub}
        onClose={onClose}
        onBankInfoUpdated={_onBankInfoUpdated} />
    </tr>
  )
}

export default HubItem
