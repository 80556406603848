import React from 'react'
import Views from "./components/views";
import WebsitePromotionCreatorProvider from "../WebsitePromotionCreator/provider";

const WebsitePromotionTargetApplied = () => {
  return (
    <WebsitePromotionCreatorProvider>
      <Views/>
    </WebsitePromotionCreatorProvider>
  )
}

export default WebsitePromotionTargetApplied
