import {Spinner, Table} from "react-bootstrap";
import React from "react";
import TablePagination from "../../../components/table_pagination";
import {useClosedLogs} from "../provider";
import ClosedLogItem from "./closed_log_item";
import ClosedLogsItem from "./closed_logs_item";
import ExcelReport from "./excel_export";

const SectionLogs = () => {
  const logsProvider = useClosedLogs();

  if (logsProvider.data.size < 1 && logsProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'row justify-content-between'}>
        <div className={'col-auto'}>
          { !logsProvider.loading && <ExcelReport/> }
        </div>
        <div className={'col-auto'}>
        </div>
      </div>
      <Table striped hover bordered responsive className="mt-2">
        <thead>
        <tr>
          <th>STT</th>
          <th>APP</th>
          <th>Tên</th>
          {
            logsProvider.queries.get('from', 0) > 0 &&
            !!logsProvider.isSingleDay &&
            <>
            <th></th>
            </>
          }
          {
            (logsProvider.queries.get('from', 0) <= 0 || (!logsProvider.isSingleDay && logsProvider.queries.get('from', 0))) &&
            <>
            <th>Status</th>
            <th>Note</th>
            <th>Thời gian</th>
            </>
          }
        </tr>
        </thead>
        <tbody>
        {
          logsProvider.queries.get('from', 0) > 0 &&
          !!logsProvider.isSingleDay &&
          logsProvider.timelines.map((item: any, index: number) => {
            return <ClosedLogsItem
              key={`closed_logs_item_${item.get('store')}_${item.get('foodApp')}`}
              index={index}
              page={logsProvider.queries.get('page')}
              log={item}/>
          })
        }
        {
          (logsProvider.queries.get('from', 0) <= 0 || (!logsProvider.isSingleDay && logsProvider.queries.get('from', 0))) &&
          logsProvider.data.map((item: any, index: number) => {
            return <ClosedLogItem
              key={`closed_log_item_${item.get('_id')}`}
              index={index}
              page={logsProvider.queries.get('page')}
              log={item}/>
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!logsProvider.data && logsProvider.data.size >= 10) ||
          logsProvider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
          page={logsProvider.queries.get('page')}
          onPreviousPage={logsProvider.onPreviousPage}
          onNextPage={logsProvider.onNextPage}
          onFirstPage={logsProvider.onFirstPage}/>
      }
    </div>
  )
}

export default SectionLogs;