import {numberFormat} from "../../../config";
import React from "react";

interface DishItemType {
  dish: any,
  index: number
}

const DishItem = (props: DishItemType) => {

  const [price, setPrice] = React.useState<number>(0);

  React.useEffect(() => {
    let newPrice = props.dish.get('itemPrice', 0);
    for (let op of props.dish.get('options')) {
      if (!!op.get('choices')) {
        for (let choice of op.get('choices')) {
          newPrice += choice.get('price') * choice.get('quantity')
        }
      }
    }
    setPrice(newPrice);
  }, [props.dish])


  return (
    <tr>
      <td>
        {props.index + 1}
      </td>
      <td>
        {props.dish.get('itemName')}
        <ul className={'note-info'}>
          {
            props.dish.get('options')?.map((option: any, index: number) => {
              return (
                <li key={`${option.get('name')}_${index}`}>
                  { option.get('optionName') }: {
                  option.get('choices').map((choice: any) => {
                    let text = '';
                    if (choice.quantity > 1) {
                      text = `${choice.quantity} x `
                    }
                    text += choice.get('name')
                    return text
                  }).join(', ')
                }
                </li>
              )
            })
          }
        </ul>
      </td>
      <td>
        {props.dish.get('quantity')}
      </td>
      <td>
        {numberFormat.format(price)}
      </td>
      <td>
        {numberFormat.format(props.dish.get('quantity') * price)}
      </td>
    </tr>
  )
}

export default DishItem;