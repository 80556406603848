import { AxiosResponse } from 'axios'
import { _get, _post, _postFile } from './request'
import { OrderApi } from './orders'
import { StoreApi } from './stores'
import { BaeminApi } from './baemin'
import { GrabApi } from './grab'
import { HubApi } from './hubs'
import { ShopeeApi } from './shopee'
import { GoFoodApi } from './gofood'
import { BeFoodApi } from './befood'
import { CrmApi } from './crm'
import {BrandApi} from "./brands";
import {MenuApi} from "./menu";
import {BannerApi} from "./banner";
import {WebPromotionApi} from "./website-promotion";
import { LogsApi } from './logs'
import { InventoryApi } from './inventory'
import { UserApi } from './users'
import {TableApi} from "./table";

export const Api = {
  login(username: string, password: string): Promise<AxiosResponse<any>> {
    return _post(`user/v1/users/login`, {
      email: username,
      password
    })
  },
  fetchSources(storeId: any): Promise<AxiosResponse<any>> {
    return _get(`brand/v1/stores/${storeId}/sources`)
  },
  fetchMenuList(sourceId: any): Promise<AxiosResponse<any>> {
    return _get(`brand/v1/menu?source=${sourceId}`)
  },
  uploadImage(file: File): Promise<AxiosResponse<any>> {
    return _postFile(`image/v1/images`, file)
  },
  fetchLocationFromAddress(query: string, session: string): Promise<AxiosResponse<any>> {
    return _get(`map/v1/places/autocomplete?query=${query}&sessiontoken=${session}`)
  },
  fetchLocationFromId(placeId: string): Promise<AxiosResponse<any>> {
    return _get(`map/v1/geocode?placeID=${placeId}`)
  },
  fetchAllQuerySources(brands: any, hubs?: any): Promise<AxiosResponse<any>> {
    const filters: any = {
      brands
    }
    if (hubs) {
      filters['hubs'] = hubs
    }
    return _post(`brand/admin/v1/sources/names`, {
      filters,
    })
  },
  order: OrderApi,
  store: StoreApi,
  table: TableApi,
  baemin: BaeminApi,
  grab: GrabApi,
  hub: HubApi,
  shopee: ShopeeApi,
  gofood: GoFoodApi,
  befood: BeFoodApi,
  crm: CrmApi,
  brand: BrandApi,
  menu: MenuApi,
  banner: BannerApi,
  webPromotion: WebPromotionApi,
  log: LogsApi,
  inventory: InventoryApi,
  user: UserApi
}
