import { Stack } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'

interface ChipProps {
  data?: any
  text: string
  onDeleteClicked?: (data: any) => void
}

const Chip: React.FC<ChipProps> = ({ data, onDeleteClicked, text }) => {
  return (
    <Stack direction="horizontal" gap={2} className="px-2 py-1 bg-primary rounded-pill">
      <div className="text-light text-nowrap ps-1">{text}</div>
      {!!onDeleteClicked && (
        <TiDelete
          fontSize={25}
          cursor="pointer"
          color="#d8d8d8"
          onClick={() => !!onDeleteClicked && onDeleteClicked(data)}
        />
      )}
    </Stack>
  )
}

export default Chip
