import {useEffect, useState} from 'react'
import {FormControl, Row} from 'react-bootstrap'
import useDebounce from '../../../util/hook/useDebounce'
import {useWebsitePromotion} from "../provider";


const PromotionFilter = () => {
  const [search, setSearch] = useState<string>('')
  const debouncedValue = useDebounce<string>(search)
  const {onSearchChanged} = useWebsitePromotion()

  useEffect(() => {
    onSearchChanged(search)
  }, [debouncedValue])
  return (
    <Row>
      <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
        <div className={'shadow-card'}>
          <h4 className={'section-title'}>Mã giảm giá</h4>
          <FormControl
            type="text"
            placeholder="Mã giảm giá"
            value={search}
            onChange={(el) => setSearch(el.target.value)}
          />
        </div>
      </div>
    </Row>
  )
}

export {PromotionFilter}
