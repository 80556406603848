import {numberFormat} from "../../../config";
import React from "react";

interface MenuItemType {
  item: any,
  index: number
}

const MenuItem = (props: MenuItemType) => {

  const [price, setPrice] = React.useState<number>(0);
  const [originalPrice, setOriginalPrice] = React.useState<number>(0);

  React.useEffect(() => {
    let totalPrice = props.item.get('price');
    let totalOriginalPrice = props.item.get('originalPrice', 0);
    if (!!props.item.get('options')) {
      for (let option of props.item.get('options')) {
        for (let choice of option.get('choices')) {
          totalPrice += choice.get('price') * choice.get('quantity', 0);
          totalOriginalPrice += choice.get('price') * choice.get('quantity', 0);
        }
      }
    }
    setPrice(totalPrice);
    setOriginalPrice(totalOriginalPrice);
  }, [props.item])

  const _getOptions = () => {

    let choices: any = [];
    for (let option of props.item?.get('options', [])) {
      for (let choice of option.get('choices')) {
        if (choice.get('quantity', 0) > 0) {
          choices.push(<div className={'opacity-50 ms-3'} key={`${props.item.get('key')}_menu_item_${choice.get('_id')}`}>{choice.get('quantity')} x {choice.get('name')}</div>)
        }
      }
    }

    return choices.map((o: any) => o);
  }

  return (
    <tr>
      <td>{props.index + 1}</td>
      <td>
        <strong>{props.item.get('name')}</strong>
        {_getOptions()}
      </td>
      <td>
        {props.item.get('quantity')}
      </td>
      <td>
        {numberFormat.format(originalPrice)}
      </td>
      <td>
        {numberFormat.format(price)}
      </td>
      <td>
        {numberFormat.format(price * props.item.get('quantity'))}
      </td>
    </tr>
  )
}

export default MenuItem;