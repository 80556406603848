import React from 'react';
import Views from "./components/views";
import MenuItemSourcesProvider from "./provider";

const MenuItemSources = () => {

  return (
    <MenuItemSourcesProvider>
      <Views/>
    </MenuItemSourcesProvider>);
};

export default MenuItemSources;