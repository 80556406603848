import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {MenuCategoryType} from "../../repository/model/menuItem";
import {useApp} from "../../components/AdminLayout/admin_provider";

interface MenuItemCategoriesContextType {
  queries: any,
  data: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  onStoreChanged: (store?: any) => void,
  onSourceChanged: (source?: any) => void,
  deleteMenuItemCategory: (itemId: any) => void,
  onCreateMenuItemCategory: (cat: MenuCategoryType) => any,
  onEditMenuItemCategory: (catId: string, cat: MenuCategoryType) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
}

let MenuItemCategoriesContext = React.createContext<MenuItemCategoriesContextType>(null!);

const useMenuItemCategories = () => {
  return React.useContext(MenuItemCategoriesContext);
};

function MenuItemCategoriesProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const app = useApp()

  const _fetchMenuItemCategories = async() => {
    try {
      const rs: any = await Api.menu.fetchMenuCategoryList({
        brandFields: "_id name",
        limit: 20,
        page: queries.get('page'),
        brand: queries.getIn(['brand', '_id']),
        store: queries.getIn(['store', '_id']),
        source: queries.getIn(['source', '_id']),
        search: queries.get('search')
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading || !queries.getIn(['brand', '_id'])) return
    setLoading(true)

    const list: any = await _fetchMenuItemCategories()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['store'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onStoreChanged = (store?: any) => {
    setQueries(
      queries.updateIn(['store'], () => store)
        .updateIn(['source'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onSourceChanged = (source?: any) => {
    setQueries(
      queries.updateIn(['source'], () => source)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onCreateMenuItemCategory = async (cat: MenuCategoryType) => {
    try {
      const rs = await Api.menu.createMenuCategory(cat)
      window.alert('Thêm mới thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const deleteMenuItemCategory = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const catItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.menu.deleteCategory(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, catItem))
      return null
    }
  }

  const onEditMenuItemCategory = async (catId: string, cat: MenuCategoryType) => {
    try {
      await Api.menu.editMenuCategory(catId, cat)
      window.alert('Chỉnh sửa thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  React.useEffect(() => {
    if (!app.brands) {
      app.fetchBrand();
      return;
    }
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, [app.brands]);

  React.useEffect(() => {
    if (queries.size < 2) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onSourceChanged,
    onBrandChanged,
    onStoreChanged,
    deleteMenuItemCategory,
    onCreateMenuItemCategory,
    onEditMenuItemCategory,
    onNextPage,
    onPreviousPage,
    onFirstPage,
  };

  return <MenuItemCategoriesContext.Provider value={value}>{children}</MenuItemCategoriesContext.Provider>;
}

export default MenuItemCategoriesProvider;
export { useMenuItemCategories };