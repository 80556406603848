import {useStores} from "../provider";
import PageLoading from "../../../components/page_loading";
import {Container, Dropdown, Row} from "react-bootstrap";
import React from "react";
import Nav from "../../../components/Navbars/Nav";
import SectionStores from "./section_stores";
import {FaChevronDown, FaPlus} from "react-icons/fa";
import SectionFilterBrandItem from "../../../components/Filters/section_filter_brand_item";
import {useApp} from "../../../components/AdminLayout/admin_provider";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {useNavigate} from "react-router";


const Views = () => {

  const storesProvider = useStores();
  const app = useApp();
  const navigate = useNavigate();

  const _onBrandChanged = (brand?: any) => {
    storesProvider.onBrandChanged(brand);
  }

  const _onCreateClicked = () => {
    navigate('/stores/create')
  }

  return (
    <main>
      <Nav title={'Danh sách cửa hàng'} />
      {
        storesProvider.loading && storesProvider.data.size < 1 && <PageLoading/>
      }
      <Container className={'pb-4'}>
        <Row>
          <div className={'mt-3 col-6 col-xl-4 col-sm-4'}>
            <div className={'shadow-card'}>
              <h4 className={'section-title'}>
                Thương hiệu
              </h4>
              <Dropdown>
                <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
                  <div className={'hstack'}>
                    <div className={'text-truncate'}>
                      {storesProvider.queries.getIn(['brand', 'name']) ?? 'Tất cả thương hiệu'}
                    </div>
                    <FaChevronDown size={13} className={'ms-auto'}/>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <SectionFilterBrandItem
                    label={'Tất cả thương hiệu'}
                    brand={null}
                    onSelect={_onBrandChanged}/>
                  {
                    app.brands?.map((b: any) => {
                      return (
                        <SectionFilterBrandItem
                          key={`filter_brand_${b.get('_id')}`}
                          brand={b}
                          onSelect={_onBrandChanged}/>
                      )
                    })
                  }
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Row>
        <div className={'d-flex justify-content-between'}>
          <div></div>
          <div>
            <LabelIconBtn
              label={'Thêm mới'}
              icon={<FaPlus size={12} />}
              onClick={_onCreateClicked}
            />
          </div>
        </div>
        <SectionStores/>
      </Container>
    </main>
  );
}

export default Views;