import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {useLocation} from "react-router-dom";

interface StoresContextType {
  queries: any,
  data: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  onBankInfoChanged: (store?: any) => void,
  deleteStore: (storeId: any) => void
}

let StoresContext = React.createContext<StoresContextType>(null!);

const useStores = () => {
  return React.useContext(StoresContext);
};

function StoresProvider({ children }: { children: React.ReactNode }) {

  const location: any = useLocation();
  const [queries, setQueries] = React.useState<any>(fromJS({
    brand: location.state?.brand
  }));
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);

  const _fetchStores = async() => {
    try {
      const rs: any = await Api.store.fetchStores(queries.get('page'), queries.get('search'), queries.getIn(['brand', '_id']))
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await _fetchStores()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBankInfoChanged = (store?: any) => {
    if (!store) return
    const itemIndex = data.findIndex((s: any) => s.get('_id') === store._id)
    if(itemIndex != -1) return 
    setData(data.updateIn([itemIndex, 'bankInfo'], () => fromJS(store.bankInfo)))
  }

  const deleteStore = async (storeId: any) => {
    const storeIndex = data.findIndex((s: any) => s.get('_id') === storeId)

    if (storeIndex < 0) return

    const store = data.get(storeIndex)

    setData(data.delete(storeIndex))
    try {
      const rs = await Api.store.deleteStore(storeId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(storeIndex, store))
      return null
    }
  }

  React.useEffect(() => {
    if (data.size < 1 && !loading) {
      _initData();
    }
  }, []);

  React.useEffect(() => {
    if (queries.size < 2) return;
    _refresh()
  }, [queries]);

  let value = {
    queries,
    data,
    loading,
    onBrandChanged,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    onBankInfoChanged,
    deleteStore
  };

  return <StoresContext.Provider value={value}>{children}</StoresContext.Provider>;
}

export default StoresProvider;
export { useStores };