export const GOOGLE_MAP_KEY: any = process.env.REACT_APP_GOOGLE_MAP_KEY

export const MENU_ITEM_TYPE = {
  SINGLE: 'single',
  COMBO: 'combo'
}

export const STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted'
}

export const STATUS_LABEL = {
  ACTIVE: 'Mở',
  INACTIVE: 'Đóng',
  DELETED: 'Xóa'
}

export const PROMOTION_TYPE = {
  FIXED: 'fixed',
  PERCENTAGE: 'percentage'
}

export const PROMOTION_TYPE_LABEL = {
  FIXED: 'Cố định',
  PERCENTAGE: 'Phần trăm'
}