import { fromJS } from 'immutable'
import React, { useEffect, useState } from 'react'
import { Dropdown, FormControl, FormLabel, Stack } from 'react-bootstrap'
import { TiDelete } from 'react-icons/ti'
import { useParams } from 'react-router'
import { useApp } from '../../../components/AdminLayout/admin_provider'
import { useHubCreator } from '../provider'

interface ChipType {
  store: any
  onClick: (store: any) => void
}

type SelectStoreProps = {
  onStoreChanged: (stores: any) => void
}

const SelectStore: React.FC<SelectStoreProps> = (props) => {
  const hubCreatorProvider = useHubCreator()
  const [stores, setStore] = useState<any>(fromJS([]))
  const [existStore, setExitStore] = useState<string[]>([])
  const [value, setValue] = useState<string>('')
  const params = useParams()
  const app = useApp()

  useEffect(() => {
    props.onStoreChanged(stores)
  }, [stores])

  useEffect(() => {
    if (params.id && hubCreatorProvider.hub) {
      setStore(hubCreatorProvider.hub.get('stores'))
      const arrStoreId: string[] = []
      app.brands?.toJS()?.filter((brand: any) => {
        const storeId = brand.stores.filter((store: any) =>
          hubCreatorProvider.hub
            ?.toJS()
            .stores?.map((store2: any) => store2._id)
            .includes(store._id)
        )
        if (storeId.length) {
          arrStoreId.push(...brand.stores.map((store: any) => store._id))
          return brand
        }
        return false
      })
      setExitStore(arrStoreId)
    }
  }, [params.id, hubCreatorProvider.hub, app.brands])

  const storeOptions = hubCreatorProvider.stores.filter((store: any) => {
    return (
      !value ||
      store.get('name', '').toLowerCase().includes(value.toLowerCase()) ||
      store.get('address', '').toLowerCase().includes(value.toLowerCase()) ||
      store.getIn(['brand', 'name'], '').toLowerCase().includes(value.toLowerCase())
    )
  })

  const handleStoreChanged = (store: any) => {
    const storeOfTheSameBrand = hubCreatorProvider.stores.filter(
      (el: any) => el.getIn(['brand', '_id']) === store.getIn(['brand', '_id'])
    )
    const arr = existStore.filter(
      (el: string) => !storeOfTheSameBrand.map((el: any) => el.get('_id')).includes(el)
    )
    setExitStore(arr)

    setStore(stores.filter((el: any) => el !== store))
  }

  const storeItemClicked = (item: any) => {
    const storeOfTheSameBrand = hubCreatorProvider.stores.filter(
      (store: any) => store.getIn(['brand', '_id']) === item.getIn(['brand', '_id'])
    )

    setExitStore((prev) => [...prev, ...storeOfTheSameBrand.map((store: any) => store.get('_id'))])

    const newStore = stores.push(item)
    setStore(newStore)
  }

  const Chip = (props: ChipType) => {
    const handleDelete = () => {
      props.onClick(props.store)
    }

    return (
      <Stack direction="horizontal" gap={2} className="p-2 bg-primary rounded-pill">
        <div className="text-light text-nowrap">
          {(props.store.getIn(['brand', 'name'])
            ? props.store.getIn(['brand', 'name']) + ' | '
            : '') + props.store.get('name')}
        </div>
        <TiDelete fontSize={25} cursor="pointer" color="#d8d8d8" onClick={handleDelete} />
      </Stack>
    )
  }
  return (
    <>
      <FormLabel>
        Cửa hàng (Mỗi Thương hiệu chỉ được thêm 1 Cửa hàng duy nhất vào 1 Điểm bán)
      </FormLabel>
      <Dropdown>
        <Dropdown.Toggle
          className="filter text-start"
          variant={'light'}
          style={{ whiteSpace: 'normal' }}
        >
          <FormControl
            className="mx-3 my-2 w-auto"
            placeholder="Tìm kiếm..."
            onChange={(e: any) => setValue(e.target.value)}
            value={value}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {storeOptions.map((store: any, index: number) => {
            return (
              <Dropdown.Item
                disabled={existStore.includes(store.get('_id'))}
                key={index}
                onClick={() => storeItemClicked(store)}
              >
                {(store.getIn(['brand', 'name']) && store.getIn(['brand', 'name']) + ' | ') +
                  store.get('name') +
                  ' - ' +
                  store.get('address')}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>

        <Stack direction="horizontal" gap={2} className="mt-3 flex-wrap">
          {!!stores &&
            stores.map((store: any, index: number) => (
              <Chip key={index} store={store} onClick={handleStoreChanged} />
            ))}
        </Stack>
      </Dropdown>
    </>
  )
}

export default SelectStore
