import { Table } from "react-bootstrap"
import { useTablesProvider } from "../provider";
import { TableModel } from "../../../repository/model/TableModel";
import TableItem from "./table_item";
import TablePagination from "../../../components/table_pagination";

const SectionTables = () => {

    const tablesProvider = useTablesProvider()

    

    return (
        <div className={'mt-3 shadow-card'}>
            {
                (
                    (!!tablesProvider.tables && tablesProvider.tables.length >= 50)  ||
                    tablesProvider.getPageIndex() > 1
                ) &&
                 <TablePagination
                    page={tablesProvider.getPageIndex()}
                    onPreviousPage={() => tablesProvider.onPageChanged(tablesProvider.getPageIndex() - 1)}
                    onNextPage={() => tablesProvider.onPageChanged(tablesProvider.getPageIndex() + 1)}
                    onFirstPage={() => tablesProvider.onPageChanged(1)} />

            }
            <Table striped hover bordered responsive>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Tên</th>
                        <th>Trạng thái tại cửa hàng</th>
                        <th>Trạng thái</th>
                    </tr>
                </thead>
                {
                    <tbody>
                        {
                            tablesProvider.queries?.get('source')?.get('pickingTableEnabled') &&
                            tablesProvider.tables.map((item: TableModel, index: number) => {
                                return <TableItem
                                    key={`menu_item_${item._id}`}
                                    index={index + 1}
                                    page={tablesProvider.queries.get('pageIndex')}
                                    table={item} />
                            })
                        }
                    </tbody>
                }

            </Table>
            {
                (
                    (!!tablesProvider.tables && tablesProvider.tables.length >= 50) ||
                    tablesProvider.getPageIndex() > 1
                ) &&
                 <TablePagination
                    page={tablesProvider.getPageIndex()}
                    onPreviousPage={() => tablesProvider.onPageChanged(tablesProvider.getPageIndex() - 1)}
                    onNextPage={() => tablesProvider.onPageChanged(tablesProvider.getPageIndex() + 1)}
                    onFirstPage={() => tablesProvider.onPageChanged(1)} />

            }
        </div>
    );
}

export default SectionTables