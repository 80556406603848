import React from "react";
import {Dropdown} from 'react-bootstrap'
import {FaEllipsisV} from "react-icons/fa";
import {Colors} from "../../../util/color_utils";
import {useWebsiteBanners} from "../provider";
import {useNavigate, useParams} from "react-router";
import {bannerConfigs} from "../../../util/banner_utils";

interface BannerItemType {
  item: any,
  index: number,
  page: number,
}

const BannerItem = (props: BannerItemType) => {

  const bannersProvider = useWebsiteBanners()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const {websiteId} = useParams()

  const _onEditClicked = () => {
    navigate(`/websites/${websiteId}/banner/${props.item.get('_id')}/edit`)
  }

  const _onDeleteClicked = () => {
    const rs = window.confirm('Bạn có chắc chắn muốn xóa Món này?')
    if (!rs) return
    bannersProvider.deleteBanner(props.item.get('_id'))
  }

  return (
    <tr>
      <td>{(props.page - 1) * 20 + props.index + 1}</td>
      <td>
        {props.item.get('name')}
      </td>
      <td>
        <div className="text-center" style={{ minWidth: 300, maxWidth: 400, height: 'auto' }}>
          {!!props.item.get('desktop_image_url') && (
            <img
              className="d-block rounded img-fluid btn"
              src={props.item.get('desktop_image_url')}
              alt={props.item.get('desktop_image_url')}
              onClick={() => window.open(props.item.get('desktop_image_url'))}
              style={{
                width: '100%',
                maxHeight: 150,
                height: '100%',
                objectFit: 'cover'
              }}
            />
          )}
        </div>
      </td>
      <td className="text-center" style={{ minWidth: 300, maxWidth: 400, height: 'auto' }}>
        <div>
          {!!props.item.get('mobile_image_url') && (
            <img
              className="d-block rounded mx-auto img-fluid btn"
              src={props.item.get('mobile_image_url')}
              alt={props.item.get('mobile_image_url')}
              onClick={() => window.open(props.item.get('mobile_image_url'))}
              style={{
                width: '100%',
                maxHeight: 150,
                height: ' 100%',
                objectFit: 'cover'
              }}
            />
          )}
        </div>
      </td>
      <td>
        <div className="text-wrap">
          <a href={props.item.get('link', '')}>{props.item.get('link', '')}</a>
        </div>
      </td>
      <td>
        <Dropdown>
          <Dropdown.Toggle className={'filter w-100'} variant={'light'}>
            <FaEllipsisV size={13} className={'ms-auto'}/>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={'button'} onClick={_onEditClicked} disabled={loading}>
              Sửa
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as={'button'} onClick={_onDeleteClicked} color={'red'}>
              <span style={{color: Colors.Error}}>Xóa</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  )
}

export default BannerItem;