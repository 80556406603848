export const Colors = {
  primaryColor: '#6B0772',
  primaryBtn: '#57035d',
  Baemin: '#3ac5c9',
  Grab: '#00b14f',
  Shopee: '#ee4d2d',
  Gofood: '#ed2937',
  Befood: '#ffcc02',
  Primary: '#57035d',
  colors: ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7", "#aad684",
    "#fbf87d", "#fcc66e", "#f298b1", "#9876b1", "#F9C475", "#F28A8A", "#9099D1", "#B9DD90", "#F0F0C0",
  ],
  Error: '#cb0909',
  Success: '#029444',
  Inactive: '#bdbdbd',
}