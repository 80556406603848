import {Spinner, Table} from "react-bootstrap";
import React from "react";
import OrderItem from "./order_item";
import TablePagination from "../../../components/table_pagination";
import {PAGE_SIZE} from "../index";
import {useShopeePlatformOrders} from "../provider";

const SectionOrders = () => {
  const provider = useShopeePlatformOrders();

  if (provider.data.size < 1) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        { provider.loading && <Spinner animation="border"/>}
      </div>
    )
  }

  console.log(' page', provider.queries.get('page', 1))

  return (
    <div className={'mt-3'}>
      <div className={'row justify-content-between'}>
        <div className={'col'}>
          {
            (
              (!!provider.data && provider.data.size >= PAGE_SIZE) ||
              provider.queries.get('page', 1) > 1
            ) &&
            <TablePagination
              page={provider.queries.get('page', 1)}
              onFirstPage={provider.onFirstPage}
              onNextPage={provider.onNextPage}
              onPreviousPage={provider.onPreviousPage}/>
          }
        </div>
      </div>
      <Table striped hover responsive bordered className={'shadow-card'}>
        <thead>
        <tr>
          <th>STT</th>
          <th>Đơn hàng</th>
          <th>Giá trị đơn(NET)</th>
          <th>Trạng thái</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          provider.data?.map((order: any, index: number) => {
            return (
              <OrderItem
                key={order.get('id')}
                index={index}
                order={order}
                storeId={provider.config.getIn(['store', '_id'])}
                provider={provider.config.getIn(['provider'])}
                page={provider.queries.get('page')}/>
            )
          })
        }
        </tbody>
      </Table>
      {
        (
          (!!provider.data && provider.data.size >= PAGE_SIZE) ||
          provider.queries.get('page', 1) > 1
        ) &&
        <TablePagination
	        page={provider.queries.get('page', 1)}
	        onFirstPage={provider.onFirstPage}
	        onNextPage={provider.onNextPage}
	        onPreviousPage={provider.onPreviousPage}/>
      }
    </div>
  )
}

export default SectionOrders;