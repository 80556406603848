export enum TableMode{
    occupied = 'occupied',
    unoccupied = 'unoccupied',
}
export enum TableStatus{
    active = 'active',
    inactive = 'inactive',
    deleted = 'deleted',
}

export interface TableModel{
    _id: string;
    name: string;
    status: string;
    tableMode: string;
}