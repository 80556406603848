import React from "react";
import {fromJS} from "immutable";
import {Api} from "../../repository/api";
import {useLocation} from "react-router-dom";
import {useApp} from "../../components/AdminLayout/admin_provider";
import {useParams} from "react-router";

interface WebsiteMenuItemsContextType {
  queries: any,
  data: any,
  website: any,
  loading: boolean,
  onBrandChanged: (brand?: any) => void,
  deleteMenuItem: (itemId: any) => void,
  onNextPage: () => void,
  onPreviousPage: () => void,
  onFirstPage: () => void,
  updateStatusItem: (item: any) => void,
  onItemStoresChanged: (item: any, stores: any) => void
}

let WebsiteMenuItemsContext = React.createContext<WebsiteMenuItemsContextType>(null!);

const useWebsiteMenuItems = () => {
  return React.useContext(WebsiteMenuItemsContext);
};

function WebsiteMenuItemsProvider({ children }: { children: React.ReactNode }) {

  const [queries, setQueries] = React.useState<any>(fromJS({}));
  const [website, setWebsite] = React.useState<any>(fromJS({}))
  const [data, setData] = React.useState<any>(fromJS([]))
  const [meta, setMeta] = React.useState<any>()
  const [loading, setLoading] = React.useState(false);
  const {websiteId} = useParams()
  const app = useApp()

  const _fetchWebsite = async () => {
    try {
      if (websiteId) {
        setLoading(true)
        const res = await Api.brand.fetchBrand(websiteId)
        setLoading(false)
        setWebsite(fromJS(res.data.data))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  const _fetchMenuItems = async() => {
    try {
      const rs: any = await Api.menu.fetchMenuItems({
        page: queries.get('page'),
        majorBrand: websiteId,
        brand: queries.getIn(['brand', '_id']),
        store: queries.getIn(['store', '_id']),
        search: queries.get('search')
      })
      setMeta(rs.data.meta)
      return fromJS(rs.data.data)
    } catch (e: any) {
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return fromJS([])
    }
  }

  const _refresh = async () => {
    if (loading) return
    setLoading(true)

    const list: any = await _fetchMenuItems()
    setData(list)

    setLoading(false)
  }

  const _initData = async () => {
    _initQueries()
  }

  const _initQueries = async () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const onBrandChanged = (brand?: any) => {
    setQueries(
      queries.updateIn(['brand'], () => brand)
        .updateIn(['store'], () => undefined)
        .updateIn(['source'], () => undefined)
        .updateIn(['page'], () => 1)
    )
  }

  const onNextPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value + 1))
  }

  const onPreviousPage = () => {
    setQueries(queries.updateIn(['page'], (value: any) => value - 1))
  }

  const onFirstPage = () => {
    setQueries(queries.updateIn(['page'], () => 1))
  }

  const deleteMenuItem = async (itemId: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === itemId)

    if (itemIndex < 0) return

    const menuItem = data.get(itemIndex)

    setData(data.delete(itemIndex))
    try {
      const rs = await Api.menu.deleteMenuItem(itemId)
      return rs.data.data
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.insert(itemIndex, menuItem))
      return null
    }
  }

  const updateStatusItem = async (item: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === item.get('_id'))

    if (itemIndex < 0) return
    const lastStatus = item.get('status')
    try {
      if (item.get('status') === 'active') {
        setData(data.updateIn([itemIndex, 'status'], () => 'inactive'))
        await Api.menu.inActiveMenuItem(item.get('_id'))
      } else {
        setData(data.updateIn([itemIndex, 'status'], () => 'active'))
        await Api.menu.activeMenuItem(item.get('_id'))
      }
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
        'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      setData(data.updateIn([itemIndex, 'status'], () => lastStatus))
    }
  }

  const onItemStoresChanged = (item: any, stores: any) => {
    const itemIndex = data.findIndex((s: any) => s.get('_id') === item.get('_id'))

    if (itemIndex < 0) return
    setData(data.updateIn([itemIndex, 'stores'], () => stores))
  }

  React.useEffect(() => {
    if (queries.size < 1) return;
    _refresh()
  }, [queries]);

  React.useEffect(() => {
    if (website.get('subBrands') && data.size < 1 && !loading) {
      _initData();
    }
  }, [website.get('subBrands')]);

  React.useEffect(() => {
    _fetchWebsite()
  }, [])

  let value = {
    queries,
    data,
    website,
    loading,
    onBrandChanged,
    deleteMenuItem,
    onNextPage,
    onPreviousPage,
    onFirstPage,
    updateStatusItem,
    onItemStoresChanged
  };

  return <WebsiteMenuItemsContext.Provider value={value}>{children}</WebsiteMenuItemsContext.Provider>;
}

export default WebsiteMenuItemsProvider;
export { useWebsiteMenuItems };