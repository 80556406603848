import {useOrders} from "../provider";
import {Row, Spinner, Table} from "react-bootstrap";
import React from "react";
import OrderItem from "./order_item";
import LabelIconBtn from "../../../components/Button/label_icon_btn";
import {FaPlus} from "react-icons/fa";
import {useNavigate} from "react-router";
import PlatformOrderBtn from "./platform_order_btn";
import TablePagination from "../../../components/table_pagination";
import {numberFormat} from "../../../config";

const SectionOrders = () => {
  const ordersProvider = useOrders();
  const navigate = useNavigate();

  if ((!ordersProvider.data || ordersProvider.data.size < 1) && ordersProvider.loading) {
    return (
      <div className={'mt-3 d-flex justify-content-center'}>
        <Spinner animation="border"/>
      </div>
    )
  }

  const _onCreateClicked = () => {
    navigate('/order/create', {
      state: {
        brand: ordersProvider.queries?.get('brand')?.toJS(),
        store: ordersProvider.queries?.get('store')?.toJS()
      }
    });
  }

  return (
    <div className={'mt-3 shadow-card'}>
      <div className={'row justify-content-between'}>
        <div className={'col-auto'}>
          {
            (
              (!!ordersProvider.data && ordersProvider.data.size >= 20) ||
              ordersProvider.queries?.get('page', 1) > 1
            ) &&
		        <TablePagination
			        page={ordersProvider.queries?.get('page', 1)}
			        onFirstPage={ordersProvider.onFirstPage}
			        onNextPage={ordersProvider.onNextPage}
			        onPreviousPage={ordersProvider.onPreviousPage}/>
          }
        </div>
        <div className={'col-auto'}>
          {
            ordersProvider.platformAccounts.map((provider: any, index: number) => {
              return (
                <PlatformOrderBtn provider={provider} key={`acc_pl_${index}_${provider.get('name')}`}/>
              )
            })
          }

          <LabelIconBtn
            label={'Tạo đơn'}
            icon={<FaPlus size={12}/>}
            onClick={_onCreateClicked}/>
        </div>
      </div>
      <Table striped hover responsive bordered >
        <thead>
        <tr>
          <th>STT</th>
          <th>Mã đơn</th>
          <th>Nguồn</th>
          <th>Net</th>
          <th>Trạng thái</th>
          <th>Thời gian</th>
        </tr>
        </thead>
        <tbody>
        {
          ordersProvider.data.map((order: any, index: number) => {
            return <OrderItem key={order.get('_id')} index={index} order={order} page={ordersProvider.queries.get('page')}/>
          })
        }
        <tr className={'bg-white'}>
          <td colSpan={6}/>
        </tr>
        <tr className={'bg-white'}>
          <td colSpan={3}>

          </td>
          <td>
            <strong className={'text-danger'}>
              {
                ordersProvider.meta?.get('totalNetPrice', 0) > 0 &&
                numberFormat.format(ordersProvider.meta?.get('totalNetPrice', 0))
              }
            </strong>
          </td>
          <td>
            <div>
              <strong className={'text-danger'}>{numberFormat.format(ordersProvider.meta?.get('total', 0) ?? 0)}</strong> <span className={'fw-lighter opacity-50'}>đơn</span>
            </div>
          </td>
          <td></td>
        </tr>
        </tbody>
      </Table>
      <Row>
        <div className={'col'}>
          {
            (
              (!!ordersProvider.data && ordersProvider.data.size >= 20) ||
              ordersProvider.queries.get('page', 1) > 1
            ) &&
            <TablePagination
              page={ordersProvider.queries.get('page', 1)}
              onFirstPage={ordersProvider.onFirstPage}
              onNextPage={ordersProvider.onNextPage}
              onPreviousPage={ordersProvider.onPreviousPage}/>
          }
        </div>
      </Row>
    </div>
  )
}

export default SectionOrders;