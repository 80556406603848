import SectionMenuItemOptionChoice from "./section_menu_item_option_choice";

interface SectionMenuItemOptionType {
  itemKey: any,
  option: any
}

const SectionMenuItemOption = (props: SectionMenuItemOptionType) => {

  return (
    <div>
      <strong className={'opacity-75'}>{props.option.get('name')}:</strong>
      {
        props.option.get('choices')?.map((choice: any) => {
          return (
            <SectionMenuItemOptionChoice
              key={`${props.itemKey}_${props.option.get('_id')}_${choice.get('_id')}`}
              itemKey={props.itemKey}
              optionId={props.option.get('_id')}
              choice={choice}/>
          )
        })
      }
    </div>
  )
}

export default SectionMenuItemOption;