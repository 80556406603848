import { useState } from 'react'
import { Button, Container, Stack, Table } from 'react-bootstrap'
import UserItem from './userItem'
import BackBtn from '../../../components/Button/back_btn'
import { useNavigate } from 'react-router'
import { useUsers } from '../provider'
import { fromJS } from 'immutable'
import UserFormModal from './UserFormModal'
import { HubApi } from '../../../repository/api/hubs'
import NormalModal from '../../../components/Modal/normalModal'
import PageLoading from '../../../components/page_loading'
import Nav from '../../../components/Navbars/Nav'

const UserTable: React.FC = () => {
  const { hub, _refresh, loading } = useUsers()
  const [openAddUser, setOpen] = useState<boolean>(false)
  const [bodyRequest, setBody] = useState<any>(fromJS({}))
  const navigate = useNavigate()
  const _onBackClicked = () => {
    navigate('/hubs')
  }
  const handleOpenAddUser = () => setOpen(true)
  const handleCloseAddUser = () => {
    setOpen(false)
    setBody(fromJS({}))
  }

  const handleAddUser = async () => {
    try {
      if (!bodyRequest.get('role')?.length) {
        window.alert('Vui lòng chọn vai trò!')
        return
      }
      await HubApi.addUserToHub(hub.get('_id'), bodyRequest.toJS())
      handleCloseAddUser()
      window.alert('Thêm thành công!')
      _refresh()
    } catch (e: any) {
      console.log(e)
      window.alert(
        e?.response?.data?.error?.message ??
          'Có lỗi xảy ra trong quá trình xử lý. Vui lòng thử lại sau'
      )
      return
    }
  }

  return (
    <main>
      <Nav title={'Danh sách thành viên'} />
      {loading && <PageLoading />}
      <Container className={'pb-4'}>
        <div>
          <Stack direction="horizontal" className="my-3 justify-content-between">
            <BackBtn onBackClicked={_onBackClicked} label={'Danh sách'} />
            <Button variant="primary" onClick={handleOpenAddUser}>
              Thêm thành viên
            </Button>
          </Stack>
          <Table striped hover responsive bordered className="shadow-card">
            <thead>
              <tr>
                <th>Tên tài khoản</th>
                <th>Số điện thoại</th>
                <th>Vai trò</th>
              </tr>
            </thead>
            <tbody>
              {!!hub.get('users') &&
                hub.get('users').map((user: any, index: number) => {
                  return <UserItem key={index} index={index} user={user} />
                })}
            </tbody>
          </Table>
          <NormalModal
            open={openAddUser}
            options={{
              title: 'Thêm thành viên',
              content: <UserFormModal setBody={setBody} bodyRequest={bodyRequest} />,
              confirmationText: 'Thêm'
            }}
            onCancel={handleCloseAddUser}
            onConfirm={handleAddUser}
          />
        </div>
      </Container>
    </main>
  )
}

export default UserTable
